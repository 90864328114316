import React from 'react';
import { NavLink } from 'react-router-dom';

import logoImage from 'assets/images/logo.png';

import { styles } from './styles';


const Logo = React.memo(() => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <NavLink to="/">
        <img
          src={logoImage}
          alt="logo"
        />
      </NavLink>
    </div>
  );
});


export default Logo;
