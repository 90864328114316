export const PROFILES_NODE = 'profiles';
export const PROFILES_MESSAGES_LIMITS = 'profilesMessagesLimits';

export const CLOUD_FUNCTIONS = {
  PROFILE_SYNC_PRODUCTS: 'profileSyncProducts',
};

export const LIMIT_MESSAGES_PER_TYPES = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};
