import { convertDBImageToFormImage } from '@pro/web-common/utils/image';

import { MESSAGE_TYPE } from '@pro/web-common/core/messages/constants';

import { LIMIT_MESSAGES_PER_TYPES } from './constants';


export const normalizeProfile = (profile) => ({
  ...profile,
  image: profile.image ? convertDBImageToFormImage(profile.image.url) : profile.image,
});

export const normalizeProfiles = (profiles) => profiles?.map(normalizeProfile);

export const getLimitsData = (limits, messageType, messages) => {
  if (!limits) {
    return {};
  }

  const { limitImageMessage, limitQuickMessage } = limits;
  if ((messageType === MESSAGE_TYPE.IMAGE && !limitImageMessage) || (messageType === MESSAGE_TYPE.QUICK && !limitQuickMessage)) {
    return {};
  }

  const { messageLimits, limitMessagePer, productId } = limits;
  const result = {
    messageLimits,
    limitMessagePer,
    availableMessages: Number(messageLimits),
  };

  if (!messages || !messages.length) {
    return result;
  }

  const filteredMessages = messages.filter(({ productsIds }) => productsIds.includes(productId));
  if (!filteredMessages.length) {
    return result;
  }

  const startDate = new Date();
  startDate.setUTCHours(0, 0, 0, 0);
  switch (limitMessagePer) {
    case LIMIT_MESSAGES_PER_TYPES.WEEK:
      startDate.setUTCDate(startDate.getUTCDate() - startDate.getUTCDay() + (startDate.getUTCDay() === 0 ? -6 : 1));
      break;
    case LIMIT_MESSAGES_PER_TYPES.MONTH:
      startDate.setUTCDate(1);
      break;
    default:
      break;
  }

  const sentMessagesLength = filteredMessages.filter(({ createdAt }) => startDate <= (createdAt.toDate ? createdAt.toDate() : createdAt)).length;
  const availableMessages = messageLimits - sentMessagesLength;

  return {
    ...result,
    availableMessages: availableMessages < 0 ? 0 : availableMessages,
  };
};
