const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 668,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    h1: {
      fontSize: '1.5rem',
      lineHeight: 1.33,
      fontWeight: 400,
    },
  },
};

export default theme;
