import firebase from '@pro/web-common/services/firebase';
import 'firebase/auth';


class FirebaseAuthService {
  constructor () {
    this.auth = firebase.auth();
  }

  async refreshToken () {
    return this.auth.currentUser.getIdToken(true);
  }

  signIn (email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  signOut () {
    return this.auth.signOut();
  }

  subscribeToAuthState (cb) {
    return this.auth.onAuthStateChanged(cb);
  }

  verifyEmailBeforeUpdate (email) {
    return this.auth.currentUser.verifyBeforeUpdateEmail(email);
  }

  verifyEmail (actionCode) {
    return this.auth.applyActionCode(actionCode);
  }

  verifyResetPasswordCode (actionCode) {
    return this.auth.verifyPasswordResetCode(actionCode);
  }

  resetPassword (actionCode, newPassword) {
    return this.auth.confirmPasswordReset(actionCode, newPassword);
  }
}


const firebaseFunctions = new FirebaseAuthService();
export default firebaseFunctions;
