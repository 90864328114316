import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
} from '@material-ui/core';

import { withFormik } from 'formik';

import { formValidationSchema } from './config';
import { styles } from './styles';


const ContactUsForm = React.memo(({ fetching, isError, onSubmit, ...formikProps }) => {
  const classes = styles();

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    resetForm,
  } = formikProps;

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                variant="filled"
                fullWidth
                id="name"
                label="Name"
                placeholder="Name"
                name="name"
                InputLabelProps={{ shrink: true }}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name && touched.name}
                className={classes.textfield}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id="email"
                label="Email"
                placeholder="Email"
                name="email"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                variant="filled"
                className={classes.textfield}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <TextField
                variant="filled"
                fullWidth
                id="website"
                label="Website Address"
                placeholder="Website Address"
                name="website"
                value={values.website}
                autoComplete="new-password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.website && touched.website}
                className={classes.textfield}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                variant="filled"
                fullWidth
                id="message"
                label="Message"
                placeholder="Message"
                name="message"
                InputLabelProps={{ shrink: true }}
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.message && touched.message}
                className={`${classes.textfield} ${classes.textarea}`}
                multiline
                rows="8"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Box
            textAlign="right"
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              size="large"
            >
              Send
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
});

ContactUsForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

ContactUsForm.defaultProps = {
  isError: false,
};


export default withFormik({
  mapPropsToValues: () => {
    const values = {
      name: '',
      email: '',
      website: '',
      message: '',
    };

    return values;
  },
  handleSubmit: (data, { props: { onSubmit } }) => onSubmit(data),
  validationSchema: () => formValidationSchema(),
})(ContactUsForm);
