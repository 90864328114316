import FirebaseFunctionsService from '@pro/web-common/services/functions';

import {
  CLOUD_FUNCTIONS,
} from './constants';


const SupportService = {
  sendMessage: (data) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.SEND_MESSAGE, { formData: data }),
};


export default SupportService;
