import DataWithLoader from '@pro/web-common/components/data-with-loader';
import React, { useState, useContext, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Box,
  Button,
} from '@material-ui/core';

import { BrandContext, UserRoleContext } from '@pro/web-common/containers/providers';
import { getSubscriptionPermissions } from '@pro/web-common/core/brand/utils';

import { actions as messagesActions } from '@pro/web-common/core/messages/actions';
import { getTagsList, getFetching, getUpdatingState } from '@pro/web-common/core/messages/selectors';

import SectionTitle from '@pro/web-common/components/section-title';
import ConditionalTooltip from '@pro/web-common/components/conditional-tooltip';
import TagsInput from '@pro/web-common/components/tags-input';

import { isTestVersion } from 'constants/product-config';


const MAX_MESSAGES_TAGS = 6;
const AdminMessagesTags = React.memo(({ fetching, tags: defaultTags, getTags, updateTags, updatingState: { fetching: isUpdating } }) => {
  const { brand } = useContext(BrandContext);
  const { isDemo } = useContext(UserRoleContext);

  const { canAddTags } = useMemo(() => getSubscriptionPermissions(brand), [brand]);

  const [tags, setTags] = useState(defaultTags || []);

  const onSave = useCallback(() => {
    updateTags({ data: tags });
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTags(defaultTags);
  }, [defaultTags]);

  useEffect(() => {
    if (!isDemo) {
      getTags();
    }
  }, [isDemo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SectionTitle title={`Messages tags (max ${MAX_MESSAGES_TAGS})`} />

      <DataWithLoader isLoaded={!fetching}>
        <TagsInput
          tags={tags}
          onUpdate={setTags}
          {...(tags.length === MAX_MESSAGES_TAGS ? {
            placeholder: '',
            inputAttributes: { disabled: true },
          } : {})}
        />

        <Box textAlign="right">
          <Box
            mt={2}
            mb={1}
          >
            <ConditionalTooltip
              title={`${isTestVersion ? 'Upgrade' : 'Subscribe'} to add messages tags`}
              isShown={!canAddTags}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!canAddTags || isUpdating}
                onClick={onSave}
              >
                Save
              </Button>
            </ConditionalTooltip>
          </Box>
        </Box>
      </DataWithLoader>
    </>
  );
});

AdminMessagesTags.propTypes = {
  fetching: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  getTags: PropTypes.func.isRequired,
  updateTags: PropTypes.func.isRequired,
  updatingState: PropTypes.shape({
    fetching: PropTypes.bool,
  }).isRequired,
};

AdminMessagesTags.defaultProps = {
  tags: [],
};

const mapStateToProps = (state) => ({
  fetching: getFetching(state),
  tags: getTagsList(state),
  updatingState: getUpdatingState(state),
});

const mapDispatchToProps = {
  getTags: messagesActions.getTags,
  updateTags: messagesActions.updateTags,
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminMessagesTags);
