import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: () => ({
    marginBottom: theme.spacing(4),
    '& + div': {
      paddingTop: 0,
    },
  }),
  imageBackground: ({ image }) => ({
    backgroundImage: `url(/${image})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),
}));
