import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  storeLink: {
    display: 'inline-block',
    height: '43px',
    marginRight: theme.spacing(1),

    '& > img': {
      maxHeight: '100%',
    },
  },
}));
