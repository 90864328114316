import Artworks1 from 'assets/images/artworks/artwork-1.png';


export const ARTWORKS = [
  {
    id: 1,
    artwork: Artworks1,
    dimensions: {
      width: 964,
      height: 1248,
    },
    qrPositions: [
      {
        x: 50,
        y: 750,
        width: 440,
        height: 440,
      },
      {
        x: 475,
        y: 50,
        width: 440,
        height: 440,
      },
    ],
  },
];
