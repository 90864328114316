import { combineActions, handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';

import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';

import { PRODUCTS_IDS } from 'constants/products';

import { constants } from './actions';


const defaultState = {
  paymentIntentKeys: {},
  form: {
    fetching: false,
    error: null,
  },
};

export default handleActions({
  [constants.PAY_FOR_SETUP]: (state) => (
    update(state, {
      form: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.PAY_FOR_SETUP)]: (state) => (
    update(state, {
      paymentIntentKeys: {
        [PRODUCTS_IDS.SETUP]: { $set: null },
      },
      form: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.PAY_FOR_SETUP)]: (state, { payload: { error } }) => (
    update(state, {
      paymentIntentKeys: {
        [PRODUCTS_IDS.SETUP]: { $set: null },
      },
      form: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [resolved(constants.CREATE_PAYMENT_INTENT)]: (state, { payload: { productId, key } }) => (
    update(state, {
      paymentIntentKeys: {
        [productId]: { $set: key },
      },
    })
  ),
  [rejected(constants.CREATE_PAYMENT_INTENT)]: (state, { payload: { productId, error } }) => (
    update(state, {
      paymentIntentKeys: {
        [productId]: { $set: null },
      },
      form: {
        error: { $set: error },
      },
    })
  ),

  [combineActions(
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
  )]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
