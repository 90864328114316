import React from 'react';
import PropTypes from 'prop-types';

import { Button, Link } from '@material-ui/core';


const SubscriptionActions = React.memo(({ canUserSubscribe, isTrial, isActive, isAwaitCancellation, onCancel, onRenew, onUpgrade, disabled, isIncomplete }) => (
  <>
    {isTrial ? (
      <Button
        variant="contained"
        color="primary"
        onClick={onUpgrade}
        disabled={disabled}
      >
        Upgrade
      </Button>
    ) : (
      <>
        {!isIncomplete && !isActive && canUserSubscribe && (
          <Button
            variant="contained"
            color="primary"
            onClick={onRenew}
            disabled={disabled}
          >
            Renew
          </Button>
        )}

        {!isIncomplete && isAwaitCancellation && (
          <Button
            variant="contained"
            color="primary"
            onClick={onRenew}
            disabled={disabled}
          >
            Renew payments
          </Button>
        )}

        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        {!isIncomplete && (isActive && !isAwaitCancellation) && (
          <Link
            component="button"
            variant="body2"
            onClick={onCancel}
            disabled={disabled}
          >
            Cancel
          </Link>
        )}
        {/* eslint-enable jsx-a11y/anchor-is-valid */}
      </>
    )}
  </>
));

SubscriptionActions.propTypes = {
  canUserSubscribe: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isAwaitCancellation: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRenew: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isIncomplete: PropTypes.bool.isRequired,
};

SubscriptionActions.defaultProps = {};


export default SubscriptionActions;
