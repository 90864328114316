import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';

import { constants as adminConstants } from '@pro/web-common/core/admin/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';

import { constants } from './actions';


const defaultState = {
  list: {},
  fetching: false,
};

export default handleActions({
  [constants.GET_STATISTICS]: (state) => (
    update(state, {
      fetching: { $set: true },
    })
  ),
  [resolved(constants.GET_STATISTICS)]: (state, { payload: { productId, data } }) => (
    update(state, {
      fetching: { $set: false },
      list: {
        [productId]: { $set: data },
      },
    })
  ),
  [rejected(constants.GET_STATISTICS)]: (state) => (
    update(state, {
      fetching: { $set: false },
    })
  ),

  [combineActions(
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
  )]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
