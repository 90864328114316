import development from './dev';
import production from './prod';


const config = {
  development,
  production,
};

export default config[process.env.REACT_APP_CONFIG_ENV || 'development'];
