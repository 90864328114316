import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';

import { Container, Card, Box } from '@material-ui/core';

import { actions as userActions } from '@pro/web-common/core/user/actions';
import { getVerifyEmailState, getResetPasswordState, getIsSignIn } from '@pro/web-common/core/user/selectors';

import ResetPassword from '@pro/web-common/components/reset-password';
import VerifyEmail from '@pro/web-common/components/verify-email';

import { MODES } from './constants';


const AuthActionsPage = React.memo(({ verifyEmailState, verifyEmail, resetPasswordState, resetPassword, confirmResetPassword, signOut, isSignIn }) => {
  const params = new URLSearchParams(useLocation().search);
  const mode = params.get('mode');
  const actionCode = params.get('oobCode');
  const isInviteLink = params.get('inviteLink') === 'true';

  if (!Object.values(MODES).includes(mode) || !actionCode) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    if (isSignIn) {
      signOut();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !isSignIn && (
    <Container maxWidth="xs">
      <Card>
        <Box p={2}>
          {mode === MODES.VERIFY_EMAIL && (
            <VerifyEmail
              verifyEmail={verifyEmail}
              actionCode={actionCode}
              state={verifyEmailState}
            />
          )}

          {mode === MODES.RESET_PASSWORD && (
            <ResetPassword
              resetPassword={resetPassword}
              confirmResetPassword={confirmResetPassword}
              actionCode={actionCode}
              state={resetPasswordState}
              title={isInviteLink ? 'Setup password' : 'Reset password'}
              submitLabel={isInviteLink ? 'Setup password and Log in' : 'Reset password and Log in'}
            />
          )}
        </Box>
      </Card>
    </Container>
  );
});

AuthActionsPage.propTypes = {
  verifyEmailState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
    isVerified: PropTypes.bool.isRequired,
  }).isRequired,
  resetPasswordState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
    isActionCodeValid: PropTypes.bool.isRequired,
  }).isRequired,
  verifyEmail: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  confirmResetPassword: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  isSignIn: PropTypes.bool.isRequired,
};


const mapStateToProps = (state) => ({
  isSignIn: getIsSignIn(state),
  verifyEmailState: getVerifyEmailState(state),
  resetPasswordState: getResetPasswordState(state),
});

const mapDispatchToProps = {
  verifyEmail: userActions.verifyEmail,
  resetPassword: userActions.resetPassword,
  confirmResetPassword: userActions.confirmResetPassword,
  signOut: userActions.signOut,
};


export default connect(mapStateToProps, mapDispatchToProps)(AuthActionsPage);
