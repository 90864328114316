import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import ResetPasswordForm from '@pro/web-common/components/reset-password-form';


const ResetPassword = React.memo(({ resetPassword, confirmResetPassword, actionCode, state: { fetching, error, isActionCodeValid }, title, submitLabel }) => {
  useEffect(() => {
    resetPassword({ actionCode });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box mb={4}>
        <Typography
          variant="h6"
        >
          {title}
        </Typography>
      </Box>

      {fetching && !isActionCodeValid && (
        <>
          <Typography>We are processing your info.</Typography>

          <Box
            mt={2}
            mb={2}
          >
            <LinearProgress />
          </Box>
        </>
      )}
      {
        isActionCodeValid && (
          <ResetPasswordForm
            fetching={fetching}
            isError={!!error}
            onSubmit={confirmResetPassword}
            submitLabel={submitLabel}
          />
        )
      }
      {error && (
        <Typography color="error">{error}</Typography>
      )}
    </>
  );
});

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  confirmResetPassword: PropTypes.func.isRequired,
  actionCode: PropTypes.string.isRequired,
  state: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
    isActionCodeValid: PropTypes.bool.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
};

ResetPassword.defaultProps = {};


export default ResetPassword;
