import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AlertMessage from '@pro/web-common/containers/alert-message';

import { selectCurrentModal, selectParams } from '@pro/web-common/core/modal-conductor/selectors';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';

import {
  ERROR_ALERT,
  SUCCESS_ALERT,
} from './constants';


const ModalConductor = memo((props) => {
  let modal;

  switch (props.modal) {
    case SUCCESS_ALERT:
      modal = (
        <AlertMessage
          {...props}
          params={{
            title: 'Thanks',
            ...props.params,
          }}
        />
      );
      break;
    case ERROR_ALERT:
      modal = (
        <AlertMessage
          {...props}
          params={{
            title: 'Ooops! Something went wrong.',
            ...props.params,
          }}
        />
      );
      break;
    default:
      return null;
  }

  return modal;
});


ModalConductor.propTypes = {
  modal: PropTypes.string,
  resetModal: PropTypes.func.isRequired,
  params: PropTypes.shape({}),
};

ModalConductor.defaultProps = {
  modal: null,
  params: {},
};

const mapStateToProps = (state) => ({
  modal: selectCurrentModal(state),
  params: selectParams(state),
});

const mapDispatchToProps = {
  resetModal: modalConductorActions.resetModal,
};


export default connect(mapStateToProps, mapDispatchToProps)(ModalConductor);
