import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  FormControl,
} from '@material-ui/core';


import Select from '@pro/web-common/components/select';
import ConditionalTooltip from '@pro/web-common/components/conditional-tooltip';

import { languageOptions } from './config';
import { styles } from './styles';


const AddTranslation = ({ language, onTranslate, onChange, disabled }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <FormControl
        variant="outlined"
        className={classes.formControl}
      >
        <Select
          id="languageId"
          name="languageId"
          label="Select version language"
          options={languageOptions}
          value={language}
          placeholder="Select language"
          onChange={({ target: { value } }) => onChange(value)}
        />
      </FormControl>

      <ConditionalTooltip
        title="You need a live subscription to use our translation feature and add language versions"
        isShown={disabled}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onTranslate}
          disabled={!language || disabled}
        >
          Translate automatically
        </Button>
      </ConditionalTooltip>
    </Box>
  );
};

AddTranslation.propTypes = {
  language: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onTranslate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AddTranslation.defaultProps = {
  language: null,
  disabled: false,
};


export default AddTranslation;
