import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Link,
} from '@material-ui/core';

import { styles } from './styles';


const MessageModal = React.memo(({ isOpen, onClose, message, title, errors, errorsTitle, link }) => {
  const classes = styles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>

      <DialogContent>
        {message && (
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        )}

        {errorsTitle && (<Typography>{errorsTitle}</Typography>)}
        {errors && errors.map((error) => (
          <Typography
            key={error}
            color="error"
          >
            {error}
          </Typography>
        ))}

        {link && (
          <Typography className={classes.linkContainer}>
            <Link
              href={link}
              target="_blank"
            >
              {link}
            </Link>
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          onClick={onClose}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
});

MessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorsTitle: PropTypes.string,
  link: PropTypes.string,
};

MessageModal.defaultProps = {
  message: null,
  title: null,
  errors: null,
  errorsTitle: null,
  link: null,
};


export default MessageModal;
