import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'GET_MANAGERS',
  'CREATE_MANAGER',
  'UPDATE_MANAGER',
  'DELETE_MANAGER',
], 'MANAGERS');

export const actions = createActions({
  [constants.GET_MANAGERS]: null,
  [constants.CREATE_MANAGER]: ({ data }) => ({ data }),
  [constants.UPDATE_MANAGER]: ({ id, data }) => ({ id, data }),
  [constants.DELETE_MANAGER]: ({ id }) => ({ id }),
}, {
  prefix: 'MANAGERS',
});


export default actions;
