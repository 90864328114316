export const ICONS_SET = [
  {
    id: 'AccessibilityNew',
    iconName: 'icon-accessibility_new-24px',
  },
  {
    id: 'AccountCircle',
    iconName: 'icon-account_circle-24px',
  },
  {
    id: 'Announcement',
    iconName: 'icon-announcement-24px',
  },
  {
    id: 'Assignment',
    iconName: 'icon-assignment-24px',
  },
  {
    id: 'Bookmarks',
    iconName: 'icon-bookmarks-24px',
  },
  {
    id: 'BookmarkBorder',
    iconName: 'icon-bookmark_border-24px',
  },
  {
    id: 'ChangeHistory',
    iconName: 'icon-change_history-24px',
  },
  {
    id: 'ContactSupport',
    iconName: 'icon-contact_support-24px',
  },
  {
    id: 'DoneOutline',
    iconName: 'icon-done_outline-24px',
  },
  {
    id: 'Feedback',
    iconName: 'icon-feedback-24px',
  },
  {
    id: 'BugReport',
    iconName: 'icon-bug_report-24px',
  },
  {
    id: 'Build',
    iconName: 'icon-build-24px',
  },
  {
    id: 'Reorder',
    iconName: 'icon-reorder-24px',
  },
  {
    id: 'Schedule',
    iconName: 'icon-schedule-24px',
  },
  {
    id: 'Search',
    iconName: 'icon-search-24px',
  },
  {
    id: 'Grade',
    iconName: 'icon-grade-24px',
  },
  {
    id: 'Language',
    iconName: 'icon-language-24px',
  },
  {
    id: 'SettingsApplications',
    iconName: 'icon-settings_applications-24px',
  },
  {
    id: 'Visibility',
    iconName: 'icon-visibility-24px',
  },
  {
    id: 'Warning',
    iconName: 'icon-warning-24px',
  },
  {
    id: 'Error',
    iconName: 'icon-error-24px',
  },
  {
    id: 'PlayArrow',
    iconName: 'icon-play_arrow-24px',
  },
  {
    id: 'Videocam',
    iconName: 'icon-videocam-24px',
  },
  {
    id: 'Chat',
    iconName: 'icon-chat-24px',
  },
  {
    id: 'Comment',
    iconName: 'icon-comment-24px',
  },
  {
    id: 'Call',
    iconName: 'icon-call-24px',
  },
  {
    id: 'Email',
    iconName: 'icon-email-24px',
  },
  {
    id: 'Message',
    iconName: 'icon-message-24px',
  },
  {
    id: 'SentimentSatisfiedAlt',
    iconName: 'icon-sentiment_satisfied_alt-24px',
  },
  {
    id: 'AddCircleOutline',
    iconName: 'icon-add_circle_outline-24px',
  },
  {
    id: 'Clear',
    iconName: 'icon-clear-24px',
  },
  {
    id: 'HowToReg',
    iconName: 'icon-how_to_reg-24px',
  },
  {
    id: 'RemoveCircleOutline',
    iconName: 'icon-remove_circle_outline-24px',
  },
  {
    id: 'WifiTethering',
    iconName: 'icon-wifi_tethering-24px',
  },
  {
    id: 'AddComment',
    iconName: 'icon-add_comment-24px',
  },
  {
    id: 'ModeComment',
    iconName: 'icon-mode_comment-24px',
  },
  {
    id: 'Cloud',
    iconName: 'icon-cloud-24px',
  },
  {
    id: 'RadioButtonChecked',
    iconName: 'icon-radio_button_checked-24px',
  },
  {
    id: 'RadioButtonUnchecked',
    iconName: 'icon-radio_button_unchecked-24px',
  },
  {
    id: 'CheckBox',
    iconName: 'icon-check_box-24px',
  },
  {
    id: 'LocalLibrary',
    iconName: 'icon-local_library-24px',
  },
  {
    id: 'LocalOffer',
    iconName: 'icon-local_offer-24px',
  },
  {
    id: 'Map',
    iconName: 'icon-map-24px',
  },
  {
    id: 'TripOrigin',
    iconName: 'icon-trip_origin-24px',
  },
  {
    id: 'Home',
    iconName: 'icon-home-24px',
  },
  {
    id: 'EmojiFoodBeverage',
    iconName: 'icon-emoji_food_beverage-24px',
  },
  {
    id: 'EmojiTransportation',
    iconName: 'icon-emoji_transportation-24px',
  },
  {
    id: 'KingBed',
    iconName: 'icon-king_bed-24px',
  },
  {
    id: 'PeopleAlt',
    iconName: 'icon-people_alt-24px',
  },
  {
    id: 'School',
    iconName: 'icon-school-24px',
  },
  {
    id: 'Share',
    iconName: 'icon-share-24px',
  },
  {
    id: 'CalendarToday',
    iconName: 'icon-calendar_today-24px',
  },
  {
    id: 'CardGiftcard',
    iconName: 'icon-card_giftcard-24px',
  },
  {
    id: 'Class',
    iconName: 'icon-class-24px',
  },
  {
    id: 'Commute',
    iconName: 'icon-commute-24px',
  },
  {
    id: 'DateRange',
    iconName: 'icon-date_range-24px',
  },
  {
    id: 'Event',
    iconName: 'icon-event-24px',
  },
  {
    id: 'Face',
    iconName: 'icon-face-24px',
  },
  {
    id: 'HelpOutline',
    iconName: 'icon-help_outline-24px',
  },
  {
    id: 'Info',
    iconName: 'icon-info-24px',
  },
  {
    id: 'Pageview',
    iconName: 'icon-pageview-24px',
  },
  {
    id: 'PanTool',
    iconName: 'icon-pan_tool-24px',
  },
  {
    id: 'Receipt',
    iconName: 'icon-receipt-24px',
  },
  {
    id: 'Room',
    iconName: 'icon-room-24px',
  },
  {
    id: 'SpeakerNotes',
    iconName: 'icon-speaker_notes-24px',
  },
  {
    id: 'Subject',
    iconName: 'icon-subject-24px',
  },
  {
    id: 'WorkOutline',
    iconName: 'icon-work_outline-24px',
  },
  {
    id: 'ImportContacts',
    iconName: 'icon-import_contacts-24px',
  },
  {
    id: 'Forum',
    iconName: 'icon-forum-24px',
  },
  {
    id: 'ListAlt',
    iconName: 'icon-list_alt-24px',
  },
  {
    id: 'Textsms',
    iconName: 'icon-textsms-24px',
  },
  {
    id: 'FileCopy',
    iconName: 'icon-file_copy-24px',
  },
  {
    id: 'AirplanemodeActive',
    iconName: 'icon-airplanemode_active-24px',
  },
  {
    id: 'BubbleChart',
    iconName: 'icon-bubble_chart-24px',
  },
  {
    id: 'Notes',
    iconName: 'icon-notes-24px',
  },
  {
    id: 'Computer',
    iconName: 'icon-computer-24px',
  },
  {
    id: 'Adjust',
    iconName: 'icon-adjust-24px',
  },
  {
    id: 'Collections',
    iconName: 'icon-collections-24px',
  },
  {
    id: 'DirectionsBike',
    iconName: 'icon-directions_bike-24px',
  },
  {
    id: 'DirectionsBus',
    iconName: 'icon-directions_bus-24px',
  },
  {
    id: 'DirectionsCar',
    iconName: 'icon-directions_car-24px',
  },
  {
    id: 'Flight',
    iconName: 'icon-flight-24px',
  },
  {
    id: 'LocalBar',
    iconName: 'icon-local_bar-24px',
  },
  {
    id: 'LocalCafe',
    iconName: 'icon-local_cafe-24px',
  },
  {
    id: 'LocalAtm',
    iconName: 'icon-local_atm-24px',
  },
  {
    id: 'LocalDining',
    iconName: 'icon-local_dining-24px',
  },
  {
    id: 'Apps',
    iconName: 'icon-apps-24px',
  },
  {
    id: 'Cancel',
    iconName: 'icon-cancel-24px',
  },
  {
    id: 'DriveEta',
    iconName: 'icon-drive_eta-24px',
  },
  {
    id: 'AcUnit',
    iconName: 'icon-ac_unit-24px',
  },
  {
    id: 'AllInclusive',
    iconName: 'icon-all_inclusive-24px',
  },
  {
    id: 'Percent',
    iconName: 'icon-percent-24px',
  },
  {
    id: 'Coupon',
    iconName: 'icon-coupon-24px',
  },
  {
    id: 'Feefo',
    iconName: 'icon-feefo-24px',
  },
  {
    id: 'Here',
    iconName: 'icon-here-24px',
  },
  {
    id: 'Tripadvisor',
    iconName: 'icon-tripadvisor-24px',
  },
  {
    id: 'Pinterest',
    iconName: 'icon-pinterest-24px',
  },
  {
    id: 'Linkedin',
    iconName: 'icon-linkedin-24px',
  },
  {
    id: 'Twitter',
    iconName: 'icon-twitter-24px',
  },
  {
    id: 'Facebook',
    iconName: 'icon-facebook-24px',
  },
  {
    id: 'Guestbook',
    iconName: 'icon-guestbook-24px',
  },
  {
    id: 'Callnow',
    iconName: 'icon-callnow-24px',
  },
  {
    id: 'Instagram',
    iconName: 'icon-insta-24px',
  },
  {
    id: 'Images',
    iconName: 'icon-images',
  },
  {
    id: 'Book1',
    iconName: 'icon-book1',
  },
  {
    id: 'Books1',
    iconName: 'icon-books1',
  },
  {
    id: 'Stack',
    iconName: 'icon-stack',
  },
  {
    id: 'Location2',
    iconName: 'icon-location2',
  },
  {
    id: 'Map2',
    iconName: 'icon-map',
  },
  {
    id: 'UserPlus',
    iconName: 'icon-user-plus',
  },
  {
    id: 'Key2',
    iconName: 'icon-key2',
  },
  {
    id: 'Lock',
    iconName: 'icon-lock',
  },
  {
    id: 'Warning2',
    iconName: 'icon-warning',
  },
  {
    id: 'Notification',
    iconName: 'icon-notification',
  },
  {
    id: 'CheckboxUnchecked',
    iconName: 'icon-checkbox-unchecked',
  },
  {
    id: 'RadioChecked',
    iconName: 'icon-radio-checked',
  },
  {
    id: 'Facebook2',
    iconName: 'icon-facebook2',
  },
  {
    id: 'Whatsapp',
    iconName: 'icon-whatsapp',
  },
  {
    id: 'Youtube',
    iconName: 'icon-youtube',
  },
  {
    id: 'Vimeo2',
    iconName: 'icon-vimeo2',
  },
  {
    id: 'Onedrive',
    iconName: 'icon-onedrive',
  },
  {
    id: 'Linkedin2',
    iconName: 'icon-linkedin',
  },
  {
    id: 'Stackoverflow',
    iconName: 'icon-stackoverflow',
  },
  {
    id: 'Pinterest2',
    iconName: 'icon-pinterest',
  },
  {
    id: 'FilePdf',
    iconName: 'icon-file-pdf',
  },
  {
    id: 'Book',
    iconName: 'icon-book',
  },
  {
    id: 'Books',
    iconName: 'icon-books',
  },
  {
    id: 'AddressBook',
    iconName: 'icon-address-book',
  },
  {
    id: 'Clock',
    iconName: 'icon-clock',
  },
  {
    id: 'Calendar',
    iconName: 'icon-calendar',
  },
  {
    id: 'Bubbles3',
    iconName: 'icon-bubbles3',
  },
  {
    id: 'UserTie',
    iconName: 'icon-user-tie',
  },
  {
    id: 'Key',
    iconName: 'icon-key',
  },
  {
    id: 'Cog',
    iconName: 'icon-cog',
  },
  {
    id: 'Sphere',
    iconName: 'icon-sphere',
  },
  {
    id: 'Link',
    iconName: 'icon-link',
  },
  {
    id: 'StarEmpty',
    iconName: 'icon-star-empty',
  },
  {
    id: 'Hangouts',
    iconName: 'icon-hangouts',
  },
];
