import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'WATCH_BRAND',
  'UPDATE_BRAND',
  'UPDATE_ENTERPRISE_HOME_DATA',
  'GET_BRAND',
], 'BRAND');

export const actions = createActions({
  [constants.WATCH_BRAND]: null,
  [constants.UPDATE_BRAND]: ({ data }) => ({ data }),
  [constants.UPDATE_ENTERPRISE_HOME_DATA]: ({ image, text }) => ({ image, text }),
  [constants.GET_BRAND]: ({ brandId }) => ({ brandId }),
}, {
  prefix: 'BRAND',
});


export default actions;
