import FirebaseDatabaseService from '@pro/web-common/services/database';
import FirebaseFunctionsService from '@pro/web-common/services/functions';


import {
  PAYMENTS_NODE,
  CLOUD_FUNCTIONS,
} from './constants';


const PaymentService = {
  createPaymentIntent: (productId, currency) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.CREATE_PAYMENT_INTENT, { productId, currency }),
  addPayment: (id, data) => FirebaseDatabaseService.addData(
    `${PAYMENTS_NODE}/${id}`,
    FirebaseDatabaseService.REF_TYPES.DOC,
    false,
    data,
  ),
};


export default PaymentService;
