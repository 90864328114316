import { createSelector } from 'reselect';


const getLocalState = (state) => state.admin;

export const getCreateAdminState = createSelector(
  getLocalState,
  ({ createAdmin }) => createAdmin,
);

export const getCreateDemoState = createSelector(
  getLocalState,
  ({ createDemoUser }) => createDemoUser,
);

export const getSignInAsUserState = createSelector(
  getLocalState,
  ({ signInAsUser }) => signInAsUser,
);

export const getSignInAsUserInfo = createSelector(
  getLocalState,
  ({ signInAsUserInfo }) => signInAsUserInfo,
);

export const getSignOutFromUserState = createSelector(
  getLocalState,
  ({ signOutFromUser }) => signOutFromUser,
);

export const getUsers = createSelector(
  getLocalState,
  ({ users }) => users,
);
