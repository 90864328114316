import React from 'react';

import { SortableHandle } from 'react-sortable-hoc';

import { Box } from '@material-ui/core';
import { UnfoldMore as UnfoldMoreIcon } from '@material-ui/icons';

import { styles } from './styles';


const SortableListItemHandle = React.memo(() => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <UnfoldMoreIcon />
    </Box>
  );
});


export default SortableHandle(SortableListItemHandle);
