import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'WATCH_PRODUCTS',
  'WATCH_PRODUCTS_ORDER',
  'CREATE_PRODUCT',
  'UPDATE_PRODUCT',
  'DELETE_PRODUCT',
  'UNLOCK_PRODUCT',
  'COPY_PRODUCT',
  'SAVE_PRODUCTS_ORDER',
], 'PRODUCT');

export const actions = createActions({
  [constants.WATCH_PRODUCTS]: null,
  [constants.WATCH_PRODUCTS_ORDER]: null,
  [constants.CREATE_PRODUCT]: ({ productName, address, pushMessagesRadius, pages, tags, productId }) => ({ productName, address, pushMessagesRadius, pages, tags, productId }),
  [constants.DELETE_PRODUCT]: ({ id }) => ({ id }),
  [constants.UPDATE_PRODUCT]: ({ id, productName, address, pushMessagesRadius, tags, pages, isVisible, showSubmitSuccess }) => ({ id, productName, address, pushMessagesRadius, tags, pages, isVisible, showSubmitSuccess }),
  [constants.UNLOCK_PRODUCT]: ({ id }) => ({ id }),
  [constants.COPY_PRODUCT]: ({ id }) => ({ id }),
  [constants.SAVE_PRODUCTS_ORDER]: ({ order }) => ({ order }),
}, {
  prefix: 'PRODUCT',
});


export default actions;
