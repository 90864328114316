import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

import { findElementByProp } from '@pro/web-common/utils';


const getLocalState = (state) => state.product;

export const getProductsList = createSelector(
  getLocalState,
  ({ list }) => list,
);

export const getProductsOrder = createSelector(
  getLocalState,
  ({ order }) => order,
);

export const getActiveProductsList = createSelector(
  getProductsList,
  (list) => list.filter(({ isLimited }) => !isLimited),
);

export const getProduct = memoize((id) => createSelector(
  getProductsList,
  (list) => findElementByProp(list, 'id', id),
));

export const getProductUpdatingState = createSelector(
  getLocalState,
  ({ updatingState: { fetching, error } }) => ({ fetching, error }),
);

export const getProductCreatingState = createSelector(
  getLocalState,
  ({ creatingState: { fetching, error } }) => ({ fetching, error }),
);
