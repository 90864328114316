export const CURRENCY_LIST = {
  EUR: {
    sign: '€',
    vat: '20%',
  },
  GBP: {
    sign: '£',
    vat: '20%',
  },
  USD: {
    sign: '$',
  },
};

export const DEFAULT_CURRENCY_ID = 'GBP';
