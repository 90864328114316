import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  contentContainer: () => ({
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
  }),
  contentContainerHome: () => ({
    paddingTop: theme.spacing(7),
    paddingBottom: 0,
  }),
  contentContainerDemo: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(7),
    },
  },
}));
