import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';

import { constants as subscriptionsConstants } from '@pro/web-common/core/subscriptions/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';
import { findIndexByProp } from '@pro/web-common/utils';

import { constants } from './actions';


const defaultState = {
  info: {
    uid: null,
    role: null,
    brandId: null,
    fetching: false,
    error: null,
  },
  signUp: {
    fetching: false,
    error: null,
  },
  signIn: {
    fetching: false,
    error: null,
  },
  sendResetPasswordLink: {
    fetching: false,
    error: null,
  },
  verifyEmail: {
    fetching: false,
    error: null,
    isVerified: false,
  },
  resetPassword: {
    fetching: false,
    error: null,
    isActionCodeValid: false,
  },
  updateEmail: {
    fetching: false,
  },
};

export default handleActions({
  [constants.SIGN_IN]: (state) => (
    update(state, {
      signIn: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.SIGN_IN)]: (state, { payload: { uid, role, brandId } }) => (
    update(state, {
      signIn: {
        fetching: { $set: false },
      },
      info: {
        uid: { $set: uid },
        role: { $set: role },
        brandId: { $set: brandId },
      },
    })
  ),
  [rejected(constants.SIGN_IN)]: (state, { payload: { error } }) => (
    update(state, {
      signIn: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [resolved(adminConstants.SIGN_IN_AS_USER)]: (state, { payload: { data: { brandId } } }) => (
    update(state, {
      info: {
        brandId: { $set: brandId },
      },
    })
  ),

  [constants.SIGN_UP]: (state) => (
    update(state, {
      signUp: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.SIGN_UP)]: (state) => (
    update(state, {
      signUp: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.SIGN_UP)]: (state, { payload: { error } }) => (
    update(state, {
      signUp: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.VERIFY_EMAIL]: (state) => (
    update(state, {
      verifyEmail: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.VERIFY_EMAIL)]: (state) => (
    update(state, {
      verifyEmail: {
        fetching: { $set: false },
        isVerified: { $set: true },
      },
    })
  ),
  [rejected(constants.VERIFY_EMAIL)]: (state, { payload: { error } }) => (
    update(state, {
      verifyEmail: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.UPDATE_EMAIL]: (state) => (
    update(state, {
      updateEmail: {
        fetching: { $set: true },
      },
    })
  ),
  [resolved(constants.UPDATE_EMAIL)]: (state) => (
    update(state, {
      updateEmail: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.UPDATE_EMAIL)]: (state) => (
    update(state, {
      updateEmail: {
        fetching: { $set: false },
      },
    })
  ),

  [combineActions(
    constants.RESET_PASSWORD,
    constants.CONFIRM_RESET_PASSWORD,
  )]: (state) => (
    update(state, {
      resetPassword: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.RESET_PASSWORD)]: (state) => (
    update(state, {
      resetPassword: {
        fetching: { $set: false },
        isActionCodeValid: { $set: true },
      },
    })
  ),
  [rejected(constants.RESET_PASSWORD)]: (state, { payload: { error } }) => (
    update(state, {
      resetPassword: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.SEND_RESET_PASSWORD_LINK]: (state) => (
    update(state, {
      sendResetPasswordLink: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.SEND_RESET_PASSWORD_LINK)]: (state) => (
    update(state, {
      sendResetPasswordLink: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.SEND_RESET_PASSWORD_LINK)]: (state, { payload: { error } }) => (
    update(state, {
      sendResetPasswordLink: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [resolved(constants.WATCH_USER)]: (state, { payload: { data: { id, ...data } } }) => (
    update(state, {
      info: {
        $merge: { ...data, fetching: false },
      },
    })
  ),

  [resolved(subscriptionsConstants.ADD_SUBSCRIPTION)]: (state) => (
    update(state, {
      info: {
        hasPaymentMethods: { $set: true },
      },
    })
  ),

  [combineActions(
    subscriptionsConstants.ADD_PAYMENT_METHOD,
    subscriptionsConstants.REMOVE_PAYMENT_METHOD,
  )]: (state) => (
    update(state, {
      info: {
        fetching: { $set: true },
      },
    })
  ),
  [resolved(subscriptionsConstants.ADD_PAYMENT_METHOD)]: (state, { payload: { id, brand, last4 } }) => (
    update(state, {
      info: {
        paymentMethods: {
          $push: [{ id, brand, last4 }],
        },
        fetching: { $set: false },
      },
    })
  ),
  [resolved(subscriptionsConstants.REMOVE_PAYMENT_METHOD)]: (state, { payload: { id } }) => {
    const index = state.info.paymentMethods ? findIndexByProp(state.info.paymentMethods, 'id', id) : -1;
    if (index === -1) {
      return state;
    }

    return (
      update(state, {
        info: {
          paymentMethods: {
            $splice: [[index, 1]],
          },
          fetching: { $set: false },
        },
      })
    );
  },
  [combineActions(
    rejected(subscriptionsConstants.ADD_PAYMENT_METHOD),
    rejected(subscriptionsConstants.REMOVE_PAYMENT_METHOD),
  )]: (state) => (
    update(state, {
      info: {
        fetching: { $set: false },
      },
    })
  ),

  [resolved(constants.SIGN_OUT)]: (state) => (
    update(state, { $set: defaultState })
  ),

  [resolved(adminConstants.SIGN_OUT_FROM_USER)]: (state) => (
    update(defaultState, {
      info: {
        uid: { $set: state.info.uid },
        role: { $set: state.info.role },
      },
    })
  ),
}, defaultState);
