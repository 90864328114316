import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { move } from 'ramda';

import { Box, Button } from '@material-ui/core';

import history from '@pro/web-common/core/history';
import { findElementByProp } from '@pro/web-common/utils';

import ProductListingItem from '@pro/web-common/components/product-listing-item';
import PaginatedList from '@pro/web-common/components/paginated-list';
import SortableList from '@pro/web-common/components/sortable-list';
import SortableListItem from '@pro/web-common/components/sortable-list-item';
import EnterpriseImageEditor from '@pro/web-common/components/enterprise-image-editor';
import { BrandContext } from '@pro/web-common/containers/providers';

import { PRODUCTS_LISTING_NO_DATA_TEXT } from 'content/texts';

import { orderProductsList } from './utils';
import { HOME_PAGE_ID } from './constants';


const ITEMS_PER_PAGE = 5;
const ProductsListing = React.memo(({ products, order, onProductDelete, underlineLast, onProductUnlock, onVisibilityUpdate, isUpdating, isBrandUpdating, withProductCopy, onProductCopy, withDragging, onSaveOrder, onEnterpriseDataUpdate }) => {
  const { brand } = useContext(BrandContext);
  const { enterpriseHomeImage, enterpriseHomeText } = brand || {};

  const [sortableProducts, setSortableProducts] = useState([]);
  const orderWithHome = useMemo(() => {
    const productsLength = withDragging ? products.length + 1 : products.length;

    if (!order || order.length === 0 || productsLength < order.length) {
      return products.map(({ id }) => id).concat([HOME_PAGE_ID]);
    }

    if (productsLength !== order.length) {
      const productsIds = products.map(({ id }) => id);

      // New product was added, but order was not yet updated in DB
      if (productsLength > order.length) {
        const newProductIds = productsIds.filter((id) => order.indexOf(id) === -1);
        return order.concat(newProductIds);
      }

      // Product was deleted, but order was not yet updated in DB
      return order.filter((id) => id !== 'home' && productsIds.indexOf(id) !== -1);
    }

    return order;
  }, [order, products, withDragging]);

  const getItem = useCallback((id, fetching, props, isLast) => (
    <ProductListingItem
      key={id}
      onEdit={() => history.push(`/edit-product/${id}`)}
      onDelete={onProductDelete ? () => onProductDelete({ id }) : null}
      onUnlock={onProductUnlock ? () => onProductUnlock({ id }) : null}
      onCopy={onProductCopy ? () => onProductCopy({ id }) : null}
      fetching={fetching || isUpdating}
      isLast={isLast}
      underlineLast={underlineLast}
      withProductCopy={withProductCopy}
      onVisibilityUpdate={(isVisible) => onVisibilityUpdate({
        id,
        ...props,
        isVisible,
      })}
      {...props}
    />
  ), []);

  const getHomeItem = useCallback((id) => (
    <EnterpriseImageEditor
      key={id}
      defaultImage={enterpriseHomeImage}
      defaultText={enterpriseHomeText}
      onSubmit={onEnterpriseDataUpdate}
      isSubmitting={isBrandUpdating}
    />
  ), [enterpriseHomeImage, enterpriseHomeText, isBrandUpdating]);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setSortableProducts(move(oldIndex, newIndex, sortableProducts));
  }, [sortableProducts]);

  const handleSaveOrder = useCallback(() => {
    onSaveOrder({ order: sortableProducts.map(({ id }) => id) });
  }, [sortableProducts]);

  useEffect(() => {
    const productsLength = withDragging ? products.length + 1 : products.length;

    if (withDragging && products && products.length && sortableProducts.length !== productsLength) {
      const orderedList = orderProductsList(products, orderWithHome);
      setSortableProducts(orderedList);
    }

    const numOfSortableLimited = sortableProducts.filter(({ isLimited }) => isLimited).length;
    const numOfSortableDisabled = sortableProducts.filter(({ isVisible }) => isVisible === false).length;
    const numOfProductsLimited = products.filter(({ isLimited }) => isLimited).length;
    const numOfProductsDisabled = products.filter(({ isVisible }) => isVisible === false).length;

    if (products && products.length && sortableProducts.length === productsLength && (numOfSortableLimited !== numOfProductsLimited || numOfSortableDisabled !== numOfProductsDisabled)) {
      const updatedList = sortableProducts.map((item) => {
        const { id } = item;
        return findElementByProp(products, 'id', id) || item;
      });
      setSortableProducts(updatedList);
    }
  }, [products, sortableProducts, orderWithHome, withDragging]);

  return withDragging ? (
    <Box pl={3}>
      <SortableList
        onSortEnd={onSortEnd}
        useDragHandle
        lockAxis="y"
        distance={1}
      >
        {sortableProducts.map(({ id, fetching, ...rest }, index) => (
          <SortableListItem
            key={id}
            index={index}
          >
            {id === HOME_PAGE_ID ? getHomeItem(id, rest) : getItem(id, fetching, rest)}
          </SortableListItem>
        ))}
      </SortableList>

      <Box
        mt={2}
        mb={1}
        textAlign="right"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveOrder}
        >
          Save order
        </Button>
      </Box>
    </Box>
  ) : (
    <PaginatedList
      data={products}
      itemsPerPage={ITEMS_PER_PAGE}
      noDataText={PRODUCTS_LISTING_NO_DATA_TEXT}
      renderListingItem={({ data: { id, fetching, ...rest }, isLast }) => getItem(id, fetching, rest, isLast)}
    />
  );
});

ProductsListing.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  order: PropTypes.arrayOf(PropTypes.string),
  onProductDelete: PropTypes.func,
  onProductUnlock: PropTypes.func,
  onVisibilityUpdate: PropTypes.func.isRequired,
  onProductCopy: PropTypes.func,
  underlineLast: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isBrandUpdating: PropTypes.bool.isRequired,
  withProductCopy: PropTypes.bool.isRequired,
  withDragging: PropTypes.bool,
  onSaveOrder: PropTypes.func,
  onEnterpriseDataUpdate: PropTypes.func,
};

ProductsListing.defaultProps = {
  order: [],
  onProductCopy: null,
  onProductDelete: null,
  onProductUnlock: null,
  withDragging: false,
  onSaveOrder: null,
  onEnterpriseDataUpdate: null,
};


export default ProductsListing;
