import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Link,
  Box,
  Typography,
} from '@material-ui/core';

import { BrandContext } from '@pro/web-common/containers/providers';

import { actions as facebookActions } from '@pro/web-common/core/facebook/actions';
import { getFeed as getFeedSelector } from '@pro/web-common/core/facebook/selectors';

import EmptyContentPreview from '@pro/web-common/components/empty-content-preview';

import { styles } from './styles';


const FacebookPageContentPreview = React.memo(({ pageId, feed, getFeed }) => {
  const { brand: { typeface, fontColor, sectionHeaderColor, sectionHeaderTextColor } } = useContext(BrandContext);

  const { data, profile, isFetching } = feed;

  const classes = styles({
    typeface,
    fontColor,
    sectionHeaderColor,
    sectionHeaderTextColor,
  });
  // TODO: integrate feed
  useEffect(() => {
    getFeed({ pageId });
  }, [pageId]);

  return (
    <>
      {!isFetching && feed && data && data.length > 0 ? (
        data.map(({ id, full_picture: image, date, message, permalink_url: url }) => (
          <Box
            key={id}
            className={classes.container}
          >
            <Box className={classes.header}>
              <Box className={classes.avatar}>
                <img
                  src={profile.image}
                  alt="facebook avatar"
                />
              </Box>

              <Box>
                <Typography
                  variant="caption"
                  className={`${classes.text} ${classes.name}`}
                  display="block"
                >
                  {profile.name}
                </Typography>

                <Typography
                  variant="caption"
                  className={`${classes.text} ${classes.date}`}
                >
                  {date}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.content}>
              <Typography
                variant="body2"
                className={`${classes.text} ${classes.message}`}
                display="block"
              >
                {message}
              </Typography>

              <img
                src={image}
                alt="message"
              />

              <Box className={classes.linkContainer}>
                <Link
                  className={classes.text}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                >
                  See original post
                </Link>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <EmptyContentPreview
          text="Page does not contain any posts yet"
        />
      )}
    </>
  );
});


FacebookPageContentPreview.propTypes = {
  pageId: PropTypes.string.isRequired,
  getFeed: PropTypes.func.isRequired,
  feed: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    profile: PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
    }),
    isFetching: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  feed: getFeedSelector(state),
});

const mapDispatchToProps = {
  getFeed: facebookActions.getFeed,
};

export default connect(mapStateToProps, mapDispatchToProps)(FacebookPageContentPreview);
