import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Slider from 'react-slick';
import { Box } from '@material-ui/core';

import IconRenderer from '@pro/web-common/components/icon-renderer';
import SectionTitle from '@pro/web-common/components/section-title';
import { ICONS_SET } from '@pro/web-common/constants/icons';

import { sliderSettings } from './config';
import { styles } from './styles';


const IconsSelector = React.memo(({ onSelect, value, error }) => {
  const classes = styles();

  return (
    <>
      <SectionTitle
        title="Choose page icon"
        error={error}
      />

      <Box
        pl={2}
        pr={2}
      >
        <Slider {...sliderSettings}>
          {ICONS_SET.map((icon) => {
            const { id, ...rest } = icon;

            return (
              <div
                key={id}
              >
                <Box
                  className={clsx(classes.iconContainer, {
                    [classes.iconContainerSelected]: id === value,
                  })}
                  onClick={() => onSelect(id)}
                >
                  <IconRenderer
                    icon={rest}
                  />
                </Box>
              </div>
            );
          })}
        </Slider>
      </Box>
    </>
  );
});

IconsSelector.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

IconsSelector.defaultProps = {
  value: null,
  error: null,
};


export default IconsSelector;
