import { createMuiTheme } from '@material-ui/core/styles';

import defaultTheme from '@pro/web-common/theme';


const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: {
      light: '#c3c391',
      main: '#383819',
      additional: '#85853A',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Montseratt',
  },
});

export const LOGO_WIDTH = '200px';

export default theme;
