import * as Yup from 'yup';


export const formValidationSchema = () => Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
});
