import React from 'react';
import PropTypes from 'prop-types';


import { Box, CircularProgress } from '@material-ui/core';

import { styles } from './styles';


const ComponentWithSpinner = React.memo(({ isSubmitting, children, progressSize }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      {children}
      {isSubmitting && (
        <Box className={classes.loaderContainer}>
          <CircularProgress
            size={progressSize}
          />
        </Box>
      )}
    </Box>
  );
});

ComponentWithSpinner.propTypes = {
  isSubmitting: PropTypes.bool,
  children: PropTypes.node,
  progressSize: PropTypes.number,
};

ComponentWithSpinner.defaultProps = {
  isSubmitting: false,
  children: null,
  progressSize: 50,
};


export default ComponentWithSpinner;
