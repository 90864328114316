import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';


const PageTitle = React.memo(({ title, subtitle, titlePostfix, children, large }) => (
  <Box mb={4}>
    <Box
      display="flex"
      flexDirection="row"
    >
      <Typography variant={large ? 'h3' : 'h5'}>
        {title}
      </Typography>

      {titlePostfix}
    </Box>

    {subtitle && (
      <Box
        mt={1}
        mb={2}
      >
        <Typography variant={large ? 'h5' : 'body2'}>
          {subtitle}
        </Typography>
      </Box>
    )}

    {children}
  </Box>
));

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  titlePostfix: PropTypes.node,
  children: PropTypes.node,
  large: PropTypes.bool,
};

PageTitle.defaultProps = {
  subtitle: null,
  titlePostfix: null,
  children: null,
  large: false,
};


export default PageTitle;
