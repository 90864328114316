import { LocationOn as LocationOnIcon } from '@material-ui/icons';
import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  Box,
  Typography,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { BrandContext } from '@pro/web-common/containers/providers';
import { parseRgbaColor } from '@pro/web-common/utils/style';

import { getProfilesCollection } from '@pro/web-common/core/profile/selectors';

import MapImage from '@pro/web-common/assets/images/map-image.png';

import { styles } from './styles';


const ALL_MENU_ITEM = {
  id: 'all',
  label: 'All',
};
const LocalDirectoryPageContentPreview = React.memo(({ directories, profilesCollection }) => {
  const { brand: { typeface, fontColor, sectionHeaderColor, sectionHeaderTextColor } } = useContext(BrandContext);

  const classes = styles({
    typeface,
    fontColor,
    sectionHeaderColor,
    sectionHeaderTextColor,
  });

  const parsedSectionHeaderColor = useMemo(() => parseRgbaColor(sectionHeaderColor), [sectionHeaderColor]);
  const parsedFontColor = useMemo(() => parseRgbaColor(fontColor), [fontColor]);

  const [currentMenuId, setCurrentMenuId] = useState(ALL_MENU_ITEM.id);
  const directoriesMenu = useMemo(() => {
    const allProfiles = [];
    const menu = [];

    directories.forEach(({ directoryName, profiles, ...rest }, index) => {
      allProfiles.push(...profiles);
      menu.push({
        id: index,
        label: directoryName,
        profiles,
        ...rest,
      });
    });

    menu.unshift({
      ...ALL_MENU_ITEM,
      profiles: allProfiles,
    });

    return menu;
  }, [directories]);

  const currentProfiles = useMemo(() => directoriesMenu.find(({ id }) => id === currentMenuId).profiles, [directories, directoriesMenu, currentMenuId]);

  return (
    <Box className={classes.container}>
      <img
        src={MapImage}
        alt="not here yet"
      />

      <Box>
        <Box className={classes.tagsContainer}>
          {directoriesMenu.map(({ id, label }, index) => (
            <Box
              className={clsx(classes.tag, {
                [classes.tagActive]: id === currentMenuId,
              })}
              bgcolor={fade(parsedSectionHeaderColor, sectionHeaderColor.a * (1 - (directoriesMenu.length - index) * 0.1))}
              key={id}
              onClick={() => setCurrentMenuId(id)}
            >
              {label}
            </Box>
          ))}

          <Box
            display="flex"
            flex={1}
            bgcolor={fade(parsedSectionHeaderColor, sectionHeaderColor.a * 0.9)}
          />
        </Box>
      </Box>

      {currentProfiles.map((id, index) => (
        <Box
          key={index} // eslint-disable-line react/no-array-index-key
          display="flex"
          flexDirection="row"
          className={classes.itemContainer}
        >
          <Box className={classes.image}>
            <img
              src={profilesCollection[id].image.filePath}
              alt="profile"
            />
          </Box>

          <Box className={classes.content}>
            <Typography className={classes.contentTitle}>
              {profilesCollection[id].title}
            </Typography>

            <Typography noWrap variant="body2">
              {profilesCollection[id].description}
            </Typography>

            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <LocationOnIcon
                style={{ color: parsedFontColor, fontSize: 15 }}
              />

              <Typography className={classes.contentDistance} variant="body2">
                0.5 km
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}

      {currentProfiles && currentProfiles.length === 0 && (
        <Typography className={classes.noContent} variant="body2">
          Sorry, there are no profiles for the selected directory yet.
        </Typography>
      )}
    </Box>
  );
});

LocalDirectoryPageContentPreview.propTypes = {
  directories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  profilesCollection: PropTypes.shape({}),
};

LocalDirectoryPageContentPreview.defaultProps = {
  profilesCollection: {},
};


const mapStateToProps = (state) => ({
  profilesCollection: getProfilesCollection(state),
});


export default connect(mapStateToProps)(LocalDirectoryPageContentPreview);
