import messageFakeImage from 'assets/images/message-fake-image.jpg';


export const DEFAULT_MESSAGES = [
  {
    id: 1,
    title: 'Here is a sample headline of a message',
    image: messageFakeImage,
    date: 'Date: 12 December 2019',
  },
  {
    id: 2,
    title: 'Here is a sample headline of a message',
    image: messageFakeImage,
    date: 'Date: 12 December 2019',
  },
];

export const DEFAULT_TAGS = [
  {
    id: 'all',
    title: 'All',
  },
  {
    id: 'updates',
    title: 'Updates',
  },
  {
    id: 'offers',
    title: 'Offers',
  },
];
