import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import {
  Box,
  Button,
  Typography,
  LinearProgress,
} from '@material-ui/core';


const VerifyEmail = React.memo(({ verifyEmail, actionCode, state: { fetching, error, isVerified } }) => {
  useEffect(() => {
    verifyEmail({ actionCode });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box mb={4}>
        <Typography
          variant="h6"
        >
          Email Verification
        </Typography>
      </Box>

      {fetching && (
        <>
          <Typography>We are processing your info.</Typography>

          <Box
            mt={2}
            mb={2}
          >
            <LinearProgress />
          </Box>
        </>
      )}
      {
        !fetching && isVerified && (
          <>
            <Typography>
            Thanks! Your email is verified!<br />
            You can now log in with your credentials.
            </Typography>

            <Box
              textAlign="center"
              mt={4}
            >
              <Button
                component={NavLink}
                variant="contained"
                color="primary"
                to="/login"
              >
                Log in
              </Button>
            </Box>
          </>
        )
      }
      {error && (
        <Typography color="error">{error}</Typography>
      )}
    </>
  );
});

VerifyEmail.propTypes = {
  verifyEmail: PropTypes.func.isRequired,
  actionCode: PropTypes.string.isRequired,
  state: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
    isVerified: PropTypes.bool.isRequired,
  }).isRequired,
};

VerifyEmail.defaultProps = {};


export default VerifyEmail;
