import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Grid,
  FormControl,
} from '@material-ui/core';

import { getActiveProductsList } from '@pro/web-common/core/product/selectors';

import Select from '@pro/web-common/components/select';
import DatePicker from '@pro/web-common/components/date-picker';

import { PRODUCT_FILTERS_SELECT_ITEM_LABEL } from 'content/texts';

import { styles } from './styles';


const ProductFilters = React.memo(({ initialValues, products, onFiltersChange, withDatesFilter }) => {
  const classes = styles();
  const [productId, setProductId] = useState(initialValues.productId);
  const [fromDate, setFromDate] = useState(initialValues.fromDate);
  const [toDate, setToDate] = useState(initialValues.toDate);

  const productsOptions = useMemo(() => products.map(({ id, productName, isLimited }) => ({
    value: id,
    label: productName,
    isLimited,
  })), [products]);

  const hasAvailableProducts = productsOptions && productsOptions.length > 0;

  useEffect(() => {
    if (hasAvailableProducts) {
      setProductId(productsOptions[0].value);
    }
  }, [productsOptions, hasAvailableProducts]);

  useEffect(() => {
    onFiltersChange({ productId, fromDate, toDate });
  }, [productId, fromDate, toDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid
      container
      spacing={2}
    >
      {hasAvailableProducts && productId && (
        <Grid
          item
          xs={12}
          sm={6}
        >
          <FormControl
            variant="outlined"
            className={classes.formControl}
          >
            <Select
              id="productId"
              name="productId"
              label={PRODUCT_FILTERS_SELECT_ITEM_LABEL}
              options={productsOptions}
              value={productId}
              onChange={({ target: { value } }) => setProductId(value)}
            />
          </FormControl>
        </Grid>
      )}

      {withDatesFilter && (
        <>
          <Grid
            item
            xs={12}
            sm={3}
          >
            <DatePicker
              clearable
              fullWidth
              variant="dialog"
              id="startDate"
              label="Start date"
              value={fromDate}
              onChange={(value) => setFromDate(value)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
          >
            <DatePicker
              clearable
              fullWidth
              variant="dialog"
              id="endDate"
              label="End date"
              value={toDate}
              onChange={(value) => setToDate(value)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
});

ProductFilters.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    productId: PropTypes.string,
    fromDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
    toDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  }),
  withDatesFilter: PropTypes.bool,
};

ProductFilters.defaultProps = {
  initialValues: {},
  withDatesFilter: true,
};


const mapStateToProps = (state) => ({
  products: getActiveProductsList(state),
});


export default connect(mapStateToProps)(ProductFilters);
