import { eventChannel } from 'redux-saga';
import { put, call, takeLatest, select, all, cancelled, take } from 'redux-saga/effects';

import { resolvedAction, rejectedAction } from '@pro/web-common/core/actions';

import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';

import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { getBrandId, getRole } from '@pro/web-common/core/user/selectors';

import { getIsOwner } from '@pro/web-common/core/user/utils';

import { constants as appConstants } from '@pro/web-common/core/app/actions';
import { subscribeSagaToUserSession } from '@pro/web-common/core/app/sagas';

import { MODAL_MESSAGE } from 'content/modals';

import SubscriptionsService from './service';
import { normalizeSubscription, normalizeSubscriptions } from './utils';
import { constants } from './actions';


/*
 * Sagas
 */
function* init () {
  yield all([
    call(subscribeSagaToUserSession, watchSubscriptions),
  ]);
}

function* getWatchSubscriptionsChannel () {
  const brandId = yield select(getBrandId);

  return eventChannel((emit) => {
    const unsubscribe = SubscriptionsService.watchSubscriptions((data) => emit({ data }), brandId);
    return unsubscribe;
  });
}

function* watchSubscriptions () {
  const userRole = yield select(getRole);
  const isOwner = getIsOwner(userRole);

  if (!isOwner) {
    return;
  }

  const channel = yield call(getWatchSubscriptionsChannel);

  while (channel) {
    try {
      const { data } = yield take(channel);
      const normalizedData = data ? normalizeSubscriptions(data) : null;

      yield put(resolvedAction(constants.WATCH_SUBSCRIPTIONS, { data: normalizedData }));
    } catch ({ message }) {
      yield put(rejectedAction(constants.WATCH_SUBSCRIPTIONS, { error: message }));
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* addSubscription ({ payload: { planTypeId, productsIds, source, currency } }) {
  try {
    const { data } = yield call(SubscriptionsService.addSubscription, planTypeId, productsIds, source, currency);
    const { requiredActionUrl = null } = data;
    const normalizedData = data ? normalizeSubscription(data) : null;

    yield put(resolvedAction(constants.ADD_SUBSCRIPTION, { data: normalizedData }));

    // TODO: update reducer with data on free trial plan upgrade
    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: {
        message: requiredActionUrl ? MODAL_MESSAGE.SUBSCRIPTION_ADD_3D_SECURE : MODAL_MESSAGE.SUBSCRIPTION_ADD_SUCCESS,
        link: requiredActionUrl,
      },
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.ADD_SUBSCRIPTION, { error: message }));
  }
}

function* cancelSubscription ({ payload: { subscriptionId, productsIds } }) {
  try {
    yield call(SubscriptionsService.cancelSubscription, subscriptionId, productsIds);

    yield put(resolvedAction(constants.CANCEL_SUBSCRIPTION, { subscriptionId }));

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.SUBSCRIPTION_CANCEL_SUCCESS },
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.CANCEL_SUBSCRIPTION, { subscriptionId, error: message }));
  }
}

function* renewSubscription ({ payload: { subscriptionId, productsIds, source } }) {
  try {
    yield call(SubscriptionsService.renewSubscription, subscriptionId, productsIds, source);

    yield put(resolvedAction(constants.RENEW_SUBSCRIPTION, { subscriptionId }));

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.SUBSCRIPTION_RENEW_SUCCESS },
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.RENEW_SUBSCRIPTION, { subscriptionId, error: message }));
  }
}

function* removePaymentMethod ({ payload: { id } }) {
  try {
    yield call(SubscriptionsService.removePaymentMethod, id);

    yield put(resolvedAction(constants.REMOVE_PAYMENT_METHOD, { id }));

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.PAYMENT_METHOD_REMOVE_SUCCESS },
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.REMOVE_PAYMENT_METHOD, { id, error: message }));
  }
}

function* addPaymentMethod ({ payload: { source: { id, card: { last4, brand } } } }) {
  try {
    yield call(SubscriptionsService.addPaymentMethod, id);

    yield put(resolvedAction(constants.ADD_PAYMENT_METHOD, { id, brand, last4 }));

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.PAYMENT_METHOD_ADD_SUCCESS },
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.ADD_PAYMENT_METHOD, { error: message }));
  }
}

function* addBankTransfer ({ payload: { data } }) {
  try {
    const brandId = yield select(getBrandId);

    yield call(SubscriptionsService.addBankTransfer, brandId, data);

    yield put(resolvedAction(constants.ADD_BANK_TRANSFER));
    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: {
        message: MODAL_MESSAGE.BANK_TRANSFER_ADD_SUCCESS,
      },
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.ADD_BANK_TRANSFER, { error: message }));
  }
}

function* updateBankTransfer ({ payload: { id, data } }) {
  try {
    yield call(SubscriptionsService.updateBankTransfer, id, data);

    yield put(resolvedAction(constants.UPDATE_BANK_TRANSFER));
    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: {
        message: MODAL_MESSAGE.BANK_TRANSFER_UPDATE_SUCCESS,
      },
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.UPDATE_BANK_TRANSFER, { error: message }));
  }
}

function* deleteBankTransfer ({ payload: { id } }) {
  try {
    yield call(SubscriptionsService.deleteBankTransfer, id);

    yield put(resolvedAction(constants.DELETE_BANK_TRANSFER));
    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: {
        message: MODAL_MESSAGE.BANK_TRANSFER_DELETE_SUCCESS,
      },
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.DELETE_BANK_TRANSFER, { error: message }));
  }
}

/*
 * Watchers
 */
function* initWatcher () {
  yield take(appConstants.INIT);
  yield call(init);
}

function* addSubscriptionWatcher () {
  yield takeLatest(constants.ADD_SUBSCRIPTION, addSubscription);
}

function* cancelSubscriptionWatcher () {
  yield takeLatest(constants.CANCEL_SUBSCRIPTION, cancelSubscription);
}

function* renewSubscriptionWatcher () {
  yield takeLatest(constants.RENEW_SUBSCRIPTION, renewSubscription);
}

function* removePaymentMethodWatcher () {
  yield takeLatest(constants.REMOVE_PAYMENT_METHOD, removePaymentMethod);
}

function* addPaymentMethodWatcher () {
  yield takeLatest(constants.ADD_PAYMENT_METHOD, addPaymentMethod);
}

function* addBankTransferWatcher () {
  yield takeLatest(constants.ADD_BANK_TRANSFER, addBankTransfer);
}

function* updateBankTransferWatcher () {
  yield takeLatest(constants.UPDATE_BANK_TRANSFER, updateBankTransfer);
}

function* deleteBankTransferWatcher () {
  yield takeLatest(constants.DELETE_BANK_TRANSFER, deleteBankTransfer);
}


export default [
  initWatcher,
  addSubscriptionWatcher,
  cancelSubscriptionWatcher,
  renewSubscriptionWatcher,
  removePaymentMethodWatcher,
  addPaymentMethodWatcher,
  addBankTransferWatcher,
  updateBankTransferWatcher,
  deleteBankTransferWatcher,
];
