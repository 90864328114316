import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';


import FileInput from '@pro/web-common/components/file-input';

import { styles } from './styles';


const BrandLogoUploader = React.memo(({ formats, fileName, filePath, onFileChange, disabled, ...rest }) => {
  const classes = styles();

  const onLogoChange = useCallback((file) => {
    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      onFileChange({ file, filePath: result });
    };

    reader.readAsDataURL(file);
  }, [onFileChange]);

  return (
    <>
      <FileInput
        id="brand-logo-uploader"
        onChange={(file) => onLogoChange(file)}
        formats={formats}
        submitLabel={fileName && fileName.length > 0 ? 'Update logo' : 'Add your logo'}
        disabled={disabled}
        {...rest}
      />

      {filePath && (
        <Box mt={1}>
          <img
            className={classes.imagePreview}
            alt="brand logo"
            src={filePath}
          />
        </Box>
      )}
    </>
  );
});

BrandLogoUploader.propTypes = {
  fileName: PropTypes.string,
  filePath: PropTypes.string,
  onFileChange: PropTypes.func.isRequired,
  formats: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
};

BrandLogoUploader.defaultProps = {
  fileName: '',
  filePath: null,
  disabled: false,
};


export default BrandLogoUploader;
