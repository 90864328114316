import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'GET_STATISTICS',
], 'STATISTICS');

export const actions = createActions({
  [constants.GET_STATISTICS]: ({ productId }) => ({ productId }),
}, {
  prefix: 'STATISTICS',
});


export default actions;
