import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'OPEN_MODAL',
  'RESET_MODAL',
], 'MODAL_CONDUCTOR');

export const actions = createActions({
  [constants.OPEN_MODAL]: ({ modal, params }) => ({ modal, params }),
  [constants.RESET_MODAL]: null,
}, {
  prefix: 'MODAL_CONDUCTOR',
});


export default actions;
