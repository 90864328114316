const config = {
  firebase: {
    apiKey: 'AIzaSyB9lZpKKICg05VnZ0WB_q5ibn290cuyLQ8',
    authDomain: 'pocket-conciergepro.firebaseapp.com',
    databaseURL: 'https://pocket-conciergepro.firebaseio.com',
    projectId: 'pocket-conciergepro',
    storageBucket: 'pocket-conciergepro.appspot.com',
    messagingSenderId: '836296787378',
    appId: '1:836296787378:web:91990a5467ff4480bdd0ab',
    measurementId: 'G-KC2BYWXHM3',
  },
  stripePublicAPIKey: 'pk_live_51GvgckAdqTb0dU6h21JbeVG0jv8rc9F9O3Kk3Eu0es4O8PeB6s3Edk4G6xMxyeaToEoDes2SG7y0ROPLfBJ5hgyo00ax7qPD9n',
  facebookAppId: '589215375048594',
  google: {
    apiUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBBKtkTPa2oo89bJgvh_hQgbxobbRGyx1U&libraries=places,geometry',
  },
};


export default config;
