import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
  image: {
    maxWidth: '30%',
    marginRight: theme.spacing(2),

    '& img': {
      maxWidth: '100%',
    },
  },
  content: () => ({
    display: 'flex',
    flexDirection: 'column',
  }),
  text: ({ typeface, fontColor }) => ({
    fontFamily: typeface,
    color: parseRgbaColor(fontColor),
  }),
  tagsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  tag: ({ sectionHeaderTextColor }) => ({
    padding: theme.spacing(3 / 4, 1),
    color: fade(parseRgbaColor(sectionHeaderTextColor), sectionHeaderTextColor.a * 0.6),
  }),
  tagActive: ({ sectionHeaderTextColor }) => ({
    color: parseRgbaColor(sectionHeaderTextColor),
  }),
  iconContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    padding: theme.spacing(0, 1 / 2),
  },
  searchIconContainer: ({ sectionHeaderColor }) => ({
    backgroundColor: parseRgbaColor(sectionHeaderColor),
  }),
}));
