import React from 'react';
import PropTypes from 'prop-types';


import { Typography } from '@material-ui/core';


const DataWithLoader = React.memo(({ isLoaded, children }) => (
  <>
    {isLoaded ? (
      children
    ) : (
      <Typography>Loading...</Typography>
    )}
  </>
));

DataWithLoader.propTypes = {
  isLoaded: PropTypes.bool,
  children: PropTypes.node,
};

DataWithLoader.defaultProps = {
  isLoaded: false,
  children: null,
};


export default DataWithLoader;
