import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Zoom,
} from '@material-ui/core';

import { Info as InfoIcon } from '@material-ui/icons';

import Tooltip from '@pro/web-common/components/conditional-tooltip';

import { styles } from './styles';


const InfoTooltip = React.memo(({ text, ...rest }) => {
  const classes = styles();

  return text && text.length > 0 ? (
    <Tooltip
      isShown
      TransitionComponent={Zoom}
      title={text}
    >
      <Box
        className={classes.iconContainer}
      >
        <InfoIcon {...rest} />
      </Box>
    </Tooltip>
  ) : null;
});


InfoTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

InfoTooltip.defaultProps = {};


export default InfoTooltip;
