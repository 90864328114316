const HOME_PAGE = {
  id: 'home',
  icon: {
    iconName: 'icon-home-24px', // TODO: refactor this to icon id
  },
  label: 'Home',
};

const WEBSITE_PAGE = {
  id: 'website',
  icon: {
    iconName: 'icon-language-24px',
  },
  label: 'Website',
};

const INFO_PAGE = {
  id: 'info',
  icon: {
    iconName: 'icon-warning-24px',
  },
  label: 'Info',
};

export const MORE_PAGE = {
  id: 'more',
  icon: {
    iconName: 'icon-more_horiz-24px',
  },
  label: 'More',
};

export const DEFAULT_PAGES = [
  HOME_PAGE,
  WEBSITE_PAGE,
  INFO_PAGE,
  MORE_PAGE,
];
