import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@material-ui/core';

import { BrandContext } from '@pro/web-common/containers/providers';
import IconRenderer from '@pro/web-common/components/icon-renderer';

import { styles } from './styles';


const DirectoryPageContentPreview = React.memo(({ contacts }) => {
  const { brand: { typeface, fontColor } } = useContext(BrandContext);

  const classes = styles({
    typeface,
    fontColor,
  });

  return (
    <>
      {contacts.map(({ contactName, email, phone }, index) => (
        <Box
          key={index} // eslint-disable-line react/no-array-index-key
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          className={classes.itemContainer}
        >
          <Typography variant="body2">
            {contactName}
          </Typography>

          <Box
            display="flex"
            flexDirection="row"
          >
            {email && (
              <IconRenderer
                fontSize={18}
                ml={2}
                iconId="Email"
              />
            )}

            {phone && (
              <IconRenderer
                fontSize={18}
                ml={2}
                iconId="Call"
              />
            )}
          </Box>
        </Box>
      ))}
    </>
  );
});

DirectoryPageContentPreview.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DirectoryPageContentPreview.defaultProps = {};


export default DirectoryPageContentPreview;
