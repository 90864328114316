import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { slice } from 'ramda';

import {
  Box,
  Typography,
  TablePagination,
} from '@material-ui/core';


const PaginatedList = React.memo(({ data, itemsPerPage, renderListingItem, noDataText }) => {
  const [page, setPage] = useState(0);

  const pageData = useMemo(() => {
    const fromIndex = page * itemsPerPage;
    const toIndex = (page + 1) * itemsPerPage;

    return slice(fromIndex, toIndex, data);
  }, [data, page, itemsPerPage]);

  return (
    <>
      {pageData.map((props, index) => renderListingItem({
        data: props,
        isLast: index === pageData.length - 1,
      }))}

      {data.length > itemsPerPage && (
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={itemsPerPage}
          rowsPerPageOptions={[itemsPerPage]}
          labelRowsPerPage={null}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={(e, newPage) => setPage(newPage)}
        />
      )}

      {data.length === 0 && (
        <Box
          textAlign="center"
          p={2}
        >
          <Typography variant="body2">{noDataText}</Typography>
        </Box>
      )}
    </>
  );
});

PaginatedList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemsPerPage: PropTypes.number,
  renderListingItem: PropTypes.func.isRequired,
  noDataText: PropTypes.string.isRequired,
};

PaginatedList.defaultProps = {
  itemsPerPage: 10,
};


export default PaginatedList;
