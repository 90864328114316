import React from 'react';

import SliderArrow from '@pro/web-common/components/slider-arrow';


export const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
  nextArrow: <SliderArrow />,
  prevArrow: <SliderArrow />,
};

export const imagesFormats = [
  'image/x-png',
  'image/png',
  'image/gif',
  'image/jpeg',
];
