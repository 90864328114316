import FirebaseDatabaseService from '@pro/web-common/services/database';
import FirebaseFunctionsService from '@pro/web-common/services/functions';

import { OWNERS_NODE } from '@pro/web-common/core/user/constants';

import {
  CLOUD_FUNCTIONS,
} from './constants';


const AdminService = {
  createAdmin: (email, password) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.CREATE_ADMIN, { email, password }),
  createDemoUser: (email, password) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.CREATE_DEMO_USER, { email, password }),
  signInAsUser: (id) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.SIGN_IN_AS_USER, { id }),
  signOutFromUser: () => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.SIGN_OUT_FROM_USER),

  watchUsers: (callback) => FirebaseDatabaseService.watchData({
    refPath: OWNERS_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    callback,
  }),

  updateAllStatistics: () => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.UPDATE_ALL_STATISTICS),
};


export default AdminService;
