import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles(() => ({
  container: {
    height: '100%',

    '& iframe': {
      border: 0,
    },
  },
}));
