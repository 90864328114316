import { makeStyles } from '@material-ui/core/styles';


export const stripeStyles = {
  base: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: '"Roboto", sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',

    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#f44336',
    iconColor: '#f44336',
  },
};

export const styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2.25, 1.5),
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
}));
