import { createSelector } from 'reselect';


const getLocalState = (state) => state.user;

export const getSignUpState = createSelector(
  getLocalState,
  ({ signUp }) => signUp,
);

export const getSignInState = createSelector(
  getLocalState,
  ({ signIn }) => signIn,
);

export const getVerifyEmailState = createSelector(
  getLocalState,
  ({ verifyEmail }) => verifyEmail,
);

export const getSendResetPasswordLinkState = createSelector(
  getLocalState,
  ({ sendResetPasswordLink }) => sendResetPasswordLink,
);

export const getResetPasswordState = createSelector(
  getLocalState,
  ({ resetPassword }) => resetPassword,
);

export const getUserInfo = createSelector(
  getLocalState,
  ({ info }) => info,
);

export const getIsSignIn = createSelector(
  getLocalState,
  ({ info: { uid } }) => !!uid,
);

export const getUserUID = createSelector(
  getLocalState,
  ({ info: { uid } }) => uid,
);

export const getUserEmail = createSelector(
  getLocalState,
  ({ info: { email } }) => email,
);

export const getUpdateEmailState = createSelector(
  getLocalState,
  ({ updateEmail }) => updateEmail,
);

export const getBrandId = createSelector(
  getLocalState,
  ({ info: { brandId } }) => brandId,
);

export const getUserInfoState = createSelector(
  getLocalState,
  ({ info: { fetching, error } }) => ({ fetching, error }),
);

export const getUserHasPaymentMethods = createSelector(
  getLocalState,
  ({ info: { hasPaymentMethods } }) => hasPaymentMethods,
);

export const getUserPaymentMethods = createSelector(
  getLocalState,
  ({ info: { paymentMethods } }) => paymentMethods,
);

export const getCurrencyId = createSelector(
  getLocalState,
  ({ info: { currency } }) => currency,
);

export const getRole = createSelector(
  getLocalState,
  ({ info: { role } }) => role,
);

