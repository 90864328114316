import { SUBSCRIPTION_STATUS } from '@pro/web-common/core/subscriptions/constants';


export const getCanUserSubscribe = (subscriptions, limit) => {
  if (limit === null) {
    return true;
  }

  const activeSubscriptionsCount = subscriptions.reduce((sum, { status }) => {
    if (status === SUBSCRIPTION_STATUS.ACTIVE || status === SUBSCRIPTION_STATUS.INCOMPLETE) {
      return sum + 1;
    }

    return sum;
  }, 0);

  return activeSubscriptionsCount < limit;
};
