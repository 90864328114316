import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import '@pro/web-common/assets/icons/style.css';

import { getIconDataById } from './utils';


const IconRenderer = React.memo(({ iconId, icon, ...rest }) => {
  const iconIdData = iconId ? getIconDataById(iconId) : null;
  const { iconName } = iconIdData || icon || {};

  return (
    <Box
      fontSize={24}
      lineHeight={0}
      {...rest}
    >
      <span className={iconName} />
    </Box>
  );
});

IconRenderer.propTypes = {
  icon: PropTypes.shape({
    iconName: PropTypes.string,
  }),
  iconId: PropTypes.string,
};

IconRenderer.defaultProps = {
  icon: null,
  iconId: null,
};


export default IconRenderer;
