import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  list: {
    margin: theme.spacing(2, 0),
    paddingLeft: theme.spacing(3),

    '& li': {
      marginBottom: theme.spacing(1 / 2),
    },
  },
  disc: {
    position: 'relative',
    top: '3px',
    fontSize: '1.5rem',
    lineHeight: 0,
    marginRight: theme.spacing(1),
  },
}));
