import { makeStyles } from '@material-ui/core/styles';
import React from 'react';


export const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  formControl: {
    minWidth: '250px',
    width: '100%',
  },
}));
