import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4, 0, 3),
  },
  text: {
    color: theme.palette.common.white,
    fontSize: '0.5rem',
  },
  socialLink: {
    display: 'inline-block',
    width: '35px',
    marginRight: theme.spacing(1 / 2),
  },
  storeLink: {
    display: 'inline-block',
    width: '143px',
    marginBottom: theme.spacing(1),
  },
}));
