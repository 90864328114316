import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    marginBottom: theme.spacing(2),

    '& > iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
}));
