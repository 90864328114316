import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';


export const styles = makeStyles((theme) => ({
  textfield: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.95),
  },
  textarea: {
    height: '200px',

    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
}));
