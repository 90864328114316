import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';

import { constants } from './actions';


const defaultState = {
  sendingState: {
    fetching: false,
    error: null,
  },
};

export default handleActions({
  [constants.SEND_MESSAGE]: (state) => (
    update(state, {
      sendingState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.SEND_MESSAGE)]: (state) => (
    update(state, {
      sendingState: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.SEND_MESSAGE)]: (state, { payload: { error } }) => (
    update(state, {
      sendingState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [combineActions(
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
  )]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
