import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { InputLabel, MenuItem, Select as MUISelect, Box } from '@material-ui/core';

import { styles } from './styles';


const Select = React.memo(({ id, name, value: selectedValue, label: selectLabel, placeholder, onChange, options, disabled, withFontFamily }) => {
  const classes = styles({ withValue: !!selectedValue });

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    if (selectLabel) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [selectLabel]);

  return (
    <>
      {selectLabel && (
        <InputLabel
          ref={inputLabel}
          htmlFor={id}
          shrink
          classes={{ root: classes.selectInputLabel }}
        >
          {selectLabel}
        </InputLabel>
      )}

      <MUISelect
        value={selectedValue}
        onChange={onChange}
        labelWidth={labelWidth}
        inputProps={{
          name,
          id,
        }}
        classes={{
          root: classes.selectRoot,
          select: classes.select,
        }}
        displayEmpty
        disabled={disabled}
      >
        {placeholder && (
          <MenuItem
            value=""
            disabled
          >
            {placeholder}
          </MenuItem>
        )}
        {options.map(({ value, label, icon }) => (
          <MenuItem
            style={withFontFamily ? { fontFamily: value } : {}}
            className={classes.menuItem}
            key={value}
            value={value}
          >
            {icon && (
              <img
                src={icon}
                alt="icon"
              />
            )}
            {label}
          </MenuItem>
        ))}
      </MUISelect>
    </>
  );
});

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool,
  withFontFamily: PropTypes.bool,
};

Select.defaultProps = {
  label: null,
  placeholder: null,
  disabled: false,
  withFontFamily: false,
};


export default Select;
