import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: {
    borderBottom: '5px solid',
    borderBottomColor: theme.palette.divider,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1,
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  name: {
    fontWeight: 'bold',
  },
  date: {
    fontSize: '0.6rem',
  },
  avatar: {
    marginRight: theme.spacing(1),
    maxWidth: '35px',
    lineHeight: 0,

    '& img': {
      maxWidth: '100%',
      borderRadius: '50%',
    },
  },
  message: {
    padding: theme.spacing(1),
  },
  content: () => ({
    display: 'flex',
    flexDirection: 'column',
  }),
  text: ({ typeface, fontColor }) => ({
    fontFamily: typeface,
    color: parseRgbaColor(fontColor),
  }),
  linkContainer: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));
