import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: ({ error, typeface }) => ({
    ...(error ? {
      border: '1px solid',
      borderColor: error ? theme.palette.error.main : theme.palette.divider,
    } : {}),
    ...(typeface ? {
      fontFamily: typeface,
    } : {}),
  }),
}));
