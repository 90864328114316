import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';

import { constants as adminConstants } from '@pro/web-common/core/admin/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';

import { findIndexByProp } from '@pro/web-common/utils';

import { constants } from './actions';


const defaultState = {
  creatingState: {
    fetching: false,
    error: null,
  },
  updatingState: {
    fetching: false,
    error: null,
  },
  list: [],
  fetching: false,
};

export default handleActions({
  [constants.GET_MANAGERS]: (state) => (
    update(state, {
      fetching: { $set: true },
    })
  ),
  [resolved(constants.GET_MANAGERS)]: (state, { payload: { data } }) => (
    update(state, {
      fetching: { $set: false },
      list: { $set: data },
    })
  ),
  [rejected(constants.GET_MANAGERS)]: (state) => (
    update(state, {
      fetching: { $set: false },
    })
  ),

  [constants.CREATE_MANAGER]: (state) => (
    update(state, {
      creatingState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.CREATE_MANAGER)]: (state, { payload: { data } }) => (
    update(state, {
      creatingState: {
        fetching: { $set: false },
      },
      list: { $push: [data] },
    })
  ),
  [rejected(constants.CREATE_MANAGER)]: (state, { payload: { error } }) => (
    update(state, {
      creatingState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.UPDATE_MANAGER]: (state) => (
    update(state, {
      updatingState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.UPDATE_MANAGER)]: (state, { payload: { data } }) => {
    const { id } = data;
    const index = data && findIndexByProp(state.list, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          list: {
            [index]: { $merge: data },
          },
          updatingState: {
            fetching: { $set: false },
          },
        })
      );
    }

    return state;
  },
  [rejected(constants.UPDATE_MANAGER)]: (state, { payload: { error } }) => (
    update(state, {
      updatingState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.DELETE_MANAGER]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.list, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          list: {
            [index]: {
              fetching: { $set: true },
            },
          },
        })
      );
    }

    return state;
  },
  [resolved(constants.DELETE_MANAGER)]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.list, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          list: { $splice: [[index, 1]] },
        })
      );
    }

    return state;
  },
  [rejected(constants.DELETE_MANAGER)]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.list, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          list: {
            [index]: {
              fetching: { $set: false },
            },
          },
        })
      );
    }

    return state;
  },

  [combineActions(
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
  )]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
