import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  TextField,
  FormControl,
} from '@material-ui/core';

import { withFormik } from 'formik';

import Select from '@pro/web-common/components/select';
import Checkbox from '@pro/web-common/components/checkbox';
import IconsSelector from '@pro/web-common/components/icons-selector';

import { formValidationSchema } from './config';
import { styles } from './styles';


const ConfigureProductPageModal = ({ onClose, onSubmit, isEdit, pageTypeValues, logoUrl, ...formikProps }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formikProps;

  const classes = styles({ showLogo: values.showLogo });

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {isEdit ? 'Edit' : 'Add'}
        {' Product page'}
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent
          dividers
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Box mb={2}>
                <IconsSelector
                  value={values.iconId}
                  onSelect={(iconId) => setFieldValue('iconId', iconId)}
                  error={errors.iconId && touched.iconId}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <TextField
                variant="outlined"
                fullWidth
                id="label"
                label="Page title"
                placeholder="Page title"
                name="label"
                autoComplete="label"
                value={values.label}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.label && touched.label}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <FormControl
                variant="outlined"
                fullWidth
              >
                <Select
                  id="pageTypeId"
                  name="pageTypeId"
                  label="Select page type"
                  options={pageTypeValues}
                  value={values.pageTypeId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isEdit}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Checkbox
                  id="showLogo"
                  name="showLogo"
                  value={values.showLogo}
                  label="Show Logo"
                  onChange={({ target: { checked } }) => setFieldValue('showLogo', checked)}
                  onBlur={handleBlur}
                  disabled={!logoUrl}
                />

                {logoUrl && (
                  <Box
                    className={classes.logoContainer}
                  >
                    <img
                      src={logoUrl}
                      alt="logo"
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            color="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ConfigureProductPageModal.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    iconId: PropTypes.string,
    pageTypeId: PropTypes.string,
    label: PropTypes.string,
    showLogo: PropTypes.bool,
  }),
  pageTypeValues: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  logoUrl: PropTypes.string,
};

ConfigureProductPageModal.defaultProps = {
  initialValues: {},
  isEdit: false,
  logoUrl: null,
};


export default withFormik({
  mapPropsToValues: ({ initialValues, pageTypeValues, logoUrl }) => {
    const {
      iconId = null,
      pageTypeId = pageTypeValues[0].value,
      label = '',
      showLogo = !!logoUrl,
    } = initialValues || {};

    const values = {
      iconId,
      pageTypeId,
      label,
      showLogo,
    };

    return values;
  },
  handleSubmit: (values, { props: { initialValues, onSubmit } }) => {
    const { id: pageId = null } = initialValues || {};
    onSubmit(pageId, values);
  },
  validationSchema: formValidationSchema,
})(ConfigureProductPageModal);
