import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';

import { getActiveProductsList } from '@pro/web-common/core/product/selectors';

import ProductFilters from '@pro/web-common/components/product-filters';
import PageTitle from '@pro/web-common/components/page-title';
import QrArtworks from '@pro/web-common/components/qr-artworks';

import { ARTWORKS } from 'constants/artworks-config';


const Resources = React.memo(({ products }) => {
  const [filters, setFilters] = useState({ productId: null });

  const productsOptions = useMemo(() => products.map(({ id, productName, shortLink }) => ({
    value: id,
    label: productName,
    shortLink,
  })), [products]);

  const selectedProduct = useMemo(() => productsOptions.find(({ value }) => value === filters.productId) || {}, [filters, productsOptions]);
  const hasAvailableProducts = productsOptions && productsOptions.length > 0;

  return (
    <Container maxWidth="md">
      <PageTitle
        title="Resources / artworks for you to use"
        subtitle="Choose and download artworks you can use to provide your customers a QR to scan. You can edit these, adding your own text and images if you wish. Use for print POS or add to your digital comms. If you need help with creating digital or print media, please ask - we can help!"
      >
        {!hasAvailableProducts && (
          <Typography color="error">
            You do not have products. Please create product before choosing artworks.
          </Typography>
        )}
      </PageTitle>

      {hasAvailableProducts && (
        <>
          <ProductFilters
            onFiltersChange={setFilters}
            withDatesFilter={false}
          />

          {filters.productId && (
            <Grid
              key={filters.productId}
              container
              spacing={2}
            >
              {ARTWORKS.map(({ id, ...config }) => (
                <Grid
                  key={id}
                  item
                  xs={3}
                >
                  <Box
                    mt={2}
                    mb={2}
                  >
                    <QrArtworks
                      id={id}
                      productId={selectedProduct.value}
                      productName={selectedProduct.label}
                      shortLink={selectedProduct.shortLink}
                      {...config}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Container>
  );
});

Resources.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Resources.defaultProps = {};


const mapStateToProps = (state) => ({
  products: getActiveProductsList(state),
});


export default connect(mapStateToProps)(Resources);
