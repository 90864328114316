import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Box,
} from '@material-ui/core';

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';


import { styles } from './styles';


const SliderArrow = React.memo(({ onClick, className }) => {
  const isPrev = className.indexOf('slick-prev') > -1;
  const isDisabled = className.indexOf('disabled') > -1;
  const classes = styles({ isPrev });

  return (
    <Box
      onClick={onClick}
      className={clsx(classes.container, {
        [classes.disabled]: isDisabled,
      })}
    >
      {isPrev ? (
        <ChevronLeftIcon />
      ) : (
        <ChevronRightIcon />
      )}
    </Box>
  );
});

SliderArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

SliderArrow.defaultProps = {
  className: '',
  onClick: () => null,
};


export default SliderArrow;
