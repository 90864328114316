import { generateId } from '@pro/web-common/utils/index';


export const getPageProps = ({ iconId, pageTypeId, label, isCopyAvailable = false, showLogo = true }) => ({
  id: generateId(),
  iconId,
  label,
  pageTypeId,
  isContentEditable: true,
  isDeletable: true,
  isCopyAvailable,
  content: {},
  showLogo,
});

export const getPageInnerLink = (pageId) => `@inner-link/${pageId}`; // TODO: move to constants?
