import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'WATCH_PROFILES',
  'WATCH_PROFILES_MESSAGES_LIMITS',
  'CREATE_PROFILE',
  'UPDATE_PROFILE',
  'UPDATE_PROFILE_BY_OWNER',
  'GET_PROFILE_BY_EMAIL',
], 'PROFILE');

export const actions = createActions({
  [constants.WATCH_PROFILES]: null,
  [constants.WATCH_PROFILES_MESSAGES_LIMITS]: null,
  [constants.CREATE_PROFILE]: ({ profile, profileId, productId, onSuccess, onError }) => ({ profile, profileId, productId, onSuccess, onError }),
  [constants.UPDATE_PROFILE]: ({ profile, productId }) => ({ profile, productId }),
  [constants.UPDATE_PROFILE_BY_OWNER]: ({ profile }) => ({ profile }),
  [constants.GET_PROFILE_BY_EMAIL]: ({ email, productId, onSuccess, onError }) => ({ email, productId, onSuccess, onError }),
}, {
  prefix: 'PROFILE',
});


export default actions;
