import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  Toolbar,
  IconButton,
} from '@material-ui/core';

import {
  Close as CloseIcon,
} from '@material-ui/icons';

import { styles } from './styles';


const LargeDialog = React.memo(({ open, onClose, children, ...rest }) => {
  const classes = styles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xl"
      classes={{ paper: classes.paper }}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      {children}
    </Dialog>
  );
});

LargeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
};

LargeDialog.defaultProps = {};


export default LargeDialog;
