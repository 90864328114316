import React from 'react';
import PropTypes from 'prop-types';

import MessageModal from '@pro/web-common/components/message-modal';


const AlertMessage = React.memo(({ resetModal, params }) => (
  <MessageModal
    isOpen
    onClose={resetModal}
    {...params}
  />
));


AlertMessage.propTypes = {
  resetModal: PropTypes.func.isRequired,
  params: PropTypes.shape({}),
};

AlertMessage.defaultProps = {
  params: {},
};

export default AlertMessage;
