import React from 'react';

import { Container, Box, Typography, Link } from '@material-ui/core';

import { STORE_LINKS } from 'constants/app';

import { styles } from './styles';


const ProductLink = React.memo(() => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Container maxWidth="md">
          <Typography
            variant="h3"
            gutterBottom
          >
            Visit the App Store!
          </Typography>

          <Typography>
            It looks like you are trying to download the app from your PC.<br />
            You can visit the <strong><Link href={STORE_LINKS[0].href}>App Store</Link></strong> or <strong><Link href={STORE_LINKS[1].href}>Google Play</Link></strong> and view the app.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
});

ProductLink.propTypes = {};

ProductLink.defaultProps = {};


export default ProductLink;
