import React from 'react';
import PropTypes from 'prop-types';
import scriptLoader from 'react-async-script-loader';


const withScriptLoader = (WrappedComponent, { scriptUrl }) => {
  const ComponentWithScriptLoader = React.memo(({ isScriptLoadSucceed, ...props }) => isScriptLoadSucceed && (
    <WrappedComponent
      {...props}
    />
  ));


  ComponentWithScriptLoader.displayName = `withScriptLoader(${WrappedComponent.displayName || WrappedComponent.name})`;

  ComponentWithScriptLoader.propTypes = {
    isScriptLoaded: PropTypes.bool.isRequired,
    isScriptLoadSucceed: PropTypes.bool.isRequired,
  };


  return scriptLoader(scriptUrl)(ComponentWithScriptLoader);
};

export default withScriptLoader;
