import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: ({ isPrev }) => ({
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    ...(isPrev ? { left: '-35px' } : { right: '-35px' }),
    top: '50%',
    transform: 'translate(0, -50%)',
  }),
  disabled: {
    display: 'none',
  },
}));
