import { makeStyles } from '@material-ui/core/styles';
import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles(() => ({
  text: ({ typeface, fontColor }) => ({
    fontFamily: typeface,
    color: parseRgbaColor(fontColor),
  }),
}));
