import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  slideImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  generatedLogoContainer: {
    backgroundColor: '#545454',
    color: '#FFF',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  generatedLogoText: {
    fontSize: '30px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
