import React from 'react';
import PropTypes from 'prop-types';

import { SortableContainer } from 'react-sortable-hoc';

import { styles } from './styles';


const SortableList = React.memo(({ children }) => {
  const classes = styles();

  return (
    <ul className={classes.container}>
      {children}
    </ul>
  )
});

SortableList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

SortableList.defaultProps = {};


export default SortableContainer(SortableList);
