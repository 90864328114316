import FirebaseDatabaseService from '@pro/web-common/services/database';

import {
  BRANDS_NODE,
} from './constants';


const BrandService = {
  getBrand: (id) => FirebaseDatabaseService.getData({
    refPath: `${BRANDS_NODE}/${id}`,
    refType: FirebaseDatabaseService.REF_TYPES.DOC,
  }),
  watchBrand: (callback, id) => FirebaseDatabaseService.watchData({
    refPath: `${BRANDS_NODE}/${id}`,
    refType: FirebaseDatabaseService.REF_TYPES.DOC,
    callback,
  }),
  updateBrand: (id, data) => FirebaseDatabaseService.updateDoc(
    `${BRANDS_NODE}/${id}`,
    data,
  ),
};


export default BrandService;
