import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
} from '@material-ui/core';

import {
  SwapVert as SwapVertIcon,
} from '@material-ui/icons';

import { actions as messagesActions } from '@pro/web-common/core/messages/actions';
import { getFilteredMessages } from '@pro/web-common/core/messages/utils';
import {
  getMessagesList,
  getTagsList,
  getFetching,
  getCreatingState,
  getUpdatingState,
} from '@pro/web-common/core/messages/selectors';
import { getActiveProductsList } from '@pro/web-common/core/product/selectors';
import { getProfilesCollection, getProfilesLimitsCollectionByProductId } from '@pro/web-common/core/profile/selectors';
import { UserRoleContext } from '@pro/web-common/containers/providers';
import { getLimitsData } from '@pro/web-common/core/profile/utils';

import PageTitle from '@pro/web-common/components/page-title';
import DataWithLoader from '@pro/web-common/components/data-with-loader';
import MessagesListing from '@pro/web-common/components/messages-listing';
import ProductFilters from '@pro/web-common/components/product-filters';
import DeletionConfirmationModal from '@pro/web-common/components/action-confirmation-modal';

import { MESSAGE_TYPE } from '@pro/web-common/core/messages/constants';

import { styles } from './styles';


const Messages = React.memo(({ getMessages, messages, isFetching, products, createMessage, deleteMessage, updateMessage, creatingState, updatingState, tagsSuggestions, profiles, profileLimits }) => {
  const classes = styles();

  const [sortByDateAsc, setSortByDateAsc] = useState(true);
  const [filters, setFilters] = useState({
    productId: null,
    fromDate: null,
    toDate: null,
  });
  const [isDeletionConfirmationModalVisible, setIsDeletionConfirmationModalVisible] = useState(false);
  const [deletingMessageId, setDeletingMessageId] = useState(null);

  const { isDemo, isProfile } = useContext(UserRoleContext);

  const productsOptions = useMemo(() => products.map(({ id, brandId, productName, isLimited, pages, address, pushMessagesRadius }) => ({
    value: id,
    label: productName,
    isLimited,
    pages,
    address,
    pushMessagesRadius,
    brandId,
    ...(isProfile ? getLimitsData(profileLimits[id], MESSAGE_TYPE.IMAGE, messages) : {}),
  })), [products, messages]);

  const hasAvailableProducts = productsOptions && productsOptions.length > 0;

  const filteredMessages = useMemo(() => getFilteredMessages({
    filters: {
      ...filters,
      sortByDateAsc,
    },
    list: messages,
    startDateField: 'messageSentDate',
    endDateField: 'endDate',
  }), [messages, filters, sortByDateAsc]);

  const onMessageDeletionConfirm = useCallback(() => {
    closeDeletionConfirmationModal();
    deleteMessage({ id: deletingMessageId });
  }, [deletingMessageId]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeDeletionConfirmationModal = useCallback(() => {
    setDeletingMessageId(null);
    setIsDeletionConfirmationModalVisible(false);
  }, []);

  const onMessageDelete = useCallback(({ id }) => {
    setDeletingMessageId(id);
    setIsDeletionConfirmationModalVisible(true);
  }, []);

  useEffect(() => {
    if (!isDemo) {
      getMessages();
    }
  }, [isDemo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Container maxWidth="md">
        <PageTitle
          title="Messaging"
          subtitle="Messages are posted in Users message channel in your app. You can include a headline, text, image and video, and attach a PDF if you wish. Select options for sharing and push notfications."
        >
          {!hasAvailableProducts && (
            <Typography color="error">
              NOTE: You have not completed your app set up or your subscription has lapsed. Please upgrade or subscribe before trying to send a message. If you require assistance please use the website chat.
            </Typography>
          )}
        </PageTitle>

        <DataWithLoader isLoaded={!isFetching || !(messages.length === 0)}>
          <ProductFilters
            onFiltersChange={setFilters}
          />

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Box
                mt={2}
                mb={6}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                <IconButton
                  onClick={() => setSortByDateAsc(!sortByDateAsc)}
                >
                  <SwapVertIcon color="primary" />
                </IconButton>

                <Card className={classes.cardContainer}>
                  <CardContent>
                    <MessagesListing
                      messages={filteredMessages}
                      onMessageDelete={onMessageDelete}
                      products={products}
                      productsOptions={productsOptions}
                      onMessageAdd={createMessage}
                      onMessageUpdate={updateMessage}
                      creatingState={creatingState}
                      updatingState={updatingState}
                      disabled={!hasAvailableProducts}
                      tagsSuggestions={tagsSuggestions}
                      selectedProductId={filters.productId}
                      profiles={profiles}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </DataWithLoader>
      </Container>

      {isDeletionConfirmationModalVisible && (
        <DeletionConfirmationModal
          title="Deletion confirmation"
          text="Are you sure you want to delete message?"
          onClose={() => closeDeletionConfirmationModal()}
          onConfirm={onMessageDeletionConfirm}
        />
      )}
    </>
  );
});

Messages.propTypes = {
  getMessages: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  createMessage: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
  creatingState: PropTypes.shape({}).isRequired,
  updatingState: PropTypes.shape({}).isRequired,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  profiles: PropTypes.shape({}),
  profileLimits: PropTypes.shape({}),
};

Messages.defaultProps = {
  profiles: {},
  profileLimits: {},
};


const mapStateToProps = (state) => ({
  messages: getMessagesList(state),
  isFetching: getFetching(state),
  products: getActiveProductsList(state),
  creatingState: getCreatingState(state),
  updatingState: getUpdatingState(state),
  tagsSuggestions: getTagsList(state),
  profiles: getProfilesCollection(state),
  profileLimits: getProfilesLimitsCollectionByProductId(state),
});

const mapDispatchToProps = {
  getMessages: messagesActions.getMessages,
  createMessage: messagesActions.createMessage,
  deleteMessage: messagesActions.deleteMessage,
  updateMessage: messagesActions.updateMessage,
};


export default connect(mapStateToProps, mapDispatchToProps)(Messages);
