import React from 'react';

import {
  Button,
} from '@material-ui/core';

import FbLogoImage from '@pro/web-common/assets/images/fb_logo.png';

import { styles } from './styles';


const FacebookLoginButton = React.memo((props) => {
  const classes = styles();

  return (
    <Button
      size="small"
      color="primary"
      variant="contained"
      startIcon={(
        <img
          className={classes.fbLogo}
          src={FbLogoImage}
          alt="Facebook logo"
        />
      )}
      classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
      {...props}
    >
      Login with Facebook
    </Button>
  );
});


FacebookLoginButton.propTypes = {};

FacebookLoginButton.defaultProps = {};


export default FacebookLoginButton;
