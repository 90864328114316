import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Divider } from '@material-ui/core';


const SectionTitle = React.memo(({ title, error }) => (
  <>
    <Typography color={error ? 'error' : 'initial'}>
      {title}
    </Typography>

    <Box
      mt={1}
      mb={2}
    >
      <Divider />
    </Box>
  </>
));

SectionTitle.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
};

SectionTitle.defaultProps = {
  title: null,
  error: null,
};


export default SectionTitle;
