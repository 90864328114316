import { put, call, takeLatest, select } from 'redux-saga/effects';

import { resolvedAction, rejectedAction } from '@pro/web-common/core/actions';
import { getBrandId } from '@pro/web-common/core/user/selectors';

import StatisticsService from './service';
import { constants } from './actions';


/*
 * Sagas
 */

function* getStatistics ({ payload: { productId } }) {
  try {
    const brandId = yield select(getBrandId);
    const data = yield call(StatisticsService.getStatistics, brandId, productId);

    yield put(resolvedAction(constants.GET_STATISTICS, { productId, data }));
  } catch ({ message }) {
    yield put(rejectedAction(constants.GET_STATISTICS, { error: message }));
  }
}

/*
 * Watchers
 */

function* getStatisticsWatcher () {
  yield takeLatest(constants.GET_STATISTICS, getStatistics);
}


export default [
  getStatisticsWatcher,
];
