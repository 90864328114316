import React from 'react';
import PropTypes from 'prop-types';

import { Box, Tooltip } from '@material-ui/core';

import { styles } from './styles';


const ConditionalTooltip = React.memo(({ isShown, title, children, ...rest }) => {
  const classes = styles();

  return isShown ? (
    <Tooltip
      title={title}
      placement="top"
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      {...rest}
    >
      <Box display="inline-block">
        <>
          {children}
        </>
      </Box>
    </Tooltip>
  ) : children;
});

ConditionalTooltip.propTypes = {
  isShown: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ConditionalTooltip.defaultProps = {};


export default ConditionalTooltip;
