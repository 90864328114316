import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@material-ui/core';

import UserListingItem from '@pro/web-common/components/user-listing-item';


const UsersListing = React.memo(({ users, onEdit, onDelete }) => (
  <>
    {users.map(({ id, fetching, ...rest }) => (
      <UserListingItem
        key={id}
        onEdit={() => onEdit({ id, ...rest })}
        onDelete={() => onDelete({ id })}
        isDeleting={fetching}
        {...rest}
      />
    ))}

    {users.length === 0 && (
      <Box
        textAlign="center"
        p={2}
      >
        <Typography variant="body2">You have not added users yet</Typography>
      </Box>
    )}
  </>
));

UsersListing.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    fetching: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

UsersListing.defaultProps = {};


export default UsersListing;
