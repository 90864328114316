import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import ScriptLoader from 'react-script-loader-hoc';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  FormControl,
  Button,
} from '@material-ui/core';

import config from 'config';

import PaymentMethodForm from '@pro/web-common/components/payment-method-form';
import Select from '@pro/web-common/components/select';

import { DEFAULT_LOCALE, STRIPE_LIB_URL, DEFAULT_FONT_SRC } from './constants';

import { styles } from './styles';


const AddPaymentMethodModal = React.memo(({
  onClose,
  isSubmitting,
  scriptsLoadedSuccessfully,
  onAdd,
  paymentMethods,
  paymentIntentKey,
  isPaymentIntentsMode,
  billingDetails,
  createPaymentIntent,
}) => {
  const classes = styles();

  const isSelector = useMemo(() => paymentMethods && paymentMethods.length > 0, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [selectedPaymentId, setSelectedPaymentId] = useState(paymentMethods && paymentMethods.length > 0 ? paymentMethods[0].id : null);
  const paymentMethodOptions = useMemo(() => paymentMethods ? paymentMethods.map(({ id, last4 }) => ({
    value: id,
    label: `**** **** **** ${last4}`,
  })) : [], [paymentMethods]);

  const onSubmit = useCallback((source) => onAdd({ source }), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPaymentIdSelected = useCallback(() => {
    onClose();

    const source = paymentMethods.find(({ id }) => id === selectedPaymentId);
    onSubmit(source);
  }, [selectedPaymentId, paymentMethods, onSubmit, onClose]);

  return scriptsLoadedSuccessfully && (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
    >
      <DialogTitle id="form-dialog-title">
        {isSelector ? 'Choose' : 'Add'}
        {' '}
        Payment Method
      </DialogTitle>

      <DialogContent>
        <Box mb={2}>
          {isSelector ? (
            <>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <Select
                  id="paymentId"
                  name="paymentId"
                  label="Select payment method"
                  options={paymentMethodOptions}
                  value={selectedPaymentId}
                  onChange={({ target: { value } }) => setSelectedPaymentId(value)}
                />
              </FormControl>

              <Box
                mt={3}
                textAlign="right"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onPaymentIdSelected}
                >
                  Pay
                </Button>
              </Box>
            </>
          ) : (
            <StripeProvider apiKey={config.stripePublicAPIKey}>
              <Elements
                fonts={[{ cssSrc: DEFAULT_FONT_SRC }]}
                locale={DEFAULT_LOCALE}
              >
                <PaymentMethodForm
                  onSubmit={onSubmit}
                  onClose={onClose}
                  paymentIntentKey={paymentIntentKey}
                  isPaymentIntentsMode={isPaymentIntentsMode}
                  billingDetails={billingDetails}
                  createPaymentIntent={createPaymentIntent}
                />
              </Elements>
            </StripeProvider>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
});


AddPaymentMethodModal.propTypes = {
  scriptsLoadedSuccessfully: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    last4: PropTypes.string,
  })),
  isPaymentIntentsMode: PropTypes.bool,
  paymentIntentKey: PropTypes.string,
  billingDetails: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  createPaymentIntent: PropTypes.func,
};

AddPaymentMethodModal.defaultProps = {
  paymentMethods: null,
  isPaymentIntentsMode: false,
  paymentIntentKey: null,
  billingDetails: null,
  createPaymentIntent: null,
};


const ComponentWithScriptLoader = ScriptLoader(STRIPE_LIB_URL)(AddPaymentMethodModal);

export default ComponentWithScriptLoader;
