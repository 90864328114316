import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RRule } from 'rrule';

import {
  Button,
  TextField,
  Grid,
  Box,
  FormControl,
  Typography,
} from '@material-ui/core';

import {
  Share as SharingIcon,
} from '@material-ui/icons';

import { withFormik } from 'formik';

import { UserRoleContext } from '@pro/web-common/containers/providers';
import { MESSAGES_PATH, DEMO_PREFIX } from '@pro/web-common/constants/storage';
import { getFutureDate } from '@pro/web-common/utils/date';
import { GEO_TYPES, FREQUENCY_VALUES } from '@pro/web-common/core/messages/constants';
import infoJson from 'constants/info';

import InfoSection from '@pro/web-common/components/info-section';
import Checkbox from '@pro/web-common/components/checkbox';
import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';
import CKEditorCustomized from '@pro/web-common/components/ck-editor-customized';
import FileInput from '@pro/web-common/components/file-input';
import Select from '@pro/web-common/components/select';
import SingleImageUploader from '@pro/web-common/components/single-image-uploader';
import TagsInput from '@pro/web-common/components/tags-input';
import Radio from '@pro/web-common/components/radio';
import DateTimePicker from '@pro/web-common/components/date-time-picker';

import { formValidationSchema, pdfFormats, ALL_PRODUCTS_OPTION, GEO_VALUES, IS_SCHEDULED_VALUES, IS_SCHEDULED_OPTIONS, FREQUENCY_OPTIONS } from './config';
import { styles } from './styles';


const MessageForm = React.memo(({ messageId, fetching, isError, onSubmit, onClose, isEdit, productsOptions, tagsSuggestions, handleProductIdChange, ...formikProps }) => {
  const classes = styles();

  const { isOwner, isProfile, isDemo } = useContext(UserRoleContext);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    resetForm,
  } = formikProps;

  const [canAddGeo, setCanAddGeo] = useState(true);
  const [isPushSelectorEnabled, setIsPushSelectorEnabled] = useState(true);

  const onPdfFileChange = useCallback((file) => {
    setFieldValue('newPdfFile', file);
    setFieldValue('pdfFileName', file.name);
  }, []);

  const productsOptionsWithAll = useMemo(() => {
    if (!productsOptions) {
      return null;
    }

    if (isProfile) {
      return productsOptions;
    }

    return productsOptions.concat([ALL_PRODUCTS_OPTION]);
  }, [productsOptions]);

  const filteredSuggestions = useMemo(() => {
    let suggestions = tagsSuggestions;
    if (isProfile && productsOptions && productsOptions.length && values.productId) {
      const { brandId } = productsOptions.find(({ value }) => value === values.productId);
      suggestions = suggestions.filter((tag) => tag.brandId === brandId);
    }

    return suggestions.filter(({ id }) => values.tags.findIndex(({ id: tagId }) => tagId === id) === -1);
  }, [values.tags, values.productId, productsOptions, tagsSuggestions]);

  const currentProduct = useMemo(() => (productsOptions.find(({ value }) => value === values.productId) || {}), [values.productId]);
  const geoValues = useMemo(() => GEO_VALUES.map(({ getLabel, ...rest }) => ({ ...rest, label: getLabel(currentProduct.pushMessagesRadius) })), [currentProduct]);
  const isSendMessageDisabled = useMemo(() => currentProduct.messageLimits && !currentProduct.availableMessages, [currentProduct]);
  const initialStartDate = useMemo(getFutureDate, []);

  const handleStartDateChange = useCallback((date) => {
    if (date.getMinutes() !== 0) {
      date.setMinutes(0, 0, 0);
    }
    setFieldValue('startDate', date);
    setFieldValue('isScheduled', IS_SCHEDULED_VALUES.ON);
  }, []);

  const handleIsScheduledChange = useCallback(({ target: { value } }) => {
    setFieldValue('isScheduled', value);
    if (value === IS_SCHEDULED_VALUES.OFF) {
      setFieldValue('startDate', undefined);
    }
  }, []);

  useEffect(() => {
    const cannAddGeo = currentProduct && currentProduct.address && currentProduct.address.length > 0 && currentProduct.pushMessagesRadius && currentProduct.pushMessagesRadius.length > 0;

    setCanAddGeo(!!cannAddGeo);
  }, [currentProduct]);

  useEffect(() => {
    if (!isOwner && values.productId === ALL_PRODUCTS_OPTION.value) {
      setFieldValue('withPush', false);
      setIsPushSelectorEnabled(false);
    } else {
      setIsPushSelectorEnabled(true);
    }
  }, [values.productId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEdit && !values.endDate && values.startDate) {
      const oneMonthForwardDate = new Date(values.startDate);
      oneMonthForwardDate.setMonth(oneMonthForwardDate.getMonth() + 1);
      oneMonthForwardDate.setHours(0, 0, 0, 0);
      setFieldValue('endDate', oneMonthForwardDate);
    }
  }, [values.startDate, isEdit, values.endDate, setFieldValue]);

  useEffect(() => {
    if (isProfile) {
      setFieldValue('tags', []);
    }

    handleProductIdChange(values.productId === ALL_PRODUCTS_OPTION.value ? null : values.productId);
  }, [values.productId]);

  return (
    <ComponentWithSpinner isSubmitting={isSubmitting}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          {
            currentProduct.messageLimits && (
              <Box
                mb={1}
                ml={1}
              >
                <Typography
                  variant="caption"
                  component="p"
                >
                  You can send {currentProduct.messageLimits} messages per {currentProduct.limitMessagePer}
                </Typography>
                <Typography
                  variant="caption"
                  component="p"
                >
                  Count of available messages: {currentProduct.availableMessages}
                </Typography>
              </Box>
            )
          }
          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="title"
              label="Message title"
              placeholder="Message title"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.title && touched.title}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Box className={classes.imageHtmlContainer}>
              <SingleImageUploader
                image={values.image}
                onImageDelete={() => setFieldValue('image', null)}
                onImageAdd={(image) => setFieldValue('image', image)}
                imagePreviewHeight={190}
                error={errors.image && touched.image}
              />

              <Box className={classes.htmlEditorContainer}>
                <CKEditorCustomized
                  data={values.html}
                  withBrandFont={false}
                  onChange={(data) => setFieldValue('html', data)}
                  imagesPathPrefix={`${isDemo ? DEMO_PREFIX : ''}${MESSAGES_PATH}/${messageId}`}
                  error={errors.html && touched.html}
                />
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <FileInput
                  id="pdfUploader"
                  fileName={values.pdfFileName}
                  onChange={(file) => onPdfFileChange(file)}
                  onBlur={handleBlur}
                  error={(errors.newPdfFile || errors.pdfFileName) && touched.newPdfFile}
                  formats={pdfFormats}
                  submitLabel="Upload PDF"
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Box mb={1}>
                  <Typography variant="caption">Publish start date</Typography>
                  <Radio
                    title="Publish Start Date"
                    value={values.isScheduled}
                    values={IS_SCHEDULED_OPTIONS}
                    onChange={handleIsScheduledChange}
                    disabled={false}
                  />
                </Box>
              </Grid>

              {
                (values.isScheduled === IS_SCHEDULED_VALUES.ON || (isEdit && values.startDate)) && (
                  <Grid
                    item
                    xs={12}
                  >
                    <DateTimePicker
                      clearable
                      fullWidth
                      variant="dialog"
                      id="startDate"
                      label="Publish Start Date"
                      value={values.startDate}
                      error={!!errors.startDate && touched.startDate}
                      onChange={handleStartDateChange}
                      minutesStep={60}
                      views={['date', 'hours']}
                      minDate={initialStartDate}
                      initialFocusedDate={initialStartDate}
                      onBlur={handleBlur}
                    />
                  </Grid>
                )
              }

              <Grid
                item
                xs={12}
              >
                <DateTimePicker
                  clearable
                  fullWidth
                  variant="dialog"
                  id="endDate"
                  label="Publish End Date"
                  value={values.endDate}
                  error={!!errors.endDate && touched.endDate}
                  onChange={(value) => setFieldValue('endDate', value)}
                  minutesStep={60}
                  views={['date', 'hours']}
                  initialFocusedDate={initialStartDate}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Box mb={1}>
                  <Typography variant="caption">Frequency</Typography>
                  <Radio
                    title="Frequency"
                    value={values.frequency}
                    values={FREQUENCY_OPTIONS}
                    onChange={({ target: { value } }) => setFieldValue('frequency', value)}
                    disabled={false}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TagsInput
                  tags={values.tags}
                  suggestions={filteredSuggestions}
                  onUpdate={(value) => setFieldValue('tags', value)}
                  allowNew={false}
                  placeholder="Select tags"
                  minQueryLength={0}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="youtubeId"
                  label="YouTube Video ID"
                  placeholder="YouTube Video ID"
                  name="youtubeId"
                  value={values.youtubeId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.youtubeId && touched.youtubeId}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                {productsOptionsWithAll && (
                  <InfoSection infoMessage={infoJson.message.audience}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        id="productId"
                        name="productId"
                        label="Select audience"
                        options={productsOptionsWithAll}
                        value={values.productId}
                        onChange={({ target: { value } }) => setFieldValue('productId', value)}
                        disabled={isEdit}
                      />
                    </FormControl>
                  </InfoSection>
                )}
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Box mb={1}>
                  <Checkbox
                    id="withSharing"
                    name="withSharing"
                    value={values.withSharing}
                    label={(
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <SharingIcon fontSize="small" />
                        <Box ml={1}>
                          <Typography variant="caption">Add sharing</Typography>
                        </Box>
                      </Box>
                    )}
                    onChange={({ target: { checked } }) => setFieldValue('withSharing', checked)}
                    onBlur={handleBlur}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Box mb={1}>
                  <Checkbox
                    id="withPush"
                    name="withPush"
                    value={values.withPush}
                    label="Send push"
                    onChange={({ target: { checked } }) => setFieldValue('withPush', checked)}
                    onBlur={handleBlur}
                    disabled={!isPushSelectorEnabled}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
              >
                {
                  canAddGeo && (
                    <Box mb={1}>
                      <Typography variant="caption">Geolocation settings</Typography>
                      <Radio
                        title="Geo settings"
                        value={values.geo}
                        values={geoValues}
                        onChange={({ target: { value } }) => setFieldValue('geo', value)}
                        disabled={!values.withPush}
                        isRow={false}
                      />
                    </Box>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
          >
            <Box mr={1}>
              <Button
                color="primary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSendMessageDisabled}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </form>
    </ComponentWithSpinner>
  );
});

MessageForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({
    image: PropTypes.shape({}),
    title: PropTypes.string,
    html: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    productsIds: PropTypes.arrayOf(PropTypes.string),
    withSharing: PropTypes.bool,
    pdf: PropTypes.shape({}),
    youtubeId: PropTypes.string,
  }),
  productsOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  messageId: PropTypes.string.isRequired,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  handleProductIdChange: PropTypes.func.isRequired,
};

MessageForm.defaultProps = {
  initialValues: {},
  isError: false,
  isEdit: false,
};


export default withFormik({
  mapPropsToValues: ({ initialValues, defaultProductId, isEdit }) => {
    const {
      image = null,
      title = '',
      html = '',
      isScheduled = IS_SCHEDULED_VALUES.OFF,
      startDate,
      endDate,
      productsIds,
      withSharing = false,
      pdf = null,
      youtubeId = '',
      tags = [],
      geo,
      isGeo,
      frequency = FREQUENCY_VALUES.NONE,
    } = initialValues || {};

    const initialProductId = productsIds && (productsIds.length > 1 ? ALL_PRODUCTS_OPTION.value : productsIds[0]); // if more than 1 product is in the list it means "all" option was selected on creation
    const defaultImage = image ? ({
      ...image,
      filePath: image.url,
    }) : null;

    const defaultImageRef = image ? image.ref : null;
    const defaultPdfRef = pdf ? pdf.ref : null;

    const productId = initialProductId || defaultProductId;

    const values = {
      image: defaultImage,
      title,
      html,
      startDate: startDate ? new Date(startDate) : undefined,
      isScheduled: isEdit ? IS_SCHEDULED_VALUES.ON : isScheduled,
      endDate: endDate ? new Date(endDate) : null,
      productId,
      withSharing,
      withPush: false,
      geo: geo || (isGeo && GEO_TYPES.INSIDE) || GEO_TYPES.NONE,
      pdfFileName: defaultPdfRef,
      newPdfFile: null,
      youtubeId,
      tags,
      defaultImageRef,
      defaultPdfRef,
      frequency,
    };

    return values;
  },
  handleSubmit: ({ productId, image, defaultImageRef, ...values }, { props: { messageId, isEdit, onSubmit, productsOptions } }) => {
    let productsIds = [productId];
    let brandId;
    const isAllSelected = productId === ALL_PRODUCTS_OPTION.value;

    if (isAllSelected) {
      productsIds = productsOptions.map(({ value }) => value);
      brandId = productsOptions[0].brandId;
    } else {
      brandId = productsOptions.find(({ value }) => value === productId).brandId;
    }

    let startDate;
    let rruleStartDate;
    if (values.isScheduled === IS_SCHEDULED_VALUES.OFF) {
      startDate = new Date();
      rruleStartDate = new Date(startDate.getTime());
      rruleStartDate.setHours(rruleStartDate.getHours(), 0, 0);
    } else {
      startDate = values.startDate;
      rruleStartDate = startDate;
    }

    let rrule = null;
    if (values.frequency !== FREQUENCY_VALUES.NONE) {
      rrule = new RRule({
        dtstart: rruleStartDate,
        until: values.endDate,
        freq: values.frequency,
      }).toString();
    }

    onSubmit(
      isEdit,
      messageId,
      isAllSelected,
      {
        ...values,
        brandId,
        productsIds,
        image: { ...image, ref: defaultImageRef },
        rrule,
        withRrule: !!rrule,
        startDate,
      }
    );
  },
  validationSchema: ({ isEdit }) => formValidationSchema(isEdit),
  enableReinitialize: true,
})(MessageForm);
