// import ManualEditImage from 'assets/images/support/manual_edit_image.jpg';
import ManualEditBold from 'assets/images/support/manual_edit_bold.jpg';
import AddSubs from 'assets/images/support/add_subs.jpg';
import Duplicate from 'assets/images/support/duplicate.jpg';
import Configure from 'assets/images/support/configure.jpg';
import PageTypes from 'assets/images/support/pagetypes.jpg';
import GuestAnimated from 'assets/images/support/guestInfoAnimated.gif';
import HomePageImage from 'assets/images/support/addingImagesHomeAnimation.gif';
import AddPhoneNumber from 'assets/images/support/addingPhoneAnimated.gif';
import AddRichText from 'assets/images/support/addingRichTextAnimation.gif';
import AddWebsite from 'assets/images/support/addingWebsiteAnimated.gif';
import AddMessage from 'assets/images/support/addImageMessageAnimated.gif';
import QuickMessage from 'assets/images/support/addQuickMessageAnimated.gif';


export const SUPPORT_ITEMS_FIRST_COLUMN = [
  {
    title: 'About data',
    questions: [
      {
        question: 'Do you record or access any of our guest data?',
        answer: 'No. We do not store any attributable or personal data.',
      },
      {
        question: 'Do you rent or sell any guest data?',
        answer: 'No. We do not store any data at all about your guests.',
      },
    ],
  },
  {
    title: 'About the Pocket Concierge App',
    questions: [
      {
        question: 'Is the Pocket Concierge app free?',
        answer: 'Yes. It will always be free to Guests. Hotels, Resorts, Attractions or Tour brands pay to subscribe and build their own no code app.',
      },
      {
        question: 'Is the Pocket Concierge app available for IOS and Android?',
        answer: 'Yes. It is available in both app stores. See the links at the top and bottom of this page',
      },
      {
        question: 'Will every guest see every push message?',
        answer: 'When you send messages via our Web Admin you specify your target audience by BnB - your guests have the option to manage notifications on thier own devices.',
      },
      {
        question: 'How often does the app update with my content?',
        answer: 'Some of the content may be cached on Guests phones, some other types of content will be refreshed when your guests tap to view the content. ',
      },
    ],
  },
  {
    title: 'About Images',
    questions: [
      {
        question: 'What is the spec for images in Image Messages?',
        answer: `
        <p>We recommend you provide images that are 2208 x 2208 pixels, saved for web as a .jpg.</p>
        <p>You should save them so the file size is reasonably small, around 300k. The images will be cropped left and right depending upon the device they are viewed on.</p>
        <p>It is best to have your main content within the central 700 pixels wide, content outside this area may be cropped depending upon device.</p>
        `,
      },
      {
        question: 'What is the spec for Home page images?',
        answer: `
        <p>We recommend you provide images that are 2208 x 2208 pixels, saved for web as a .jpg.</p>
        <p>You should save them so the file size is reasonably small, around 300k. The images will be cropped left and right depending upon the device they are viewed on.</p>
        <p>It is best to have your main content within the central 700 pixels wide, content outside this area may be cropped depending upon device.</p>
        `,
      },
      {
        question: 'What is the spec for PDFs I want to attach to a message?',
        answer: 'You can attach PDFs to Messages that guests can download. Please ensure your PDFs are as small in size as possible - and no larger than 400k (preferably much smaller). Turn off editing functions and keep the quality suitable for mobile screens.',
      },
    ],
  },
  {
    title: 'Adding more BnBs',
    questions: [
      {
        question: 'I have added one BnB, can I add more?',
        answer: `
        <p>Yes. Simply add a new BnB on My Home (you may need a subscription)</p>
        <img src="${AddSubs}" alt="Subscribe to our guest app" />
        `,
      },
      {
        question: 'I have one BnB already, and some pages are the same for all new ones - do I have to add all the content again for each one?',
        answer: `
        <p>No! You can duplicate a BnB which will copy all your pages. You can then make individual changes as you need to.</p>
        <img src="${Duplicate}" alt="Duploicate Guest App Pages" />
        `,
      },
      {
        question: 'How many pages or tabs can I add to each BnB?',
        answer: 'As many as you wish. We will automatically add a More tab to list any pages that dont fit on the navigation bar at the bottom of your app.',
      },
    ],
  },
  {
    title: 'Admin on mobile and tablets',
    questions: [
      {
        question: 'Does the Admin section work on mobiles and tablets?',
        answer: 'Yes. The whole Admin has been designed to work on any connected device, including Mobile and Tablets.',
      },
      {
        question: 'Is there a specific app I need to download to manage Admin?',
        answer: 'No! This is not necessary as we have optimised the site for use on Mobiles and Tablets. However, you can download and save the icon to your device Home Screen providing you with easy access right on your mobile!',
      },
      {
        question: 'How do I add Admin to my iPhone for easy access?',
        answer: 'Open Safari and go to PocketConcierge.uk. Scroll up until you see a bar appear at the bottom of your screen with some icons in it. You should see in the middle a square box with an arrow pointing up. Tap this, and scroll down until you see an option to Add to Home Screen with a square +. Tap Add in the top right. You are done!',
      },
      {
        question: 'How do I add Admin to my Android phone for easy access?',
        answer: 'Open Chrome and go to PocketConcierge.uk. Tap the 3 dots icon top right, and select Add to Home screen, tap Add. You are done!',
      },
    ],
  },
  {
    title: 'Telling guests about your app',
    questions: [
      {
        question: 'When will my app be available to guests?',
        answer: 'Once you have added your app to Pocket Concierge, it is immediately available to your guests',
      },
      {
        question: 'What do guests need to see my app?',
        answer: 'They just need a Apple IOS or Android device',
      },
      {
        question: 'How can my guests get my app?',
        answer: `
        <p>Guests can get your app in several ways;</p>
        <p>1. They can download Pocket Concierge app from the stores and search using the search terms you have added to your BnB.</p>
        <p>1. You can provide them with the QR code for each BnB, on your website or confirmation emails. You can add them to printed media (like posters at reception or labels in rooms). If they already have Pocket Concierge on their devices your guest aoppo automatically opens.</p>
      `,
      },
      {
        question: 'What happens if my guests do not have the Pocket Concierge app on their phones?',
        answer: 'Guests can just scan the QR code with their mobile phone camera. Our clever tech then automatically takes them to the correct app store, downloads Pocket Concierge which then opens immediately on your own guest app!',
      },
    ],
  },
];

export const SUPPORT_ITEMS_SECOND_COLUMN = [
  {
    title: 'Paperless Guest Information',
    questions: [
      {
        question: 'What is paperless Guest Information?',
        answer: `
        <p>The folders that some BnBs make available for guests in their rooms.</p>
        Any information about Wifi, Emergency Procedures, Check Outs, Local Partners etc.</p>
        <p><strong>This can now all be handled in your Guest app. Go paperless!</strong></p>
        `,
      },
      {
        question: 'Can I include images in the paperless Guest Information?',
        answer: `
        <p>Yes. You can add Sections and Subsections, and include an image to head up each one or within the text field.</p>
        <img src="${GuestAnimated}" alt="Edit Your Guest Information App" />
        `,
      },
      {
        question: 'Can I format the text in the Guest Information section, like Bold and itallic etc?',
        answer: `
        <p>Yes. It includes a fully formatted text editor.</p>
        <img src="${ManualEditBold}" alt="Format Images in your Guest Information App" />
      `,
      },
    ],
  },
  {
    title: 'Configuring Your Brand',
    questions: [
      {
        question: 'How do I add my organisation name and logo?',
        answer: `
        <p>You should be automatically taken to a page on Sign Up where you can add your logo, select your brand colours and font you would like to use.</p>
        <p>You can update this anytime by clicking on Home and selecting the Configure Brand button below the app preview.</p>
        <img src="${Configure}" alt="Configure your brand logo and colors" />
      `,
      },
      {
        question: 'Once I have Configured Brand - how do I add Hotels / Resorts?',
        answer: `
        <p>Once you have Configured brand, you should be asked to add a BnB.</p>
        <p>This is the name of your Hotel, Resort, Attraction or Tour.</p>
        <p>You can add 1 x for a free period, further BnBs within the same brand require a subscription.</p>
        <p>Go to My Home and add a subscription.</p>
        `,
      },
    ],
  },
  {
    title: 'About Messaging',
    questions: [
      {
        question: 'What is the difference between Messaging and a Quick Message?',
        answer: `
        <p>A quick message is just a Push notification, with no content associated with it.</p>
        <p>A Quick message will be sent to everyone with your BnB as a favorite. It's really handy to communicate something quickly, obviously.</p>
        <p>A Message usually has an image, a headline, some text and maybe a PDF or YouTube video content associated with it.</p>
        `,
      },
      {
        question: 'Do I have to send a Push with a Message?',
        answer: 'You have the option to include a Push notfication if you wish.',
      },
      {
        question: 'What images and branding are included in the Push message.',
        answer: 'Where allowed by the platform (IOS/Android) we include our logo thumbnail and your message image. For Enterprise Apps your own brand thumbnail is included.',
      },
      {
        question: 'How do I send an Image Message?',
        answer: `
        <p>Prepare your image as a .jpg (see 'About Images"), your headline and text. Go to the right hand menu and tap Messaging. </p>
        <img src="${AddMessage}" alt="Send image messages" />
      `,
      },
      {
        question: 'Sending a Quick Message',
        answer: `
        <p>This is just a quick push message to everyone in the BnB. Go to the right hand menu and tap Quick Messaging. </p>
        <img src="${QuickMessage}" alt="Send quick messages" />
      `,
      },
    ],
  },
  {
    title: 'About adding content to your app',
    questions: [
      {
        question: 'I have signed up, configured my brand. What next?',
        answer: 'You need to add your BnBs name.',
      },
      {
        question: 'How page Pages or Tabs can I add to my BnB',
        answer: 'As many as you wish. Our system will automatically add a More page to list any more than your 5th page.',
      },
      {
        question: 'What types of pages can I add?',
        answer: `
        <p>There are a number of different page types you can add to your BnB.</p>
        <p><strong>Home</strong> - Every app must have a Home page (default). Add one or more images as a gallery (just add as many images as you wish).</p>
        <p><strong>Messages</strong> - (default) Contains your image messages.</p>
        <p><strong>Guest Info</strong> - Typically in room guest information - Wifi, emergency, check out etc.</p>
        <p>Add language translations (subscription required)</p>
        <p><strong>Call</strong> - calls the number you right out of your app.</p>
        <p><strong>Rich Text Format</strong> - adds a dynamic page, with any content you wish (text and images).</p>
        <p><strong>URL website address</strong> - links direct to any page on any website - must be htts secure.</p>
        <p><strong>We are adding more page types all the time - these are in the pipeline</strong></p>
        <p>Social Media Feeds - just add your ID, we'll automatically add your social media feeds (you can use web URL page for now)</p>
        <p>Contacts directory - jadd a title, email and phone for any number of contacts (Reception, New Bookings, Housekeeping etc)</p>
        <img src="${PageTypes}" alt="Page types added to no code app" />
        `,
      },
      {
        question: 'How do I add an image to my BnB home page?',
        answer: `
        <p>Prepare an image and save as a .jpg (see 'About Images' section) and follow this animation to add an image to your BnB Home page.</p>
        <img src="${HomePageImage}" alt="Adding images to your Home Page guest app" />
      `,
      },
      {
        question: 'How do I add a phone number so guests can call right out of the app?',
        answer: `
        <p>Follow this animation, ensuring you use the international dialing code for your BnB.</p>
        <img src="${AddPhoneNumber}" alt="Call right out of the hotel guest app" />
      `,
      },
      {
        question: 'I want to add a stand alone page to the app, with text and images?',
        answer: `
        <p>It's a good idea to write your text first, and save any images as you would for a website. You can format the text as you would expect.</p>
        <img src="${AddRichText}" alt="Adding a rich text page view to your guest app" />
      `,
      },
      {
        question: 'I want to add a page that already exists on the web.',
        answer: `
        <p>As long as the website is hosted securely, and the link you add is 'https' - you can add any page you like. You can switch off the logo that appears too ensuring branding is not duplicated.</p>
        <img src="${AddWebsite}" alt="Add any secure website" />
      `,
      },
    ],
  },
  {
    title: 'Hey, I need to ask something else!',
    questions: [
      {
        question: 'Questions not answered?',
        answer: `
        <p>We're here 24/7 - tap/click the red chat icon below and ask away!</p>
      `,
      },
      {
        question: 'I have a more complicated requirement, how can I contact you?',
        answer: `
        <p>Flip to our home page, and go to the form at the bottom. We'll come back to you asap!</p>
      `,
      },
    ],
  },

];
