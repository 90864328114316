import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3),
  },
  logoContainer: ({ showLogo }) => ({
    maxWidth: '100px',
    fontSize: 0,
    opacity: showLogo ? 1 : 0.5,

    '& img': {
      maxWidth: '100%',
    },
  }),
}));
