import RRule from 'rrule';


export const MESSAGES_NODE = 'messages';
export const QUICK_MESSAGES_NODE = 'quickMessages';
export const TRACK_TRACE_MESSAGES_NODE = 'trackTraceMessages';

export const MESSAGES_TAGS_NODE = 'messageTags';

export const MESSAGE_TYPE = {
  QUICK: 'quick',
  TRACK_TRACE: 'trackTrace',
  IMAGE: 'image',
};

export const GEO_TYPES = {
  NONE: '',
  INSIDE: 'inside',
  OUTSIDE: 'outside',
};

export const FREQUENCY_VALUES = {
  NONE: '',
  WEEKLY: RRule.WEEKLY.toString(),
  MONTHLY: RRule.MONTHLY.toString(),
};

export const CLOUD_FUNCTIONS = {
  GENERATE_USERS_REPORT: 'checkInGetData',
};
