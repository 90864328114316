import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';

import AppRouter from '@pro/web-common/router';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import history from '@pro/web-common/core/history';
import appActions from '@pro/web-common/core/app/actions';
import userActions from '@pro/web-common/core/user/actions';
import adminActions from '@pro/web-common/core/admin/actions';
import { getIsSignIn, getRole } from '@pro/web-common/core/user/selectors';
import { getSignInAsUserInfo, getSignOutFromUserState } from '@pro/web-common/core/admin/selectors';
import { getBrand } from '@pro/web-common/core/brand/selectors';
import { isMobile } from '@pro/web-common/utils/style';

import ModalConductor from '@pro/web-common/containers/modal-conductor';
import { UserRoleContext, BrandContext } from '@pro/web-common/containers/providers';
import { getIsDemoUser, getIsOwner, getIsProfile, getIsSuperAdmin } from '@pro/web-common/core/user/utils';

import NavigationDrawer from '@pro/web-common/components/navigation-drawer';
import Footer from '@pro/web-common/components/footer';

import theme from 'theme';
import meta from 'content/meta';


const App = React.memo(({ init, signOut, isSignIn, role, brand, signInAsUserInfo, signOutFromUserInfo, signOutFromUser, loginDemo }) => {
  useEffect(() => {
    init();

    const { pathname } = window.location;
    const shouldRedirect = isMobile && pathname.indexOf('home') === -1 && pathname.indexOf('gdpr') === -1;
    if (shouldRedirect) {
      if (isSignIn) {
        history.push('/admin-home');
      } else {
        history.push('/login');
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {meta.default}

      <Router history={history}>
        <BrandContext.Provider value={{ brand }}>
          <UserRoleContext.Provider
            value={{
              isOwner: getIsOwner(role),
              isDemo: getIsDemoUser(role),
              isProfile: getIsProfile(role),
              isSuperAdmin: getIsSuperAdmin(role),
            }}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />

              <NavigationDrawer
                signOut={signOut}
                loginDemo={loginDemo}
                isSignIn={isSignIn}
                role={role}
                signInAsUserInfo={signInAsUserInfo}
                signOutFromUserInfo={signOutFromUserInfo}
                signOutFromUser={signOutFromUser}
              />

              <AppRouter />

              <ModalConductor />

              <Footer />
            </ThemeProvider>
          </UserRoleContext.Provider>
        </BrandContext.Provider>
      </Router>
    </>
  );
});


App.propTypes = {
  brand: PropTypes.shape({}),
  init: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  loginDemo: PropTypes.func.isRequired,
  isSignIn: PropTypes.bool.isRequired,
  role: PropTypes.string,
  signInAsUserInfo: PropTypes.shape({}),
  signOutFromUserInfo: PropTypes.shape({}),
  signOutFromUser: PropTypes.func.isRequired,
};

App.defaultProps = {
  brand: null,
  role: null,
  signInAsUserInfo: null,
  signOutFromUserInfo: null,
};

const mapStateToProps = (state) => ({
  isSignIn: getIsSignIn(state),
  role: getRole(state),
  brand: getBrand(state),
  signInAsUserInfo: getSignInAsUserInfo(state),
  signOutFromUserInfo: getSignOutFromUserState(state),
});

const mapDispatchToProps = {
  init: appActions.init,
  signOut: userActions.signOut,
  signOutFromUser: adminActions.signOutFromUser,
  loginDemo: userActions.loginDemo,
};


export default connect(mapStateToProps, mapDispatchToProps)(App);
