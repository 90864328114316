import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  html: ({ fontColor }) => ({
    margin: theme.spacing(1 / 2, 0),
    color: parseRgbaColor(fontColor),
  }),
  imageContainer: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  titleTextContainer: ({ typeface, menuItemTextColor }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.spacing(1 / 2, 0),
    fontFamily: typeface,
    color: parseRgbaColor(menuItemTextColor),
  }),
  titleText: ({ typeface }) => ({
    fontSize: '17px',
    fontFamily: typeface,
  }),
  contentContainer: ({ backgroundColor }) => ({
    backgroundColor,
  }),
}));
