import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  expansionPanelExpanded: {
    margin: '0!important',
  },
  expansionPanelRoot: {
    boxShadow: 'none',
    marginBottom: theme.spacing(1 / 2),
    backgroundColor: 'transparent',

    '&:before': {
      display: 'none',
    },
  },
  expansionPanelSummaryRoot: ({ menuTitleBackgroundColor, menuTitleTextColor, typeface }) => ({
    backgroundColor: parseRgbaColor(menuTitleBackgroundColor),
    color: parseRgbaColor(menuTitleTextColor),
    padding: theme.spacing(0, 1),
    borderRadius: theme.shape.borderRadius,
    fontFamily: typeface,

    '&, &.Mui-expanded': {
      minHeight: theme.spacing(3),
    },
  }),
  expansionPanelSummaryContent: ({ typeface }) => ({
    fontFamily: typeface,

    '&, &.Mui-expanded': {
      margin: theme.spacing(1 / 2, 0),
    },
  }),
  expansionPanelSummaryExpanded: {},
  expansionPanelSummaryExpandIcon: ({ menuTitleTextColor }) => ({
    color: parseRgbaColor(menuTitleTextColor),
    padding: theme.spacing(0, 1),
  }),
  expansionPanelDetailsRoot: ({ backgroundColor }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // backgroundColor: parseRgbaColor(backgroundColor),
  }),
  titleText: ({ typeface }) => ({
    fontWeight: 'bold',
    fontSize: '17px',
    fontFamily: typeface,
  }),
  html: ({ fontColor }) => ({
    margin: theme.spacing(1 / 2, 0),
    color: parseRgbaColor(fontColor),
    fontSize: '17px',
  }),
  imageContainer: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
