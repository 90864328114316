import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { styles } from './styles';


const RtfPageContentPreview = React.memo(({ html }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <div
        className="ck-content"
        dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
      />
    </Box>
  );
});


RtfPageContentPreview.propTypes = {
  html: PropTypes.string.isRequired,
};

RtfPageContentPreview.defaultProps = {};


export default RtfPageContentPreview;
