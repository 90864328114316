import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, move } from 'ramda';

import update from 'immutability-helper';

import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import ProductService from '@pro/web-common/core/product/service';
import { BrandContext } from '@pro/web-common/containers/providers';

import SectionWithSortableSubsections from '@pro/web-common/components/section-with-sortable-subsections';

import LargeDialog from '@pro/web-common/components/large-dialog';
import SortableList from '@pro/web-common/components/sortable-list';
import SortableListItem from '@pro/web-common/components/sortable-list-item';
import AddTranslation from '@pro/web-common/components/add-translation';
import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';
import ActionConfirmationModal from '@pro/web-common/components/action-confirmation-modal';
import InfoSection from '@pro/web-common/components/info-section';
import PageLinkCopy from '@pro/web-common/components/page-link-copy';
import ManualSectionContentEditor from '@pro/web-common/components/manual-section-content-editor';

import infoJson from 'constants/info';

import { withFormik } from 'formik';
import { findIndexByProp, generateId } from '@pro/web-common/utils';
import { MANUAL_PAGE_EDITOR_ADD_INFO_TEXT, DEFAULT_MANUAL_CONTENT } from 'content/texts';

import { getSectionDefaultProps } from './utils';
import { styles } from './styles';


const ManualPageEditor = React.memo(({ isOpened, onClose, withTranslation, isSubmitDisabled, pages, currentPageId, ...formikProps }) => {
  const classes = styles();
  const { brand } = useContext(BrandContext);
  const { hasSubscriptions } = brand || {};

  const {
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    dirty,
  } = formikProps;

  const [expandedSectionId, setExpandedSectionId] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const [isCloseConfirmationModalVisible, setIsCloseConfirmationModalVisible] = useState(false);
  const [isDeletionConfirmationModalVisible, setIsDeletionConfirmationModalVisible] = useState(false);
  const [deletingSection, setDeletingSection] = useState(null);

  const onAddSection = useCallback(() => {
    setFieldValue('sections', values.sections.concat({
      ...getSectionDefaultProps(),
      subsections: [],
    }));
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSectionDataChange = useCallback(({ id, data }) => {
    const index = findIndexByProp(values.sections, 'id', id);
    const newSections = update(values.sections, { [index]: { $merge: data } });

    setFieldValue('sections', newSections);
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSectionDelete = useCallback(({ id }, forceDeletion) => {
    const index = findIndexByProp(values.sections, 'id', id);

    if (!forceDeletion) {
      const section = values.sections[index];
      setDeletingSection({ section });
      setIsDeletionConfirmationModalVisible(true);
    } else {
      const newSections = update(values.sections, { $splice: [[index, 1]] });
      setFieldValue('sections', newSections);
    }
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubSectionDelete = useCallback(({ sectionId, subsectionId }, forceDeletion) => {
    const sectionIndex = findIndexByProp(values.sections, 'id', sectionId);
    const subsectionIndex = findIndexByProp(values.sections[sectionIndex].subsections, 'id', subsectionId);

    if (!forceDeletion) {
      const subsection = values.sections[sectionIndex].subsections[subsectionIndex];
      setDeletingSection({
        subsection,
        subsectionParentId: sectionId,
      });
      setIsDeletionConfirmationModalVisible(true);
    } else {
      const newSections = update(values.sections, {
        [sectionIndex]: {
          subsections: { $splice: [[subsectionIndex, 1]] },
        },
      });

      setFieldValue('sections', newSections);
    }
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeDeletionConfirmationModal = useCallback(() => {
    setDeletingSection(null);
    setIsDeletionConfirmationModalVisible(false);
  }, []);

  const onSectionDeletionConfirm = useCallback(() => {
    const { section, subsection, subsectionParentId } = deletingSection;

    closeDeletionConfirmationModal();

    if (section) {
      onSectionDelete({ id: section.id }, true);
    } else {
      onSubSectionDelete({
        sectionId: subsectionParentId,
        subsectionId: subsection.id,
      }, true);
    }
  }, [deletingSection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubSectionDataChange = useCallback(({ sectionId, subsectionId, data }) => {
    const sectionIndex = findIndexByProp(values.sections, 'id', sectionId);
    const subsectionIndex = findIndexByProp(values.sections[sectionIndex].subsections, 'id', subsectionId);
    const newSections = update(values.sections, {
      [sectionIndex]: {
        subsections: {
          [subsectionIndex]: { $merge: data },
        },
      },
    });

    setFieldValue('sections', newSections);
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddSubsection = useCallback(({ id }) => {
    const index = findIndexByProp(values.sections, 'id', id);
    const newSections = update(values.sections, {
      [index]: {
        subsections: { $push: [getSectionDefaultProps()] },
      },
    });

    setFieldValue('sections', newSections);
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setFieldValue('sections', move(oldIndex, newIndex, values.sections));
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubsectionsSortEnd = useCallback((id, { oldIndex, newIndex }) => {
    const index = findIndexByProp(values.sections, 'id', id);
    const newSubSections = move(oldIndex, newIndex, values.sections[index].subsections);
    const newSections = update(values.sections, {
      [index]: {
        subsections: { $set: newSubSections },
      },
    });

    setFieldValue('sections', newSections);
  }, [values.sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTranslate = async () => {
    setIsTranslating(true);

    const { data: translatedSections } = await ProductService.translateManual(values.sections, values.language);
    const translatedSectionsWithImages = translatedSections.map(({ id, image, subsections, ...rest }, index) => {
      const initialSectionData = values.sections[index];
      return ({
        id: generateId(), // NOTE: update ids to re-mount component
        image: initialSectionData.image,
        subsections: subsections.map((data, idx) => ({
          ...data,
          image: initialSectionData.subsections[idx].image,
        })),
        ...rest,
      });
    });
    setFieldValue('sections', translatedSectionsWithImages);
    setFieldValue('isTranslated', true);

    setIsTranslating(false);
  };

  const handleClose = useCallback(() => {
    if (dirty) {
      setIsCloseConfirmationModalVisible(true);
    } else {
      onClose();
      setExpandedSectionId(null);
    }
  }, [dirty, onClose]);

  const onCloseConfirm = useCallback(() => {
    setIsCloseConfirmationModalVisible(false);
    setExpandedSectionId(null);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (isOpened) {
      resetForm();
    }
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LargeDialog
      open={isOpened}
      onClose={handleClose}
      disableEnforceFocus
    >
      <Box
        pl={2}
        pr={2}
      >
        <DialogTitle id="alert-dialog-title">
          <InfoSection
            containerProps={{
              display: 'inline-block',
            }}
            infoMessage={infoJson.product.manualTranslation}
          >
            {MANUAL_PAGE_EDITOR_ADD_INFO_TEXT}
          </InfoSection>
        </DialogTitle>

        {
          pages.length && (
            <Box
              pl={3}
              pr={3}
            >
              <PageLinkCopy
                pages={pages}
                currentPageId={currentPageId}
              />
            </Box>
          )
        }

        <ComponentWithSpinner isSubmitting={isTranslating}>
          <form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              {((!values.isTranslated && withTranslation) || !hasSubscriptions) && (
                <Box mb={4}>
                  <AddTranslation
                    onTranslate={onTranslate}
                    language={values.language}
                    onChange={(value) => setFieldValue('language', value)}
                    disabled={!hasSubscriptions}
                  />
                </Box>
              )}

              <SortableList
                onSortEnd={onSortEnd}
                useDragHandle
                lockAxis="y"
              >
                {values.sections.map(({ id, ...rest }, index) => (
                  <SortableListItem
                    key={id}
                    index={index}
                    isInModal
                  >
                    <SectionWithSortableSubsections
                      sectionId={id}
                      data={rest}
                      expandedSectionId={expandedSectionId}
                      toggleSection={(sectionId) => setExpandedSectionId(sectionId)}
                      onSectionDataChange={(data) => onSectionDataChange({
                        id,
                        data,
                      })}
                      onSubSectionDataChange={(subsectionId, data) => onSubSectionDataChange({
                        sectionId: id,
                        subsectionId,
                        data,
                      })}
                      onSectionDelete={() => onSectionDelete({ id })}
                      onSubSectionDelete={(subsectionId) => onSubSectionDelete({
                        sectionId: id,
                        subsectionId,
                      })}
                      onAddSubsection={() => onAddSubsection({ id })}
                      onSubsectionsSortEnd={(data) => onSubsectionsSortEnd(id, data)}
                      SectionContentEditor={ManualSectionContentEditor}
                      SubsectionContentEditor={ManualSectionContentEditor}
                    />
                  </SortableListItem>
                ))}
              </SortableList>

              <Box className={classes.addSectionButtonContainer}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<AddIcon />}
                  onClick={onAddSection}
                >
                  Add section
                </Button>
              </Box>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
              <Button
                type="submit"
                size="small"
                color="primary"
                variant="contained"
                disabled={isSubmitDisabled}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </ComponentWithSpinner>

        {isDeletionConfirmationModalVisible && (
          <ActionConfirmationModal
            title="Deletion confirmation"
            text="Are you sure you want to delete section?"
            onClose={() => closeDeletionConfirmationModal()}
            onConfirm={onSectionDeletionConfirm}
          />
        )}

        {isCloseConfirmationModalVisible && (
          <ActionConfirmationModal
            title="Are you sure you want to exit?"
            text="You have not save your edits. Updates will be missed if you exit."
            onClose={() => setIsCloseConfirmationModalVisible(false)}
            onConfirm={onCloseConfirm}
          />
        )}
      </Box>
    </LargeDialog>
  );
});

ManualPageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  withTranslation: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape(({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }))).isRequired,
  currentPageId: PropTypes.string.isRequired,
};

ManualPageEditor.defaultProps = {
  withTranslation: false,
};


export default withFormik({
  mapPropsToValues: ({ initialValues, withTranslation }) => {
    const defaultValues = initialValues && !isEmpty(initialValues) && !isEmpty(initialValues.sections) ? initialValues : DEFAULT_MANUAL_CONTENT;
    const {
      sections = [
        {
          ...getSectionDefaultProps(),
          subsections: [],
        },
      ],
      language = '',
      isTranslated = false,
    } = defaultValues;

    const values = {
      sections,
      language: withTranslation ? language : '',
      isTranslated,
    };

    return values;
  },
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values),
  enableReinitialize: true,
})(ManualPageEditor);
