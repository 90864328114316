import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';

import { constants } from './actions';


const defaultState = {
  users: [],
  createAdmin: {
    fetching: false,
    error: null,
  },
  createDemoUser: {
    fetching: false,
    error: null,
  },
  signInAsUserInfo: null,
  signInAsUser: {
    fetching: false,
    error: null,
  },
  signOutFromUser: {
    fetching: false,
    error: null,
  },
};

export default handleActions({
  [resolved(constants.WATCH_USERS)]: (state, { payload: { data } }) => (
    update(state, {
      users: { $set: data },
    })
  ),

  [constants.CREATE_ADMIN]: (state) => (
    update(state, {
      createAdmin: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.CREATE_ADMIN)]: (state) => (
    update(state, {
      createAdmin: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.CREATE_ADMIN)]: (state, { payload: { error } }) => (
    update(state, {
      createAdmin: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.CREATE_DEMO_USER]: (state) => (
    update(state, {
      createDemoUser: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.CREATE_DEMO_USER)]: (state) => (
    update(state, {
      createDemoUser: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.CREATE_DEMO_USER)]: (state, { payload: { error } }) => (
    update(state, {
      createDemoUser: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.SIGN_IN_AS_USER]: (state) => (
    update(state, {
      signInAsUser: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.SIGN_IN_AS_USER)]: (state, { payload: { data } }) => (
    update(state, {
      signInAsUserInfo: {
        $set: {
          uid: data.id,
          email: data.email,
          brandId: data.brandId,
        },
      },
      signInAsUser: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.SIGN_IN_AS_USER)]: (state, { payload: { error } }) => (
    update(state, {
      signInAsUser: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.SIGN_OUT_FROM_USER]: (state) => (
    update(state, {
      signOutFromUser: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.SIGN_OUT_FROM_USER)]: (state) => (
    update(state, {
      signInAsUserInfo: { $set: null },
      signOutFromUser: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.SIGN_OUT_FROM_USER)]: (state, { payload: { error } }) => (
    update(state, {
      signOutFromUser: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [resolved(userConstants.SIGN_OUT)]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
