import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  titleContainer: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    color: theme.palette.common.white,
  },
  buttonStyle: {
    marginRight: 20,
  },
}));
