import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import memoize from 'lodash.memoize';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import {
  AppBar,
  Container,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Toolbar,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

import { UserRoleContext } from '@pro/web-common/containers/providers';

import NavigationDrawerListItem from '@pro/web-common/components/navigation-drawer-list-item';
import Logo from '@pro/web-common/components/logo';
import DemoBanner from '@pro/web-common/components/demo-banner';

import { TOP_LINKS } from 'constants/navigation-drawer-config';

import { styles } from './styles';


const getLink = memoize(({ id, label, to, unauthorizedOnly, authorizedOnly, allowForRoles }, isSignIn, role) => {
  let isVisible;

  isVisible = allowForRoles ? allowForRoles.includes(role) : true;
  isVisible = isVisible && (unauthorizedOnly ? !isSignIn : true);
  isVisible = isVisible && (authorizedOnly ? isSignIn : true);

  return isVisible && (
    <NavigationDrawerListItem
      key={id}
      label={label}
      to={to}
    />
  );
}, (...args) => JSON.stringify(args));

const NavigationDrawer = React.memo(({ signOut, isSignIn, role, signInAsUserInfo, signOutFromUserInfo, signOutFromUser, loginDemo }) => {
  const classes = styles();

  const { isDemo } = useContext(UserRoleContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = useCallback((open, { type, key }) => {
    if (type === 'keydown' && (key === 'Tab' || key === 'Shift')) {
      return;
    }

    setIsOpen(open);
  }, []);

  const openDrawer = useCallback((e) => toggleDrawer(true, e), []); // eslint-disable-line react-hooks/exhaustive-deps
  const closeDrawer = useCallback((e) => toggleDrawer(false, e), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
      >
        <Container
          maxWidth={false}
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Logo />
            </Grid>

            {isDemo && (
              <Grid item>
                <Box
                  display={{
                    xs: 'none',
                    sm: 'block',
                  }}
                >
                  <DemoBanner signOut={signOut} />
                </Box>
              </Grid>
            )}

            <Grid item>
              <Box
                display="flex"
                alignItems="center"
              >
                {isSignIn && signInAsUserInfo && (
                  <Box
                    display={{
                      xs: 'none',
                      sm: 'flex',
                    }}
                    alignItems="center"
                  >
                    <Typography variant="body2">
                      Logged in as {signInAsUserInfo.email}
                    </Typography>

                    <Box
                      ml={1}
                      mr={1}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => signOutFromUser()}
                      >
                        Logout
                        {signOutFromUserInfo.fetching && (
                          <Box
                            ml={1}
                            display="flex"
                            alignItems="center"
                          >
                            <CircularProgress
                              size={12}
                            />
                          </Box>
                        )}
                      </Button>
                    </Box>
                  </Box>
                )}

                {
                  !isSignIn && (
                    <>
                      <Box
                        display={{
                          xs: 'none',
                          sm: 'block',
                        }}
                        mr={2}
                      >
                        <Button
                          component={NavLink}
                          to="/login"
                          color="inherit"
                          variant="outlined"
                        >
                          Login
                        </Button>
                      </Box>

                      <Box
                        display={{
                          xs: 'none',
                          sm: 'block',
                        }}
                        mr={2}
                      >
                        <Button
                          component={NavLink}
                          to="/sign-up"
                          variant="contained"
                          color="primary"
                        >
                          Sign up free
                        </Button>
                      </Box>
                    </>
                  )
                }

                <Toolbar disableGutters>
                  <IconButton
                    aria-label="open drawer"
                    onClick={openDrawer}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              </Box>
            </Grid>
          </Grid>

          {isDemo && (
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Box
                  mb={2}
                  display={{
                    xs: 'block',
                    sm: 'none',
                  }}
                >
                  <DemoBanner signOut={signOut} />
                </Box>
              </Grid>
            </Grid>
          )}

          {isSignIn && signInAsUserInfo && (
            <Box
              display={{
                xs: 'flex',
                sm: 'none',
              }}
              alignItems="center"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography
                variant="body2"
                noWrap
              >
                {signInAsUserInfo.email}
              </Typography>

              <Box
                ml={1}
                mr={1}
              >
                <Button
                  variant="outlined"
                  onClick={() => signOutFromUser()}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          )}
        </Container>

        <Drawer
          anchor="right"
          open={isOpen}
          onClose={closeDrawer}
        >
          <div className={classes.closeIconContainer}>
            <IconButton onClick={closeDrawer}>
              <ChevronRightIcon />
            </IconButton>
          </div>

          <Divider />

          <div
            className={classes.menuContainer}
            role="presentation"
            onClick={closeDrawer}
            onKeyDown={closeDrawer}
          >
            <List className={classes.menuList}>
              {TOP_LINKS.map((link) => getLink(link, isSignIn, role))}

              {isSignIn && (
                <NavigationDrawerListItem
                  label="Logout"
                  onClick={signOut}
                  isButton
                />
              )}

              {!isSignIn && (
                <NavigationDrawerListItem
                  label="Try demo"
                  onClick={() => loginDemo()}
                  isButton
                />
              )}
            </List>
          </div>
        </Drawer>
      </AppBar>
    </>
  );
});


NavigationDrawer.propTypes = {
  signOut: PropTypes.func.isRequired,
  isSignIn: PropTypes.bool.isRequired,
  role: PropTypes.string,
  signInAsUserInfo: PropTypes.shape({
    email: PropTypes.string,
  }),
  signOutFromUserInfo: PropTypes.shape({
    fetching: PropTypes.bool,
  }),
  signOutFromUser: PropTypes.func.isRequired,
  loginDemo: PropTypes.func.isRequired,
};

NavigationDrawer.defaultProps = {
  role: null,
  signInAsUserInfo: null,
  signOutFromUserInfo: null,
};


export default NavigationDrawer;
