import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  searchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
  input: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  languageContainer: {
    marginLeft: theme.spacing(1),

    '& > img': {
      width: theme.spacing(2),
      marginRight: theme.spacing(1 / 2),
    },
  },
}));
