import React, { useContext, useMemo } from 'react';

import clsx from 'clsx';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  ImportExport as ImportExportIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  SettingsApplications as SettingsApplicationsIcon,
} from '@material-ui/icons';

import { BrandContext } from '@pro/web-common/containers/providers';
import { parseRgbaColor } from '@pro/web-common/utils/style';

import { DEFAULT_MESSAGES, DEFAULT_TAGS } from 'content/messages';
import { styles } from './styles';


const ManualPageContentPreview = React.memo(() => {
  const { brand: { typeface, fontColor, sectionHeaderColor, sectionHeaderTextColor } } = useContext(BrandContext);

  const classes = styles({ typeface, fontColor, sectionHeaderColor, sectionHeaderTextColor });

  const parsedSectionHeaderColor = useMemo(() => parseRgbaColor(sectionHeaderColor), [sectionHeaderColor]);
  const parsedSectionHeaderTextColor = useMemo(() => parseRgbaColor(sectionHeaderTextColor), [sectionHeaderTextColor]);

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.tagsContainer}>
          {DEFAULT_TAGS.map(({ id, title }, index) => (
            <Box
              className={clsx(classes.tag, {
                [classes.tagActive]: index === 0, // make first item active
              })}
              bgcolor={fade(parsedSectionHeaderColor, sectionHeaderColor.a * (1 - (DEFAULT_TAGS.length - index) * 0.1))}
              key={id}
            >
              {title}
            </Box>
          ))}

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flex={1}
            bgcolor={fade(parsedSectionHeaderColor, sectionHeaderColor.a * 0.9)}
            color={parsedSectionHeaderTextColor}
          >
            <Box
              className={`${classes.iconContainer} ${classes.searchIconContainer}`}
            >
              <SearchIcon
                fontSize="small"
              />
            </Box>

            <Box
              className={classes.iconContainer}
            >
              <SettingsApplicationsIcon
                fontSize="small"
              />
            </Box>
          </Box>
        </Box>

        {DEFAULT_MESSAGES.map(({ id, title, image, date }) => (
          <Box
            key={id}
            className={classes.messageContainer}
          >
            <Box className={classes.image}>
              <img
                src={image}
                alt="message"
              />
            </Box>

            <Box className={classes.content}>
              <Typography
                variant="body2"
                className={classes.text}
              >
                {title}
              </Typography>

              <Typography
                variant="caption"
                className={classes.text}
              >
                {date}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        pr={1 / 2}
      >
        <VisibilityIcon
          fontSize="small"
          color="disabled"
        />

        <Box ml={1}>
          <ImportExportIcon
            fontSize="small"
            color="disabled"
          />
        </Box>
      </Box>
    </Box>
  );
});


export default ManualPageContentPreview;
