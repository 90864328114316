import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1 / 2),
  },
  link: ({ small }) => ({
    color: theme.palette.common.white,
    textDecoration: 'none',
    cursor: 'pointer',
    ...(small ? { fontSize: '0.7rem' } : {}),

    '&:hover': {
      textDecoration: 'underline',
    },
  }),
}));
