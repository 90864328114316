import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import {
  ClickAwayListener,
  Box,
  Slider,
  Typography,
} from '@material-ui/core';
import { SketchPicker } from 'react-color';

import { colorPropsSchema } from '@pro/web-common/utils/style';

import {
  convertTransparencyToInt,
  convertTransparencyToFloat,
} from './utils';

import { styles } from './styles';


const ColorPicker = React.memo(({ label, color: defaultColor, onColorChange, withTransparencyPicker, withMarginBottom }) => {
  const [color, setColor] = useState(defaultColor);
  const [isPickerOpened, setIsPickerOpened] = useState(false);

  const transparency = useMemo(() => convertTransparencyToInt(color.a), [color]);

  const classes = styles({ color });

  const closeColorPicker = useCallback(() => setIsPickerOpened(false), []);
  const openColorPicker = useCallback(() => setIsPickerOpened(true), []);

  const handleTransparencyChange = useCallback((e, value) => {
    setColor({
      ...color,
      a: convertTransparencyToFloat(value),
    });
  }, [color]);

  const [debouncedOnColorChange] = useDebouncedCallback(() => {
    onColorChange(color);
  }, 10);

  useEffect(() => {
    debouncedOnColorChange();
  }, [color]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={`${classes.container} ${withMarginBottom && classes.containerMarginBottom}`}>
      <Box className={classes.colorContainer}>
        <Box
          className={`${classes.color} ${withMarginBottom && classes.colorMarginBottom}`}
          onClick={openColorPicker}
        />
        {isPickerOpened && (
          <Box className={classes.pickerContainer}>
            <ClickAwayListener
              onClickAway={closeColorPicker}
            >
              <SketchPicker
                color={color}
                onChange={({ rgb }) => setColor(rgb)}
              />
            </ClickAwayListener>
          </Box>
        )}

        {label && (
          <Typography variant="caption">
            {label}
          </Typography>
        )}
      </Box>

      {withTransparencyPicker && (
        <Box className={classes.sliderContainer}>
          <Slider
            value={transparency}
            onChange={handleTransparencyChange}
            aria-labelledby="input-slider"
          />
          <Typography
            variant="caption"
            className={classes.transparencyText}
          >
            {transparency}%
          </Typography>
        </Box>
      )}
    </Box>
  );
});

ColorPicker.propTypes = {
  label: PropTypes.string,
  color: colorPropsSchema.isRequired,
  onColorChange: PropTypes.func.isRequired,
  withTransparencyPicker: PropTypes.bool,
  withMarginBottom: PropTypes.bool,
};

ColorPicker.defaultProps = {
  label: null,
  withTransparencyPicker: true,
  withMarginBottom: true,
};


export default ColorPicker;
