import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { styles } from './styles';


const UserListingItem = React.memo(({ firstName, lastName, products, onEdit, onDelete, isDeleting }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <Typography>
        {firstName} {lastName}
        {products.length > 0 ? ' - ' : ''}
        {products.join(', ')}
      </Typography>

      <Box
        display="flex"
        flexDirection="row"
      >
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>

        <IconButton
          onClick={onDelete}
          disabled={isDeleting}
        >
          <DeleteIcon color="secondary" />
        </IconButton>
      </Box>
    </Box>
  );
});

UserListingItem.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.string).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
};

UserListingItem.defaultProps = {
  onDelete: () => null,
  isDeleting: false,
};


export default UserListingItem;
