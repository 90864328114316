import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import FileInput from '@pro/web-common/components/file-input';
import { TYPES } from '@pro/web-common/components/file-input/config';
import { generateId } from '@pro/web-common/utils';


const ImageUploader = React.memo(({ formats, onFileChange, ...rest }) => {
  const fileInputId = useMemo(() => generateId(), []);

  const onLogoChange = useCallback((file) => {
    if (formats.includes(file.type)) {
      const reader = new FileReader();

      reader.onload = ({ target: { result } }) => {
        onFileChange({ file, filePath: result });
      };

      reader.readAsDataURL(file);
    }
  }, [onFileChange, formats]);

  return (
    <FileInput
      id={fileInputId}
      onChange={(file) => onLogoChange(file)}
      formats={formats}
      type={TYPES.IMAGE}
      {...rest}
    />
  );
});

ImageUploader.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  formats: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ImageUploader.defaultProps = {};


export default ImageUploader;
