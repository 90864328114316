import { createSelector } from 'reselect';


const getLocalState = (state) => state.brand;

export const getBrandUpdatingState = createSelector(
  getLocalState,
  ({ updatingState: { fetching, error } }) => ({ fetching, error }),
);

export const getFetching = createSelector(
  getLocalState,
  ({ fetching }) => fetching,
);

export const getBrand = createSelector(
  getLocalState,
  ({ data }) => data,
);
