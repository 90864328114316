import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import { Box, Typography } from '@material-ui/core';

import { sliderSettings } from './config';
import { styles } from './styles';


const ImagePageContentPreview = React.memo(({ images, productName }) => {
  const classes = styles();

  return (
    <>
      {images && images.length > 0 ? (
        <Slider
          {...sliderSettings}
        >
          {images.map(({ filePath }) => (
            <img
              key={filePath}
              className={classes.slideImage}
              src={filePath}
              alt="slide"
            />
          ))}
        </Slider>
      ) : (
        <Box className={classes.generatedLogoContainer}>
          <Typography
            className={classes.generatedLogoText}
          >
            {productName}
          </Typography>
        </Box>
      )}
    </>
  );
});


ImagePageContentPreview.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    filePath: PropTypes.string,
  })).isRequired,
  productName: PropTypes.string.isRequired,
};

ImagePageContentPreview.defaultProps = {};


export default ImagePageContentPreview;
