import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Button, Link } from '@material-ui/core';

import { SUBSCRIPTION_STATUS } from '@pro/web-common/core/subscriptions/constants';
import InfoSection from '@pro/web-common/components/info-section';
import SubscriptionsStatuses from '@pro/web-common/components/subscription-statuses';
import SubscriptionActions from '@pro/web-common/components/subscription-actions';
import InfoTooltip from '@pro/web-common/components/info-tooltip';

import { PLAN_TYPE_FREE_ID } from 'constants/subscriptions-config';
import { isTestVersion } from 'constants/product-config';

import { styles } from './styles';


const SubscriptionPlan = React.memo(({
  id,
  planTypeId,
  title,
  features,
  status,
  canUserSubscribe,
  trialEnd,
  currentPeriodEnd,
  cancelAt,
  onCancel,
  onRenew,
  onRenewInactive,
  onUpgrade,
  disabled,
  isBankTransfer,
  availableProducts,
  trialStart,
  isSuperAdmin,
  onBankTransferUpdate,
  onBankTransferDelete,
}) => {
  const classes = styles();
  const isActive = status === SUBSCRIPTION_STATUS.ACTIVE;
  const isAwaitCancellation = isActive && !!cancelAt;
  const isIncomplete = status === SUBSCRIPTION_STATUS.INCOMPLETE;
  const isTrial = planTypeId === PLAN_TYPE_FREE_ID;

  const freeTrialText = isTestVersion ? 'Free' : 'Free Trial';
  const statusText = useMemo(() => status === SUBSCRIPTION_STATUS.TRIALED ? freeTrialText : status, [status]);

  const handleBankTransferUpdate = useCallback(() => {
    onBankTransferUpdate({ id, startDate: trialStart, endDate: trialEnd, countOfProducts: availableProducts });
  }, [trialStart, trialEnd, availableProducts]);

  const handleBankTransferDelete = useCallback(() => {
    onBankTransferDelete(id);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Box className={classes.titleContainer}>
        <Typography
          className={classes.text}
          variant="h5"
        >
          {title}
        </Typography>

        {
          isBankTransfer && (
            <Box>
              <Typography
                className={classes.text}
                variant="caption"
              >
                Expires at: {trialEnd}
              </Typography>
            </Box>
          )
        }

        {(!isTestVersion || !isTrial) && (
          <SubscriptionsStatuses
            isTrial={isTrial}
            isActive={isActive}
            isAwaitCancellation={isAwaitCancellation}
            trialEnd={trialEnd}
            currentPeriodEnd={currentPeriodEnd}
            cancelAt={cancelAt}
            className={classes.text}
            variant="caption"
          />
        )}
      </Box>

      {planTypeId && onRenew && onCancel && !isBankTransfer && (
        <Box
          textAlign="right"
          mt={1}
          mb={1}
        >
          <SubscriptionActions
            canUserSubscribe={canUserSubscribe}
            isActive={isActive}
            isAwaitCancellation={isAwaitCancellation}
            isIncomplete={isIncomplete}
            isTrial={isTrial}
            onCancel={onCancel}
            onRenew={isAwaitCancellation ? onRenew : onRenewInactive}
            onUpgrade={onUpgrade}
            disabled={disabled}
          />
        </Box>
      )}

      {isBankTransfer && isSuperAdmin && onBankTransferUpdate && onBankTransferDelete && (
        <Box
          mt={1}
          mb={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleBankTransferUpdate}
            style={styles.button}
          >
            Edit
          </Button>

          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <Box ml={2}>
            <Link
              component="button"
              variant="body2"
              onClick={handleBankTransferDelete}
            >
              Delete
            </Link>
          </Box>

          {/* eslint-enable jsx-a11y/anchor-is-valid */}
        </Box>
      )}

      <Box
        mt={1}
        pl={4}
      >
        {statusText && (
          <Typography
            component="div"
            gutterBottom
          >
            Status:{' '}
            <Box
              component="span"
              fontWeight="bold"
              fontStyle="italic"
            >
              {statusText}
              {isIncomplete && (
                <InfoTooltip
                  text="Follow the link from your email to complete payment process"
                  fontSize="small"
                />
              )}
            </Box>
          </Typography>
        )}

        {isBankTransfer && (
          <Typography
            component="div"
            gutterBottom
          >
            Available products:{' '}
            <Box
              component="span"
              fontWeight="bold"
              fontStyle="italic"
            >
              {availableProducts}
            </Box>
          </Typography>
        )}

        {isBankTransfer && (
          <Typography
            component="div"
            gutterBottom
          >
            Start date:{' '}
            <Box
              component="span"
              fontWeight="bold"
              fontStyle="italic"
            >
              {trialStart}
            </Box>
          </Typography>
        )}

        {features.map(({ id: featureId, label, description }) => (
          <InfoSection
            infoMessage={description || ''}
            key={featureId}
            left={-33}
          >
            <Typography gutterBottom>
              {label}
            </Typography>
          </InfoSection>
        ))}
      </Box>
    </Box>
  );
});

SubscriptionPlan.propTypes = {
  id: PropTypes.string.isRequired,
  planTypeId: PropTypes.string,
  title: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
  })).isRequired,
  status: PropTypes.string,
  canUserSubscribe: PropTypes.bool.isRequired,
  trialEnd: PropTypes.string,
  currentPeriodEnd: PropTypes.string,
  cancelAt: PropTypes.string,
  onCancel: PropTypes.func,
  onRenew: PropTypes.func,
  onRenewInactive: PropTypes.func,
  onUpgrade: PropTypes.func,
  disabled: PropTypes.bool,
  isBankTransfer: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  availableProducts: PropTypes.number,
  trialStart: PropTypes.string,
  onBankTransferUpdate: PropTypes.func,
  onBankTransferDelete: PropTypes.func,
};

SubscriptionPlan.defaultProps = {
  planTypeId: null,
  trialEnd: null,
  currentPeriodEnd: null,
  cancelAt: null,
  disabled: false,
  onCancel: null,
  onRenew: null,
  onRenewInactive: null,
  onUpgrade: null,
  status: null,
  availableProducts: null,
  trialStart: null,
  onBankTransferUpdate: null,
  onBankTransferDelete: null,
};


export default SubscriptionPlan;
