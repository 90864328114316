import firebase from '@pro/web-common/services/firebase';
import 'firebase/functions';

import { DEFAULT_REGION } from './constants';


class FirebaseFunctionsService {
  constructor () {
    this.functions = firebase.app().functions(DEFAULT_REGION);
  }

  call (functionName, data) {
    return this.functions.httpsCallable(functionName)(data);
  }
}


const firebaseFunctions = new FirebaseFunctionsService();
export default firebaseFunctions;
