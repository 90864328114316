import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  InputAdornment,
} from '@material-ui/core';
import {
  Search as SearchIcon,
} from '@material-ui/icons';


const SearchTextField = React.memo(({ id, name, label, placeholder, ...rest }) => (
  <TextField
    variant="outlined"
    fullWidth
    id={id}
    label={label}
    InputLabelProps={{ shrink: true }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    placeholder={placeholder}
    name={name}
    {...rest}
  />
));

SearchTextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

SearchTextField.defaultProps = {
  id: 'searchStr',
  placeholder: 'Search',
  name: 'searchStr',
};


export default SearchTextField;
