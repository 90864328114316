import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: ({ withFit, withImage, width, height, error }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: `${width}px`,
    height: `${height}px`,
    border: '1px dashed',
    borderColor: error ? theme.palette.error.main : theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    ...(!withImage && { cursor: 'pointer' }),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    '& > img': {
      ...(withFit && {
        objectFit: 'cover',
        objectPosition: 'center',
        height: '100%',
      }),
    },
  }),
  warningContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
  },
  iconContainer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    cursor: 'pointer',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  labelText: {
    fontSize: '0.6rem',
  },
}));
