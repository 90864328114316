import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core';

import { styles } from './styles';


const Checkbox = React.memo(({ value, label, error, labelClasses, ...rest }) => {
  const classes = styles({ error });

  return (
    <FormControlLabel
      control={(
        <MUICheckbox
          color="primary"
          className={classes.checkboxRoot}
          checked={value}
          value={value}
          {...rest}
        />
      )}
      label={label}
      classes={{ label: classes.label, ...labelClasses }}
    />
  );
});

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  error: PropTypes.bool,
  value: PropTypes.bool,
  labelClasses: PropTypes.shape({}),
};

Checkbox.defaultProps = {
  error: false,
  value: false,
  labelClasses: {},
};


export default Checkbox;
