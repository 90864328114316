import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'INIT',
  'GET_FACEBOOK_PAGES',
  'CONNECT',
  'LOGOUT',
  'DISCONNECT',
  'GET_FEED',
], 'FACEBOOK');

export const actions = createActions({
  [constants.INIT]: () => null,
  [constants.GET_FACEBOOK_PAGES]: () => null,
  [constants.CONNECT]: () => null,
  [constants.LOGOUT]: () => null,
  [constants.DISCONNECT]: () => null,
  [constants.GET_FEED]: ({ pageId }) => ({ pageId }),
}, {
  prefix: 'FACEBOOK',
});


export default actions;
