import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import update from 'immutability-helper';

import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Box,
  Button,
} from '@material-ui/core';

import { BrandContext } from '@pro/web-common/containers/providers';
import Checkbox from '@pro/web-common/components/checkbox';

import { PLAN_TYPES } from 'constants/subscriptions-config';


const DisableProductsModal = ({ onClose, onSubmit, planTypeId, products: initialProducts, productsToBlockIds }) => {
  const { brand } = useContext(BrandContext);
  const { availableProducts } = brand || {};

  const [products, setProducts] = useState(initialProducts);
  const [selectedProductsCount, setSelectedProductsCount] = useState(0);

  const { availableProducts: planProductsCount } = useMemo(() => PLAN_TYPES.find(({ id }) => id === planTypeId), [planTypeId]);
  const unsubscribeProductsCount = useMemo(() => planProductsCount - availableProducts, [availableProducts, planProductsCount]);

  const setProductSelection = useCallback(({ index, checked }) => {
    const newProductsValues = update(products, {
      [index]: {
        isChecked: { $set: checked },
      },
    });

    setProducts(newProductsValues);
  }, [products]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = useCallback(() => {
    onSubmit({ productsIds: products.filter(({ isChecked }) => isChecked).map(({ id }) => id) });
  }, [products]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newSelectedProductsCount = products.filter(({ isChecked }) => isChecked).length;

    setSelectedProductsCount(newSelectedProductsCount);
  }, [products]);

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Disable products
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          If you cancel subscription {unsubscribeProductsCount} product{unsubscribeProductsCount > 1 ? 's' : ''} will be
          limited in admin console as only subscription is deactivated. <br />
          Please choose which products should be limited:
        </DialogContentText>

        <Box
          mt={2}
          mb={2}
        >
          <Grid
            container
            spacing={2}
          >
            {products.map(({ id, isChecked, productName }, index) => (
              <Grid
                key={id}
                item
                xs={4}
              >
                <Checkbox
                  id={id}
                  name={id}
                  label={productName}
                  value={isChecked}
                  onChange={({ target: { checked } }) => setProductSelection({
                    index,
                    checked,
                  })}
                  disabled={productsToBlockIds.indexOf(id) > -1 || (!isChecked && selectedProductsCount === unsubscribeProductsCount)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <DialogActions>
          <Button
            color="primary"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={selectedProductsCount !== unsubscribeProductsCount}
          >
            Continue
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

DisableProductsModal.propTypes = {
  planTypeId: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  productsToBlockIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DisableProductsModal.defaultProps = {};


export default DisableProductsModal;
