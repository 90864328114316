import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';

import { constants } from './actions';


const defaultState = {
  isInitialized: false,
  isConnected: false,
  pages: [],
  userId: null,
  userAccessToken: null,
  isFetching: false,

  feed: {
    data: [],
    profile: {},
    isFetching: false,
  },
};

export default handleActions({
  [resolved(constants.INIT)]: (state) => (
    update(state, {
      isInitialized: { $set: true },
    })
  ),

  [combineActions(
    constants.GET_FACEBOOK_PAGES,
    constants.CONNECT,
    constants.DISCONNECT,
  )]: (state) => (
    update(state, {
      isFetching: { $set: true },
    })
  ),

  [combineActions(
    resolved(constants.GET_FACEBOOK_PAGES),
    resolved(constants.CONNECT),
  )]: (state, { payload: { data } }) => (
    update(state, {
      isConnected: { $set: !!data },
      pages: { $set: data ? data.pages : [] },
      userId: { $set: data ? data.userId : null },
      userAccessToken: { $set: data ? data.userAccessToken : null },
      isFetching: { $set: false },
    })
  ),

  [combineActions(
    rejected(constants.GET_FACEBOOK_PAGES),
    rejected(constants.CONNECT),
    resolved(constants.DISCONNECT),
    rejected(constants.DISCONNECT),
  )]: (state) => (
    update(state, {
      isFetching: { $set: false },
    })
  ),

  [constants.GET_FEED]: (state) => (
    update(state, {
      feed: {
        isFetching: { $set: true },
      },
    })
  ),
  [resolved(constants.GET_FEED)]: (state, { payload: { data, profile } }) => (
    update(state, {
      feed: {
        profile: { $set: profile },
        data: { $set: data },
        isFetching: { $set: false },
      },
    })
  ),
  [rejected(constants.GET_FEED)]: (state) => (
    update(state, {
      feed: {
        isFetching: { $set: false },
      },
    })
  ),

  [combineActions(
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
    resolved(constants.DISCONNECT),
  )]: (state) => (
    update(state, {
      $set: {
        ...defaultState,
        isInitialized: state.isInitialized,
      },
    })
  ),
}, defaultState);
