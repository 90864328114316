import * as Yup from 'yup';


export const formValidationSchema = () => Yup.object().shape({
  password: Yup.string()
    .min(6)
    .required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required(),
});
