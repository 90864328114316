import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
  },
  tag: ({ sectionHeaderTextColor }) => ({
    padding: theme.spacing(3 / 4, 1),
    color: fade(parseRgbaColor(sectionHeaderTextColor), sectionHeaderTextColor.a * 0.6),
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  }),
  tagActive: ({ sectionHeaderTextColor }) => ({
    color: parseRgbaColor(sectionHeaderTextColor),
    cursor: 'default',
  }),
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  image: {
    width: '30%',

    '& img': {
      maxWidth: '100%',
    },
  },
  content: () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    paddingLeft: theme.spacing(2),
  }),
  contentTitle: {
    fontSize: '1.25rem',
  },
  contentDistance: {
    marginTop: theme.spacing(1 / 2),
    marginLeft: theme.spacing(1 / 2),
  },
  text: ({ typeface, fontColor }) => ({
    fontFamily: typeface,
    color: parseRgbaColor(fontColor),
  }),
  noContent: {
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
}));
