import { getFirebaseTime } from '@pro/web-common/utils/date';


export const PRODUCTS_NODE = 'products';
export const PRODUCTS_ORDER_NODE = 'productsOrder';

export const CLOUD_FUNCTIONS = {
  TRANSLATE_MANUAL: 'productTranslateManual',
  PRODUCT_UPDATE_USERS: 'productUpdateUsers',
  PRODUCT_UNLOCK: 'productUnlock',
  PRODUCT_COPY: 'productCopy',
};

export const PRODUCT_DEFAULT_FIELDS = {
  isLimited: false,
  createdAt: getFirebaseTime(),
};
