import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Card, Box, Typography } from '@material-ui/core';

import { actions as userActions } from '@pro/web-common/core/user/actions';
import { getSendResetPasswordLinkState } from '@pro/web-common/core/user/selectors';

import SendResetLinkForm from '@pro/web-common/components/send-reset-link-form';

import meta from 'content/meta';


const ForgotPassword = React.memo(({ sendResetPasswordLinkState: { error, fetching }, sendResetLink }) => (
  <>
    {meta.forgotPassword}

    <Container maxWidth="xs">
      <Card>
        <Box p={2}>
          <Box mb={4}>
            <Typography
              variant="h6"
            >
              Reset Password
            </Typography>
          </Box>

          <SendResetLinkForm
            fetching={fetching}
            isError={!!error}
            onSubmit={sendResetLink}
          />
        </Box>
      </Card>
    </Container>
  </>
));

ForgotPassword.propTypes = {
  sendResetPasswordLinkState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  sendResetLink: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  sendResetPasswordLinkState: getSendResetPasswordLinkState(state),
});

const mapDispatchToProps = {
  sendResetLink: userActions.sendResetPasswordLink,
};


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
