import * as Yup from 'yup';


export const formValidationSchema = () => Yup.object().shape({
  startDate: Yup.date().required(),
  endDate: Yup.date().min(new Date()).required(),
  countOfProducts: Yup.number()
    .positive()
    .required(),
});
