import * as Yup from 'yup';


function emailHasDomain (ref) {
  return this.test({
    name: 'hasDomain',
    exclusive: false,
    params: {
      reference: ref.path,
    },
    test: function (value) { // eslint-disable-line object-shorthand,func-names
      const websiteValue = this.resolve(ref);
      const domain = value && value.slice(value.indexOf('@') + 1);

      return !websiteValue || (domain && websiteValue && websiteValue.indexOf(domain) > -1);
    },
  });
}

Yup.addMethod(Yup.string, 'emailHasDomain', emailHasDomain);


export const REGEXP = {
  URL: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  URL_WITH_HTTP: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  URL_WITH_HTTPS: /^(https:\/\/www\.|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  PHONE_NUMBER: /^\+?[0-9]+(-?\s?[0-9]+)+$/,
  URL_FACEBOOK: /^https?:\/\/.*facebook\.com\/[a-zA-Z0-9.]+\/?$/,
  YOUTUBE_ID: /^[a-zA-Z0-9_-]{11}$/,
};
