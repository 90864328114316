import { eventChannel } from 'redux-saga';
import { put, takeLatest, call, take, all, cancelled, select } from 'redux-saga/effects';

import { resolvedAction, rejectedAction } from '@pro/web-common/core/actions';

import { constants as appConstants } from '@pro/web-common/core/app/actions';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';

import { getRole, getBrandId } from '@pro/web-common/core/user/selectors';
import UserService from '@pro/web-common/core/user/service';
import { normalizeUserData } from '@pro/web-common/core/user/utils';

import { subscribeSagaToUserSession } from '@pro/web-common/core/app/sagas';

import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';
import { ROLES } from '@pro/web-common/core/user/constants';

import FirebaseAuthService from '@pro/web-common/services/auth';

import { MODAL_MESSAGE } from 'content/modals';

import AdminService from './service';
import { constants } from './actions';


/*
 * Sagas
 */

function* init () {
  yield all([
    call(subscribeSagaToUserSession, watchUsers, false),
    call(subscribeSagaToUserSession, getSignInAsUserInfo),
  ]);
}

function* getWatchUsersChannel () {
  return eventChannel((emit) => {
    const unsubscribe = AdminService.watchUsers((data) => emit({ data }));
    return unsubscribe;
  });
}

function* watchUsers () {
  const role = yield select(getRole);
  if (role !== ROLES.SUPER_ADMIN) {
    return;
  }

  const channel = yield call(getWatchUsersChannel);

  while (channel) {
    try {
      const { data } = yield take(channel);

      yield put(resolvedAction(constants.WATCH_USERS, { data }));
    } catch ({ message }) {
      yield put(rejectedAction(constants.WATCH_USERS, { error: message }));
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* createAdmin ({ payload: { email, password } }) {
  try {
    yield call(AdminService.createAdmin, email, password);

    yield put(resolvedAction(constants.CREATE_ADMIN));
    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.CREATE_ADMIN_SUCCESS },
    }));
  } catch ({ message }) {
    yield put(rejectedAction(constants.CREATE_ADMIN, { error: message }));
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));
  }
}

function* createDemoUser ({ payload: { email, password } }) {
  try {
    yield call(AdminService.createDemoUser, email, password);

    yield put(resolvedAction(constants.CREATE_DEMO_USER));
  } catch ({ message }) {
    yield put(rejectedAction(constants.CREATE_DEMO_USER, { error: message }));
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));
  }
}

function* signInAsUser ({ payload: { id } }) {
  try {
    const { data } = yield call(AdminService.signInAsUser, id);

    yield call([FirebaseAuthService, FirebaseAuthService.refreshToken]);

    yield put(resolvedAction(constants.SIGN_IN_AS_USER, { data }));
  } catch ({ message }) {
    yield put(rejectedAction(constants.SIGN_IN_AS_USER, { error: message }));
  }
}

function* signOutFromUser () {
  try {
    yield call(AdminService.signOutFromUser);

    yield put(resolvedAction(constants.SIGN_OUT_FROM_USER));
  } catch ({ message }) {
    yield put(rejectedAction(constants.SIGN_OUT_FROM_USER, { error: message }));
  }
}

function* getSignInAsUserInfo () {
  const role = yield select(getRole);
  if (role !== ROLES.SUPER_ADMIN) {
    return;
  }

  const brandId = yield select(getBrandId);
  const [data] = yield call(UserService.getOwnerByBrandId, brandId);
  if (!data) {
    return;
  }

  const normalizedData = normalizeUserData(data);
  yield put(resolvedAction(constants.SIGN_IN_AS_USER, { data: normalizedData }));
}

function* updateAllStatistics () {
  try {
    yield call(AdminService.updateAllStatistics);

    yield put(resolvedAction(constants.UPDATE_ALL_STATISTICS));
  } catch ({ message }) {
    yield put(rejectedAction(constants.UPDATE_ALL_STATISTICS, { error: message }));
  }
}

/*
 * Watchers
 */

function* initWatcher () {
  yield take(appConstants.INIT);
  yield call(init);
}

function* createAdminWatcher () {
  yield takeLatest(constants.CREATE_ADMIN, createAdmin);
}

function* createDemoUserWatcher () {
  yield takeLatest(constants.CREATE_DEMO_USER, createDemoUser);
}

function* signInAsUserWatcher () {
  yield takeLatest(constants.SIGN_IN_AS_USER, signInAsUser);
}

function* signOutFromUserWatcher () {
  yield takeLatest(constants.SIGN_OUT_FROM_USER, signOutFromUser);
}

function* updateAllStatisticsWatcher () {
  yield takeLatest(constants.UPDATE_ALL_STATISTICS, updateAllStatistics);
}


export default [
  initWatcher,
  createAdminWatcher,
  createDemoUserWatcher,
  signInAsUserWatcher,
  signOutFromUserWatcher,
  updateAllStatisticsWatcher,
];
