import React from 'react';
import PropTypes from 'prop-types';

import ImageUploader from '@pro/web-common/components/image-uploader';
import ImageWithPlaceholder from '@pro/web-common/components/image-with-placeholder';

import { imagesFormats } from './config';


const SingleImageUploader = React.memo(({ image, imagePreviewWidth, imagePreviewHeight, onImageDelete, onImageAdd, error, ...rest }) => image ? (
  <ImageWithPlaceholder
    image={image.filePath}
    width={imagePreviewWidth}
    height={imagePreviewHeight}
    onDelete={onImageDelete}
    {...rest}
  />
) : (
  <ImageUploader
    formats={imagesFormats}
    width={imagePreviewWidth}
    height={imagePreviewHeight}
    onFileChange={onImageAdd}
    error={error}
  />
));

SingleImageUploader.propTypes = {
  image: PropTypes.shape({
    filePath: PropTypes.string,
  }),
  imagePreviewWidth: PropTypes.number,
  imagePreviewHeight: PropTypes.number,
  onImageDelete: PropTypes.func.isRequired,
  onImageAdd: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

SingleImageUploader.defaultProps = {
  image: null,
  imagePreviewWidth: 130,
  imagePreviewHeight: 180,
  error: false,
};


export default SingleImageUploader;
