import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';

import { withFormik } from 'formik';

import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';
import DatePicker from '@pro/web-common/components/date-picker';

import { formValidationSchema } from './config';


const ConfigureBankTransferModal = ({ fetching, isError, onSubmit, onClose, isEdit, initialValues, ...formikProps }) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    resetForm,
  } = formikProps;

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  const minDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }, []);

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
    >
      <DialogTitle id="form-dialog-title">
        {isEdit ? 'Edit' : 'Add'}
        {' '}
        Bank Transfer
      </DialogTitle>

      <DialogContent>
        <Box mb={2}>
          <ComponentWithSpinner isSubmitting={isSubmitting}>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <DatePicker
                    clearable
                    fullWidth
                    variant="dialog"
                    id="startDate"
                    label="Start Date"
                    value={values.startDate}
                    error={!!errors.startDate && touched.startDate}
                    onChange={(value) => setFieldValue('startDate', value)}
                    readOnly
                    disabled
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <DatePicker
                    clearable
                    fullWidth
                    variant="dialog"
                    id="endDate"
                    label="End Date"
                    value={values.endDate}
                    error={!!errors.endDate && touched.endDate}
                    onChange={(value) => setFieldValue('endDate', value)}
                    minDate={minDate}
                    disablePast
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="countOfProducts"
                    label="Count of Products"
                    placeholder="0"
                    name="countOfProducts"
                    autoComplete="countOfProducts"
                    value={values.countOfProducts}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.countOfProducts && touched.countOfProducts}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Box mr={1}>
                      <Button
                        color="primary"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                    </Box>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isEdit ? 'Update' : 'Add'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </ComponentWithSpinner>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ConfigureBankTransferModal.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isError: PropTypes.bool,
  fetching: PropTypes.bool.isRequired,
};

ConfigureBankTransferModal.defaultProps = {
  isEdit: false,
  initialValues: null,
  isSubmitting: false,
  isError: false,
};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      startDate,
      endDate,
      countOfProducts = '',
    } = initialValues || {};

    const values = {
      startDate: startDate ? new Date(startDate) : new Date(),
      endDate: endDate ? new Date(endDate) : null,
      countOfProducts,
    };

    return values;
  },
  handleSubmit: (data, { props: { initialValues, onSubmit } }) => {
    const { id: subscriptionId } = initialValues || {};

    onSubmit(subscriptionId, data);
  },
  validationSchema: () => formValidationSchema(),
  enableReinitialize: true,
})(ConfigureBankTransferModal);
