import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';

import { BrandContext, UserRoleContext } from '@pro/web-common/containers/providers';
import { getProductsList } from '@pro/web-common/core/product/selectors';
import AdminProducts from '@pro/web-common/containers/admin-products';
import AdminMessagesTags from '@pro/web-common/containers/admin-messages-tags';

import AppPreview from '@pro/web-common/components/app-preview';
import PageTitle from '@pro/web-common/components/page-title';
import InfoSection from '@pro/web-common/components/info-section';

import infoJson from 'constants/info';
import {
  ADMIN_HOME_TITLE,
  ADMIN_HOME_SUBTITLE,
} from 'content/texts';


const AdminHome = React.memo(({ products }) => {
  const { isOwner } = useContext(UserRoleContext);
  const { brand } = useContext(BrandContext);

  return (
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title={ADMIN_HOME_TITLE}
            subtitle={ADMIN_HOME_SUBTITLE}
          />

          <Box mt={4}>
            <Card>
              <CardContent>
                <AdminProducts />
              </CardContent>
            </Card>
          </Box>

          {isOwner && (
            <Box mt={4}>
              <InfoSection infoMessage={infoJson.product.messageTags}>
                <Card>
                  <CardContent>
                    <AdminMessagesTags />
                  </CardContent>
                </Card>
              </InfoSection>
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
        >
          {brand && (
            <AppPreview
              brand={brand}
              withLogoPreview
              {...(products && products.length > 0 ? {
                productName: products[0].productName,
                pages: products[0].pages,
                withLogoPreview: false,
              } : {})}
            />
          )}

          {isOwner && (
            <Box
              textAlign="right"
              mt={2}
            >
              <Button
                component={NavLink}
                variant="contained"
                color="primary"
                to="/configure-brand"
              >
                Configure Branding
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
});

AdminHome.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({})),
    productName: PropTypes.string,
  })).isRequired,
};

AdminHome.defaultProps = {};

const mapStateToProps = (state) => ({
  products: getProductsList(state),
});


export default connect(mapStateToProps)(AdminHome);
