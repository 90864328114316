import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
} from '@material-ui/core';

import { styles } from './styles';


const DemoBanner = React.memo(({ signOut }) => {
  const classes = styles();

  const onSignUp = useCallback((event) => {
    event.preventDefault();
    signOut({ withRedirectToSignUp: true });
  }, []);

  return (
    <Button
      onClick={onSignUp}
      color="inherit"
      variant="outlined"
      classes={{ label: classes.buttonLabel }}
    >
      DEMO - Your data will not be saved and some features are disabled. SIGN UP!
    </Button>
  );
});

DemoBanner.propTypes = {
  signOut: PropTypes.func.isRequired,
};

DemoBanner.defaultProps = {};


export default DemoBanner;
