import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import {
  Link,
  Box,
} from '@material-ui/core';

import { getQrCodeLink } from '@pro/web-common/components/product-configurator/utils';
import { useInterval } from '@pro/web-common/utils/hooks';


const QrArtworks = React.memo(({ id, productId, productName, artwork, dimensions, qrPositions }) => {
  const canvas = useRef();
  const [dataUrl, setDataUrl] = useState(null);

  const [qrCodeCanvasContext, setQrCodeCanvasContext] = useState(null);
  const [isIntervalRunning, setIsIntervalRunning] = useState(true);

  useInterval(() => {
    const qrCodeCanvas = document.querySelector('.qrCodeContainer > canvas');

    if (qrCodeCanvas) {
      setQrCodeCanvasContext(qrCodeCanvas.getContext('2d'));
      setIsIntervalRunning(false);
    }
  }, isIntervalRunning ? 500 : null);

  useEffect(() => {
    if (qrCodeCanvasContext !== null) {
      const ctx = canvas.current.getContext('2d');

      const image = new Image();
      image.src = artwork;
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
        qrPositions.forEach(({ x, y, width, height }) => {
          ctx.drawImage(qrCodeCanvasContext.canvas, x, y, width, height);
        });

        setDataUrl(canvas.current.toDataURL());
      };
    }
  }, [artwork, qrCodeCanvasContext, qrPositions]);

  return (
    <>
      <Box
        display="none"
        className="qrCodeContainer"
      >
        <QRCode
          size={512}
          value={getQrCodeLink(productId)}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <canvas
          ref={canvas}
          style={{ maxWidth: '100%' }}
          width={dimensions.width}
          height={dimensions.height}
        />

        <Link
          href={dataUrl}
          download={`${productName}-artwork-${id}.png`}
        >
          Download
        </Link>
      </Box>
    </>
  );
});

QrArtworks.propTypes = {
  id: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  artwork: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  qrPositions: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  })).isRequired,
  productId: PropTypes.string.isRequired,
};

QrArtworks.defaultProps = {};


export default QrArtworks;
