import { pick } from 'ramda';

import { formatDate, getFirestoreTimestampDate } from '@pro/web-common/utils/date';


const SUBSCRIPTION_MODEL = [
  'id',
  'availableProducts',
  'cancelAt',
  'currentPeriodEnd',
  'status',
  'planTypeId',
  'productsToBlock',
  'amount',
  'taxPercent',
  'currency',
];

export const normalizeSubscription = (data) => {
  const { trialStart, trialEnd, cancelAt, currentPeriodEnd } = data;

  return ({
    ...pick(SUBSCRIPTION_MODEL, data),
    ...(trialStart ? { trialStart: formatDate(getFirestoreTimestampDate(trialStart)) } : {}),
    ...(trialEnd ? { trialEnd: formatDate(getFirestoreTimestampDate(trialEnd)) } : {}),
    ...(cancelAt ? { cancelAt: formatDate(getFirestoreTimestampDate(cancelAt)) } : {}),
    ...(currentPeriodEnd ? { currentPeriodEnd: formatDate(getFirestoreTimestampDate(currentPeriodEnd)) } : {}),
  });
};

export const normalizeSubscriptions = (data) => data.map(normalizeSubscription);
