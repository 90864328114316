import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';

import { actions as subscriptionsActions } from '@pro/web-common/core/subscriptions/actions';
import {
  getCreatingState as getSubscriptionCreatingState,
} from '@pro/web-common/core/subscriptions/selectors';
import { getUserPaymentMethods, getCurrencyId, getUserInfoState } from '@pro/web-common/core/user/selectors';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { ERROR_ALERT } from '@pro/web-common/containers/modal-conductor/constants';

import { MODAL_MESSAGE } from 'content/modals';
import { UserRoleContext } from '@pro/web-common/containers/providers';

import SectionTitle from '@pro/web-common/components/section-title';
import CardListingItem from '@pro/web-common/components/card-listing-item';
import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';
import AddPaymentMethodModal from '@pro/web-common/components/add-payment-method-modal';


const AdminCards = React.memo(({ paymentMethods, addPaymentMethod, removePaymentMethod, userPaymentMethodsState: { fetching, error }, openModal }) => {
  const { isDemo } = useContext(UserRoleContext);

  const [isAddPaymentModalVisible, setIsAddPaymentModalVisible] = useState(false);

  const onAddCard = useCallback(() => {
    if (isDemo) {
      return openModal({
        modal: ERROR_ALERT,
        params: { message: MODAL_MESSAGE.DEMO_USER_CANNOT_ADD_CARD },
      })
    }

    return setIsAddPaymentModalVisible(true)
  }, [isDemo]);

  useEffect(() => {
    if (!fetching && !error) {
      setIsAddPaymentModalVisible(false);
    }
  }, [fetching, error]);

  return (
    <>
      <SectionTitle title="Cards" />

      <ComponentWithSpinner
        progressSize={20}
        isSubmitting={fetching}
      >
        {paymentMethods.length === 0 ? (
          <Box textAlign="center">
            <Typography
              variant="body2"
            >
              No cards added
            </Typography>
          </Box>
        ) : (
          paymentMethods.map(({ id, last4 }) => (
            <CardListingItem
              key={id}
              last4={last4}
              onRemove={() => removePaymentMethod({ id })}
            />
          ))
        )}

        <Box
          mt={3}
          textAlign="right"
        >
          <Button
            variant="contained"
            color="primary"
            disabled={fetching}
            onClick={onAddCard}
          >
            Add card
          </Button>
        </Box>
      </ComponentWithSpinner>

      {isAddPaymentModalVisible && (
        <AddPaymentMethodModal
          onClose={() => setIsAddPaymentModalVisible(false)}
          isSubmitting={fetching}
          isError={!!error}
          onAdd={addPaymentMethod}
        />
      )}
    </>
  );
});

AdminCards.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({})),
  removePaymentMethod: PropTypes.func.isRequired,
  addPaymentMethod: PropTypes.func.isRequired,
  userPaymentMethodsState: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
};

AdminCards.defaultProps = {
  paymentMethods: [],
};

const mapStateToProps = (state) => ({
  paymentMethods: getUserPaymentMethods(state),
  creatingState: getSubscriptionCreatingState(state),
  currencyId: getCurrencyId(state),
  userPaymentMethodsState: getUserInfoState(state),
});

const mapDispatchToProps = {
  removePaymentMethod: subscriptionsActions.removePaymentMethod,
  addPaymentMethod: subscriptionsActions.addPaymentMethod,
  openModal: modalConductorActions.openModal,
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminCards);
