import { useEffect, useRef } from 'react';


export const useInterval = (callback, timeout) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick () {
      savedCallback.current();
    }

    if (timeout !== null) {
      const id = setInterval(tick, timeout);
      return () => clearInterval(id);
    }

    return () => null;
  }, [timeout]);
};
