import { all, put, call, takeLatest, select, take } from 'redux-saga/effects';

import { resolvedAction, rejectedAction, resolved } from '@pro/web-common/core/actions';

import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';
import { MESSAGE_TYPE } from '@pro/web-common/core/messages/constants';
import { MESSAGES_PATH, DEMO_PREFIX } from '@pro/web-common/constants/storage';

import { constants as appConstants } from '@pro/web-common/core/app/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { getBrandId, getRole, getUserUID, getUserInfo } from '@pro/web-common/core/user/selectors';
import FileService from '@pro/web-common/core/file/service';
import { ROLES } from '@pro/web-common/core/user/constants';
import { subscribeSagaToProfileSession } from '@pro/web-common/core/app/sagas';

import { getIsDemoUser, getIsOwner, getIsProfile } from '@pro/web-common/core/user/utils';

import { generateId } from '@pro/web-common/utils';
import { formatDate, getStartOfTheDay, getEndOfTheDay } from '@pro/web-common/utils/date';

import { MODAL_MESSAGE } from 'content/modals';

import { getMessageDataFromForm, getQuickMessageDataFromForm, normalizeTrackTraceMessage, normalizeTrackTraceMessages, normalizeTagsForProfile } from './utils';
import MessagesService from './service';
import { constants } from './actions';


/*
 * Sagas
 */

function* init () {
  yield call(subscribeSagaToProfileSession, getTagsForProfile);
}

function* handleMessageImageUpdate (image, messageId) {
  const role = yield select(getRole);
  const isDemoUser = getIsDemoUser(role);

  const { file, ref } = image;
  let newImage = null;
  const isNewImageFile = !!file;

  if (isNewImageFile) {
    const imageId = generateId();
    const filesStorageRef = ref || `${isDemoUser ? DEMO_PREFIX : ''}${MESSAGES_PATH}/${messageId}/${imageId}`;
    const { url } = yield call(FileService.uploadFile, file, filesStorageRef);

    newImage = {
      ref: filesStorageRef,
      url,
    };
  }

  return newImage;
}

function* handleMessagePDFUpdate (defaultPdfRef, newPdfFile, messageId) {
  const role = yield select(getRole);
  const isDemoUser = getIsDemoUser(role);

  const isNewPDFFile = !!newPdfFile;
  let newPdf = null;

  if (isNewPDFFile) {
    const fileId = generateId();
    const filesStorageRef = defaultPdfRef || `${isDemoUser ? DEMO_PREFIX : ''}${MESSAGES_PATH}/${messageId}/${fileId}`;
    newPdf = yield call(FileService.uploadFile, newPdfFile, filesStorageRef);
  }

  return newPdf;
}

function* getMessageData (messageId, data) {
  const userRole = yield select(getRole);
  const isProfile = getIsProfile(userRole);
  const userId = yield select(getUserUID);
  const { image, defaultPdfRef, newPdfFile } = data;

  const newImage = yield call(handleMessageImageUpdate, image, messageId);
  const newPdf = yield call(handleMessagePDFUpdate, defaultPdfRef, newPdfFile, messageId);

  return ({
    brandId: data.brandId,
    ...(isProfile ? { profileId: userId } : {}),
    ...getMessageDataFromForm(data),
    ...(newImage ? { image: newImage } : {}),
    ...(newPdf ? { pdf: newPdf } : {}),
  });
}

function* createMessage ({ payload: { id, data: formData, allProducts } }) {
  const userRole = yield select(getRole);
  const isOwner = getIsOwner(userRole);
  const isDemoUser = getIsDemoUser(userRole);

  try {
    const data = yield call(getMessageData, id, formData);

    if (!isDemoUser) {
      yield call(MessagesService.createMessage, id, {
        ...data,
        allProducts: isOwner ? allProducts : false,
      });
    }

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.MESSAGE_CREATE_SUCCESS },
    }));

    yield put(resolvedAction(constants.CREATE_MESSAGE, {
      id,
      data,
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.CREATE_MESSAGE, { error: message }));
  }
}

function* createQuickMessage ({ payload: { data: formData, allProducts } }) {
  const userRole = yield select(getRole);
  const isOwner = getIsOwner(userRole);
  const isDemoUser = getIsDemoUser(userRole);
  const isProfile = getIsProfile(userRole);
  const userId = yield select(getUserUID);

  try {
    const id = generateId();
    const brandId = yield select(getBrandId);
    const date = formatDate(new Date());
    const data = {
      brandId,
      date,
      createdAt: new Date(),
      ...(formData.type === MESSAGE_TYPE.TRACK_TRACE ? normalizeTrackTraceMessage(formData) : formData),
    };

    if (!isDemoUser) {
      if (formData.type === MESSAGE_TYPE.QUICK) {
        yield call(MessagesService.createQuickMessage, id, {
          ...getQuickMessageDataFromForm(data),
          ...(isProfile ? { profileId: userId } : {}),
          allProducts: isOwner ? allProducts : false,
        });
      } else if (formData.type === MESSAGE_TYPE.TRACK_TRACE) {
        yield call(MessagesService.createTrackTraceMessage, id, data);
      }
    }

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.QUICK_MESSAGE_CREATE_SUCCESS },
    }));

    yield put(resolvedAction(constants.CREATE_QUICK_MESSAGE, {
      id,
      data,
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.CREATE_QUICK_MESSAGE, { error: message }));
  }
}

function* updateMessage ({ payload: { id, data: formData, allProducts } }) {
  const userRole = yield select(getRole);
  const isOwner = getIsOwner(userRole);

  try {
    const data = yield call(getMessageData, id, formData);

    yield call(MessagesService.updateMessage, id, {
      ...data,
      updatedAt: new Date(),
      allProducts: isOwner ? allProducts : false,
    });

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.MESSAGE_UPDATE_SUCCESS },
    }));

    yield put(resolvedAction(constants.UPDATE_MESSAGE, {
      id,
      data,
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.UPDATE_MESSAGE, { error: message }));
  }
}

function* updateTags ({ payload: { data } }) {
  try {
    const role = yield select(getRole);
    const brandId = yield select(getBrandId);

    if (role !== ROLES.DEMO) {
      yield call(MessagesService.updateTags, brandId, {
        brandId,
        tags: data,
      });
    }

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.MESSAGE_TAGS_UPDATE_SUCCESS },
    }));

    yield put(resolvedAction(constants.UPDATE_TAGS, { data }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.UPDATE_TAGS, { error: message }));
  }
}

function* generateUsersReport ({ payload: { productId, fromDate, toDate } }) {
  try {
    const brandId = yield select(getBrandId);

    const { data } = yield call(MessagesService.generateUsersReport, {
      brandId,
      productId,
      fromDate: fromDate ? getStartOfTheDay(fromDate).getTime() : null,
      toDate: toDate ? getEndOfTheDay(toDate).getTime() : null,
    });

    if (data) {
      window.open(data);
    } else {
      yield put(modalConductorActions.openModal({
        modal: ERROR_ALERT,
        params: { message: 'There is no report' }, // TODO: update error message
      }));
    }

    yield put(resolvedAction(constants.GENERATE_USERS_REPORT));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.GENERATE_USERS_REPORT, { error: message }));
  }
}

function* getMessages () {
  try {
    const userRole = yield select(getRole);
    const isProfile = getIsProfile(userRole);
    let requestParams;

    if (isProfile) {
      const userId = yield select(getUserUID);
      requestParams = ['profileId', '==', userId];
    } else {
      const brandId = yield select(getBrandId);
      requestParams = ['brandId', '==', brandId];
    }

    const [messages, quickMessages, trackTraceMessages] = yield all([
      call(MessagesService.getMessages, requestParams),
      call(MessagesService.getQuickMessages, requestParams),
      call(MessagesService.getTrackTraceMessages, requestParams),
    ]);

    yield put(resolvedAction(constants.GET_MESSAGES, {
      messages,
      quickMessages,
      trackTraceMessages: normalizeTrackTraceMessages(trackTraceMessages),
    }));
  } catch ({ message }) {
    yield put(rejectedAction(constants.GET_MESSAGES, { error: message }));
  }
}

function* getTags () {
  try {
    const brandId = yield select(getBrandId);

    const data = yield call(MessagesService.getTags, brandId);

    yield put(resolvedAction(constants.GET_TAGS, { tags: data ? data.tags : [] }));
  } catch ({ message }) {
    yield put(rejectedAction(constants.GET_TAGS, { error: message }));
  }
}

function* getTagsForProfile () {
  try {
    yield take(resolved(userConstants.WATCH_USER));
    const { brands } = yield select(getUserInfo);
    const data = yield call(MessagesService.getTagsForBrands, brands);
    const normalizedData = normalizeTagsForProfile(data);
    yield put(resolvedAction(constants.GET_TAGS, { tags: normalizedData }));
  } catch ({ message }) {
    yield put(rejectedAction(constants.GET_TAGS, { error: message }));
  }
}

function* deleteMessage ({ payload: { id } }) {
  try {
    yield call(MessagesService.deleteMessage, id);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.MESSAGE_DELETE_SUCCESS },
    }));

    yield put(resolvedAction(constants.DELETE_MESSAGE, { id }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.DELETE_MESSAGE, {
      error: message,
      id,
    }));
  }
}

function* deleteQuickMessage ({ payload: { id } }) {
  try {
    yield call(MessagesService.deleteQuickMessage, id);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.QUICK_MESSAGE_DELETE_SUCCESS },
    }));

    yield put(resolvedAction(constants.DELETE_QUICK_MESSAGE, { id }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.DELETE_QUICK_MESSAGE, {
      error: message,
      id,
    }));
  }
}

function* deleteTrackTraceMessage ({ payload: { id } }) {
  try {
    yield call(MessagesService.deleteTrackTraceMessage, id);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.QUICK_MESSAGE_DELETE_SUCCESS },
    }));

    yield put(resolvedAction(constants.DELETE_QUICK_MESSAGE, { id }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.DELETE_QUICK_MESSAGE, {
      error: message,
      id,
    }));
  }
}

/*
 * Watchers
 */

function* initWatcher () {
  yield take(appConstants.INIT);
  yield call(init);
}

function* createMessageWatcher () {
  yield takeLatest(constants.CREATE_MESSAGE, createMessage);
}

function* createQuickMessageWatcher () {
  yield takeLatest(constants.CREATE_QUICK_MESSAGE, createQuickMessage);
}

function* updateMessageWatcher () {
  yield takeLatest(constants.UPDATE_MESSAGE, updateMessage);
}

function* getMessagesWatcher () {
  yield takeLatest(constants.GET_MESSAGES, getMessages);
}

function* getTagsWatcher () {
  yield takeLatest(constants.GET_TAGS, getTags);
}

function* deleteMessageWatcher () {
  yield takeLatest(constants.DELETE_MESSAGE, deleteMessage);
}

function* deleteQuickMessageWatcher () {
  yield takeLatest(constants.DELETE_QUICK_MESSAGE, deleteQuickMessage);
}

function* deleteTrackTraceMessageWatcher () {
  yield takeLatest(constants.DELETE_TRACK_TRACE_MESSAGE, deleteTrackTraceMessage);
}

function* updateTagsWatcher () {
  yield takeLatest(constants.UPDATE_TAGS, updateTags);
}

function* generateUsersReportWatcher () {
  yield takeLatest(constants.GENERATE_USERS_REPORT, generateUsersReport);
}


export default [
  initWatcher,
  createMessageWatcher,
  createQuickMessageWatcher,
  updateMessageWatcher,
  getMessagesWatcher,
  deleteMessageWatcher,
  getTagsWatcher,
  updateTagsWatcher,
  deleteQuickMessageWatcher,
  deleteTrackTraceMessageWatcher,
  generateUsersReportWatcher,
];
