import firebase from '@pro/web-common/services/firebase';
import 'firebase/storage';


class FirebaseStorageService {
  constructor () {
    this.storageInstance = firebase.storage();
    this.storageRef = this.storageInstance.ref();

    this.TaskEvent = firebase.storage.TaskEvent;
    this.TaskState = firebase.storage.TaskState;
  }

  createRef (file, path) {
    return ({
      ref: this.storageRef.child(path),
      refPath: path,
    });
  }

  async uploadFile ({ file, path = null, metadata }) {
    const { ref, refPath } = this.createRef(file, path);
    // NOTE: for some reason Firebase iOS SDK wait for an extension for push notification with image
    // NOTE: and firebase storage override file extension with contentDisposition header
    // NOTE: so we need to reset contentDisposition header and let iOS SDK rely on contentType header
    await ref.put(file, { ...metadata, contentDisposition: 'inline' });
    const url = await ref.getDownloadURL();
    return { url, ref: refPath };
  }

  getUploadTask ({ file, path }) {
    const { ref } = this.createRef(file, path);
    return ref.put(file);
  }

  async getDownloadUrl (path) {
    try {
      const url = await this.storageRef.child(path).getDownloadURL();
      return url;
    } catch ({ code, message }) {
      if (code === 'storage/object-not-found') {
        return null;
      }

      throw new Error(message);
    }
  }
}

const firebaseStorage = new FirebaseStorageService();
export default firebaseStorage;
