export const normalizeData = (brandId, auth, response) => ({
  brandId,
  userId: auth.userID,
  userAccessToken: auth.longLivedAccessToken,
  pages: response.data.map(({ access_token: accessToken, id, name }) => ({
    accessToken,
    id,
    name,
  })),
});
