import React from 'react';
import PropTypes from 'prop-types';

import { PAGE_TYPE } from 'constants/product-config';
import ImagePageContentPreview from '@pro/web-common/components/image-page-content-preview';
import ManualPageContentPreview from '@pro/web-common/components/manual-page-content-preview';
import MessagesPageContentPreview from '@pro/web-common/components/messages-page-content-preview';
import UrlPageContentPreview from '@pro/web-common/components/url-page-content-preview';
import PhoneNumberPageContentPreview from '@pro/web-common/components/phone-number-page-content-preview';
import RtfPageContentPreview from '@pro/web-common/components/rtf-page-content-preview';
import DirectoryPageContentPreview from '@pro/web-common/components/directory-page-content-preview';
import FacebookPageContentPreview from '@pro/web-common/components/facebook-page-content-preview';
import MenuPageContentPreview from '@pro/web-common/components/menu-page-content-preview';
import LocalDirectoryPageContentPreview from '@pro/web-common/components/local-directory-page-content-preview';
import EmptyContentPreview from '@pro/web-common/components/empty-content-preview';


const AppPreviewContent = React.memo(({ pageTypeId, content, isDynamic, productName }) => {
  if (!content) {
    return null;
  }

  if (pageTypeId === PAGE_TYPE.IMAGE.id && ((content.files && content.files.length > 0) || isDynamic)) {
    return (
      <ImagePageContentPreview
        images={content.files}
        productName={productName}
      />
    );
  }

  if (PAGE_TYPE.MANUAL && pageTypeId === PAGE_TYPE.MANUAL.id && content.sections && content.sections.length > 0) {
    return <ManualPageContentPreview sections={content.sections} />;
  }

  if (pageTypeId === PAGE_TYPE.MESSAGES.id) {
    return <MessagesPageContentPreview />;
  }

  if (pageTypeId === PAGE_TYPE.URL.id && content.url) {
    return <UrlPageContentPreview url={content.url} />;
  }

  if (pageTypeId === PAGE_TYPE.PHONE_NUMBER.id && content.phoneNumber) {
    return <PhoneNumberPageContentPreview phoneNumber={content.phoneNumber} />;
  }

  if (pageTypeId === PAGE_TYPE.RTF.id && content.html) {
    return <RtfPageContentPreview html={content.html} />;
  }

  if (PAGE_TYPE.DIRECTORY && pageTypeId === PAGE_TYPE.DIRECTORY.id && content.contacts && content.contacts.length > 0) {
    return <DirectoryPageContentPreview contacts={content.contacts} />;
  }

  if (pageTypeId === PAGE_TYPE.FACEBOOK.id && content.id) {
    return <FacebookPageContentPreview pageId={content.id} />;
  }

  if (PAGE_TYPE.MENU && pageTypeId === PAGE_TYPE.MENU.id && content.sections && content.sections.length > 0) {
    return (
      <MenuPageContentPreview
        stylesConfig={content.stylesConfig}
        sections={content.sections}
        currencyId={content.currencyId}
        footerHtml={content.footerHtml}
      />
    );
  }

  if (PAGE_TYPE.TIMETABLE && pageTypeId === PAGE_TYPE.TIMETABLE.id && content.sections && content.sections.length > 0) {
    return (
      <MenuPageContentPreview
        stylesConfig={content.stylesConfig}
        sections={content.sections}
        currencyId={content.currencyId}
        footerHtml={content.footerHtml}
      />
    );
  }

  if (PAGE_TYPE.CHECK_IN && pageTypeId === PAGE_TYPE.CHECK_IN.id) {
    return <EmptyContentPreview text="Mobile app users will see form to check in here" />;
  }

  if (PAGE_TYPE.LOCAL_DIRECTORY && pageTypeId === PAGE_TYPE.LOCAL_DIRECTORY.id) {
    return (
      <LocalDirectoryPageContentPreview
        directories={content.directories}
      />
    );
  }

  const emptyContentText = (Object.values(PAGE_TYPE).find(({ id }) => id === pageTypeId) || {}).noContentText;

  return (
    <EmptyContentPreview
      {...(emptyContentText ? {
        text: emptyContentText,
      } : {})}
    />
  );
});


AppPreviewContent.propTypes = {
  pageTypeId: PropTypes.string.isRequired,
  content: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.shape({})),
    sections: PropTypes.arrayOf(PropTypes.shape({})),
    directories: PropTypes.arrayOf(PropTypes.shape({})),
    stylesConfig: PropTypes.shape({}),
    currencyId: PropTypes.string,
    url: PropTypes.string,
    phoneNumber: PropTypes.string,
    html: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string,
    footerHtml: PropTypes.string,
  }).isRequired,
  isDynamic: PropTypes.bool,
  productName: PropTypes.string.isRequired,
};

AppPreviewContent.defaultProps = {
  isDynamic: false,
};


export default AppPreviewContent;
