import React from 'react';

import { Container, Box, Typography } from '@material-ui/core';

import { styles } from './styles';


const NotFound = React.memo(() => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Container maxWidth="md">
          <Typography
            variant="h3"
            gutterBottom
          >
            We're sorry! 404 Not Found.
          </Typography>

          <Typography>
            Sorry, requested page does not exist, let us know using chat below if you'd like assistance.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
});

NotFound.propTypes = {};

NotFound.defaultProps = {};


export default NotFound;
