import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    textAlign: 'center',
    fontSize: theme.typography.overline.fontSize,
  },
}));
