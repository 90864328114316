import React from 'react';
import PropTypes from 'prop-types';
import 'date-fns';

import {
  MuiPickersUtilsProvider,
  DateTimePicker as MuiDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { styles } from './styles';


const DateTimePicker = React.memo(({ value, fullWidth, ...rest }) => {
  const classes = styles({ fullWidth });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiDateTimePicker
        variant="inline"
        inputVariant="outlined"
        placeholder="DD/MM/YYYY HH:mm"
        format="dd/MM/yyyy HH:mm"
        value={value}
        className={classes.datepicker}
        InputLabelProps={{ shrink: true }}
        invalidDateMessage={null}
        maxDateMessage={null}
        minDateMessage={null}
        ampm={false}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
});

DateTimePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  fullWidth: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  value: null,
  fullWidth: false,
};


export default DateTimePicker;
