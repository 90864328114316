import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
} from '@material-ui/core';

import { BrandContext } from '@pro/web-common/containers/providers';
import { getDaysFromToday } from '@pro/web-common/utils/date';

import SectionTitle from '@pro/web-common/components/section-title';
import MessageListingItem from '@pro/web-common/components/message-listing-item';
import ConfigureMessageModal from '@pro/web-common/components/configure-message-modal';
import PaginatedList from '@pro/web-common/components/paginated-list';
import InfoSection from '@pro/web-common/components/info-section';


import { MESSAGES_LISTING_NO_DATA_TEXT } from 'content/texts';
import infoJson from 'constants/info';
import { isTestVersion } from 'constants/product-config';


const MessagesListing = React.memo(({ messages, onMessageDelete, creatingState: { fetching: isCreating, error: messageCreationError }, updatingState: { fetching: isUpdating, error: messageUpdateError }, productsOptions, products, onMessageAdd, onMessageUpdate, disabled, tagsSuggestions, selectedProductId, profiles }) => {
  const { brand } = useContext(BrandContext);
  const { hasSubscriptions } = brand || {};

  const isAddMessageDisabled = useMemo(() => {
    const lastMessage = messages[messages.length - 1];
    let isDateMatch = true;

    if (lastMessage) {
      const lastMessageCreatedAt = new Date(lastMessage.createdAt?.seconds ? lastMessage.createdAt.seconds * 1000 : lastMessage.createdAt); // NOTE: fallback for firebase timestamps
      isDateMatch = getDaysFromToday(lastMessageCreatedAt) >= 7;
    }

    return !hasSubscriptions && isTestVersion && !isDateMatch;
  }, [messages, hasSubscriptions]);
  const [isConfigureMessageModalOpened, setIsConfigureMessageModalOpened] = useState(false);
  const [messageData, setMessageData] = useState(null);

  const handleMessageEdit = useCallback((data) => {
    setMessageData(data);
    setIsConfigureMessageModalOpened(true);
  }, []);

  const handleMessageAdd = useCallback((isEdit, messageId, allProducts, data) => {
    if (isEdit) {
      onMessageUpdate({
        id: messageId,
        allProducts,
        data,
      });
    } else {
      onMessageAdd({
        id: messageId,
        allProducts,
        data,
      });
    }
  }, [onMessageAdd, onMessageUpdate]);

  const handleEditMessageModalClose = useCallback(() => {
    setIsConfigureMessageModalOpened(false);
  }, []);

  useEffect(() => {
    if (!isConfigureMessageModalOpened) {
      setMessageData(null);
    }
  }, [isConfigureMessageModalOpened]);

  useEffect(() => {
    if (!isCreating && !messageCreationError && !isUpdating && !messageUpdateError) {
      setIsConfigureMessageModalOpened(false);
    }
  }, [isCreating, messageCreationError, isUpdating, messageUpdateError]);

  return (
    <>
      <SectionTitle title="Messages" />

      <PaginatedList
        data={messages}
        noDataText={MESSAGES_LISTING_NO_DATA_TEXT}
        renderListingItem={({ data: { id, fetching, profileId, ...rest } }) => (
          <MessageListingItem
            key={id}
            onEdit={() => handleMessageEdit({ id, ...rest })}
            onDelete={() => onMessageDelete({ id })}
            isDeleting={fetching}
            profileId={profileId}
            profile={profiles[profileId]}
            {...rest}
          />
        )}
      />

      <Box
        textAlign="right"
        mt={2}
        pr={5}
      >
        <InfoSection
          infoMessage={isAddMessageDisabled ? infoJson.message.imageMessageDisabled : infoJson.message.imageMessage}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsConfigureMessageModalOpened(true)}
            disabled={disabled || isAddMessageDisabled}
          >
            New image message
          </Button>
        </InfoSection>
      </Box>

      {isConfigureMessageModalOpened && (
        <ConfigureMessageModal
          defaultProductId={selectedProductId}
          isEdit={!!messageData}
          initialValues={messageData}
          onClose={handleEditMessageModalClose}
          onSubmit={handleMessageAdd}
          productsOptions={productsOptions}
          products={products}
          isSubmitting={isCreating || isUpdating}
          isError={!!messageCreationError || !!messageUpdateError}
          tagsSuggestions={tagsSuggestions}
        />
      )}
    </>
  );
});

MessagesListing.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
    ]),
  })).isRequired,
  onMessageDelete: PropTypes.func.isRequired,
  productsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onMessageAdd: PropTypes.func.isRequired,
  onMessageUpdate: PropTypes.func.isRequired,
  creatingState: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  updatingState: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  selectedProductId: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  profiles: PropTypes.shape({}).isRequired,
};

MessagesListing.defaultProps = {
  disabled: false,
  selectedProductId: null,
  products: [],
};


export default MessagesListing;
