import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@material-ui/core';

import IconRenderer from '@pro/web-common/components/icon-renderer';

import { styles } from './styles';


const MoreMenuItem = React.memo(({ iconId, icon, label, onClick, typeface, color }) => {
  const classes = styles({ typeface, color });

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={onClick}
    >
      <IconRenderer
        className={classes.icon}
        iconId={iconId}
        icon={icon}
      />

      <Typography
        noWrap
        component="div"
        className={classes.label}
      >
        {label}
      </Typography>
    </Box>
  );
});


MoreMenuItem.propTypes = {
  iconId: PropTypes.string,
  icon: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  typeface: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string.isRequired,
};

MoreMenuItem.defaultProps = {
  iconId: null,
  icon: null,
  onClick: null,
};


export default MoreMenuItem;
