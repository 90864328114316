import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles(() => ({
  toolbar: {
    justifyContent: 'flex-end',
  },
  paper: {
    minHeight: '90%',
  },
}));
