import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';

import update from 'immutability-helper';

import { TextField } from '@material-ui/core';

import { generateId } from '@pro/web-common/utils';

import './styles.css';


const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];
const TagsInput = React.memo(({ tags, onUpdate, disabled, ...rest }) => {
  const handleTagDelete = useCallback((index) => {
    const newTags = update(tags, { $splice: [[index, 1]] });

    onUpdate(newTags);
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTagAddition = useCallback(({ id, name }) => {
    const newTags = tags.concat([{ id: id || generateId(), name }]);

    onUpdate(newTags);
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  return !disabled ? (
    <ReactTags
      addOnBlur
      allowNew
      tags={tags}
      handleDelete={handleTagDelete}
      handleAddition={handleTagAddition}
      delimiters={delimiters}
      placeholder="Add tag"
      autofocus={false}
      {...rest}
    />
  ) : (
    <TextField
      variant="outlined"
      fullWidth
      placeholder="Add tag"
      disabled
    />
  );
});

TagsInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TagsInput.defaultProps = {
  tags: [],
  disabled: false,
};


export default TagsInput;
