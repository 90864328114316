import React from 'react';

import { Container, Grid, Box, Typography } from '@material-ui/core';

import PageTitle from '@pro/web-common/components/page-title';
import AboutUsImage from 'assets/images/about-us/AdobeStock_198887954.jpeg';

import meta from 'content/meta';


const AboutUs = React.memo(() => (
  <>
    {meta.aboutUs}

    <Container maxWidth="md">
      <PageTitle
        title="About us"
        subtitle="This is us. Yes it is."
        large
      />

      <Grid
        container
        justify="space-between"
        spacing={10}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <img
            src={AboutUsImage}
            alt="about us"
          />

          <Box mt={2}>
            <Typography
              variant="h5"
              gutterBottom
            >
              Here&apos;s My Name
            </Typography>

            <Typography
              gutterBottom
            >
              <Box
                component="span"
                fontWeight="fontWeightBold"
              >
                This is my title
              </Box>
            </Typography>

            <Typography>
              Here&apos;s some copy about me. Where I was born and what I love to do.
              This page can also be used for case studies and stuff.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <img
            src={AboutUsImage}
            alt="about us"
          />

          <Box mt={2}>
            <Typography
              variant="h5"
              gutterBottom
            >
              Here&apos;s My Name
            </Typography>

            <Typography
              gutterBottom
            >
              <Box
                component="span"
                fontWeight="fontWeightBold"
              >
                This is my title
              </Box>
            </Typography>

            <Typography>
              Here&apos;s some copy about me. Where I was born and what I love to do.
              This page can also be used for case studies and stuff.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  </>
));

AboutUs.propTypes = {};

AboutUs.defaultProps = {};


export default AboutUs;
