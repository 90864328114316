import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import PlacesAutocomplete from 'react-places-autocomplete';
import { TextField, Paper, MenuItem } from '@material-ui/core';

import { styles } from './styles';


const GoogleAutocomplete = React.memo(({ value, onChange, onBlurEvent, error, ...rest }) => {
  const classes = styles();

  const [inputValue, setInputValue] = useState(value);
  const [address, setAddress] = useState({ value });

  const handleSelect = useCallback((data, placeId) => {
    setInputValue(data);

    setAddress({
      value: data,
      placeId,
    });

    onChange({
      value: data,
      placeId,
    });
  }, []);

  const renderInput = useCallback((getInputProps) => {
    // TODO: autoComplete field wait for bugfix https://bugs.chromium.org/p/chromium/issues/detail?id=914451
    const { onBlur, autoComplete, ...inputProps } = getInputProps();

    const onInputBlur = (e) => {
      onBlur(e);
      onBlurEvent({ value: inputValue });
    };

    return (
      <>
        <TextField
          onBlur={onInputBlur}
          autoComplete="new-password"
          error={error}
          {...inputProps}
          {...rest}
        />
      </>
    );
  }, [onBlurEvent, inputValue, error]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSuggestions = useCallback((suggestions, getSuggestionItemProps) => (
    <Paper className={classes.paper}>
      {suggestions.map((suggestion) => (
        <MenuItem
          component="div"
          {...getSuggestionItemProps(suggestion)}
          key={suggestion.placeId}
        >
          <span>{suggestion.description}</span>
        </MenuItem>
      ))}
    </Paper>
  ), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (address && address.value !== inputValue) {
      setAddress(null);
      onChange(null);
    }
  }, [inputValue, address]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInputValue(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PlacesAutocomplete
      value={inputValue}
      onChange={setInputValue}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div className={classes.container}>
          {renderInput(getInputProps)}
          {renderSuggestions(suggestions, getSuggestionItemProps)}
        </div>
      )}
    </PlacesAutocomplete>
  );
});

GoogleAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlurEvent: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

GoogleAutocomplete.defaultProps = {
  error: false,
};


export default GoogleAutocomplete;
