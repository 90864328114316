import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';


const ActionConfirmationModal = React.memo(({ onClose, onConfirm, title, text }) => (
  <Dialog
    open
    onClose={onClose}
    aria-labelledby="form-dialog-title"
    maxWidth="xs"
    fullWidth
  >
    <DialogTitle
      id="alert-dialog-title"
    >
      {title}
    </DialogTitle>

    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {text}
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button
        onClick={onClose}
        color="primary"
        autoFocus
      >
        Cancel
      </Button>

      <Button
        onClick={onConfirm}
        variant="contained"
        color="primary"
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
));

ActionConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

ActionConfirmationModal.defaultProps = {};


export default ActionConfirmationModal;
