import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  checkboxRoot: (props) => ({
    paddingTop: theme.spacing(1 / 2),
    paddingBottom: theme.spacing(1 / 2),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
    color: props.error && theme.palette.error.main,
  }),
  label: {
    fontSize: '0.75rem',
  },
}));
