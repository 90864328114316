import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: () => ({
    padding: theme.spacing(1.5, 3),
    cursor: 'pointer',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  }),
  icon: ({ color }) => ({
    color,
  }),
  label: ({ typeface, color }) => ({
    marginLeft: theme.spacing(1),
    fontFamily: typeface,
    fontSize: '0.85rem',
    color,
  }),
}));
