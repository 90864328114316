import React, { memo, useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Box,
  Button,
  FormControl,
} from '@material-ui/core';

import Select from '@pro/web-common/components/select';
import InfoSection from '@pro/web-common/components/info-section';

import { getPageInnerLink } from '@pro/web-common/utils/product';

import infoJson from 'constants/info';

import { styles } from './styles';


const PageLinkCopy = memo(({ pages, currentPageId, infoText }) => {
  const classes = styles();

  const pagesOptions = useMemo(() => pages.filter(({ id }) => currentPageId !== id).map(({ id, label }) => ({
    value: getPageInnerLink(id),
    label,
  })), [pages, currentPageId]);

  const [selectedPage, setSelectedPage] = useState(pagesOptions[0].value);

  const handleChange = useCallback(({ target: { value } }) => setSelectedPage(value), []);

  useEffect(() => {
    setSelectedPage(pagesOptions[0].value);
  }, [pagesOptions]);

  return (
    <InfoSection
      containerProps={{
        display: 'inline-block',
      }}
      infoMessage={infoText || infoJson.product.appPageLink}
    >
      <Box className={classes.container}>
        <Box
          mr={[0, 2]}
          mb={[2, 0]}
        >
          <FormControl
            variant="outlined"
            className={classes.formControl}
          >
            <Select
              id="pagesLinkToCopy"
              name="pagesLinkToCopy"
              label="Select product page"
              options={pagesOptions}
              value={selectedPage}
              onChange={handleChange}
            />
          </FormControl>
        </Box>

        <CopyToClipboard
          text={selectedPage}
        >
          <Button
            color="primary"
            variant="contained"
            style={{ alignSelf: 'center' }}
          >
            Copy link to clipboard
          </Button>
        </CopyToClipboard>
      </Box>
    </InfoSection>
  );
});


PageLinkCopy.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape(({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }))).isRequired,
  currentPageId: PropTypes.string,
  infoText: PropTypes.string,
};

PageLinkCopy.defaultProps = {
  currentPageId: null,
  infoText: null,
};

export default PageLinkCopy;
