import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@material-ui/core';


const CaseItem = React.memo(({ image, title, subtitle, alt, description }) => (
  <>
    <img
      src={image}
      alt={alt}
    />

    <Box mt={2}>
      <Typography
        variant="h5"
        gutterBottom
      >
        {title}
      </Typography>

      <Typography
        gutterBottom
      >
        <Box
          component="span"
          fontWeight="fontWeightBold"
        >
          {subtitle}
        </Box>
      </Typography>

      {description && (
        <Typography>
          {description}
        </Typography>
      )}
    </Box>
  </>
));


CaseItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  description: PropTypes.node,
};

CaseItem.defaultProps = {
  description: null,
};


export default CaseItem;
