import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  selectInputLabel: ({ withValue }) => ({
    backgroundColor: withValue ? 'transparent' : theme.palette.background.default,
    paddingLeft: theme.spacing(1 / 2),
    paddingRight: theme.spacing(1 / 2),
  }),
  select: ({ withValue }) => ({
    '&:focus': {
      backgroundColor: withValue ? 'transparent' : theme.palette.background.default,
    },
  }),
  selectRoot: {
    display: 'block',
    flexDirection: 'row',
    alignItems: 'center',

    '& > img': {
      maxWidth: theme.spacing(2),
      marginRight: theme.spacing(1 / 2),
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > img': {
      maxWidth: theme.spacing(2),
      marginRight: theme.spacing(1 / 2),
    },
  },
}));
