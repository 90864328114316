import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: ({ backgroundColor }) => ({
    minHeight: '100%',
    flex: 1,
    backgroundColor: parseRgbaColor(backgroundColor),
    padding: theme.spacing(1),
  }),
}));
