import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


const PREFIX = 'PAYMENT';
export const constants = createConstants([
  'CREATE_PAYMENT_INTENT',
  'PAY_FOR_SETUP',
], PREFIX);

export const actions = createActions({
  [constants.CREATE_PAYMENT_INTENT]: ({ productId, currency }) => ({ productId, currency }),
  [constants.PAY_FOR_SETUP]: ({ id, name, email, website, file }) => ({ id, name, email, website, file }),
}, {
  prefix: PREFIX,
});


export default actions;
