import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1, 3),
    minWidth: 0,
    fontSize: '2rem',
    opacity: 0.5,
  },
  buttonActive: {
    opacity: 1,
  },
}));
