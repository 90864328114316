import * as Yup from 'yup';


export const formValidationSchema = () => Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string()
    .required(),
});
