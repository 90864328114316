export const SUBSCRIPTIONS_NODE = 'subscriptions';
export const CLOUD_FUNCTIONS = {
  ADD_SUBSCRIPTION: 'subscriptionCreate',
  CANCEL_SUBSCRIPTION: 'subscriptionCancel',
  RENEW_SUBSCRIPTION: 'subscriptionRenew',
  ADD_PAYMENT_METHOD: 'subscriptionAddPaymentMethod',
  REMOVE_PAYMENT_METHOD: 'subscriptionRemovePaymentMethod',
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  INCOMPLETE: 'incomplete',
  TRIALED: 'trialed',
};
