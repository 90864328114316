import FirebaseStorageService from '@pro/web-common/services/storage';

import { STATISTICS_NODE } from './constants';


const StatisticsService = {
  getStatistics: async (brandId, productId) => {
    const url = await FirebaseStorageService.getDownloadUrl(`${STATISTICS_NODE}/${brandId}/${productId}`);
    if (!url) {
      return [];
    }
    return fetch(url).then((response) => response.json());
  },
};


export default StatisticsService;
