import React, { useMemo, useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Button,
  Link,
  Typography,
} from '@material-ui/core';

import {
  SUBSCRIPTION_STATUS,
} from '@pro/web-common/core/subscriptions/constants';
import { actions as subscriptionsActions } from '@pro/web-common/core/subscriptions/actions';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import {
  getList as getSubscriptionsList,
  getProductsToBlockList,
  getCreatingState as getSubscriptionCreatingState,
  getBankTransferState,
} from '@pro/web-common/core/subscriptions/selectors';
import {
  getUserHasPaymentMethods,
  getCurrencyId,
  getUserPaymentMethods,
} from '@pro/web-common/core/user/selectors';
import { getProductsList } from '@pro/web-common/core/product/selectors';
import { ERROR_ALERT } from '@pro/web-common/containers/modal-conductor/constants';
import { CURRENCY_LIST, DEFAULT_CURRENCY_ID } from 'constants/currency';
import { MODAL_MESSAGE } from 'content/modals';

import { BrandContext, UserRoleContext } from '@pro/web-common/containers/providers';
import AdminCards from '@pro/web-common/containers/admin-cards';

import SectionTitle from '@pro/web-common/components/section-title';
import SubscriptionPlan from '@pro/web-common/components/subscription-plan';
import AddPaymentMethodModal from '@pro/web-common/components/add-payment-method-modal';
import ActivateProductsModal from '@pro/web-common/components/activate-products-modal';
import DisableProductsModal from '@pro/web-common/components/disable-products-modal';
import PageTitle from '@pro/web-common/components/page-title';
import CurrencySelector from '@pro/web-common/components/currency-selector';
import ConfigureBankTransferModal from '@pro/web-common/components/configure-bank-transfer-modal';

import {
  PLAN_TYPES,
  UPGRADE_PLAN_FEATURES,
  PLAN_TYPE_SINGLE_ID,
  PLAN_TYPE_PRICES,
  ACTIVE_SUBSCRIPTION_LIMIT,
  PLAN_TYPE_BANK_TRANSFER,
} from 'constants/subscriptions-config';

import {
  ADMIN_SUBSCRIPTIONS_TITLE,
  ADMIN_SUBSCRIPTIONS_SUBTITLE,
  ADMIN_SUBSCRIPTIONS_CONTACT_US_LABEL,
} from 'content/texts';

import { CONTACT_EMAIL } from 'constants/contacts';

import {
  UPGRADE_PLAN_TITLE,
  RENEW_SUBSCRIPTION_ACTION,
  RENEW_INACTIVE_SUBSCRIPTION_ACTION,
  ADD_SUBSCRIPTION_ACTION,
} from './config';
import { getCanUserSubscribe } from './utils';


const AdminSubscriptions = React.memo(({
  subscriptions,
  cancelSubscription,
  renewSubscription,
  hasPaymentMethods,
  addSubscription,
  creatingState: { fetching: isCreating, error: creationError },
  bankTransferState: { fetching: isBankTransferFetching, error: bankTransferError },
  products,
  productsToBlockIds,
  currencyId: defaultCurrencyId,
  paymentMethods,
  openModal,
  addBankTransfer,
  updateBankTransfer,
  deleteBankTransfer,
}) => {
  const { isDemo, isSuperAdmin } = useContext(UserRoleContext);

  const [isAddPaymentModalVisible, setIsAddPaymentModalVisible] = useState(false);
  const [isActivateProductsModalVisible, setIsActivateProductsModalVisible] = useState(false);
  const [isDisableProductsModalVisible, setIsDisableProductsModalVisible] = useState(false);
  const [selectedPlanTypeId, setSelectedPlanTypeId] = useState(null);
  const [renewingSubscriptionId, setRenewingSubscriptionId] = useState(null);
  const [cancellingSubscriptionId, setCancellingSubscriptionId] = useState(null);
  const [activatedProductIds, setActivatedProductIds] = useState([]);
  const [disableProductIds, setDisableProductIds] = useState([]);
  const [activatingProductsNextAction, setActivatingProductsNextAction] = useState(null);
  const [currencyId, setCurrencyId] = useState(defaultCurrencyId || DEFAULT_CURRENCY_ID);
  const [canUserSubscribe, setCanUserSubscribe] = useState(true);
  const [isBankTransferModalOpened, setIsBankTransferModalOpened] = useState(false);
  const [bankTransferData, setBankTransferData] = useState(null);

  const { brand } = useContext(BrandContext);
  const { hasCurrencySetup, availableProducts } = brand || {};

  const subscriptionsData = useMemo(() => (
    subscriptions.map(({ planTypeId, amount, taxPercent, currency, ...rest }) => {
      const { title, features = [] } = PLAN_TYPES.find(({ id }) => id === planTypeId);
      const taxMessage = taxPercent ? `(plus ${taxPercent}% VAT)` : '';
      const featuresWithPrice = [
        ...(currency ? [{
          id: 'price',
          label: `Price: ${CURRENCY_LIST[currency].sign}${amount / 100} per month ${taxMessage}`,
        }] : []),
        ...features,
      ];

      const isBankTransfer = planTypeId === PLAN_TYPE_BANK_TRANSFER;

      return ({ planTypeId, title, features: featuresWithPrice, isBankTransfer, ...rest });
    })
  ), [subscriptions]);

  const limitedProducts = useMemo(() => products.filter(({ isLimited }) => isLimited), [products]);
  const activeProducts = useMemo(() => products.filter(({ isLimited }) => !isLimited), [products]);

  const handleSubscriptionActions = useCallback(({ action = activatingProductsNextAction, subscriptionId = renewingSubscriptionId, planTypeId = selectedPlanTypeId, skipProductsSelection = false, productsIds = activatedProductIds }) => {
    if (isDemo) {
      openModal({
        modal: ERROR_ALERT,
        params: { message: MODAL_MESSAGE.DEMO_USER_CANNOT_SUBSCRIBE },
      });
      return;
    }

    setSelectedPlanTypeId(planTypeId);
    setRenewingSubscriptionId(subscriptionId);
    setActivatingProductsNextAction(action);

    if ((skipProductsSelection || limitedProducts.length === 0)) {
      if (action === RENEW_SUBSCRIPTION_ACTION) {
        renewSubscription({
          subscriptionId,
          productsIds,
        });
      } else {
        setIsAddPaymentModalVisible(true);
      }
    } else {
      setIsActivateProductsModalVisible(true);
    }
  }, [hasPaymentMethods, selectedPlanTypeId, renewingSubscriptionId, activatingProductsNextAction, activatedProductIds, limitedProducts.length, isDemo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubscriptionCancellation = useCallback(({ planTypeId = selectedPlanTypeId, subscriptionId = cancellingSubscriptionId, skipProductsSelection = false, productsIds = disableProductIds }) => {
    setSelectedPlanTypeId(planTypeId);
    setCancellingSubscriptionId(subscriptionId);

    const { availableProducts: unsubscribeProductsCount } = PLAN_TYPES.find(({ id }) => id === planTypeId);

    if ((skipProductsSelection || unsubscribeProductsCount <= availableProducts)) {
      cancelSubscription({ subscriptionId, productsIds });
    } else {
      setIsDisableProductsModalVisible(true);
    }
  }, [availableProducts, selectedPlanTypeId, cancellingSubscriptionId, disableProductIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePaymentAdd = useCallback(({ source: { id: sourceId } }) => {
    if (activatingProductsNextAction === RENEW_INACTIVE_SUBSCRIPTION_ACTION) {
      renewSubscription({
        subscriptionId: renewingSubscriptionId,
        productsIds: activatedProductIds,
        source: sourceId,
      });
    } else {
      addSubscription({
        planTypeId: selectedPlanTypeId,
        productsIds: activatedProductIds,
        source: sourceId,
        currency: currencyId,
      });
    }
  }, [selectedPlanTypeId, activatedProductIds, activatingProductsNextAction, renewingSubscriptionId, selectedPlanTypeId, activatedProductIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductsActivation = useCallback(({ productsIds }) => {
    setIsActivateProductsModalVisible(false);
    setActivatedProductIds(productsIds);
    handleSubscriptionActions({ skipProductsSelection: true, productsIds });
  }, [handleSubscriptionActions]);

  const handleProductsDisabling = useCallback(({ productsIds }) => {
    setIsDisableProductsModalVisible(false);
    setDisableProductIds(productsIds);
    handleSubscriptionCancellation({ skipProductsSelection: true, productsIds });
  }, [handleSubscriptionCancellation]);

  const getAvailablePlansButtons = useCallback(() => (
    PLAN_TYPES.filter(({ canAdd }) => canAdd).map(({ id, getAddButtonLabel }) => (
      <Box
        key={id}
        mt={1}
        mb={1}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubscriptionActions({
            action: ADD_SUBSCRIPTION_ACTION,
            planTypeId: id,
          })}
          disabled={isCreating}
        >
          {getAddButtonLabel({
            currencySign: CURRENCY_LIST[currencyId].sign,
            price: PLAN_TYPE_PRICES[id][currencyId].price,
          })}
        </Button>
      </Box>
    ))
  ), [isCreating, currencyId]);

  const handleBankTransferEdit = useCallback((data) => {
    setBankTransferData(data);
    setIsBankTransferModalOpened(true);
  }, []);

  const handleBankTransferDelete = useCallback((id) => {
    deleteBankTransfer({ id });
  }, []);

  const handleBankTransferAdd = useCallback((id, data) => {
    if (id) {
      updateBankTransfer({ id, data });
    } else {
      addBankTransfer({ data });
    }
  }, []);

  useEffect(() => {
    if (!isBankTransferModalOpened) {
      setBankTransferData(null);
    }
  }, [isBankTransferModalOpened]);

  useEffect(() => {
    if (!isAddPaymentModalVisible) {
      setActivatedProductIds([]);
      setSelectedPlanTypeId(null);
    }
  }, [isAddPaymentModalVisible]);

  useEffect(() => {
    setIsAddPaymentModalVisible(false);
    setActivatedProductIds([]);
    setSelectedPlanTypeId(null);
    setRenewingSubscriptionId(null);
    setCanUserSubscribe(getCanUserSubscribe(subscriptionsData, ACTIVE_SUBSCRIPTION_LIMIT));
  }, [subscriptionsData]);

  useEffect(() => {
    if (!isBankTransferFetching && !bankTransferError) {
      setIsBankTransferModalOpened(false);
    }
  }, [isBankTransferFetching, bankTransferError]);

  return (
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <PageTitle
            title={ADMIN_SUBSCRIPTIONS_TITLE}
            subtitle={ADMIN_SUBSCRIPTIONS_SUBTITLE}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
        >
          <Card>
            <CardContent>
              <>
                <SectionTitle title="Subscriptions" />

                {subscriptionsData.map(({ id, planTypeId, fetching, status, isBankTransfer, ...rest }) => (
                  <Box
                    key={id}
                    mt={2}
                    mb={2}
                  >
                    <SubscriptionPlan
                      id={id}
                      planTypeId={planTypeId}
                      status={status}
                      canUserSubscribe={canUserSubscribe}
                      onCancel={() => handleSubscriptionCancellation({ planTypeId, subscriptionId: id })}
                      onRenew={() => handleSubscriptionActions({
                        action: RENEW_SUBSCRIPTION_ACTION,
                        subscriptionId: id,
                        planTypeId,
                        skipProductsSelection: status === SUBSCRIPTION_STATUS.ACTIVE,
                      })}
                      onRenewInactive={() => handleSubscriptionActions({
                        action: RENEW_INACTIVE_SUBSCRIPTION_ACTION,
                        subscriptionId: id,
                        planTypeId,
                        skipProductsSelection: status === SUBSCRIPTION_STATUS.ACTIVE,
                      })}
                      onUpgrade={() => handleSubscriptionActions({
                        action: ADD_SUBSCRIPTION_ACTION,
                        planTypeId: PLAN_TYPE_SINGLE_ID,
                      })}
                      onDelete={handleBankTransferDelete}
                      disabled={fetching || isCreating}
                      {...(isBankTransfer && isSuperAdmin ? {
                        onBankTransferUpdate: handleBankTransferEdit,
                        onBankTransferDelete: handleBankTransferDelete,
                      } : {})}
                      isBankTransfer={isBankTransfer}
                      isSuperAdmin={isSuperAdmin}
                      {...rest}
                    />
                  </Box>
                ))}

                {!hasCurrencySetup && (
                  <SubscriptionPlan
                    canUserSubscribe={canUserSubscribe}
                    title={UPGRADE_PLAN_TITLE}
                    features={UPGRADE_PLAN_FEATURES}
                  />
                )}

                {
                  canUserSubscribe && (
                    <Box
                      textAlign="right"
                      mt={3}
                    >
                      {!defaultCurrencyId && !hasCurrencySetup && (
                        <CurrencySelector
                          currencyId={currencyId}
                          onChange={setCurrencyId}
                        />
                      )}

                      {currencyId && (
                        <>
                          {getAvailablePlansButtons()}

                          {CURRENCY_LIST[currencyId].vat && (
                            <Box mt={1}>
                              <Typography variant="caption">
                                Plus VAT {CURRENCY_LIST[currencyId].vat}
                              </Typography>
                            </Box>
                          )}

                          {ADMIN_SUBSCRIPTIONS_CONTACT_US_LABEL && (
                            <Box mt={1}>
                              <Link
                                color="primary"
                                href={`mailto:${CONTACT_EMAIL}`}
                              >
                                {ADMIN_SUBSCRIPTIONS_CONTACT_US_LABEL}
                              </Link>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  )
                }

              </>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
        >
          <Card>
            <CardContent>
              <AdminCards />
            </CardContent>
          </Card>

          {
            isSuperAdmin && (
              <Box mt={2}>
                <Card>
                  <CardContent>
                    <SectionTitle title="Bank Transfer" />

                    <Box textAlign="right">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsBankTransferModalOpened(true)}
                      >
                        Add Bank Transfer
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            )
          }
        </Grid>
      </Grid>

      {isAddPaymentModalVisible && (
        <AddPaymentMethodModal
          onClose={() => setIsAddPaymentModalVisible(false)}
          isSubmitting={isCreating}
          isError={!!creationError}
          onAdd={handlePaymentAdd}
          paymentMethods={paymentMethods}
        />
      )}

      {isActivateProductsModalVisible && (
        <ActivateProductsModal
          planTypeId={selectedPlanTypeId}
          products={limitedProducts}
          onClose={() => setIsActivateProductsModalVisible(false)}
          onSubmit={handleProductsActivation}
        />
      )}

      {isDisableProductsModalVisible && (
        <DisableProductsModal
          planTypeId={selectedPlanTypeId}
          products={activeProducts}
          onClose={() => setIsDisableProductsModalVisible(false)}
          onSubmit={handleProductsDisabling}
          productsToBlockIds={productsToBlockIds}
        />
      )}

      {isBankTransferModalOpened && (
        <ConfigureBankTransferModal
          isEdit={!!bankTransferData}
          products={products}
          initialValues={bankTransferData}
          onClose={() => setIsBankTransferModalOpened(false)}
          onSubmit={handleBankTransferAdd}
          fetching={isBankTransferFetching}
          isError={!!bankTransferError}
        />
      )}
    </Container>
  );
});

AdminSubscriptions.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  renewSubscription: PropTypes.func.isRequired,
  hasPaymentMethods: PropTypes.bool,
  addSubscription: PropTypes.func.isRequired,
  creatingState: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  bankTransferState: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productsToBlockIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  currencyId: PropTypes.string,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({})),
  openModal: PropTypes.func.isRequired,
  addBankTransfer: PropTypes.func.isRequired,
  updateBankTransfer: PropTypes.func.isRequired,
  deleteBankTransfer: PropTypes.func.isRequired,
};

AdminSubscriptions.defaultProps = {
  hasPaymentMethods: false,
  currencyId: null,
  paymentMethods: [],
};

const mapStateToProps = (state) => ({
  subscriptions: getSubscriptionsList(state),
  products: getProductsList(state),
  productsToBlockIds: getProductsToBlockList(state),
  hasPaymentMethods: getUserHasPaymentMethods(state),
  creatingState: getSubscriptionCreatingState(state),
  currencyId: getCurrencyId(state),
  paymentMethods: getUserPaymentMethods(state),
  bankTransferState: getBankTransferState(state),
});

const mapDispatchToProps = {
  cancelSubscription: subscriptionsActions.cancelSubscription,
  renewSubscription: subscriptionsActions.renewSubscription,
  addSubscription: subscriptionsActions.addSubscription,
  addBankTransfer: subscriptionsActions.addBankTransfer,
  updateBankTransfer: subscriptionsActions.updateBankTransfer,
  deleteBankTransfer: subscriptionsActions.deleteBankTransfer,
  openModal: modalConductorActions.openModal,
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminSubscriptions);
