import FirebaseDatabaseService from '@pro/web-common/services/database';
import FirebaseFunctionsService from '@pro/web-common/services/functions';

import {
  PLAN_TYPE_BANK_TRANSFER,
} from 'constants/subscriptions-config';

import { SUBSCRIPTIONS_NODE, SUBSCRIPTION_STATUS, CLOUD_FUNCTIONS } from './constants';


const SubscriptionsService = {
  watchSubscriptions: (callback, brandId) => FirebaseDatabaseService.watchData({
    refPath: SUBSCRIPTIONS_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    callback,
    whereParams: [
      ['brandId', '==', brandId],
    ],
  }),
  addSubscription: (planTypeId, productsIds, source, currency) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.ADD_SUBSCRIPTION, {
    planTypeId,
    productsIds,
    source,
    currency,
  }),
  cancelSubscription: (subscriptionId, productsIds) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.CANCEL_SUBSCRIPTION, {
    subscriptionId,
    productsIds,
  }),
  renewSubscription: (subscriptionId, productsIds, source) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.RENEW_SUBSCRIPTION, {
    subscriptionId,
    productsIds,
    source,
  }),

  addPaymentMethod: (source) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.ADD_PAYMENT_METHOD, {
    source,
  }),
  removePaymentMethod: (source) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.REMOVE_PAYMENT_METHOD, {
    source,
  }),

  addBankTransfer: (brandId, data) => FirebaseDatabaseService.addData(SUBSCRIPTIONS_NODE, FirebaseDatabaseService.REF_TYPES.DOC, true, {
    availableProducts: Number(data.countOfProducts),
    brandId,
    cancelAt: null,
    currentPeriodEnd: null,
    currentPeriodStart: null,
    planTypeId: PLAN_TYPE_BANK_TRANSFER,
    startDate: new Date(data.startDate),
    status: SUBSCRIPTION_STATUS.ACTIVE,
    stripePlanId: {
      EUR: PLAN_TYPE_BANK_TRANSFER,
      GBP: PLAN_TYPE_BANK_TRANSFER,
      USD: PLAN_TYPE_BANK_TRANSFER,
    },
    trialEnd: new Date(data.endDate),
    trialStart: new Date(data.startDate),
  }),

  updateBankTransfer: (id, data) => FirebaseDatabaseService.updateDoc(`${SUBSCRIPTIONS_NODE}/${id}`, {
    availableProducts: Number(data.countOfProducts),
    trialEnd: new Date(data.endDate),
  }),

  deleteBankTransfer: (id) => FirebaseDatabaseService.deleteDoc(`${SUBSCRIPTIONS_NODE}/${id}`),
};


export default SubscriptionsService;
