import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';


const SubscriptionsStatuses = React.memo(({ isTrial, isActive, isAwaitCancellation, trialEnd, currentPeriodEnd, cancelAt, ...rest }) => (
  <>
    {isTrial && trialEnd && (
      <Typography {...rest}>
        Expires at: {trialEnd}
      </Typography>
    )}

    {isActive && currentPeriodEnd && !isAwaitCancellation && (
      <Typography {...rest}>
        Next payment: {currentPeriodEnd}
      </Typography>
    )}

    {isAwaitCancellation && (
      <Typography {...rest}>
        Active till: {cancelAt}
      </Typography>
    )}
  </>
));

SubscriptionsStatuses.propTypes = {
  isTrial: PropTypes.bool,
  isActive: PropTypes.bool,
  isAwaitCancellation: PropTypes.bool,
  trialEnd: PropTypes.string,
  currentPeriodEnd: PropTypes.string,
  cancelAt: PropTypes.string,
};

SubscriptionsStatuses.defaultProps = {
  isTrial: false,
  isActive: false,
  isAwaitCancellation: false,
  trialEnd: null,
  currentPeriodEnd: null,
  cancelAt: null,
};


export default SubscriptionsStatuses;
