import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { Box } from '@material-ui/core';

import { ROLES } from '@pro/web-common/core/user/constants';

import { BrandContext, UserRoleContext } from '@pro/web-common/containers/providers';
import { getIsSignIn, getRole } from '@pro/web-common/core/user/selectors';
import HeroSection from '@pro/web-common/components/hero-section';
import NotFound from '@pro/web-common/pages/not-found';

import ROUTES from 'constants/routes';

import { styles } from './styles';


const Router = ({ isSignIn, role }) => {
  const classes = styles();

  const { pathname } = useLocation();

  const { isDemo } = useContext(UserRoleContext);
  const { brand } = useContext(BrandContext);
  const { isConfigured } = brand || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      {
        ROUTES.map(({ unauthorizedOnly, authorizedOnly, notLoggedByUserOnly, component: RenderComponent, componentProps, exact, path, allowForRoles, hero }, index) => {
          const preventAccessForRole = allowForRoles && !allowForRoles.includes(role);

          const shouldRedirectToHome = (unauthorizedOnly && isSignIn && brand) || preventAccessForRole;
          const shouldRedirectToLogin = authorizedOnly && !isSignIn;
          const shouldRedirectToSelectUser = role === ROLES.SUPER_ADMIN && brand === null;
          const shouldRedirectToAdminHome = role === ROLES.SUPER_ADMIN && brand && notLoggedByUserOnly;

          return (
            <Route
              key={index} // eslint-disable-line react/no-array-index-key
              exact={exact}
              path={path}
              render={(props) => {
                if (shouldRedirectToAdminHome) {
                  return <Redirect to="/admin-home" />;
                }

                if (shouldRedirectToSelectUser && path !== '/select-user') {
                  return <Redirect to="/select-user" />;
                }

                if ((shouldRedirectToHome || shouldRedirectToLogin) && path !== '/') {
                  return <Redirect to="/" />;
                }

                if (isSignIn && brand && !isConfigured && !isDemo && path !== '/configure-brand') {
                  return <Redirect to="/configure-brand" />;
                }

                return (
                  <Box className={classes.container}>
                    {hero && (<HeroSection {...hero} />)}

                    <Box
                      className={`${classes.contentContainer} ${path === '/' || path === '/home' ? classes.contentContainerHome : ''}`}
                    >
                      <Box className={isDemo ? classes.contentContainerDemo : ''}>
                        <RenderComponent
                          {...props}
                          {...componentProps}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              }}
            />
          );
        })
      }
      <Route component={NotFound} />
    </Switch>
  );
};

Router.propTypes = {
  isSignIn: PropTypes.bool.isRequired,
  role: PropTypes.string,
};

Router.defaultProps = {
  role: null,
};


const mapStateToProps = (state) => ({
  isSignIn: getIsSignIn(state),
  role: getRole(state),
});

export default connect(mapStateToProps, null)(Router);
