import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
} from '@material-ui/core';

import MenuSectionPreview from '@pro/web-common/components/menu-section-preview';
import MenuSubSectionPreview from '@pro/web-common/components/menu-subsection-preview';
import { colorPropsSchema } from '@pro/web-common/utils/style';

import { styles } from './styles';


const MenuPageContentPreview = React.memo(({ sections, stylesConfig, currencyId, footerHtml }) => {
  const { backgroundColor } = stylesConfig;
  const classes = styles({
    backgroundColor,
  });

  return (
    <Box className={classes.container}>
      {sections.map(({ id, subsections, isVisible, ...rest }) => isVisible && (
        <MenuSectionPreview
          key={id}
          stylesConfig={stylesConfig}
          {...rest}
        >
          {subsections?.map(({ id: subsectionId, isVisible: isSubsectionVisible, ...restSubsectionProps }) => isSubsectionVisible && (
            <MenuSubSectionPreview
              key={subsectionId}
              stylesConfig={stylesConfig}
              {...restSubsectionProps}
              currencyId={currencyId}
            />
          ))}
        </MenuSectionPreview>
      ))}

      {footerHtml && (
        <Box mt={2}>
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: footerHtml }} // eslint-disable-line react/no-danger
          />
        </Box>
      )}
    </Box>
  );
});


MenuPageContentPreview.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.shape({
      filePath: PropTypes.string,
    }),
    html: PropTypes.string,
    title: PropTypes.string,
    subsections: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  currencyId: PropTypes.string,
  stylesConfig: PropTypes.shape({
    backgroundColor: colorPropsSchema.isRequired,
  }).isRequired,
  footerHtml: PropTypes.string,
};

MenuPageContentPreview.defaultProps = {
  footerHtml: '',
  currencyId: '',
};


export default MenuPageContentPreview;
