import PropTypes from 'prop-types';
import memoize from 'lodash.memoize';


export const parseRgbaColor = memoize(({ r, g, b, a }) => `rgba(${r}, ${g}, ${b}, ${a})`, (...args) => JSON.stringify(args));

export const colorPropsSchema = PropTypes.shape({
  r: PropTypes.number,
  g: PropTypes.number,
  b: PropTypes.number,
  a: PropTypes.number,
});

export const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
