import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Card, Box, Typography } from '@material-ui/core';

import { actions as userActions } from '@pro/web-common/core/user/actions';
import { getSignInState } from '@pro/web-common/core/user/selectors';

import UserLoginForm from '@pro/web-common/components/user-login-form';

import meta from 'content/meta';


const SignIn = React.memo(({ signInState: { error, fetching }, signIn }) => (
  <>
    {meta.logIn}

    <Container maxWidth="xs">
      <Card>
        <Box p={2}>
          <Box mb={4}>
            <Typography
              variant="h6"
            >
              Login
            </Typography>
          </Box>

          <UserLoginForm
            fetching={fetching}
            isError={!!error}
            onSubmit={signIn}
          />
        </Box>
      </Card>
    </Container>
  </>
));

SignIn.propTypes = {
  signInState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  signIn: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  signInState: getSignInState(state),
});

const mapDispatchToProps = {
  signIn: userActions.signIn,
};


export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
