import FirebaseFunctionsService from '@pro/web-common/services/functions';
import FirebaseDatabaseService from '@pro/web-common/services/database';
import FacebookService from '@pro/web-common/services/facebook';


import { CLOUD_FUNCTIONS, FACEBOOK_PAGES_NODE } from './constants';


const FacebookPagesService = {
  getLoginStatus: () => FacebookService.getLoginStatus(),
  init: () => FacebookService.init(),
  login: () => FacebookService.login(['pages_show_list', 'pages_read_user_content', 'pages_read_engagement']),
  logout: () => FacebookService.logout(),
  api: (...params) => FacebookService.api(...params),

  getLongLivedUserAccessToken: (accessToken) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.GET_LONG_LIVED_USER_ACCESS_TOKEN, {
    accessToken,
  }),

  addFacebookPages: (brandId, data) => FirebaseDatabaseService.updateDocWithSet(`${FACEBOOK_PAGES_NODE}/${brandId}`, data),
  getFacebookPages: (brandId) => FirebaseDatabaseService.getData({
    refPath: `${FACEBOOK_PAGES_NODE}/${brandId}`,
    refType: FirebaseDatabaseService.REF_TYPES.DOC,
  }),
  deleteFacebookPages: (brandId) => FirebaseDatabaseService.deleteDoc(`${FACEBOOK_PAGES_NODE}/${brandId}`),

  getFeed: ({ brandId, pageId, includeProfileInfo, limit }) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.GET_FEED, { brandId, pageId, includeProfileInfo, limit }),
};


export default FacebookPagesService;
