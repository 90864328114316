import { findElementByProp } from '@pro/web-common/utils';

import { HOME_PAGE_ID } from './constants';


export const orderProductsList = (list, order) => {
  if (order && order.length > 0) {
    if (list && list.length > 0) {
      return order.map((id) => {
        if (id === HOME_PAGE_ID) {
          return ({ id: HOME_PAGE_ID });
        }

        return findElementByProp(list, 'id', id);
      });
    }

    return [];
  }

  return list;
};
