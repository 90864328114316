import React from 'react';
import PropTypes from 'prop-types';
import 'date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker as MuiDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { styles } from './styles';


const DatePicker = React.memo(({ value, fullWidth, withKeyboard, ...rest }) => {
  const classes = styles({ fullWidth });
  const Picker = withKeyboard ? KeyboardDatePicker : MuiDatePicker;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Picker
        disableToolbar
        autoOk
        variant="inline"
        inputVariant="outlined"
        placeholder="DD/MM/YYYY"
        format="dd/MM/yyyy"
        value={value}
        className={classes.datepicker}
        InputLabelProps={{ shrink: true }}
        invalidDateMessage={null}
        maxDateMessage={null}
        minDateMessage={null}
        {...(withKeyboard ? {
          KeyboardButtonProps: {
            'aria-label': 'change date',
          },
        } : {})}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
});

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  fullWidth: PropTypes.bool,
  withKeyboard: PropTypes.bool,
};

DatePicker.defaultProps = {
  value: null,
  fullWidth: false,
  withKeyboard: true,
};


export default DatePicker;
