import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';


const getLocalState = (state) => state.facebook;


export const getIsInitialized = createSelector(
  getLocalState,
  ({ isInitialized }) => isInitialized,
);

export const getFacebookData = createSelector(
  getLocalState,
  ({ isConnected, isFetching, pages }) => ({ isConnected, isFetching, pages }),
);

export const getUser = createSelector(
  getLocalState,
  ({ userId, userAccessToken }) => ({ userId, userAccessToken }),
);

export const getAuth = createSelector(
  getLocalState,
  ({ auth }) => auth,
);

export const getFeed = createSelector(
  getLocalState,
  ({ feed }) => feed,
);
