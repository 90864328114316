import { eventChannel } from 'redux-saga';
import { put, takeLatest, call, take, all, select, cancelled } from 'redux-saga/effects';
import yup from 'yup';

import { resolvedAction, rejectedAction, resolved } from '@pro/web-common/core/actions';
import history from '@pro/web-common/core/history';

import { constants as appConstants } from '@pro/web-common/core/app/actions';
import { constants as brandConstants, actions as brandActions } from '@pro/web-common/core/brand/actions';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { subscribeSagaToUserSession } from '@pro/web-common/core/app/sagas';
import { ROLES } from '@pro/web-common/core/user/constants';
import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';
import { getSignInAsUserInfo } from '@pro/web-common/core/admin/selectors';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';
import { generateId } from '@pro/web-common/utils';
import { DEMO_PREFIX } from '@pro/web-common/constants/storage';

import { MODAL_MESSAGE } from 'content/modals';

import UserService from './service';
import { actions as userActions, constants } from './actions';
import { getIsSignIn, getRole, getUserUID } from './selectors';
import { getIsDemoUser, normalizeUserData } from './utils';
import { DEMO_USER_CREDENTIALS } from './constants';

/*
 * Sagas
 */

function* init () {
  yield all([
    call(setUserSessionSubscriber),
    call(subscribeSagaToUserSession, getUserInfoSubscriber, true, true),
  ]);
}

function getAuthChannel () {
  return eventChannel((emit) => {
    const unsubscribe = UserService.subscribeToAuthState((user) => emit({ user }));
    return unsubscribe;
  });
}

function* getWatchUserInfoChannel () {
  const userRole = yield select(getRole);
  let userId = null;

  if (userRole === ROLES.SUPER_ADMIN) {
    yield take(resolved(adminConstants.SIGN_IN_AS_USER));
    const user = yield select(getSignInAsUserInfo);
    userId = user.uid;
  } else {
    userId = yield select(getUserUID);
  }

  return eventChannel((emit) => {
    const unsubscribe = UserService.watchUser((data) => emit({ data }), userId, userRole);
    return unsubscribe;
  });
}

function* getUserInfoSubscriber () {
  const channel = yield call(getWatchUserInfoChannel);

  while (channel) {
    try {
      const { data } = yield take(channel);
      const normalizedData = normalizeUserData(data);

      yield put(resolvedAction(constants.WATCH_USER, { data: normalizedData }));
    } catch ({ message }) {
      yield put(rejectedAction(constants.WATCH_USER, { error: message }));
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* setUserSessionSubscriber () {
  const channel = yield call(getAuthChannel);

  while (true) {
    const { user } = yield take(channel);
    const isSignIn = yield select(getIsSignIn);

    if (user === null && isSignIn === true) {
      yield put(resolvedAction(constants.SIGN_OUT));
    } else if (user !== null && isSignIn === false) {
      yield call(UserService.signOut);
    }

    if (isSignIn === false) {
      channel.close();
      return;
    }
  }
}

function* signIn ({ payload: { email, password } }) {
  try {
    const { user } = yield call(UserService.signIn, email, password);

    if (!user.emailVerified) {
      yield call(UserService.signOut);
      throw new Error('Email is not verified.');
    } else {
      // TODO: test token expiration
      const { claims: { role, brandId } } = yield user.getIdTokenResult();

      yield put(resolvedAction(constants.SIGN_IN, {
        uid: user.uid,
        role,
        brandId,
      }));
      yield call(setUserSessionSubscriber);
    }
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.SIGN_IN, { error: message }));
  }
}

function* loginDemo () {
  try {
    const { email, password } = DEMO_USER_CREDENTIALS;
    const { user } = yield call(UserService.signIn, email, password);
    const { claims: { role, brandId } } = yield user.getIdTokenResult();
    const tempBrandId = `${DEMO_PREFIX}${generateId()}`;

    yield put(resolvedAction(constants.SIGN_IN, {
      uid: user.uid,
      role,
      brandId: tempBrandId,
    }));

    yield put(brandActions.getBrand({ brandId }));

    yield call(setUserSessionSubscriber);
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.SIGN_IN, { error: message }));
  }
}

function* signInSuccess ({ payload: { role, brandId } }) {
  const isDemoUser = getIsDemoUser(role);

  if (isDemoUser) {
    history.push('/demo-intro');
  } else if (!brandId && role === ROLES.SUPER_ADMIN) {
    history.push('/select-user');
  } else if (role === ROLES.PROFILE) {
    history.push('/profile-home');
  } else {
    const { payload: { data: { isConfigured } } } = yield take(resolved(brandConstants.WATCH_BRAND));
    history.push(isConfigured ? '/admin-home' : '/configure-brand');
  }
}

function* signUp ({ payload: { data } }) {
  try {
    yield call(UserService.addUser, data);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.SIGN_UP_SUCCESS },
    }));

    yield put(resolvedAction(constants.SIGN_UP));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.SIGN_UP, { error: message }));
  }
}

function* verifyEmail ({ payload: { actionCode } }) {
  try {
    yield call(UserService.verifyEmail, actionCode);
    yield put(resolvedAction(constants.VERIFY_EMAIL));
  } catch ({ message }) {
    yield put(rejectedAction(constants.VERIFY_EMAIL, { error: message }));
  }
}

function* sendResetPasswordLink ({ payload: { email, isProfile } }) {
  try {
    yield call(UserService.sendResetPasswordLink, email);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: isProfile ? MODAL_MESSAGE.RESET_PROFILE_PASSWORD_SUCCESS : MODAL_MESSAGE.SEND_RESET_LINK_SUCCESS },
    }));

    yield put(resolvedAction(constants.SEND_RESET_PASSWORD_LINK));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.SEND_RESET_PASSWORD_LINK, { error: message }));
  }
}

function* resetPassword ({ payload: { actionCode } }) {
  try {
    const email = yield call(UserService.verifyResetPasswordCode, actionCode);
    yield put(resolvedAction(constants.RESET_PASSWORD));

    const { payload: { newPassword } } = yield take(constants.CONFIRM_RESET_PASSWORD);
    yield call(UserService.resetPassword, actionCode, newPassword);
    // TODO: looks like there is maybe race condition in sign in. test it
    yield put(userActions.signIn({
      email,
      password: newPassword,
    }));
  } catch ({ message }) {
    yield put(rejectedAction(constants.RESET_PASSWORD, { error: message }));
  }
}


function* updateEmail ({ payload: { email } }) {
  try {
    const role = yield select(getRole);
    const isDemoUser = getIsDemoUser(role);

    if (!isDemoUser) {
      yield call(UserService.updateEmail, email);
    }

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.UPDATE_EMAIL_SUCCESS },
    }));

    yield put(resolvedAction(constants.UPDATE_EMAIL));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.UPDATE_EMAIL, { error: message }));
  }
}

function* signOut ({ payload: { withRedirectToSignUp } }) {
  try {
    yield call(UserService.signOut);

    yield put(resolvedAction(constants.SIGN_OUT));

    if (withRedirectToSignUp) {
      history.push('/sign-up');
    }
  } catch (e) {
    yield put(rejectedAction(constants.SIGN_OUT));
  }
}

/*
 * Watchers
 */

function* initWatcher () {
  yield take(appConstants.INIT);
  yield call(init);
}

function* signInWatcher () {
  yield takeLatest(constants.SIGN_IN, signIn);
}

function* signInSuccessWatcher () {
  yield takeLatest(resolved(constants.SIGN_IN), signInSuccess);
}

function* signUpWatcher () {
  yield takeLatest(constants.SIGN_UP, signUp);
}

function* verifyEmailWatcher () {
  yield takeLatest(constants.VERIFY_EMAIL, verifyEmail);
}

function* sendResetPasswordLinkWatcher () {
  yield takeLatest(constants.SEND_RESET_PASSWORD_LINK, sendResetPasswordLink);
}

function* resetPasswordWatcher () {
  yield takeLatest(constants.RESET_PASSWORD, resetPassword);
}

function* updateEmailWatcher () {
  yield takeLatest(constants.UPDATE_EMAIL, updateEmail);
}

function* signOutWatcher () {
  yield takeLatest(constants.SIGN_OUT, signOut);
}

function* loginDemoWatcher () {
  yield takeLatest(constants.LOGIN_DEMO, loginDemo);
}


export default [
  initWatcher,
  signInWatcher,
  signInSuccessWatcher,
  signUpWatcher,
  signOutWatcher,
  verifyEmailWatcher,
  sendResetPasswordLinkWatcher,
  resetPasswordWatcher,
  updateEmailWatcher,
  loginDemoWatcher,
];
