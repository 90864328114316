import FirebaseDatabaseService from '@pro/web-common/services/database';
import FirebaseFunctionsService from '@pro/web-common/services/functions';

import { MANAGERS_NODE, CLOUD_FUNCTIONS } from './constants';


const ManagerService = {
  getManagers: (...params) => FirebaseDatabaseService.getData({
    refPath: MANAGERS_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    whereParams: params,
  }),

  createManager: (data) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.CREATE_MANAGER, { data }),
  deleteManager: (id) => FirebaseDatabaseService.deleteDoc(`${MANAGERS_NODE}/${id}`),
  updateManager: (id, data) => FirebaseDatabaseService.updateDoc(`${MANAGERS_NODE}/${id}`, data),
};


export default ManagerService;
