import { isNil, pipe } from 'ramda';

import { formatDate, getFirestoreTimestampDate, filterListByDatesRange, sortByDate } from '@pro/web-common/utils/date';
import { MESSAGE_TYPE } from '@pro/web-common/core/messages/constants';


export const getMessageDataFromForm = (data) => {
  const {
    image,
    title,
    html,
    startDate,
    endDate,
    productsIds,
    withSharing,
    withPush,
    youtubeId,
    tags,
    isGeo,
    geo,
    isScheduled,
    frequency,
    rrule,
    withRrule,
  } = data;

  const { ref, url } = image || {};

  return ({
    image: image ? {
      ref,
      url,
    } : null,
    title,
    html,
    startDate: startDate.getTime(),
    endDate: endDate ? endDate.getTime() : null,
    createdAt: new Date(),
    updatedAt: new Date(),
    productsIds,
    withSharing,
    withPush,
    youtubeId,
    tags,
    ...(isNil(isGeo) ? {} : { isGeo }),
    geo,
    isScheduled,
    frequency,
    rrule,
    withRrule,
  });
};

export const getQuickMessageDataFromForm = (data) => {
  const {
    isGeo,
    ...rest
  } = data;

  return ({
    ...rest,
    ...(isNil(isGeo) ? {} : { isGeo }),
  });
};

const filterMessagesByProductId = (productId) => (list) => list.filter(({ productsIds }) => productsIds.indexOf(productId) > -1);

const filterListByTrackTrace = (showTrackTraceMessages) => (list) => {
  if (showTrackTraceMessages === undefined) {
    return list;
  }

  return list.filter(({ type }) => showTrackTraceMessages ? type === MESSAGE_TYPE.TRACK_TRACE : type !== MESSAGE_TYPE.TRACK_TRACE);
};

const sortMessagesByDate = (sortByDateAsc, dateField) => (list) => list.sort(sortByDate(sortByDateAsc, dateField));

export const getFilteredMessages = ({ filters: { productId, fromDate, toDate, sortByDateAsc, showTrackTraceMessages }, list, startDateField, endDateField }) => pipe(
  filterMessagesByProductId(productId),
  filterListByTrackTrace(showTrackTraceMessages),
  filterListByDatesRange(fromDate, toDate, startDateField, endDateField),
  sortMessagesByDate(sortByDateAsc, startDateField),
)(list);

export const normalizeTrackTraceMessage = ({ checkInDate, checkInTime, ...data }) => {
  const formattedCheckInDate = checkInDate;
  formattedCheckInDate.setHours(checkInTime.getHours(), checkInTime.getMinutes());

  return {
    ...data,
    checkInDateTime: formattedCheckInDate,
  };
};

export const normalizeFbTrackTraceMessage = ({ checkInDateTime, ...data }) => {
  const formattedDateTime = formatDate(getFirestoreTimestampDate(checkInDateTime));

  return {
    ...data,
    ...(checkInDateTime ? { checkInDateTime } : {}),
    date: formattedDateTime,
  };
};
export const normalizeTrackTraceMessages = (data) => data.map((item) => normalizeFbTrackTraceMessage(item));

export const normalizeTagsForProfile = (data) => data.filter((tags) => tags).map(({ brandId, tags }) => tags.map((tag) => ({ ...tag, brandId }))).flat();
