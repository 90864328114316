import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: ({ isInModal }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    ...(isInModal && { zIndex: theme.zIndex.modal }),
  }),
  handleContainer: {
    position: 'absolute',
    left: '-30px',
    marginTop: theme.spacing(2),
  },
}));
