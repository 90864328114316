import memoize from 'lodash.memoize';
import 'firebase/firestore';

import firebase from '@pro/web-common/services/firebase';


export const getStartDayToday = (() => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
})();

export const getStartOfTheDay = (dateTime) => {
  const date = new Date(dateTime);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getEndOfTheDay = (dateTime) => {
  const date = new Date(dateTime);
  date.setHours(23, 59, 59, 59);
  return date;
};

export const getDaysFromToday = (dateTime) => {
  const date = new Date(dateTime);
  return ((new Date() - date) / (1000 * 3600 * 24));
};

export const getFutureDate = (hours = 1) => {
  const date = new Date();
  date.setHours(date.getHours() + hours, 0, 0, 0);
  return date;
};

export const formatDate = memoize((date) => {
  const year = date.getFullYear();

  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month;

  const day = date.getDate();
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}/${formattedMonth}/${formattedDay}`;
});

export const formatTime = memoize((date) => {
  const hours = date.getHours();
  const formattedHours = hours < 10 ? `0${hours}` : hours;

  const minutes = date.getMinutes();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}`;
});

export const formatDateAndTime = memoize((date) => `${formatDate(date)} ${formatTime(date)}`);

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
export const formatDateForChart = (date, withYear) => {
  const dateObject = new Date(date);

  const day = dateObject.getDate();
  const monthIndex = dateObject.getMonth();
  const year = dateObject.getFullYear();

  return `${day} ${monthNames[monthIndex]}${withYear ? ` ${year}` : ''}`;
};

export const isToday = memoize((checkDate) => {
  const today = new Date();
  const date = new Date(checkDate);

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
});

export const getFirebaseTime = () => firebase.firestore.Timestamp.now();

export const getFirestoreTimestampDate = (date) => {
  if (date instanceof firebase.firestore.Timestamp) {
    return date.toDate();
  }

  const { seconds, nanoseconds } = date; // TODO: check date format on subscriptions
  return new firebase.firestore.Timestamp(seconds, nanoseconds).toDate();
};

export const convertDateToUTCWithoutTimezone = (date, diff = 0) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0) + diff - new Date(date).getTimezoneOffset() * 60 * 1000);

export const filterListByDatesRange = (fromDate, toDate, fromDateField, toDateField) => (list) => {
  if (!fromDate && !toDate) {
    return list;
  }

  const dateRangeStart = fromDate ? convertDateToUTCWithoutTimezone(fromDate, -1) : null;
  const dateRangeEnd = toDate ? convertDateToUTCWithoutTimezone(toDate, -1) : null;

  return list.filter(({ [fromDateField]: startDateStr, [toDateField]: endDateStr }) => {
    const startDate = convertDateToUTCWithoutTimezone(new Date(startDateStr));
    const endDate = endDateStr ? convertDateToUTCWithoutTimezone(new Date(endDateStr)) : null;

    let isInRange = true;

    if (dateRangeStart) {
      isInRange = dateRangeStart <= startDate;
    }

    if (isInRange && dateRangeEnd) {
      if (endDate) {
        isInRange = dateRangeEnd <= endDate;
      } else {
        isInRange = dateRangeEnd >= startDate;
      }
    }

    return isInRange;
  });
};

export const sortByDate = (asc = true, sortField) => (a, b) => {
  const result = new Date(b[sortField]) - new Date(a[sortField]);

  return asc ? -1 * result : result;
};
