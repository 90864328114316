import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
