import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';

import ImageUploader from '@pro/web-common/components/image-uploader';
import ImageWithPlaceholder from '@pro/web-common/components/image-with-placeholder';

import { sliderSettings, imagesFormats } from './config';


const MultipleImagesUploader = React.memo(({ images, onImageDelete, onImageAdd, ...rest }) => (
  <Slider
    {...sliderSettings}
  >
    {images.map(({ filePath }, index) => (
      <ImageWithPlaceholder
        key={filePath}
        image={filePath}
        onDelete={() => onImageDelete(index)}
        {...rest}
      />
    ))}

    <ImageUploader
      formats={imagesFormats}
      onFileChange={onImageAdd}
    />
  </Slider>
));

MultipleImagesUploader.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    filePath: PropTypes.string,
  })),
  onImageDelete: PropTypes.func.isRequired,
  onImageAdd: PropTypes.func.isRequired,
};

MultipleImagesUploader.defaultProps = {
  images: [],
};


export default MultipleImagesUploader;
