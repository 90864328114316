import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@material-ui/core';

import { colorPropsSchema } from '@pro/web-common/utils/style';
import { getCurrencySymbol } from '@pro/web-common/utils/currency';

import { styles } from './styles';


const MenuSubSectionPreview = React.memo(({ title, image, html, price, currencyId, stylesConfig }) => {
  const {
    typeface,
    backgroundColor,
    fontColor,
    menuItemTextColor,
  } = stylesConfig;

  const classes = styles({
    typeface,
    backgroundColor,
    fontColor,
    menuItemTextColor,
  });

  return (
    <Box>
      {image && (
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={image.filePath}
            alt="section-hero"
          />
        </div>
      )}

      {title && (
        <Box className={classes.titleTextContainer}>
          <Typography
            variant="body2"
          >
            <Box
              className={classes.titleText}
              component="span"
              fontWeight="bold"
            >
              {title}
            </Box>
          </Typography>

          {!!price && (
            <Typography
              variant="body2"
            >
              <Box
                component="span"
                className={classes.titleText}
              >
                {getCurrencySymbol(currencyId)}
              </Box>

              <Box
                component="span"
                fontWeight="bold"
                className={classes.titleText}
              >
                {parseFloat(price).toFixed(2)}
              </Box>
            </Typography>
          )}
        </Box>
      )}

      <Box className={styles.contentContainer}>
        {html && (
          <Box className={classes.html}>
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
            />
          </Box>
        )}
      </Box>
    </Box>
  );
});

MenuSubSectionPreview.propTypes = {
  image: PropTypes.shape({
    filePath: PropTypes.string,
  }),
  html: PropTypes.string,
  title: PropTypes.string,
  stylesConfig: PropTypes.shape({
    typeface: PropTypes.string.isRequired,
    backgroundColor: colorPropsSchema.isRequired,
    fontColor: colorPropsSchema.isRequired,
    menuItemTextColor: colorPropsSchema.isRequired,
  }).isRequired,
  price: PropTypes.string,
  currencyId: PropTypes.string.isRequired,
};

MenuSubSectionPreview.defaultProps = {
  image: null,
  title: null,
  html: null,
  price: null,
};


export default MenuSubSectionPreview;
