import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { styles } from './styles';


const SupportSection = React.memo(({ title, questions }) => {
  const classes = styles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={3}
    >
      <Typography
        className={classes.text}
        variant="h5"
        gutterBottom
      >
        {title}
      </Typography>

      <Box mt={1}>
        {questions.map(({ question, answer }, index) => (
          <ExpansionPanel
            key={index} // eslint-disable-line react/no-array-index-key
            classes={{
              root: classes.expansionPanelRoot,
              expanded: classes.expansionPanelExpanded,
            }}
            square
          >
            <ExpansionPanelSummary
              classes={{
                root: classes.expansionPanelSummaryRoot,
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expansionPanelSummaryExpandIcon,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
            >
              <Box
                component="span"
                fontWeight="fontWeightBold"
              >
                {question}
              </Box>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
              classes={{ root: classes.expansionPanelDetailsRoot }}
            >
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: answer }} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </Box>
    </Box>
  );
});

SupportSection.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
  })).isRequired,
};

SupportSection.defaultProps = {};


export default SupportSection;
