import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Collapse, TextField,
} from '@material-ui/core';

import SingleImageUploader from '@pro/web-common/components/single-image-uploader';
import EnterpriseImageListingItem from '@pro/web-common/components/enterprise-image-listing-item';

import { styles } from './styles';


const EnterpriseImageEditor = React.memo(({ defaultImage, defaultText, onSubmit, isSubmitting }) => {
  const classes = styles();

  const [image, setImage] = useState(defaultImage ? { filePath: defaultImage.url } : null);
  const [text, setText] = useState(defaultText);
  const [isEditorOpened, setIsEditorOpened] = useState(false);

  const toggleEditor = useCallback(
    () => setIsEditorOpened(!isEditorOpened),
    [isEditorOpened],
  );

  const onSave = useCallback(
    () => onSubmit({ image, text }),
    [onSubmit, image, text],
  );

  return (
    <Box className={classes.container}>
      <EnterpriseImageListingItem
        onEdit={toggleEditor}
      />

      <Collapse
        in={isEditorOpened}
        mountOnEnter
        unmountOnExit
      >
        <Box
          pt={1}
          pb={1}
        >
          <SingleImageUploader
            image={image}
            onImageAdd={(fileData) => setImage(fileData)}
            onImageDelete={() => setImage(null)}
            recommendedMaxWidth={2300}
          />
        </Box>

        <Box
          pt={2}
          pb={1}
        >
          <TextField
            variant="outlined"
            fullWidth
            label="Text"
            placeholder="Text"
            value={text}
            onChange={({ target: { value } }) => setText(value)}
          />
        </Box>

        <Box
          mt={1}
          textAlign="right"
          mb={2}
        >
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={onSave}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
});

EnterpriseImageEditor.propTypes = {
  defaultImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  defaultText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

EnterpriseImageEditor.defaultProps = {
  defaultImage: null,
  defaultText: '',
};


export default EnterpriseImageEditor;
