import firebase from '@pro/web-common/services/firebase';
import 'firebase/firestore';

import { REF_TYPES } from './constants';


export class FirebaseDatabaseService {
  constructor () {
    this.db = firebase.firestore();
    this.REF_TYPES = REF_TYPES;
  }

  getRef = ({ refPath, refType, forceDoc = false, whereParams, orderBy, limit }) => {
    let ref = null;

    if (refType === this.REF_TYPES.DOC) {
      ref = forceDoc ? this.db.collection(refPath).doc() : this.db.doc(refPath);
    }

    if (refType === this.REF_TYPES.COLLECTION) {
      ref = this.db.collection(refPath);
    }

    ref = this.applyParams(ref, 'where', whereParams);
    ref = this.applyParams(ref, 'orderBy', orderBy);
    ref = this.applyParam(ref, 'limit', limit);

    return ref;
  }

  applyParams = (ref, paramName, params = []) => {
    let refWithParams = ref;

    params.forEach((param) => {
      refWithParams = refWithParams[paramName](...param);
    });

    return refWithParams;
  }

  applyParam = (ref, paramName, param) => {
    if (param) {
      return ref[paramName](param);
    }

    return ref;
  }

  getData = async ({ refPath, refType, callback = (data) => data, dataExtractor, normalize, whereParams, limit, orderBy }) => {
    const ref = this.getRef({ refPath, refType, whereParams, limit, orderBy });

    let extractData;

    if (refType === this.REF_TYPES.COLLECTION) {
      extractData = this.onCollectionReceive.bind(this, callback, dataExtractor, normalize);
    }

    if (refType === this.REF_TYPES.DOC) {
      extractData = this.onDocReceive.bind(this, callback, dataExtractor, normalize);
    }

    const data = await ref.get().then(extractData);

    return data;
  }

  addData (refPath, refType, forceDoc, data, options) {
    const ref = this.getRef({ refPath, refType, forceDoc });

    return ref.set(data, options);
  }

  updateDoc (refPath, data) {
    const ref = this.getRef({ refPath, refType: this.REF_TYPES.DOC });

    return ref.update(data);
  }

  updateDocWithSet (refPath, data, options = {}) { // TODO: refactor to single update method
    const ref = this.getRef({ refPath, refType: this.REF_TYPES.DOC });
    return ref.set(data, options);
  }

  deleteDoc (refPath) {
    const ref = this.getRef({ refPath, refType: this.REF_TYPES.DOC });

    return ref.delete();
  }

  watchData ({ refPath, refType, callback, dataExtractor, normalize, whereParams, limit, orderBy }) {
    const ref = this.getRef({ refPath, refType, whereParams, limit, orderBy });

    let listener;
    if (refType === this.REF_TYPES.COLLECTION) {
      listener = this.onCollectionReceive.bind(this, callback, dataExtractor, normalize);
    }

    if (refType === this.REF_TYPES.DOC) {
      listener = this.onDocReceive.bind(this, callback, dataExtractor, normalize);
    }

    return ref.onSnapshot(listener);
  }

  extractDocData = (doc, dataExtractor, normalize) => {
    const data = doc.data();

    if (!data) {
      return null;
    }

    const docData = dataExtractor ? dataExtractor(doc) : ({
      id: doc.id,
      ...data,
    });

    return normalize ? normalize(docData) : docData;
  }

  onDocReceive = (callback, dataExtractor, normalize, data) => {
    const doc = this.extractDocData(data, dataExtractor, normalize);
    return callback(doc);
  }

  onCollectionReceive = (callback, dataExtractor, normalize, data) => {
    const docs = data.docs.map((doc) => this.extractDocData(doc, dataExtractor, normalize));
    return callback(docs);
  }

  runTransaction = (updateFunction) => this.db.runTransaction(updateFunction)
}


const firebaseDatabase = new FirebaseDatabaseService();
export default firebaseDatabase;
