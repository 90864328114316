import React from 'react';

import { Container, Grid, Typography } from '@material-ui/core';

import PageTitle from '@pro/web-common/components/page-title';

import meta from 'content/meta';


const Terms = React.memo(() => (
  <>
    {meta.terms}

    <Container maxWidth="md">
      <PageTitle
        title="Terms"
        subtitle={<>We take this really seriously.<br />Seriously</>}
        large
      />

      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Typography>
            Our Company collects Personal identification information including name, email address, phone number, etc.
            How
            do we collect your data? You directly provide Our Company with most of the data we collect. We collect data
            and process data when you: Register online or place an order for any of our products or services. Use or
            view
            our website via your browser’s cookies. How will we use your data? Process your order and manage your
            account.
            How do we store your data? Our Company securely stores your data in the cloud. Our Company will keep your
            data
            until you no longer use our services, and up to 6 months thereafter. Once this time period has expired. What
            are your data protection rights? Our Company would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following: The right to access – You have the right to
            request Our Company for copies of your personal data. We may charge you a small fee for this service. The
            right to rectification – You have the right to request that Our Company correct any information you believe
            is
            inaccurate. You also have the right to request Our Company to complete the information you believe is
            incomplete. The right to erasure – You have the right to request that Our Company erase your personal data,
            under certain conditions. The right to restrict processing – You have the right to request that Our Company
            restrict the processing of your personal data, under certain conditions. The right to object to processing –
            You have the right to object to Our Company’s processing of your personal data, under certain conditions.
            The
            right to data portability – You have the right to request that Our Company transfer the data that we have
            collected to another organization, or directly to you, under certain conditions.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <Typography>
            If you make a request, we have one month to respond to you. If you would like to exercise any of these
            rights,
            please contact us at our email: Call us at: Or write to us: Cookies Cookies are text files placed on your
            computer to collect standard Internet log information and visitor behavior information. When you visit our
            websites, we may collect information from you automatically through cookies or similar technology For
            further
            information, visit all about cookies.org. How do we use cookies? Our Company uses cookies in a range of ways
            to
            improve your experience on our website, including: Keeping you signed in Understanding how you use our
            website
            What types of cookies do we use? There are a number of different types of cookies, however, our website
            uses:
            Functionality – Our Company uses these cookies so that we recognize you on our website and remember your
            previously selected preferences. These could include what language you prefer and location you are in. A mix
            of first-party and third-party cookies are used. How to manage cookies You can set your browser not to
            accept
            cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases,
            some of our website features may not function as a result. Terms policies of other websites The Our Company
            website contains links to other websites. Our Terms policy applies only to our website, so if you click on a
            link to another website, you should read their Terms policy. Changes to our Terms policy Our Company keeps
            its Terms policy under regular review and places any updates on this web page. This Terms policy was last
            updated on 9 January 2019. How to contact us If you have any questions about Our Company’s Terms policy, the
            data we hold on you, or you would like to exercise one of your data protection rights, please do not
            hesitate
            to contact us. Email us at: Call us: Or write to us at: How to contact the appropriate authority Should you
            wish to report a complaint or if you feel that Our Company has not addressed your concern in a satisfactory
            manner, you may contact the Information Commissioner’s Office. Email: Address
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </>
));

Terms.propTypes = {};

Terms.defaultProps = {};


export default Terms;
