import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Button,
  TextField,
} from '@material-ui/core';

import { actions as managersActions } from '@pro/web-common/core/managers/actions';
import { getManagerCreatingState, getManagersList, getManagerUpdatingState } from '@pro/web-common/core/managers/selectors';
import { getProductsList } from '@pro/web-common/core/product/selectors';
import { getSubscriptionPermissions } from '@pro/web-common/core/brand/utils';
import { ALL_PRODUCTS_OPTION } from '@pro/web-common/core/product/config';
import { getUserEmail, getUpdateEmailState } from '@pro/web-common/core/user/selectors';
import { actions as userActions } from '@pro/web-common/core/user/actions';

import { BrandContext } from '@pro/web-common/containers/providers';

import ConditionalTooltip from '@pro/web-common/components/conditional-tooltip';
import ConfigureManagerModal from '@pro/web-common/components/configure-manager-modal';
import UsersListing from '@pro/web-common/components/users-listing';
import PageTitle from '@pro/web-common/components/page-title';
import SectionTitle from '@pro/web-common/components/section-title';

import {
  ADMIN_USERS_TITLE,
  ADMIN_USERS_SUBTITLE,
} from 'content/texts';
import { isTestVersion } from 'constants/product-config';

import { emailValidationSchema } from './config';


const AdminUsers = React.memo(({
  products,
  managers,
  creatingState: {
    fetching: isCreating,
    error: userCreationError,
  },
  updatingState: {
    fetching: isUpdating,
    error: userUpdateError,
  },
  getManagers,
  createManager,
  updateManager,
  deleteManager,
  updateEmail,
  userEmail,
  updateEmailState,
}) => {
  const [isConfigureUserModalOpened, setIsConfigureUserModalOpened] = useState(false);
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState(userEmail);
  const [emailError, setEmailError] = useState(false);

  const { brand } = useContext(BrandContext);
  const { canAddUsers } = useMemo(() => getSubscriptionPermissions(brand), [brand]);

  const handleUserEdit = useCallback((data) => {
    setUserData(data);
    setIsConfigureUserModalOpened(true);
  }, []);

  const handleUserAdd = useCallback((id, data) => {
    if (id) {
      updateManager({ id, data });
    } else {
      createManager({ data });
    }
  }, [createManager, updateManager]);

  const transformedManagersList = useMemo(() => (
    managers.map(({ id, ...rest }) => {
      const managerProducts = products.filter(({ users }) => users.indexOf(id) > -1).map(({ productName }) => `#${productName}`);
      const isAllProducts = managerProducts.length === products.length;

      return ({
        id,
        products: isAllProducts ? [ALL_PRODUCTS_OPTION.productName] : managerProducts,
        ...rest,
      });
    })
  ), [managers, products]);

  const onEmailChange = useCallback(({ target: { value } }) => {
    setEmail(value);
  }, []);

  const handleEmailUpdate = useCallback(async () => {
    setEmailError(false);
    if (email === userEmail) {
      return;
    }

    const isValid = await emailValidationSchema.isValid({ email });
    if (!isValid) {
      setEmailError(true);
      return;
    }

    updateEmail({ email });
  }, [email, userEmail]);

  useEffect(() => {
    if (!isConfigureUserModalOpened) {
      setUserData(null);
    }
  }, [isConfigureUserModalOpened]);

  useEffect(() => {
    if (!isCreating && !userCreationError && !isUpdating && !userUpdateError) {
      setIsConfigureUserModalOpened(false);
    }
  }, [isCreating, userCreationError, isUpdating, userUpdateError]);

  useEffect(() => {
    getManagers();
  }, [getManagers]);

  return (
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={9}
        >
          <PageTitle
            title={ADMIN_USERS_TITLE}
            subtitle={ADMIN_USERS_SUBTITLE}
          />

          <Box mt={6}>
            <Card>
              <CardContent>
                <UsersListing
                  users={transformedManagersList}
                  onEdit={handleUserEdit}
                  onDelete={deleteManager}
                />

                <Box
                  textAlign="right"
                  mt={2}
                >
                  <ConditionalTooltip
                    title={`${isTestVersion ? 'Upgrade' : 'Subscribe'} to add more users`}
                    isShown={!(canAddUsers || managers.length === 0)}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setIsConfigureUserModalOpened(true)}
                      disabled={!(canAddUsers || managers.length === 0)}
                    >
                      Add User
                    </Button>
                  </ConditionalTooltip>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>

      <Box mt={4}>
        <SectionTitle title="Contact information" />
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={onEmailChange}
              error={emailError}
            />
          </Grid>

          <Grid
            item
            xs={6}
          >
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleEmailUpdate}
              disabled={updateEmailState.fetching}
            >
                  Update email
            </Button>
          </Grid>
        </Grid>
      </Box>

      {isConfigureUserModalOpened && (
        <ConfigureManagerModal
          isEdit={!!userData}
          products={products}
          initialValues={userData}
          onClose={() => setIsConfigureUserModalOpened(false)}
          onSubmit={handleUserAdd}
          isSubmitting={isCreating || isUpdating}
          isError={!!userCreationError || !!userUpdateError}
        />
      )}
    </Container>
  );
});

AdminUsers.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  managers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  creatingState: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  updatingState: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  getManagers: PropTypes.func.isRequired,
  createManager: PropTypes.func.isRequired,
  updateManager: PropTypes.func.isRequired,
  deleteManager: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  updateEmail: PropTypes.func.isRequired,
  updateEmailState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
  }).isRequired,
};

AdminUsers.defaultProps = {
  userEmail: null,
};

const mapStateToProps = (state) => ({
  products: getProductsList(state),
  managers: getManagersList(state),
  creatingState: getManagerCreatingState(state),
  updatingState: getManagerUpdatingState(state),
  updateEmailState: getUpdateEmailState(state),
  userEmail: getUserEmail(state),
});

const mapDispatchToProps = {
  getManagers: managersActions.getManagers,
  createManager: managersActions.createManager,
  updateManager: managersActions.updateManager,
  deleteManager: managersActions.deleteManager,
  updateEmail: userActions.updateEmail,
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
