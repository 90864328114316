import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from '@material-ui/core';

import ManagerForm from '@pro/web-common/components/manager-form';


const ConfigureManagerModal = ({ isEdit, onClose, onSubmit, isSubmitting, isError, initialValues, products }) => {
  const { id: managerId } = initialValues || {};
  const transformedProducts = useMemo(() => products.map(({ users = [], ...rest }) => ({
    isChecked: users.indexOf(managerId) > -1,
    ...rest,
  })), [products, managerId]);

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
    >
      <DialogTitle id="form-dialog-title">
        {isEdit ? 'Edit' : 'Add'}
        {' '}
        User
      </DialogTitle>

      <DialogContent>
        <Box mb={2}>
          <ManagerForm
            isEdit={isEdit}
            initialValues={initialValues}
            products={transformedProducts}
            fetching={isSubmitting}
            isError={isError}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ConfigureManagerModal.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isError: PropTypes.bool,
};

ConfigureManagerModal.defaultProps = {
  isEdit: false,
  initialValues: null,
  isSubmitting: false,
  isError: false,
};


export default ConfigureManagerModal;
