import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles(() => ({
  qrCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '128px',

    '& > canvas': {
      maxWidth: '128px',
      maxHeight: '128px',
    },
  },
}));
