import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'INIT',
], 'APP');

export const actions = createActions({
  [constants.INIT]: null,
}, {
  prefix: 'APP',
});


export default actions;
