import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';

import { BrandContext, UserRoleContext } from '@pro/web-common/containers/providers';

import { actions as productActions } from '@pro/web-common/core/product/actions';
import { actions as brandActions } from '@pro/web-common/core/brand/actions';
import { getProductsList, getProductsOrder, getProductUpdatingState } from '@pro/web-common/core/product/selectors';
import { getBrandUpdatingState } from '@pro/web-common/core/brand/selectors';
import { getSubscriptionPermissions } from '@pro/web-common/core/brand/utils';

import SectionTitle from '@pro/web-common/components/section-title';
import ProductsListing from '@pro/web-common/components/products-listing';

import {
  ADMIN_PRODUCTS_TITLE,
  ADMIN_PRODUCTS_ADD_PRODUCT_BUTTON_LABEL,
  ADMIN_PRODUCTS_ADD_SUBSCRIPTION_TEXT,
  getAdminProductsAddMoreItemsText,
} from 'content/texts';


const AdminProducts = React.memo(({ products, productsOrder, deleteProduct, unlockProduct, copyProduct, updateProduct, updatingState: { fetching: isUpdating }, brandUpdatingState: { fetching: isBrandUpdating }, saveProductsOrder, updateEnterpriseHomeData }) => {
  const { isOwner } = useContext(UserRoleContext);
  const { brand } = useContext(BrandContext);
  const { availableProducts = 0, isEnterprise = false } = brand || {};

  const { canAddProduct } = useMemo(() => getSubscriptionPermissions(brand), [brand]);

  return (
    <>
      <SectionTitle title={ADMIN_PRODUCTS_TITLE} />

      <ProductsListing
        products={products}
        order={productsOrder}
        onProductDelete={isOwner ? deleteProduct : null}
        onProductUnlock={isOwner && availableProducts > 0 ? unlockProduct : null}
        onProductCopy={isOwner ? copyProduct : null}
        onVisibilityUpdate={updateProduct}
        underlineLast={isOwner}
        isUpdating={isUpdating}
        isBrandUpdating={isBrandUpdating}
        withProductCopy={canAddProduct}
        withDragging={isEnterprise && isOwner}
        onSaveOrder={saveProductsOrder}
        onEnterpriseDataUpdate={updateEnterpriseHomeData}
      />

      {isOwner && (
        <Box textAlign="right">
          <Box
            mt={2}
            mb={1}
          >
            <Button
              component={NavLink}
              variant="contained"
              color="primary"
              to={canAddProduct ? '/add-product' : '/admin-subscriptions'}
            >
              {canAddProduct ? ADMIN_PRODUCTS_ADD_PRODUCT_BUTTON_LABEL : 'Add Subscription'}
            </Button>
          </Box>
          <Typography variant="caption">
            {availableProducts > 0 ? (
              getAdminProductsAddMoreItemsText(availableProducts)
            ) : (
              ADMIN_PRODUCTS_ADD_SUBSCRIPTION_TEXT
            )}
          </Typography>
        </Box>
      )}
    </>
  );
});

AdminProducts.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  productsOrder: PropTypes.arrayOf(PropTypes.string),
  deleteProduct: PropTypes.func.isRequired,
  unlockProduct: PropTypes.func.isRequired,
  copyProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  updatingState: PropTypes.shape({
    fetching: PropTypes.bool,
  }).isRequired,
  brandUpdatingState: PropTypes.shape({
    fetching: PropTypes.bool,
  }).isRequired,
  saveProductsOrder: PropTypes.func.isRequired,
  updateEnterpriseHomeData: PropTypes.func.isRequired,
};

AdminProducts.defaultProps = {
  productsOrder: [],
};

const mapStateToProps = (state) => ({
  products: getProductsList(state),
  productsOrder: getProductsOrder(state),
  updatingState: getProductUpdatingState(state),
  brandUpdatingState: getBrandUpdatingState(state),
});

const mapDispatchToProps = {
  deleteProduct: productActions.deleteProduct,
  unlockProduct: productActions.unlockProduct,
  copyProduct: productActions.copyProduct,
  updateProduct: productActions.updateProduct,
  saveProductsOrder: productActions.saveProductsOrder,
  updateEnterpriseHomeData: brandActions.updateEnterpriseHomeData,
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminProducts);
