import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'WATCH_USERS',
  'CREATE_ADMIN',
  'CREATE_DEMO_USER',
  'SIGN_IN_AS_USER',
  'SIGN_OUT_FROM_USER',
  'UPDATE_ALL_STATISTICS',
], 'ADMIN');

export const actions = createActions({
  [constants.WATCH_USERS]: null,
  [constants.CREATE_ADMIN]: ({ email, password }) => ({ email, password }),
  [constants.CREATE_DEMO_USER]: ({ email, password }) => ({ email, password }),
  [constants.SIGN_IN_AS_USER]: ({ id }) => ({ id }),
  [constants.SIGN_OUT_FROM_USER]: null,
  [constants.UPDATE_ALL_STATISTICS]: null,
}, {
  prefix: 'ADMIN',
});


export default actions;
