import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import { ListItem, ListItemText } from '@material-ui/core';


const NavigationDrawerListItem = React.memo(({ label, labelProps, isButton, ...rest }) => (
  <ListItem
    button
    component={isButton ? 'div' : NavLink}
    {...rest}
  >
    <ListItemText
      {...labelProps}
      primary={label}
    />
  </ListItem>
));

NavigationDrawerListItem.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.objectOf(PropTypes.shape({})),
  isButton: PropTypes.bool,
};

NavigationDrawerListItem.defaultProps = {
  labelProps: {},
  isButton: false,
};


export default NavigationDrawerListItem;
