import FirebaseStorageService from '@pro/web-common/services/storage';
import { generateId } from '@pro/web-common/utils';


class UploadAdapter {
  constructor (prefixPath, loader) {
    this.loader = loader;
    this.pathPrefix = prefixPath;
    this.uploadTask = null;
  }

  async upload () {
    const file = await this.loader.file;
    const id = generateId();
    this.uploadTask = FirebaseStorageService.getUploadTask({ file, path: `${this.pathPrefix}/${id}` });
    return new Promise(this.initListeners.bind(this));
  }

  abort () {
    if (this.uploadTask) {
      this.uploadTask.cancel();
    }
  }

  initListeners (resolve, reject) {
    this.uploadTask.on(FirebaseStorageService.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        this.loader.uploadTotal = snapshot.totalBytes;
        this.loader.uploaded = snapshot.bytesTransferred;
      }, (error) => {
        reject(error.code);
      }, () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve({
            default: downloadURL,
          });
        });
      });
  }
}

function UploadAdapterPlugin (prefixPath, editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new UploadAdapter(prefixPath, loader); // eslint-disable-line no-param-reassign
}


export default UploadAdapterPlugin;
