import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerMarginBottom: {
    marginBottom: theme.spacing(2),
  },
  colorContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  color: ({ color }) => ({
    height: '25px',
    width: '100px',
    cursor: 'pointer',
    border: '1px solid',
    backgroundColor: parseRgbaColor(color),
    borderColor: theme.palette.grey.A100,
  }),
  colorMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  pickerContainer: {
    position: 'absolute',
    left: '110px',
    top: '0',
    zIndex: 1,
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '80px',
  },
  transparencyText: {
    marginTop: theme.spacing(1),
  },
}));
