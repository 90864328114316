import { makeStyles } from '@material-ui/core/styles';


const drawerWidth = 240;

export const styles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.light,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuContainer: {
    width: drawerWidth,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  menuList: {
    padding: 0,
  },
  closeIconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
}));
