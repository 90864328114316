import * as Yup from 'yup';

import { getFutureDate } from '@pro/web-common/utils/date';
import { REGEXP } from '@pro/web-common/utils/validators';
import { GEO_TYPES, FREQUENCY_VALUES } from '@pro/web-common/core/messages/constants';

import { ALL_ITEMS_LABEL } from 'content/texts';


export const pdfFormats = [
  'application/pdf',
];

export const formValidationSchema = () => Yup.object().shape({
  title: Yup.string().required(),
  html: Yup.string().required(),
  startDate: Yup.date().when(['isScheduled'], (isScheduled, schema) => {
    if (isScheduled === IS_SCHEDULED_VALUES.ON) {
      return schema.min(getFutureDate()).required();
    }

    return schema;
  }),
  endDate: Yup.date()
    .when('startDate', (startDate, schema) => {
      const minDate = startDate || new Date();
      return schema.min(minDate).required();
    }),
  productId: Yup.string().required(),
  newPdfFile: Yup.mixed()
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value ? pdfFormats.includes(value.type) : true,
    ),
  image: Yup.string().required(),
  youtubeId: Yup.string()
    .matches(REGEXP.YOUTUBE_ID),
});

export const ALL_PRODUCTS_OPTION = {
  value: 'all',
  label: ALL_ITEMS_LABEL,
};

export const GEO_VALUES = [
  {
    value: GEO_TYPES.NONE,
    getLabel: () => 'None',
  },
  {
    value: GEO_TYPES.INSIDE,
    getLabel: (radius) => `Within ${radius}km radius`,
  },
  {
    value: GEO_TYPES.OUTSIDE,
    getLabel: (radius) => `Outside ${radius}km radius`,
  },
];

export const IS_SCHEDULED_VALUES = {
  ON: 'on',
  OFF: 'off',
};

export const IS_SCHEDULED_OPTIONS = [
  {
    value: IS_SCHEDULED_VALUES.OFF,
    label: 'Now',
  },
  {
    value: IS_SCHEDULED_VALUES.ON,
    label: 'Specific date and time',
  },
];

export const FREQUENCY_OPTIONS = [
  {
    value: FREQUENCY_VALUES.NONE,
    label: 'None',
  },
  {
    value: FREQUENCY_VALUES.WEEKLY,
    label: 'Every week',
  },
  {
    value: FREQUENCY_VALUES.MONTHLY,
    label: 'Every month',
  },
];
