import React from 'react';

import SliderArrow from '@pro/web-common/components/slider-arrow';


export const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 7,
  rows: 5,
  nextArrow: <SliderArrow />,
  prevArrow: <SliderArrow />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};
