import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'SEND_MESSAGE',
], 'SUPPORT');

export const actions = createActions({
  [constants.SEND_MESSAGE]: (data) => ({ data }),
}, {
  prefix: 'SUPPORT',
});


export default actions;
