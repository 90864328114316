import { eventChannel } from 'redux-saga';
import { put, call, takeLatest, select, take, all, cancelled, fork } from 'redux-saga/effects';

import { resolvedAction, rejectedAction, resolved } from '@pro/web-common/core/actions';

import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';

import { constants as appConstants } from '@pro/web-common/core/app/actions';
import { subscribeSagaToUserSession, subscribeSagaToProfileSession } from '@pro/web-common/core/app/sagas';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { getBrandId, getRole, getUserInfo, getUserUID } from '@pro/web-common/core/user/selectors';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { getProduct } from '@pro/web-common/core/product/selectors';

import FileService from '@pro/web-common/core/file/service';
import UserService from '@pro/web-common/core/user/service';

import { getIsDemoUser, getIsProfile } from '@pro/web-common/core/user/utils';
import { generateId } from '@pro/web-common/utils';

import { DEMO_PREFIX, PROFILES_PATH } from '@pro/web-common/constants/storage';

import { MODAL_MESSAGE } from 'content/modals';

import ProfileService from './service';
import { normalizeProfile, normalizeProfiles } from './utils';
import { constants } from './actions';
import { getProfileLimitsByProfileIdAndProductId } from './selectors';


/*
 * Sagas
 */

function* init () {
  yield all([
    call(subscribeSagaToUserSession, watchProfiles),
    call(subscribeSagaToUserSession, watchProfilesLimits, true, true),
    call(subscribeSagaToProfileSession, syncProfile),
  ]);
}

function* getWatchProfilesChannel () {
  const brandId = yield select(getBrandId);
  const requestParams = ['brands', 'array-contains', brandId];

  return eventChannel((emit) => {
    const unsubscribe = ProfileService.watchProfiles((data) => emit({ data }), requestParams);
    return unsubscribe;
  });
}

function* watchProfiles () {
  const channel = yield call(getWatchProfilesChannel);

  while (channel) {
    try {
      const { data } = yield take(channel);
      const normalizedData = normalizeProfiles(data);

      yield put(resolvedAction(constants.WATCH_PROFILES, { data: normalizedData }));
    } catch ({ message }) {
      yield put(rejectedAction(constants.WATCH_PROFILES, { error: message }));
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* getWatchProfilesLimitsChannel () {
  const userRole = yield select(getRole);
  const isProfile = getIsProfile(userRole);
  let requestParams;
  if (isProfile) {
    const userId = yield select(getUserUID);
    requestParams = ['profileId', '==', userId];
  } else {
    const brandId = yield select(getBrandId);
    requestParams = ['brandId', '==', brandId];
  }

  return eventChannel((emit) => {
    const unsubscribe = ProfileService.watchProfilesLimits((data) => emit({ data }), requestParams);
    return unsubscribe;
  });
}

function* watchProfilesLimits () {
  const channel = yield call(getWatchProfilesLimitsChannel);

  while (channel) {
    try {
      const { data } = yield take(channel);

      yield put(resolvedAction(constants.WATCH_PROFILES_MESSAGES_LIMITS, { data }));
    } catch ({ message }) {
      yield put(rejectedAction(constants.WATCH_PROFILES_MESSAGES_LIMITS, { error: message }));
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* createProfile ({ payload: { profile, profileId, productId, onSuccess, onError } }) {
  try {
    const role = yield select(getRole);
    const isDemoUser = getIsDemoUser(role);
    const brandId = yield select(getBrandId);
    const product = yield select(getProduct(productId));
    const imagePath = profile.image.file ? yield call(FileService.uploadFile, profile.image.file, `${isDemoUser ? DEMO_PREFIX : ''}${PROFILES_PATH}/${profileId}`) : profile.image;

    const normalizedProfile = {
      address: profile.address,
      description: profile.description,
      email: profile.email,
      phoneNumber: profile.phoneNumber,
      title: profile.title,
      website: profile.website,
      image: imagePath,
      addressLat: profile.addressLat,
      addressLng: profile.addressLng,
      creatorBrandId: brandId,
      brands: [brandId],
      products: [productId],
      creatorProductName: product?.productName || null,
      isAuthorized: false,
    };

    const normalizedProfileLimits = (profile.limitImageMessage || profile.limitQuickMessage) && profile.messageLimits.length ? {
      limitMessagePer: profile.limitMessagePer,
      limitImageMessage: profile.limitImageMessage,
      limitQuickMessage: profile.limitQuickMessage,
      messageLimits: profile.messageLimits,
      brandId,
      productId,
      profileId,
    } : null;

    if (!isDemoUser) {
      yield call(ProfileService.createProfile, profileId, normalizedProfile, normalizedProfileLimits);
    }

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.CREATE_PROFILE_SUCCESS },
    }));

    const profileData = {
      id: profileId,
      ...normalizedProfile,
    };

    const profileLimitsData = {
      id: generateId(),
      ...normalizedProfileLimits,
    };

    const resolvedData = isDemoUser ? {
      profile: normalizeProfile(profileData),
      profileLimits: profileLimitsData,
    } : null;
    yield put(resolvedAction(constants.CREATE_PROFILE, resolvedData));
    onSuccess();
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.CREATE_PROFILE, { error: message }));
    onError();
  }
}

function* updateProfile ({ payload: { profile, productId } }) {
  try {
    const role = yield select(getRole);
    const isDemoUser = getIsDemoUser(role);
    const brandId = yield select(getBrandId);

    let normalizedProfile = {};
    if (profile.canUpdate) {
      normalizedProfile = profile;
      const imagePath = profile.image.file ? yield call(FileService.uploadFile, profile.image.file, `${isDemoUser ? DEMO_PREFIX : ''}${PROFILES_PATH}/${profile.profileId}`) : null;

      normalizedProfile = {
        address: profile.address,
        addressLat: profile.addressLat,
        addressLng: profile.addressLng,
        description: profile.description,
        phoneNumber: profile.phoneNumber,
        title: profile.title,
        website: profile.website,
        ...(profile.email ? { email: profile.email } : {}),
        ...(imagePath ? { image: imagePath } : {}),
      };
    }

    normalizedProfile.brands = [...new Set([...profile.brands, brandId])];
    normalizedProfile.products = [...new Set([...profile.products, productId])];

    let normalizedProfileLimits = null;
    if ((profile.limitImageMessage || profile.limitQuickMessage) && profile.messageLimits.length) {
      normalizedProfileLimits = {
        limitMessagePer: profile.limitMessagePer,
        limitImageMessage: profile.limitImageMessage,
        limitQuickMessage: profile.limitQuickMessage,
        messageLimits: profile.messageLimits,
        brandId,
        productId,
        profileId: profile.profileId,
      };
    }

    if (!isDemoUser) {
      yield call(ProfileService.updateProfile, profile.profileId, normalizedProfile, profile.limitsId, normalizedProfileLimits);
    }

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.UPDATE_PROFILE_SUCCESS },
    }));

    const resolvedData = isDemoUser ? {
      profile: normalizeProfile(normalizedProfile),
      profileLimits: normalizedProfileLimits,
    } : null;
    yield put(resolvedAction(constants.UPDATE_PROFILE, resolvedData));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));
    yield put(rejectedAction(constants.UPDATE_PROFILE, { message }));
  }
}

function* updateProfileByOwner ({ payload: { profile } }) {
  try {
    const imagePath = profile.image.file ? yield call(FileService.uploadFile, profile.image.file, `${PROFILES_PATH}/${profile.uid}`) : null;

    const normalizedProfile = {
      address: profile.address,
      description: profile.description,
      phoneNumber: profile.phoneNumber,
      title: profile.title,
      website: profile.website,
      addressLat: profile.addressLat,
      addressLng: profile.addressLng,
      ...(imagePath ? { image: imagePath } : {}),
    };

    yield call(ProfileService.updateProfile, profile.uid, normalizedProfile);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.UPDATE_PROFILE_SUCCESS },
    }));

    yield put(resolvedAction(constants.UPDATE_PROFILE_BY_OWNER));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));
    yield put(rejectedAction(constants.UPDATE_PROFILE_BY_OWNER, { message }));
  }
}

function* getProfileByEmail ({ payload: { email, productId, onSuccess, onError } }) {
  try {
    const [[profile], { data: { isExist } }] = yield all([
      call(ProfileService.getProfile, ['email', '==', email]),
      call(UserService.getIsExistByEmail, email),
    ]);

    if (!profile) {
      if (isExist) {
        yield put(modalConductorActions.openModal({
          modal: ERROR_ALERT,
          params: { message: 'User with this email is already exist.' },
        }));

        onError(null);
        return;
      }

      onSuccess(null);
    }

    const limits = yield select(getProfileLimitsByProfileIdAndProductId(profile.id, productId));

    const normalizedProfile = normalizeProfile(profile);
    yield put(resolvedAction(constants.GET_PROFILE_BY_EMAIL, { profile: normalizedProfile }));
    if (onSuccess) {
      onSuccess({
        ...limits,
        ...normalizedProfile,
        limitsId: limits?.id,
        profileId: profile.id,
      });
    }
  } catch ({ message }) {
    yield put(rejectedAction(constants.GET_PROFILE_BY_EMAIL, { error: message }));
    if (onError) {
      onError(null);
    }
  }
}

function* syncProfile () {
  try {
    yield take(resolved(userConstants.WATCH_USER));
    const { uid, isAuthorized, products } = yield select(getUserInfo);
    if (!isAuthorized) {
      yield fork(authorizeProfile, uid);
    }

    if (products.length) {
      yield call(ProfileService.syncProducts, products);
    }
  } catch (e) {
    console.log('Failed to sync profile', e);
  }
}

function* authorizeProfile (uid) {
  try {
    yield call(ProfileService.updateProfile, uid, { isAuthorized: true });
  } catch (e) {
    console.log('Failed to authorize profile', e);
  }
}

/*
 * Watchers
 */

function* initWatcher () {
  yield take(appConstants.INIT);
  yield call(init);
}

function* createProfileWatcher () {
  yield takeLatest(constants.CREATE_PROFILE, createProfile);
}

function* updateProfileWatcher () {
  yield takeLatest(constants.UPDATE_PROFILE, updateProfile);
}

function* updateProfileByOwnerWatcher () {
  yield takeLatest(constants.UPDATE_PROFILE_BY_OWNER, updateProfileByOwner);
}

function* getProfileByEmailWatcher () {
  yield takeLatest(constants.GET_PROFILE_BY_EMAIL, getProfileByEmail);
}


export default [
  initWatcher,
  createProfileWatcher,
  updateProfileWatcher,
  updateProfileByOwnerWatcher,
  getProfileByEmailWatcher,
];
