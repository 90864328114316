import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  LockOpen as LockOpenIcon,
  FilterNone as FilterNoneIcon,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import LimitedAccessBadge from '@pro/web-common/components/limited-access-badge';
import Tooltip from '@pro/web-common/components/conditional-tooltip';

import {
  PRODUCT_ITEM_COPY_TEXT,
  PRODUCT_ITEM_SUBSCRIBE_TEXT,
  PRODUCT_ITEM_RESUME_ACCESS_TEXT,
} from 'content/texts';

import { styles } from './styles';


const ProductListingItem = React.memo(({ productName, isLimited, onEdit, onDelete, onUnlock, fetching, isLast, underlineLast, withProductCopy, onCopy, isVisible, onVisibilityUpdate }) => {
  const classes = styles({
    isLast,
    underlineLast,
  });

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        flexDirection="row"
      >
        <Typography>
          {productName}
        </Typography>

        {isLimited && (
          <LimitedAccessBadge />
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
      >
        {onCopy && (
          <Tooltip
            title={withProductCopy ? PRODUCT_ITEM_COPY_TEXT : PRODUCT_ITEM_SUBSCRIBE_TEXT}
            isShown
          >
            <div>
              <IconButton
                onClick={onCopy}
                disabled={!withProductCopy}
              >
                <FilterNoneIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}

        {isLimited && onUnlock && (
          <Tooltip
            title={PRODUCT_ITEM_RESUME_ACCESS_TEXT}
            isShown
          >
            <IconButton
              onClick={onUnlock}
              disabled={fetching}
            >
              <LockOpenIcon />
            </IconButton>
          </Tooltip>
        )}

        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>

        {onDelete && (
          <IconButton
            onClick={onDelete}
            disabled={fetching}
          >
            <DeleteIcon color="secondary" />
          </IconButton>
        )}

        {!isLimited && (
          <Tooltip
            title={isVisible ? 'Hide' : 'Show'}
            isShown
          >
            <IconButton onClick={() => onVisibilityUpdate(!isVisible)}>
              {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
});

ProductListingItem.propTypes = {
  productName: PropTypes.string.isRequired,
  isLimited: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onUnlock: PropTypes.func,
  fetching: PropTypes.bool,
  isLast: PropTypes.bool,
  underlineLast: PropTypes.bool,
  withProductCopy: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  onVisibilityUpdate: PropTypes.func.isRequired,
};

ProductListingItem.defaultProps = {
  onDelete: null,
  onUnlock: null,
  fetching: false,
  isLast: false,
  underlineLast: false,
  isVisible: true,
};


export default ProductListingItem;
