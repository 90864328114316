import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  formControl: {
    width: '300px',
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));
