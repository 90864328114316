import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';

import { styles } from './styles';


const HeroSection = React.memo(({ image, title, textLeft, textRight, imageHeightMobile, imageHeightDesktop, contentWidth }) => {
  const classes = styles({ image });

  return (image || title || textLeft || textRight) && (
    <div className={classes.container}>
      {image && (
        <Box
          height={[imageHeightMobile, imageHeightDesktop]}
          className={classes.imageBackground}
          mb={4}
        />
      )}

      {(title || textLeft || textRight) && (
        <Container maxWidth={contentWidth}>
          {title && (
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
            >
              {title}
            </Typography>
          )}

          <Grid
            container
            spacing={2}
          >
            {textLeft && (
              <Grid
                item
                xs={12}
                sm={textRight ? 6 : 12}
              >
                <Typography>
                  {textLeft}
                </Typography>
              </Grid>
            )}

            {textRight && (
              <Grid
                item
                xs={12}
                sm={textLeft ? 6 : 12}
              >
                <Typography>
                  {textRight}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      )}
    </div>
  );
});

HeroSection.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  textLeft: PropTypes.string,
  textRight: PropTypes.string,
  imageHeightMobile: PropTypes.number,
  imageHeightDesktop: PropTypes.number,
  contentWidth: PropTypes.string,
};

HeroSection.defaultProps = {
  image: null,
  title: null,
  textLeft: null,
  textRight: null,
  imageHeightMobile: 180,
  imageHeightDesktop: 300,
  contentWidth: 'md',
};


export default HeroSection;
