import { makeStyles } from '@material-ui/core/styles';


const FB_COLOR = '#1877F2';
const FB_LOGO_SIZE = '24px';
export const styles = makeStyles((theme) => ({
  fbLogo: {
    maxWidth: FB_LOGO_SIZE,
    maxHeight: FB_LOGO_SIZE,
  },
  buttonRoot: {
    paddingTop: theme.spacing(2 / 3),
    paddingBottom: theme.spacing(2 / 3),
    border: `1px solid ${FB_COLOR}`,

    '&, &:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
    },
  },
  buttonLabel: {
    color: FB_COLOR,
    textTransform: 'none',
  },
}));
