import React from 'react';

import { Box } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';

import Tooltip from '@pro/web-common/components/conditional-tooltip';


const LimitedAccessBadge = React.memo(() => (
  <Box
    mr={1 / 2}
    ml={1 / 2}
  >
    <Tooltip
      title="Limited access"
      aria-label="locked"
      isShown
    >
      <LockIcon
        fontSize="small"
        color="disabled"
      />
    </Tooltip>
  </Box>
));


LimitedAccessBadge.propTypes = {};

LimitedAccessBadge.defaultProps = {};


export default LimitedAccessBadge;
