import React, { useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import {
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Settings as SettingsIcon,
  Delete as DeleteIcon,
  FilterNone as FilterNoneIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  ChevronRight as ChevronRightIcon, VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon,
} from '@material-ui/icons';

import { getSubscriptionPermissions } from '@pro/web-common/core/brand/utils';

import IconRenderer from '@pro/web-common/components/icon-renderer';
import { BrandContext } from '@pro/web-common/containers/providers';
import Tooltip from '@pro/web-common/components/conditional-tooltip';

import {
  PRODUCT_PAGE_ADD_VERSIONS_TEXT,
  PRODUCT_PAGE_SUBSCRIBE_TO_ADD_VERSIONS_TEXT,
} from 'content/texts';

import { styles } from './styles';


const ProductPage = React.memo(({ id, parentPageId, iconId, label, content, isContentEditable, isCopyAvailable, isEditable, isDeletable, isVisible, onEdit, onCopy, onDelete, onUpdate, onVersionsToggle, onVisibilityUpdate, Editor, withVersions, isVersionsExpanded, isSubmitAvailable, pages, onError }) => {
  const { brand } = useContext(BrandContext);
  const { hasSubscriptions } = brand || {};
  const classes = styles();

  const [isEditorOpened, setIsEditorOpened] = useState(false);
  const { canAddManualVersions } = useMemo(() => getSubscriptionPermissions(brand), [brand]);

  const toggleContentEditor = useCallback(
    () => setIsEditorOpened(!isEditorOpened),
    [isEditorOpened],
  );

  const handleEditClick = useCallback((e) => {
    e.stopPropagation();
    onEdit();
  }, [onEdit]);

  const handleCopyClick = useCallback((e) => {
    e.stopPropagation();
    onCopy();
  }, [onCopy]);

  const handleDeleteClick = useCallback((e) => {
    e.stopPropagation();
    onDelete();
  }, [onDelete]);

  const handleContentOnUpdate = useCallback((data) => {
    setIsEditorOpened(false);
    onUpdate(data);
  }, [onUpdate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
    >
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box
            display="flex"
            flexDirection="row"
          >
            <Box mr={2}>
              <IconRenderer
                iconId={iconId}
              />
            </Box>

            <Typography>
              {label}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
          >
            {isCopyAvailable && (
              <Tooltip
                title={canAddManualVersions ? PRODUCT_PAGE_ADD_VERSIONS_TEXT : PRODUCT_PAGE_SUBSCRIBE_TO_ADD_VERSIONS_TEXT}
                isShown
              >
                <IconButton
                  onClick={handleCopyClick}
                  disabled={!canAddManualVersions}
                >
                  <FilterNoneIcon />
                </IconButton>
              </Tooltip>
            )}

            {isContentEditable && (
              <Tooltip
                title="Edit content"
                isShown
              >
                <IconButton onClick={toggleContentEditor}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}

            {isEditable && (
              <Tooltip
                title="Page settings"
                isShown
              >
                <IconButton onClick={handleEditClick}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            )}

            {isDeletable && (
              <Tooltip
                title="Delete"
                isShown
              >
                <IconButton onClick={handleDeleteClick}>
                  <DeleteIcon color="secondary" />
                </IconButton>
              </Tooltip>
            )}

            {onVisibilityUpdate && (
              <Tooltip
                title={isVisible ? 'Hide' : 'Show'}
                isShown
              >
                <IconButton onClick={() => onVisibilityUpdate(!isVisible)}>
                  {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </Tooltip>
            )}

            {withVersions && (
              <Tooltip
                title="Show versions"
                isShown
              >
                <IconButton onClick={onVersionsToggle}>
                  {isVersionsExpanded ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>

      {Editor && (
        <Editor
          withTranslation={!isNil(parentPageId) || !hasSubscriptions}
          isOpened={isEditorOpened}
          onClose={() => setIsEditorOpened(false)}
          onSubmit={handleContentOnUpdate}
          onError={onError}
          initialValues={content}
          isSubmitDisabled={!isSubmitAvailable}
          pages={pages}
          currentPageId={id}
        />
      )}
    </Box>
  );
});

ProductPage.propTypes = {
  id: PropTypes.string.isRequired,
  parentPageId: PropTypes.string,
  iconId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isContentEditable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isCopyAvailable: PropTypes.bool,
  isEditable: PropTypes.bool,
  Editor: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  onEdit: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  onVersionsToggle: PropTypes.func,
  onVisibilityUpdate: PropTypes.func,
  content: PropTypes.shape({}),
  withVersions: PropTypes.bool,
  isVersionsExpanded: PropTypes.bool,
  isSubmitAvailable: PropTypes.bool,
  pages: PropTypes.arrayOf(PropTypes.shape(({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }))).isRequired,
  isVisible: PropTypes.bool,
};

ProductPage.defaultProps = {
  parentPageId: null,
  isContentEditable: true,
  isDeletable: true,
  isCopyAvailable: false,
  isEditable: true,
  Editor: null,
  onEdit: () => null,
  onCopy: () => null,
  onDelete: () => null,
  content: null,
  withVersions: false,
  isVersionsExpanded: false,
  onVersionsToggle: null,
  isSubmitAvailable: true,
  onVisibilityUpdate: null,
  isVisible: true,
};


export default ProductPage;
