import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';

import {
  Edit as EditIcon,
} from '@material-ui/icons';

import { styles } from './styles';


const EnterpriseImageListingItem = React.memo(({ onEdit }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        flexDirection="row"
      >
        <Typography>
          Home Page
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
      >
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Box>
    </Box>
  );
});

EnterpriseImageListingItem.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

EnterpriseImageListingItem.defaultProps = {};


export default EnterpriseImageListingItem;
