import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { isNil, move } from 'ramda';
import QRCode from 'qrcode.react';

import {
  Button,
  Grid,
  Box,
  Link,
  Typography,
} from '@material-ui/core';

import { useInterval } from '@pro/web-common/utils/hooks';

import ProductPage from '@pro/web-common/components/product-page';
import AppPreview from '@pro/web-common/components/app-preview';
import ProductAddPage from '@pro/web-common/components/product-add-page';
import SortableList from '@pro/web-common/components/sortable-list';
import SortableListItem from '@pro/web-common/components/sortable-list-item';
import InfoSection from '@pro/web-common/components/info-section';
import infoJson from 'constants/info';
import SectionTitle from '@pro/web-common/components/section-title';
import ConfigureProductPageModal from '@pro/web-common/components/configure-product-page-modal';
import DeletionConfirmationModal from '@pro/web-common/components/action-confirmation-modal';
import ProductBaseInfoEditor from '@pro/web-common/components/product-base-info-editor';
import { BrandContext } from '@pro/web-common/containers/providers';

import { PAGE_TYPE, DEFAULT_PAGES } from 'constants/product-config';
import { generateId } from '@pro/web-common/utils';
import { getPageProps } from '@pro/web-common/utils/product';

import {
  PRODUCT_CONFIGURATION_FINISH_CONFIG_TEXT,
} from 'content/texts';

import {
  getPageTypeValues,
  validateProductData,
  getEditorByPageType,
  getInfoMessageByPageType,
  groupPagesByType,
  getQrCodeLink,
} from './utils';

import { styles } from './styles';


const ProductConfigurator = React.memo(({ initialValues, fetching, onSubmit, submitLabel, brandConfig, onError, isSubmitAvailable, isCreationMode, isLimited }) => {
  const classes = styles();

  const { brand } = useContext(BrandContext);
  const { hasSubscriptions, logo } = brand || {};

  const { url: logoUrl } = logo || {};

  const {
    id: productId,
    productName: initialProductName,
    pages: initialPages,
    tags: initialTags,
    isVisible: initialIsProductVisible = true,
    address: initialAddress,
    pushMessagesRadius: initialPushMessagesRadius,
    shortLink,
  } = initialValues;

  const [isIntervalRunning, setIsIntervalRunning] = useState(true);
  const [qrCodeBase64, setQrCodeBase64] = useState(null);

  useInterval(() => {
    const qrCodeCanvas = document.querySelector('.qrCodeContainer > canvas');

    if (qrCodeCanvas) {
      setQrCodeBase64(qrCodeCanvas.toDataURL());
      setIsIntervalRunning(false);
    }
  }, isIntervalRunning ? 500 : null);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [productName, setProductName] = useState(initialProductName || '');
  const [address, setAddress] = useState(initialAddress || '');
  const [pushMessagesRadius, setPushMessagesRadius] = useState(initialPushMessagesRadius || '');
  const [tags, setTags] = useState(initialTags || []);
  const defaultPages = useMemo(() => (
    (initialPages || DEFAULT_PAGES).map(({ showLogo, ...rest }) => ({
      ...rest,
      showLogo: isNil(logoUrl) ? false : showLogo,
    }))
  ), [logoUrl, initialPages]);
  const [pages, setPages] = useState(defaultPages);
  const [isProductVisible, setIsProductVisible] = useState(initialIsProductVisible);
  const [isConfigureProductPageModalVisible, setIsConfigureProductPageModalVisible] = useState(false);
  const [pageEditorData, setPageEditorData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVersionsExpanded, setIsVersionsExpanded] = useState(false);
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);

  const [isDeletionConfirmationModalVisible, setIsDeletionConfirmationModalVisible] = useState(false);
  const [deletingPage, setDeletingPage] = useState(null);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    // indexes should be +1 to ignore first non-sortable
    setShowSubmitSuccess(false);
    setPages(move(oldIndex + 1, newIndex + 1, pages));
  }, [pages]);

  const getPageIndexById = useCallback(
    (id) => pages.findIndex(({ id: pageId }) => pageId === id),
    [pages],
  );

  const onPageDelete = useCallback((id, forceDeletion) => {
    const index = getPageIndexById(id);

    if (!forceDeletion) {
      const page = pages[index];
      setDeletingPage(page);
      setIsDeletionConfirmationModalVisible(true);
    } else {
      const newPages = update(pages, { $splice: [[index, 1]] }).filter(({ parentPageId }) => parentPageId !== id);

      setShowSubmitSuccess(false);
      setPages(newPages);
    }
  }, [getPageIndexById, pages]);

  const onPageContentUpdate = useCallback(({ id, data }) => {
    const index = getPageIndexById(id);
    const newPages = update(pages, { [index]: { content: { $set: data } } });

    setShowSubmitSuccess(true);
    setPages(newPages);
  }, [getPageIndexById, pages]);

  const addNewPage = useCallback((config) => {
    const newPage = getPageProps({
      ...config,
      isCopyAvailable: PAGE_TYPE.MANUAL && (config.pageTypeId === PAGE_TYPE.MANUAL.id),
    });
    const newPages = pages.concat(newPage).sort(({ parentPageId: aParentPageId }, { parentPageId: bParentPageId }) => !!aParentPageId - !!bParentPageId);

    setShowSubmitSuccess(false);
    setPages(newPages);
  }, [pages]);

  const updatePageConfig = useCallback((pageId, config) => {
    const index = getPageIndexById(pageId);
    const newPages = update(pages, { [index]: { $merge: config } });

    setShowSubmitSuccess(true);
    setPages(newPages);
  }, [getPageIndexById, pages]);

  const onPageConfigUpdate = useCallback((pageId, config) => {
    const isNewPage = isNil(pageId);

    if (isNewPage) {
      addNewPage(config);
    } else {
      updatePageConfig(pageId, config);
    }

    closeConfigureProductPageModal();
  }, [getPageIndexById, pages]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeConfigureProductPageModal = useCallback(() => {
    setPageEditorData(null);
    setIsConfigureProductPageModalVisible(false);
  }, []);

  const closeDeletionConfirmationModal = useCallback(() => {
    setDeletingPage(null);
    setIsDeletionConfirmationModalVisible(false);
  }, []);

  const onPageDeletionConfirm = useCallback(() => {
    const { id } = deletingPage;

    closeDeletionConfirmationModal();
    onPageDelete(id, true);
  }, [deletingPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const openProductPageEditor = useCallback((pageData) => {
    setPageEditorData(pageData);
    setIsConfigureProductPageModalVisible(true);
  }, []);

  const duplicateProductPage = useCallback(({ id, ...pageData }) => {
    setIsVersionsExpanded(true);

    const newPage = ({
      ...pageData,
      isCopyAvailable: false,
      isEditable: false,
      iconId: null,
      parentPageId: id,
      id: generateId(),
    });

    const newPages = update(pages, { $push: [newPage] });
    setShowSubmitSuccess(false);
    setPages(newPages);
  }, [pages]);

  const updateProductPageVisibility = useCallback((id, isVisible) => {
    const index = getPageIndexById(id);
    const newPages = update(pages, { [index]: { $merge: { isVisible } } });

    setPages(newPages);
  }, [pages]);

  const onBaseInfoSave = useCallback(({ productName: newProductName, tags: newTags, address: newAddress, pushMessagesRadius: newPushMessagesRadius }) => {
    setShowSubmitSuccess(true);
    setProductName(newProductName);
    setAddress(newAddress);
    setPushMessagesRadius(newPushMessagesRadius);
    setTags(newTags);
  }, []);

  const handleSubmit = useCallback(() => {
    const { isValid, errors } = validateProductData(productName, pages);

    if (!isValid) {
      onError({
        errors,
        errorsTitle: PRODUCT_CONFIGURATION_FINISH_CONFIG_TEXT,
      });
    } else {
      onSubmit({
        productName,
        address,
        pushMessagesRadius,
        pages,
        tags,
        showSubmitSuccess,
        isVisible: !!isProductVisible,
      });
    }
  }, [onError, onSubmit, pages, productName, address, pushMessagesRadius, tags, showSubmitSuccess, isProductVisible]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    setIsSubmitting(fetching);
  }, [fetching]);

  useEffect(() => {
    if (!isFirstRender && isSubmitAvailable && !isCreationMode) {
      handleSubmit();
    }
  }, [pages, productName, address, pushMessagesRadius, tags, isProductVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDeletingPageText = useCallback(() => {
    const { label, content } = deletingPage || {};
    const { language } = content || {};
    const langPrefix = language ? ` (${language})` : '';

    return `Are you sure you want to delete ${label}${langPrefix} page?`;
  }, [deletingPage]);

  const { original: originalPages = [], version: versionPages = [] } = useMemo(() => groupPagesByType(pages), [pages]);
  const sortablePages = useMemo(() => originalPages.slice(1, originalPages.length), [originalPages]);
  const nonSortablePage = useMemo(() => originalPages[0], [originalPages]);

  const sortableListContent = useMemo(() => (sortablePages.map((pageData, index) => {
    const { id, pageTypeId, iconId } = pageData;
    const pageVersions = versionPages.filter(({ parentPageId }) => parentPageId === id);
    const hasVersions = pageVersions.length > 0;

    return (
      <SortableListItem
        key={id}
        index={index}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <InfoSection
            infoMessage={infoJson.product[getInfoMessageByPageType(pageTypeId)]}
            top={20}
          >
            <ProductPage
              {...pageData}
              onDelete={() => onPageDelete(id)}
              onUpdate={(data) => onPageContentUpdate({
                id,
                data,
              })}
              onEdit={() => openProductPageEditor(pageData)}
              onCopy={() => duplicateProductPage(pageData)}
              onError={onError}
              onVersionsToggle={() => setIsVersionsExpanded(!isVersionsExpanded)}
              Editor={getEditorByPageType(pageTypeId)}
              withVersions={hasVersions}
              isVersionsExpanded={isVersionsExpanded}
              isSubmitAvailable={isSubmitAvailable}
              pages={pages}
              {...(isLimited ? {} : { onVisibilityUpdate: (isVisible) => updateProductPageVisibility(id, isVisible) })}
            />
          </InfoSection>

          {isVersionsExpanded && pageVersions && pageVersions.length > 0 && (
            <Box pl={4}>
              {pageVersions.map((versionPageData) => {
                const { id: versionId, content: { language = '' }, label } = versionPageData;
                const langPrefix = language ? `(${language})` : '';

                return (
                  <ProductPage
                    key={versionId}
                    {...versionPageData}
                    iconId={iconId}
                    label={`${label} ${langPrefix}`}
                    onDelete={() => onPageDelete(versionId)}
                    onUpdate={(data) => onPageContentUpdate({
                      id: versionId,
                      data,
                    })}
                    onEdit={() => openProductPageEditor(versionPageData)}
                    onError={onError}
                    Editor={getEditorByPageType(pageTypeId)}
                    isSubmitAvailable={isSubmitAvailable}
                    pages={pages}
                    {...(isLimited ? {} : { onVisibilityUpdate: (isVisible) => updateProductPageVisibility(versionId, isVisible) })}
                  />
                );
              })}
            </Box>
          )}
        </Box>
      </SortableListItem>
    );
  })), [isVersionsExpanded, sortablePages, versionPages]);

  const pageTypeValues = useMemo(() => (
    getPageTypeValues({
      pages,
      isGetAll: !!pageEditorData,
      hasSubscriptions,
    })
  ), [pages, pageEditorData]);

  return (
    <>
      <Grid
        container
        justify="space-between"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <ProductBaseInfoEditor
            initialValues={{
              productName,
              address,
              pushMessagesRadius,
              tags,
            }}
            withSubmitButton={!isCreationMode}
            isSubmitAvailable={isSubmitAvailable}
            onSubmit={onBaseInfoSave}
          />

          <InfoSection
            infoMessage={infoJson.product[getInfoMessageByPageType(nonSortablePage.pageTypeId)]}
            top={20}
          >
            <ProductPage
              onUpdate={(data) => onPageContentUpdate({
                id: nonSortablePage.id,
                data,
              })}
              onEdit={() => openProductPageEditor(nonSortablePage)}
              Editor={getEditorByPageType(nonSortablePage.pageTypeId)}
              isSubmitAvailable={isSubmitAvailable}
              pages={pages}
              onError={onError}
              {...nonSortablePage}
            />
          </InfoSection>

          <SortableList
            onSortEnd={onSortEnd}
            useDragHandle
            lockAxis="y"
            distance={1}
          >
            {sortableListContent}
          </SortableList>

          <ProductAddPage
            onClick={() => setIsConfigureProductPageModalVisible(true)}
          />

          {productId && (
            <Grid
              item
              xs={12}
            >
              <Box mt={6}>
                <SectionTitle title="QR code" />

                <Box className={`${classes.qrCodeContainer} qrCodeContainer`}>
                  <QRCode
                    size={512}
                    value={getQrCodeLink(productId)}
                  />

                  <Link
                    href={qrCodeBase64}
                    download={`${productName}-qr.png`}
                  >
                    Download
                  </Link>
                </Box>

                <Box mt={2}>
                  <Typography variant="body2"><strong>Link to your App (for use in email/text comms):{'  '}</strong>
                  </Typography>
                  <Typography variant="body2">
                    <Link href={shortLink}>{shortLink}</Link>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}

          {!isCreationMode && !isLimited && (
            <Grid
              item
              xs={12}
            >
              <Box mt={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsProductVisible(!isProductVisible)}
                >
                  {isProductVisible ? 'Hide' : 'Show'} product
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
        >
          <InfoSection infoMessage={infoJson.product.appPreview}>
            <AppPreview
              brand={brandConfig}
              pages={pages}
              productName={productName}
              isInteractive
            />
          </InfoSection>
        </Grid>
      </Grid>

      {isCreationMode && (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              textAlign="right"
              mt={4}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isSubmitAvailable}
                onClick={handleSubmit}
              >
                {submitLabel}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}

      {isConfigureProductPageModalVisible && (
        <ConfigureProductPageModal
          onClose={() => closeConfigureProductPageModal()}
          onSubmit={onPageConfigUpdate}
          initialValues={pageEditorData}
          pageTypeValues={pageTypeValues}
          isEdit={!!pageEditorData}
          logoUrl={logoUrl}
        />
      )}

      {isDeletionConfirmationModalVisible && (
        <DeletionConfirmationModal
          title="Deletion confirmation"
          text={getDeletingPageText()}
          onClose={() => closeDeletionConfirmationModal()}
          onConfirm={onPageDeletionConfirm}
        />
      )}
    </>
  );
});

ProductConfigurator.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    productName: PropTypes.string,
    address: PropTypes.string,
    pushMessagesRadius: PropTypes.string,
    pages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      pageTypeId: PropTypes.string,
    })),
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
    isVisible: PropTypes.bool,
    shortLink: PropTypes.string,
  }),
  isSubmitAvailable: PropTypes.bool,
  fetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  brandConfig: PropTypes.shape({}).isRequired,
  onError: PropTypes.func.isRequired,
  isCreationMode: PropTypes.bool,
  isLimited: PropTypes.bool.isRequired,
};

ProductConfigurator.defaultProps = {
  initialValues: {},
  isSubmitAvailable: true,
  isCreationMode: false,
};


export default ProductConfigurator;
