import { currencyValues } from '@pro/web-common/utils/currency';


export const FOOTER_TEXT_LINE_1 = '© 2020 App Sprout Limited.';
export const FOOTER_TEXT_LINE_2 = 'Registered in England. No. 10411536';
export const MANAGER_FORM_CHOOSE_ITEM_LABEL = 'Choose BnB';
export const MANUAL_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Guest Information for this BnB';
export const TIMETABLE_PAGE_EDITOR_ADD_INFO_TEXT = 'Add the Timetable for this BnB';
export const ALL_ITEMS_LABEL = 'All BnBs';
export const PRODUCT_CONFIGURATION_FINISH_CONFIG_TEXT = 'Please add your BnB name';
export const PRODUCT_CONFIGURATION_ITEM_NAME_LABEL = 'Hotel / Resort / Attraction / Tour';
export const PRODUCT_CONFIGURATION_ITEM_NAME_PLACEHOLDER = 'Add BnB Name';
export const PRODUCT_CONFIGURATION_ITEM_NAME_REQUIRED_ERROR = 'Add BnB name;';
export const PRODUCT_CONFIGURATION_PAGES_COUNT_ERROR = 'Add minimum four pages for your Hotel, Resort, Attraction or Tour;';
export const PRODUCT_FILTERS_SELECT_ITEM_LABEL = 'Select BnB';
export const PRODUCTS_LISTING_NO_DATA_TEXT = 'You have not added a BnB yet. Add your Hotel, Resort, Attraction or Tour name here.';
export const PRODUCT_ITEM_COPY_TEXT = 'Duplicate BnB';
export const PRODUCT_ITEM_SUBSCRIBE_TEXT = 'Please subscribe to add more BnBs';
export const PRODUCT_ITEM_RESUME_ACCESS_TEXT = 'Resume access';
export const PRODUCT_PAGE_ADD_VERSIONS_TEXT = 'Add versions';
export const PRODUCT_PAGE_SUBSCRIBE_TO_ADD_VERSIONS_TEXT = 'You need a subscription to add language versions';
export const QUICK_MESSAGES_LISTING_NO_DATA_TEXT = 'You have not added quick messages for the selected BnB yet';
export const MESSAGES_LISTING_NO_DATA_TEXT = 'You have not added messages for your BnB';
export const RTF_PAGE_EDITOR_TITLE = 'Add text page for this BnB';
export const ADMIN_PRODUCTS_TITLE = 'BnB Hotel, Resort, Attraction or Tour';
export const ADMIN_PRODUCTS_ADD_PRODUCT_BUTTON_LABEL = 'Add a BnB';
export const getAdminProductsAddMoreItemsText = (availableProducts) => `You can add ${availableProducts} more hotel/resort${availableProducts > 1 ? 's' : ''}`;
export const ADMIN_PRODUCTS_ADD_SUBSCRIPTION_TEXT = 'Add subscription to add a new BnB';
export const ADD_FIRST_ITEM_TITLE = 'Add your first BnB';
export const ADD_PRODUCT_TITLE = 'Add BnB';
export const ADD_PRODUCT_SUBTITLE = 'Add a hotel or resort + any support pages';
export const ADD_PRODUCT_ADD_NEW_PRODUCTS_TEXT = 'add new hotels and resorts';
export const ADMIN_HOME_TITLE = 'My Home';
export const ADMIN_HOME_SUBTITLE = 'Manage all your content, messaging and more';
export const ADMIN_SUBSCRIPTIONS_TITLE = 'My Subscriptions';
export const ADMIN_SUBSCRIPTIONS_SUBTITLE = 'Manage subscriptions for all your BnB. Upgrade your free trial for full features, subscribe for more destinations in singles or packs of ten.';
export const ADMIN_SUBSCRIPTIONS_CONTACT_US_LABEL = null;
export const ADMIN_USERS_TITLE = 'My Users';
export const ADMIN_USERS_SUBTITLE = 'Users can be associated with a single or multiple BnBs, and can manage content and messaging for them. Additional uers do not have access to Brand Configuration.';
export const EDIT_PRODUCT_SUBTITLE = 'Your Hotel, Resort, Attraction or Tour App + any support pages';
export const EDIT_PRODUCT_LIMITED_ACCESS_NOTE = 'NOTE: You have limited access because your trial has ended or your subscription has lapsed. You will not be able to save updates until you renew your subscription (top right menu - My Subscriptions)!';
export const QUICK_MESSAGES_TITLE = 'Quick Messaging';
export const QUICK_MESSAGES_SUBTITLE = 'Manage quick messaging for your BnBs. Quick messages are just simple Push Notifications.';
export const QUICK_MESSAGES_NO_ITEMS_NOTE = 'NOTE: You have not added any BnBs yet or they have limited access at the moment. Contact support chat for more help.';
export const DEFAULT_MANUAL_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default guestbook section title',
      html: '<p>Default guestbook section content</p>',
      image: null,
      isVisible: true,
      subsections: [
        {
          id: 2,
          title: 'Default guestbook sub section title',
          html: '<p>Default guestbook sub section content</p>',
          image: null,
          currencyId: currencyValues[0].value,
          isVisible: true,
        },
      ],
    },
  ],
};

export const DEFAULT_TIMETABLE_CONTENT = {
  sections: [
    {
      id: 1,
      title: 'Default Timetable Item - 9.00am',
      html: '<p>Default Timetable Item - 9.00am</p>',
      image: null,
      isVisible: true,
    },
  ],
};
