import { put, takeLatest, call } from 'redux-saga/effects';

import { resolvedAction, rejectedAction } from '@pro/web-common/core/actions';

import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';
import { PAYMENTS_PATH } from '@pro/web-common/constants/storage';
import { generateId } from '@pro/web-common/utils';
import FileService from '@pro/web-common/core/file/service';

import { MODAL_MESSAGE } from 'content/modals';

import PaymentService from './service';
import { constants } from './actions';

/*
 * Sagas
 */

function* createPaymentIntent ({ payload: { productId, currency } }) {
  try {
    const { data: key } = yield call(PaymentService.createPaymentIntent, productId, currency);

    yield put(resolvedAction(constants.CREATE_PAYMENT_INTENT, { productId, key }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.CREATE_PAYMENT_INTENT, { productId, error: message }));
  }
}

function* payForSetup ({ payload: { id, name, email, website, file } }) {
  try {
    const fileRef = `${PAYMENTS_PATH}/${id}`;
    const { url: fileUrl } = yield call(FileService.uploadFile, file, fileRef);

    const data = { name, email, website, fileUrl, isPaid: false };

    yield call(PaymentService.addPayment, id, data);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.PAY_FOR_SETUP_SUCCESS },
    }));

    yield put(resolvedAction(constants.PAY_FOR_SETUP));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.PAY_FOR_SETUP, { error: message }));
  }
}

/*
 * Watchers
 */

function* createPaymentIntentWatcher () {
  yield takeLatest(constants.CREATE_PAYMENT_INTENT, createPaymentIntent);
}

function* payForSetupWatcher () {
  yield takeLatest(constants.PAY_FOR_SETUP, payForSetup);
}


export default [
  createPaymentIntentWatcher,
  payForSetupWatcher,
];
