import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@material-ui/core';

import { BrandContext } from '@pro/web-common/containers/providers';

import { styles } from './styles';


const PhoneNumberPageContentPreview = React.memo(({ phoneNumber }) => {
  const { brand: { typeface, fontColor } } = useContext(BrandContext);

  const classes = styles({
    typeface,
    fontColor,
  });

  return (
    <Box
      mt={2}
      ml={1}
      mr={1}
    >
      <Typography
        variant="body2"
        className={classes.text}
      >
        This will call {phoneNumber}
      </Typography>
    </Box>
  );
});

PhoneNumberPageContentPreview.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

PhoneNumberPageContentPreview.defaultProps = {};


export default PhoneNumberPageContentPreview;
