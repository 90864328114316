import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
} from '@material-ui/core';

import { withFormik } from 'formik';

import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';

import { formValidationSchema } from './config';

import { styles } from './styles';


const ResetPasswordForm = React.memo(({ fetching, isError, onSubmit, submitLabel, ...formikProps }) => {
  const classes = styles();

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    resetForm,
  } = formikProps;

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ComponentWithSpinner
      isSubmitting={isSubmitting}
      progressSize={30}
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="password"
              label="Password"
              placeholder="Password"
              name="password"
              type="password"
              autoComplete="new-password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password && touched.password}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm Password"
              name="confirmPassword"
              type="password"
              autoComplete="new-password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.confirmPassword && touched.confirmPassword}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              textAlign="center"
              mt={2}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {submitLabel}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </ComponentWithSpinner>
  );
});

ResetPasswordForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
};

ResetPasswordForm.defaultProps = {
  isError: false,
};


export default withFormik({
  mapPropsToValues: () => ({
    password: '',
    confirmPassword: '',
  }),
  handleSubmit: (data, { props: { onSubmit } }) => onSubmit({ newPassword: data.password }),
  validationSchema: () => formValidationSchema(),
})(ResetPasswordForm);
