import FirebaseDatabaseService from '@pro/web-common/services/database';
import FirebaseFunctionsService from '@pro/web-common/services/functions';

import { PROFILES_NODE, PROFILES_MESSAGES_LIMITS, CLOUD_FUNCTIONS } from './constants';



const ProfileService = {
  watchProfiles: (callback, requestParams) => FirebaseDatabaseService.watchData({
    refPath: PROFILES_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    callback,
    whereParams: [requestParams],
  }),
  watchProfilesLimits: (callback, requestParams) => FirebaseDatabaseService.watchData({
    refPath: PROFILES_MESSAGES_LIMITS,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    callback,
    whereParams: [requestParams],
  }),
  getProfile: (...params) => FirebaseDatabaseService.getData({
    refPath: PROFILES_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    whereParams: params,
  }),

  createProfile: (id, profile, profileLimits) => Promise.all([
    FirebaseDatabaseService.addData(`${PROFILES_NODE}/${id}`, FirebaseDatabaseService.REF_TYPES.DOC, false, profile),
    ...(profileLimits ? [FirebaseDatabaseService.addData(PROFILES_MESSAGES_LIMITS, FirebaseDatabaseService.REF_TYPES.DOC, true, profileLimits)] : []),
  ]),

  updateProfile: (profileId, profile, profileLimitsId, profileLimits) => {
    const promises = [];

    promises.push(FirebaseDatabaseService.updateDoc(`${PROFILES_NODE}/${profileId}`, profile));
    if (profileLimits) {
      if (profileLimitsId) {
        promises.push(FirebaseDatabaseService.updateDoc(`${PROFILES_MESSAGES_LIMITS}/${profileLimitsId}`, profileLimits));
      } else {
        promises.push(FirebaseDatabaseService.addData(PROFILES_MESSAGES_LIMITS, FirebaseDatabaseService.REF_TYPES.DOC, true, profileLimits));
      }
    } else if (profileLimitsId) {
      promises.push(FirebaseDatabaseService.deleteDoc(`${PROFILES_MESSAGES_LIMITS}/${profileLimitsId}`));
    }

    return Promise.all(promises);
  },

  syncProducts: (productIds) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.PROFILE_SYNC_PRODUCTS, { productIds }),
};


export default ProfileService;
