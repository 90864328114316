import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, RadioGroup, Radio as MUIRadio } from '@material-ui/core';

import { styles } from './styles';


const Radio = React.memo(({ title, error, values, labelClasses, disabled, isRow, ...rest }) => {
  const classes = styles({ error });

  return (
    <RadioGroup
      row={isRow}
      aria-label={title}
      {...rest}
    >
      {values.map(({ value, label }) => (
        <FormControlLabel
          key={value}
          value={value}
          control={<MUIRadio color="primary" />}
          label={label}
          classes={{ label: classes.label, ...labelClasses }}
          disabled={disabled}
        />
      ))}
    </RadioGroup>
  );
});

Radio.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  labelClasses: PropTypes.shape({}),
  disabled: PropTypes.bool,
  isRow: PropTypes.bool,
};

Radio.defaultProps = {
  error: false,
  labelClasses: {},
  disabled: false,
  isRow: true,
};


export default Radio;
