import React from 'react';

import {
  Container,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';

import SectionImage from 'assets/images/home/anglePhonePC.png';

import { styles } from './styles';


const BuildManualSection = React.memo(() => {
  const classes = styles();

  return (
    <Box
      pt={6}
      pb={4}
    >
      <Container maxWidth="md">
        <Grid
          container
          justify="space-between"
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h4"
            >
              Your own B&amp;B guest experience app!
            </Typography>

            <Box
              fontSize="body1.fontSize"
              className={classes.list}
            >
              <ul>
                <li>Add your logo, images and colors</li>
                <li>Include all your B&amp;B information</li>
                <li>Paperless guest information</li>
                <li>Powerful messaging with push notifications</li>
                <li>Local places with maps and directions</li>
                <li>Update guests before, during and after their stay</li>
                <li>Promote reviews and direct bookings</li>
                <li>Update anytime</li>
              </ul>
            </Box>

            <Typography>
              <Box
                mt={1}
                display="block"
                component="span"
                fontWeight="fontWeightBold"
              >
                A great way to help keep your guests safe and informed during their stay.
              </Box>
            </Typography>
            <Typography
              variant="h4"
            >
              Why PocketConcierge?
            </Typography>

            <Box
              fontSize="body1.fontSize"
              className={classes.list}
            >
              <ul>
                <li>No coding required</li>
                <li>Simple no contract monthly subscription</li>
                <li>Unrivalled support</li>
              </ul>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <img
              src={SectionImage}
              alt="hero"
            />

          </Grid>

        </Grid>
      </Container>
    </Box>
  );
});


BuildManualSection.propTypes = {};

BuildManualSection.defaultProps = {};


export default BuildManualSection;
