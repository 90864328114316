import FirebaseDatabaseService from '@pro/web-common/services/database';
import FirebaseFunctionsService from '@pro/web-common/services/functions';
import FirebaseAuthService from '@pro/web-common/services/auth';

import { PROFILES_NODE } from '@pro/web-common/core/profile/constants';

import {
  CLOUD_FUNCTIONS,
  ROLES,
  OWNERS_NODE,
  MANAGERS_NODE,
} from './constants';


const getNodeByRole = (role) => {
  switch (role) {
    case ROLES.OWNER:
    case ROLES.SUPER_ADMIN:
      return OWNERS_NODE;
    case ROLES.MANAGER:
      return MANAGERS_NODE;
    case ROLES.PROFILE:
      return PROFILES_NODE;
    default:
      throw new Error('Unknown role.');
  }
};

const UserService = {
  signIn: (email, password) => FirebaseAuthService.signIn(email, password),
  signOut: () => FirebaseAuthService.signOut(),
  subscribeToAuthState: (cb) => FirebaseAuthService.subscribeToAuthState(cb),

  addUser: (data) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.ADD_OWNER, { data }),

  watchUser: (callback, id, role) => FirebaseDatabaseService.watchData({
    refPath: `${getNodeByRole(role)}/${id}`,
    refType: FirebaseDatabaseService.REF_TYPES.DOC,
    callback,
  }),

  getOwnerByBrandId: (brandId) => FirebaseDatabaseService.getData({
    refPath: OWNERS_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    whereParams: [['brandId', '==', brandId]],
  }),

  verifyEmail: (actionCode) => FirebaseAuthService.verifyEmail(actionCode),

  sendResetPasswordLink: (email) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.SEND_RESET_PASSWORD_LINK, { email }),
  verifyResetPasswordCode: (actionCode) => FirebaseAuthService.verifyResetPasswordCode(actionCode),
  resetPassword: (actionCode, newPassword) => FirebaseAuthService.resetPassword(actionCode, newPassword),

  getIsExistByEmail: (email) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.GET_IS_EXIST_BY_EMAIL, { email }),

  updateEmail: (email) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.UPDATE_EMAIL, { email }),
  verifyEmailBeforeUpdate: (email) => FirebaseAuthService.verifyEmailBeforeUpdate(email),
};


export default UserService;
