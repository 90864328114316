import React from 'react';

import {
  Container,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';

import Easy1 from 'assets/images/123/Brand_Your_Guest_App.png';
import Easy2 from 'assets/images/123/Add_Your_Hotel_Resort_Attraction_Tour.png';
import Easy3 from 'assets/images/123/Tell_Your_Guests_QR_Code.png';
import Easy4 from 'assets/images/123/App_Different_Kettle_Of_Fish.png';

import CaseItem from './case-item';


const CasesSection = React.memo(() => (
  <Box
    bgcolor="primary.light"
    pt={8}
    pb={8}
    id="cases"
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
        >
          <Box width={1}>
            <Typography
              variant="h3"
            >
              Easy as 1, 2, 3
            </Typography>

            <Box mt={1}>
              <Typography
                gutterBottom
                variant="h5"
              >
                Creating your own app could not be easier! You need a logo, some colors and at least 1 image for your B&amp;B.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy1}
            title="Step 1"
            subtitle="Setting up your B&amp;B guest app - takes 5 minutes!"
            alt="Set up your own B&amp;B guest app in 5 minutes"
            description={(
              <>
                Add your logo, then select the colors and font you want to use. Our preview shows you exactly how your guest app will look.
              </>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy2}
            title="Step 2"
            subtitle="Add your content and pages!"
            alt="Add your Bed and Breakfast guest app as you need"
            description={(
              <>
                Once you have created your &apos;look and feel&apos; - add your  B&amp;B details and pages - like your booking page, website home page, social media, guest information.
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy3}
            title="Step 3"
            subtitle="Let your guests know!"
            alt="Your guests just need a QR code"
            description={(
              <>
                Your guests they can simply point their phone camera at your QR code (on a label, printed sheet, email or website - or they can use search terms you provide.
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <CaseItem
            image={Easy4}
            title="It&apos;s a completely different kettle of fish"
            subtitle="We love fish, but this is different"
            alt="Easy to use app builder no code required"
            description={(
              <>
                You can communicate direct with guests that are checked-in and use PocketConcierge to keep guests safe and informed.
              </>
            )}
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
));


CasesSection.propTypes = {};

CasesSection.defaultProps = {};


export default CasesSection;
