import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'GET_MESSAGES',
  'CREATE_MESSAGE',
  'CREATE_QUICK_MESSAGE',
  'UPDATE_MESSAGE',
  'DELETE_MESSAGE',
  'DELETE_QUICK_MESSAGE',
  'DELETE_TRACK_TRACE_MESSAGE',
  'GET_TAGS',
  'UPDATE_TAGS',
  'GENERATE_USERS_REPORT',
], 'MESSAGE');

export const actions = createActions({
  [constants.GET_MESSAGES]: null,
  [constants.CREATE_MESSAGE]: ({ id, allProducts, data }) => ({ id, allProducts, data }),
  [constants.CREATE_QUICK_MESSAGE]: ({ data, allProducts }) => ({ data, allProducts }),
  [constants.DELETE_MESSAGE]: ({ id }) => ({ id }),
  [constants.DELETE_QUICK_MESSAGE]: ({ id }) => ({ id }),
  [constants.DELETE_TRACK_TRACE_MESSAGE]: ({ id }) => ({ id }),
  [constants.UPDATE_MESSAGE]: ({ id, allProducts, data }) => ({ id, allProducts, data }),
  [constants.GET_TAGS]: null,
  [constants.UPDATE_TAGS]: ({ data }) => ({ data }),
  [constants.GENERATE_USERS_REPORT]: ({ fromDate, toDate, productId }) => ({ fromDate, toDate, productId }),
}, {
  prefix: 'MESSAGE',
});


export default actions;
