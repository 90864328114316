import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import {
  Box,
  Link,
} from '@material-ui/core';

import { styles } from './styles';


const FooterLink = React.memo(({ title, small, isAnchor, to, ...rest }) => {
  const classes = styles({ small });

  const scrollWithOffset = useCallback((el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Box className={classes.container}>
      {
        isAnchor ? (
          <NavHashLink
            className={classes.link}
            to={`/#${to}`}
            scroll={(el) => scrollWithOffset(el, 64)}
          >
            {title}
          </NavHashLink>
        ) : (
          <Link
            className={classes.link}
            component={NavLink}
            to={to}
            {...rest}
          >
            {title}
          </Link>
        )
      }
    </Box>
  );
});

FooterLink.propTypes = {
  title: PropTypes.string.isRequired,
  small: PropTypes.bool,
  isAnchor: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

FooterLink.defaultProps = {
  small: false,
  isAnchor: false,
};


export default FooterLink;
