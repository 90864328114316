import { put, takeLatest, call } from 'redux-saga/effects';

import { resolvedAction, rejectedAction } from '@pro/web-common/core/actions';

import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';

import { MODAL_MESSAGE } from 'content/modals';

import SupportService from './service';
import { constants } from './actions';

/*
 * Sagas
 */

function* sendMessage ({ payload: { data } }) {
  try {
    yield call(SupportService.sendMessage, data);

    yield put(resolvedAction(constants.SEND_MESSAGE));

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.SUPPORT_MESSAGE_SEND_SUCCESS },
    }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.SEND_MESSAGE, { error: message }));
  }
}

/*
 * Watchers
 */
function* sendMessageWatcher () {
  yield takeLatest(constants.SEND_MESSAGE, sendMessage);
}


export default [
  sendMessageWatcher,
];
