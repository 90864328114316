import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'WATCH_SUBSCRIPTIONS',
  'ADD_SUBSCRIPTION',
  'CANCEL_SUBSCRIPTION',
  'RENEW_SUBSCRIPTION',
  'REMOVE_PAYMENT_METHOD',
  'ADD_PAYMENT_METHOD',
  'ADD_BANK_TRANSFER',
  'UPDATE_BANK_TRANSFER',
  'DELETE_BANK_TRANSFER',
], 'SUBSCRIPTIONS');

export const actions = createActions({
  [constants.WATCH_SUBSCRIPTIONS]: null,
  [constants.ADD_SUBSCRIPTION]: ({ planTypeId, productsIds, source, currency }) => ({ planTypeId, productsIds, source, currency }),
  [constants.CANCEL_SUBSCRIPTION]: ({ subscriptionId, productsIds }) => ({ subscriptionId, productsIds }),
  [constants.RENEW_SUBSCRIPTION]: ({ subscriptionId, productsIds, source }) => ({ subscriptionId, productsIds, source }),
  [constants.REMOVE_PAYMENT_METHOD]: ({ id }) => ({ id }),
  [constants.ADD_PAYMENT_METHOD]: ({ source }) => ({ source }),
  [constants.ADD_BANK_TRANSFER]: ({ data }) => ({ data }),
  [constants.UPDATE_BANK_TRANSFER]: ({ id, data }) => ({ id, data }),
  [constants.DELETE_BANK_TRANSFER]: ({ id }) => ({ id }),
}, {
  prefix: 'SUBSCRIPTIONS',
});


export default actions;
