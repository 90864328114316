import React, { useMemo, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  DialogTitle,
  Box,
} from '@material-ui/core';

import { UserRoleContext } from '@pro/web-common/containers/providers';
import { generateId } from '@pro/web-common/utils';
import { PAGE_TYPE } from 'constants/product-config';

import LargeDialog from '@pro/web-common/components/large-dialog';
import MessageForm from '@pro/web-common/components/message-form';
import PageLinkCopy from '@pro/web-common/components/page-link-copy';


const ConfigureMessageModal = ({ initialValues, onClose, onSubmit, isSubmitting, isError, isEdit, productsOptions, products, tagsSuggestions, ...rest }) => {
  const { isProfile } = useContext(UserRoleContext);

  const [productPages, setProductPages] = useState([]);

  const newMessageId = useMemo(() => generateId(), []);
  const { id: messageId = newMessageId } = initialValues || {};

  const currentPageId = useMemo(() => {
    const messagesPages = productPages.find(({ pageTypeId }) => pageTypeId === PAGE_TYPE.MESSAGES.id) || {};
    return messagesPages.id;
  }, [productPages]);

  const handleProductIdChange = useCallback((productId) => {
    if (!productId) {
      setProductPages([]);
      return;
    }

    const product = products.find(({ id }) => id === productId) || {};
    setProductPages(product.pages || []);
  }, [products]);

  return (
    <LargeDialog
      open
      onClose={onClose}
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      disableEnforceFocus
    >
      <Container maxWidth="md">
        <DialogTitle id="form-dialog-title">
          {isEdit ? 'Edit' : 'New'}
          {' Message'}
        </DialogTitle>

        {
          !!productPages.length && !isProfile && (
            <Box
              pl={3}
              pr={3}
              mb={3}
            >
              <PageLinkCopy
                pages={productPages}
                currentPageId={currentPageId}
              />
            </Box>
          )
        }

        <Box
          mb={2}
          pt={1}
          pb={1}
          pl={3}
          pr={3}
        >
          <MessageForm
            messageId={messageId}
            isEdit={isEdit}
            initialValues={initialValues}
            fetching={isSubmitting}
            isError={isError}
            onSubmit={onSubmit}
            onClose={onClose}
            productsOptions={productsOptions}
            tagsSuggestions={tagsSuggestions}
            handleProductIdChange={handleProductIdChange}
            {...rest}
          />
        </Box>
      </Container>
    </LargeDialog>
  );
};

ConfigureMessageModal.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
  }),
  productsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSubmitting: PropTypes.bool,
  isError: PropTypes.bool,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })),
  })).isRequired,
};

ConfigureMessageModal.defaultProps = {
  initialValues: {},
  isEdit: false,
  isSubmitting: false,
  isError: false,
};


export default ConfigureMessageModal;
