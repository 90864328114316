import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';

import { findIndexByProp } from '@pro/web-common/utils';

import { constants } from './actions';


const defaultState = {
  creatingState: {
    fetching: false,
    error: null,
  },
  updatingState: {
    fetching: false,
    error: null,
  },
  list: [],
  order: [],
};

export default handleActions({
  [resolved(constants.WATCH_PRODUCTS)]: (state, { payload: { data } }) => (
    update(state, {
      list: { $set: data },
      updatingState: { $set: defaultState.updatingState },
      creatingState: { $set: defaultState.creatingState },
    })
  ),

  [resolved(constants.WATCH_PRODUCTS_ORDER)]: (state, { payload: { data } }) => (
    update(state, {
      order: { $set: data },
    })
  ),

  [rejected(constants.WATCH_PRODUCTS_ORDER)]: (state) => (
    update(state, {
      order: { $set: [] },
    })
  ),

  [resolved(constants.SAVE_PRODUCTS_ORDER)]: (state, { payload: { data } }) => (
    update(state, {
      order: { $set: data },
    })
  ),

  [constants.CREATE_PRODUCT]: (state) => (
    update(state, {
      creatingState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.CREATE_PRODUCT)]: (state, { payload }) => (
    update(state, {
      creatingState: {
        fetching: { $set: false },
      },
      ...(payload && payload.data ? {
        list: { $push: [payload.data] },
      } : {}),
    })
  ),
  [rejected(constants.CREATE_PRODUCT)]: (state, { payload: { error } }) => (
    update(state, {
      creatingState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [combineActions(
    constants.UPDATE_PRODUCT,
    constants.UNLOCK_PRODUCT,
  )]: (state) => (
    update(state, {
      updatingState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [combineActions(
    resolved(constants.UPDATE_PRODUCT),
    resolved(constants.UNLOCK_PRODUCT),
  )]: (state, { payload }) => {
    const productId = payload ? payload.data.id : null;
    const productIndex = productId ? findIndexByProp(state.list, 'id', productId) : -1;

    return (
      update(state, {
        updatingState: {
          fetching: { $set: false },
        },
        ...(productIndex > -1 ? {
          list: { $set: [payload.data] },
        } : {}),
      })
    );
  },
  [combineActions(
    rejected(constants.UPDATE_PRODUCT),
    rejected(constants.UNLOCK_PRODUCT),
  )]: (state, { payload: { error } }) => (
    update(state, {
      updatingState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.DELETE_PRODUCT]: (state, { payload: { id } }) => {
    const productIndex = findIndexByProp(state.list, 'id', id);
    const productOrderIndex = state.order.indexOf(id);

    if (productIndex > -1) {
      let newState = state;

      if (productOrderIndex > -1) {
        newState = update(state, {
          order: { $splice: [[productOrderIndex, 1]] },
        });
      }

      return (
        update(newState, {
          list: {
            [productIndex]: {
              fetching: { $set: true },
            },
          },
        })
      );
    }

    return state;
  },
  [resolved(constants.DELETE_PRODUCT)]: (state, { payload }) => {
    const index = findIndexByProp(state.list, 'id', payload ? payload.id : null);

    if (index > -1) {
      return (
        update(state, {
          list: { $splice: [[index, 1]] },
        })
      );
    }

    return state;
  },
  [rejected(constants.DELETE_PRODUCT)]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.list, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          list: {
            [index]: {
              fetching: { $set: false },
            },
          },
        })
      );
    }

    return state;
  },

  [combineActions(
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
  )]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
