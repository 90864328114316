import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';

import { BrandContext } from '@pro/web-common/containers/providers';

import { styles } from './styles';


const EmptyContentPreview = React.memo(({ text }) => {
  const { brand: { typeface, fontColor } } = useContext(BrandContext);

  const classes = styles({ typeface, fontColor });

  return (
    <Box className={classes.container}>
      <Typography
        variant="body2"
        className={classes.text}
      >
        {text}
      </Typography>
    </Box>
  );
});


EmptyContentPreview.propTypes = {
  text: PropTypes.string,
};

EmptyContentPreview.defaultProps = {
  text: 'You have not yet added any content',
};


export default EmptyContentPreview;
