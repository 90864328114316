import * as Yup from 'yup';

import { REGEXP } from '@pro/web-common/utils/validators';


export const formValidationSchema = () => Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  website: Yup.string()
    .matches(REGEXP.URL)
    .required(),
  message: Yup.string()
    .min(6)
    .required(),
});
