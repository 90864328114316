import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
} from '@material-ui/core';

import { withFormik } from 'formik';

import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';

import { isTestVersion } from 'constants/product-config';

import { formValidationSchema } from './config';

import { styles } from './styles';


const UserRegistrationForm = React.memo(({ fetching, isError, onSubmit, ...formikProps }) => {
  const classes = styles();

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    resetForm,
  } = formikProps;

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ComponentWithSpinner isSubmitting={isSubmitting}>
      <form
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="firstName"
                  label="First name"
                  placeholder="First name"
                  name="firstName"
                  autoComplete="fname"
                  autoFocus
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.firstName && touched.firstName}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  label="Last name"
                  placeholder="Last name"
                  name="lastName"
                  autoComplete="lname"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName && touched.lastName}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label={`Email${isTestVersion ? '' : ' (must match Website Address domain)'}`}
                  placeholder="Email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email && touched.email}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="organisationName"
                  label="Organisation Name"
                  placeholder="Organisation Name"
                  name="organisationName"
                  value={values.organisationName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.organisationName && touched.organisationName}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="website"
                  label="Website Address"
                  placeholder="Website Address"
                  name="website"
                  value={values.website}
                  autoComplete="new-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.website && touched.website}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="password"
                  label="Password"
                  placeholder="Password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password && touched.password}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.confirmPassword && touched.confirmPassword}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              textAlign="right"
              mt={4}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </ComponentWithSpinner>
  );
});

UserRegistrationForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

UserRegistrationForm.defaultProps = {
  isError: false,
};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      firstName = '',
      lastName = '',
      organisationName = '',
      email = '',
      website = '',
      password = '',
      confirmPassword = '',
    } = initialValues || {};

    const values = {
      firstName,
      lastName,
      organisationName,
      email,
      website,
      password,
      confirmPassword,
    };

    return values;
  },
  handleSubmit: (data, { props: { onSubmit } }) => onSubmit({ data }),
  validationSchema: () => formValidationSchema(),
})(UserRegistrationForm);
