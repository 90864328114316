import { put, takeLatest, call, select, all, take } from 'redux-saga/effects';

import { resolved, resolvedAction, rejectedAction } from '@pro/web-common/core/actions';
import { constants as appConstants } from '@pro/web-common/core/app/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';

import { getBrandId, getRole } from '@pro/web-common/core/user/selectors';

import { subscribeSagaToUserSession } from '@pro/web-common/core/app/sagas';
import { getIsDemoUser } from '@pro/web-common/core/user/utils';

import { constants as facebookConstants, actions as facebookActions } from './actions';
import { getIsInitialized, getUser } from './selectors';
import FacebookService from './service';
import { normalizeData } from './utils';


/*
 * Sagas
 */

function* init () {
  yield all([
    call(subscribeSagaToUserSession, initGetFacebookPages),
  ]);
}

function* initFacebook () {
  try {
    FacebookService.init();
    yield put(resolvedAction(facebookConstants.INIT));
  } catch ({ message }) {
    yield put(rejectedAction(facebookConstants.INIT, { error: message }));
  }
}

function* initGetFacebookPages () {
  yield put(facebookActions.getFacebookPages());
}

function* getFacebookPages () {
  try {
    const brandId = yield select(getBrandId);
    const data = yield call(FacebookService.getFacebookPages, brandId);

    yield put(resolvedAction(facebookConstants.GET_FACEBOOK_PAGES, { data }));
  } catch ({ message }) {
    yield put(rejectedAction(facebookConstants.GET_FACEBOOK_PAGES, { error: message }));
  }
}

function* connect () {
  try {
    const role = yield select(getRole);
    const isDemoUser = getIsDemoUser(role);

    const isInitialized = yield select(getIsInitialized);
    if (!isInitialized) {
      yield take(resolved(facebookConstants.INIT));
    }
    const brandId = yield select(getBrandId);

    const { authResponse } = yield call(FacebookService.login);
    if (authResponse === null) {
      throw new Error('Failed with connect to Facebook.');
    }

    const { access_token: longLivedAccessToken } = yield call(getLongLivedUserAccessToken, authResponse);
    const auth = { ...authResponse, longLivedAccessToken };

    const pagesResponse = yield call(FacebookService.api, `${authResponse.userID}/accounts`, { access_token: longLivedAccessToken, limit: 100 });
    const normalizedData = normalizeData(brandId, auth, pagesResponse);

    if (!isDemoUser) {
      yield call(FacebookService.addFacebookPages, brandId, normalizedData);
    }

    yield put(resolvedAction(facebookConstants.CONNECT, { data: normalizedData }));
  } catch ({ message }) {
    yield put(rejectedAction(facebookConstants.CONNECT, { error: message }));
  }
}

function* logout () {
  try {
    yield call(FacebookService.logout);
    yield put(resolvedAction(facebookConstants.LOGOUT));
  } catch ({ message }) {
    yield put(rejectedAction(facebookConstants.LOGOUT, { error: message }));
  }
}

function* getLongLivedUserAccessToken (authResponse) {
  const { accessToken } = authResponse;
  const { data } = yield call(FacebookService.getLongLivedUserAccessToken, accessToken);
  return data || {};
}

function* disconnect () {
  try {
    const { userId, userAccessToken } = yield select(getUser);
    const brandId = yield select(getBrandId);

    yield all([
      call(FacebookService.deleteFacebookPages, brandId),
      userId && userAccessToken ? call(FacebookService.api, `${userId}/permissions`, 'delete', { access_token: userAccessToken }) : null,
    ]);

    yield put(resolvedAction(facebookConstants.DISCONNECT));
  } catch ({ message }) {
    yield put(rejectedAction(facebookConstants.DISCONNECT, { error: message }));
  }
}

function* getFeed ({ payload: { productId, pageId } }) {
  try {
    const brandId = yield select(getBrandId);
    const { data: { profile, feed: { data } } } = yield call(FacebookService.getFeed, { brandId, pageId, includeProfileInfo: true, limit: 20 });
    // TODO: currenlty facebook api doesn't react on limit param. need to check full url
    yield put(resolvedAction(facebookConstants.GET_FEED, {
      productId,
      profile: profile || {},
      data,
    }));
  } catch ({ message }) {
    yield put(rejectedAction(facebookConstants.GET_FEED, {
      productId,
      error: message,
    }));
  }
}

/*
 * Watchers
 */


function* initWatcher () {
  yield takeLatest(appConstants.INIT, init);
}

function* initFacebookWatcher () {
  yield takeLatest(facebookConstants.INIT, initFacebook);
}

function* getFacebookPagesWatcher () {
  yield takeLatest(facebookConstants.GET_FACEBOOK_PAGES, getFacebookPages);
}

function* connectWatcher () {
  yield takeLatest(facebookConstants.CONNECT, connect);
}

function* getFeedWatcher () {
  yield takeLatest(facebookConstants.GET_FEED, getFeed);
}

function* logoutWatcher () {
  yield takeLatest([
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
  ], logout);
}

function* disconnectWatcher () {
  yield takeLatest(facebookConstants.DISCONNECT, disconnect);
}


export default [
  initWatcher,
  initFacebookWatcher,
  getFacebookPagesWatcher,
  connectWatcher,
  getFeedWatcher,
  logoutWatcher,
  disconnectWatcher,
];
