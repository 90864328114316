import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { connect as reduxConnect } from 'react-redux';

import {
  Box,
  Button,
  Collapse,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';

import { actions as facebookActions } from '@pro/web-common/core/facebook/actions';

import { getFacebookData } from '@pro/web-common/core/facebook/selectors'; // TODO: not the best naming for selector
import FacebookLoginButton from '@pro/web-common/components/facebook-login-button';


const FacebookPageEditor = React.memo(({ isOpened, facebookData, connect, disconnect, ...formikProps }) => {
  const {
    values,
    handleChange,
    handleSubmit,
    resetForm,
  } = formikProps;

  const {
    isConnected,
    isFetching,
    pages,
  } = facebookData;

  const handleConnect = useCallback(() => connect(), [connect]);
  const handleDisconnect = useCallback(() => disconnect(), [disconnect]);
  // TODO: save data to firebase like { content: { id: pageId }}

  useEffect(() => {
    if (isOpened) {
      resetForm();
    }
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >
      <form onSubmit={handleSubmit}>
        <Box
          mt={2}
          textAlign="right"
        >
          {!isFetching && pages && pages.length > 0 && (
            <RadioGroup
              aria-label="facebook page"
              name="id"
              value={values.id}
              onChange={handleChange}
              color="primary"
            >
              {pages.map(({ name, id }) => (
                <FormControlLabel
                  key={id}
                  value={id}
                  control={<Radio color="primary" />}
                  label={name}
                />
              ))}
            </RadioGroup>
          )}

          {!isFetching && isConnected && pages && pages.length === 0 && (
            <Box
              mb={2}
              textAlign="center"
            >
              <span>Sorry, you do not have facebook pages</span>
            </Box>
          )}

          {isFetching && (
            <Box
              mb={2}
              textAlign="center"
            >
              <span>Loading...</span>
            </Box>
          )}

          {isConnected ? (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={handleDisconnect}
              disabled={isFetching}
            >
              Disconnect Facebook
            </Button>
          ) : (
            <FacebookLoginButton
              onClick={handleConnect}
              disabled={isFetching}
            />
          )}
        </Box>

        <Box
          mt={2}
          textAlign="right"
        >
          <Button
            type="submit"
            size="small"
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </form>
    </Collapse>
  );
});


FacebookPageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  facebookData: PropTypes.shape({
    isConnected: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape({
      accessToken: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  connect: PropTypes.func.isRequired,
  disconnect: PropTypes.func.isRequired,
};

FacebookPageEditor.defaultProps = {};


const mapStateToProps = (state) => ({
  facebookData: getFacebookData(state),
});

const mapDispatchToProps = {
  connect: facebookActions.connect,
  disconnect: facebookActions.disconnect,
};


const withFormikComponent = withFormik({
  mapPropsToValues: ({ initialValues, facebookData }) => {
    const { pages } = facebookData || {};
    const firstPageId = pages && pages.length > 0 ? pages[0].id : '';
    const {
      id: defaultFbPageId,
    } = initialValues || {};

    const values = {
      id: defaultFbPageId || firstPageId,
    };

    return values;
  },
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values),
  enableReinitialize: true,
})(FacebookPageEditor);


export default reduxConnect(mapStateToProps, mapDispatchToProps)(withFormikComponent);
