import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';

import { findIndexByProp } from '@pro/web-common/utils';

import { constants } from './actions';


const defaultState = {
  creatingState: {
    fetching: false,
    error: null,
  },
  updatingState: {
    fetching: false,
    error: null,
  },
  generatingReportState: {
    fetching: false,
    error: null,
  },
  messagesList: [],
  quickMessagesList: [],
  tagsList: [],
  fetching: false,
};

export default handleActions({
  [constants.GET_MESSAGES]: (state) => (
    update(state, {
      fetching: { $set: true },
    })
  ),
  [resolved(constants.GET_MESSAGES)]: (state, { payload: { messages, quickMessages, trackTraceMessages = [] } }) => (
    update(state, {
      fetching: { $set: false },
      messagesList: { $set: messages },
      quickMessagesList: { $set: [...quickMessages, ...trackTraceMessages] },
    })
  ),
  [rejected(constants.GET_MESSAGES)]: (state) => (
    update(state, {
      fetching: { $set: false },
    })
  ),

  [constants.GET_TAGS]: (state) => (
    update(state, {
      fetching: { $set: true },
    })
  ),
  [resolved(constants.GET_TAGS)]: (state, { payload: { tags } }) => (
    update(state, {
      fetching: { $set: false },
      tagsList: { $set: tags },
    })
  ),
  [rejected(constants.GET_TAGS)]: (state) => (
    update(state, {
      fetching: { $set: false },
    })
  ),

  [combineActions(
    constants.CREATE_MESSAGE,
    constants.CREATE_QUICK_MESSAGE,
  )]: (state) => (
    update(state, {
      creatingState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.CREATE_MESSAGE)]: (state, { payload: { id, data } }) => (
    update(state, {
      messagesList: {
        $push: [{ id, ...data }],
      },
      creatingState: {
        fetching: { $set: false },
      },
    })
  ),
  [resolved(constants.CREATE_QUICK_MESSAGE)]: (state, { payload: { id, data } }) => (
    update(state, {
      quickMessagesList: {
        $push: [{ id, ...data }],
      },
      creatingState: {
        fetching: { $set: false },
      },
    })
  ),
  [combineActions(
    rejected(constants.CREATE_MESSAGE),
    rejected(constants.CREATE_QUICK_MESSAGE),
  )]: (state, { payload: { error } }) => (
    update(state, {
      creatingState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.UPDATE_MESSAGE]: (state) => (
    update(state, {
      updatingState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.UPDATE_MESSAGE)]: (state, { payload: { id, data } }) => {
    const index = data && findIndexByProp(state.messagesList, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          messagesList: {
            [index]: { $merge: data },
          },
          updatingState: {
            fetching: { $set: false },
          },
        })
      );
    }

    return state;
  },
  [rejected(constants.UPDATE_MESSAGE)]: (state, { payload: { error } }) => (
    update(state, {
      updatingState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.UPDATE_TAGS]: (state) => (
    update(state, {
      updatingState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.UPDATE_TAGS)]: (state, { payload: { data } }) => (
    update(state, {
      tagsList: { $set: data },
      updatingState: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.UPDATE_TAGS)]: (state, { payload: { error } }) => (
    update(state, {
      updatingState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [constants.DELETE_MESSAGE]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.messagesList, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          messagesList: {
            [index]: {
              fetching: { $set: true },
            },
          },
        })
      );
    }

    return state;
  },
  [resolved(constants.DELETE_MESSAGE)]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.messagesList, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          messagesList: { $splice: [[index, 1]] },
        })
      );
    }

    return state;
  },
  [rejected(constants.DELETE_MESSAGE)]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.messagesList, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          messagesList: {
            [index]: {
              fetching: { $set: false },
            },
          },
        })
      );
    }

    return state;
  },

  [combineActions(
    constants.DELETE_QUICK_MESSAGE,
    constants.DELETE_TRACK_TRACE_MESSAGE,
  )]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.quickMessagesList, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          quickMessagesList: {
            [index]: {
              fetching: { $set: true },
            },
          },
        })
      );
    }

    return state;
  },
  [combineActions(
    resolved(constants.DELETE_QUICK_MESSAGE),
    resolved(constants.DELETE_TRACK_TRACE_MESSAGE),
  )]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.quickMessagesList, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          quickMessagesList: { $splice: [[index, 1]] },
        })
      );
    }

    return state;
  },
  [combineActions(
    rejected(constants.DELETE_QUICK_MESSAGE),
    rejected(constants.DELETE_TRACK_TRACE_MESSAGE),
  )]: (state, { payload: { id } }) => {
    const index = findIndexByProp(state.quickMessagesList, 'id', id);

    if (index > -1) {
      return (
        update(state, {
          quickMessagesList: {
            [index]: {
              fetching: { $set: false },
            },
          },
        })
      );
    }

    return state;
  },

  [constants.GENERATE_USERS_REPORT]: (state) => (
    update(state, {
      generatingReportState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved(constants.GENERATE_USERS_REPORT)]: (state) => (
    update(state, {
      generatingReportState: {
        fetching: { $set: false },
      },
    })
  ),
  [rejected(constants.GENERATE_USERS_REPORT)]: (state, { payload: { error } }) => (
    update(state, {
      generatingReportState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [combineActions(
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
  )]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
