import * as Yup from 'yup';

import { REGEXP } from '@pro/web-common/utils/validators';


export const formValidationSchema = Yup.object().shape({
  url: Yup.string()
    .matches(REGEXP.URL_WITH_HTTPS)
    .required(),
});
