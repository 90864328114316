import AppStoreIcon from 'assets/images/store-icons/appStore.png';
import PlayStoreIcon from 'assets/images/store-icons/playStore.png';


export const STORE_LINKS = [
  {
    id: 'appStore',
    href: 'https://apps.apple.com/gb/app/pocket-concierge/id1345424883',
    icon: AppStoreIcon,
  },
  {
    id: 'playStore',
    href: 'https://play.google.com/store/apps/details?id=com.boluga.pocketconcierge',
    icon: PlayStoreIcon,
  },
];

export const dynamicLink = 'https://pocketconciergepro.page.link';
export const websiteDomain = 'https://pocketconcierge.uk';
export const appStoreId = '1345424883';
export const iosBundleId = 'com.appsprout.pocketconciergepro';
export const apn = 'com.boluga.pocketconcierge';
