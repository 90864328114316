import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3 / 2),
    marginBottom: theme.spacing(3 / 2),
  },
  text: {
    display: 'inline-block',
    verticalAlign: 'sub',
    height: '10px',
  },
}));
