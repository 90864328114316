import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';

import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  IconButton, Collapse,
} from '@material-ui/core';

import {
  Edit as EditIcon,
} from '@material-ui/icons';

import { BrandContext } from '@pro/web-common/containers/providers';

import InfoSection from '@pro/web-common/components/info-section';
import infoJson from 'constants/info';
import TagsInput from '@pro/web-common/components/tags-input';
import SectionTitle from '@pro/web-common/components/section-title';
import Tooltip from '@pro/web-common/components/conditional-tooltip';
import GoogleAutocomplete from '@pro/web-common/components/google-autocomplete';
import withScriptLoader from '@pro/web-common/components/with-script-loader';

import {
  PRODUCT_CONFIGURATION_ITEM_NAME_LABEL,
  PRODUCT_CONFIGURATION_ITEM_NAME_PLACEHOLDER,
} from 'content/texts';

import { withLocationSetup } from 'constants/product-config';
import config from 'config';

import { formValidationSchema } from './config';
import { styles } from './styles';


const ProductBaseInfoEditor = React.memo(({ initialValues, onSubmit, isSubmitAvailable, withSubmitButton, ...formikProps }) => {
  const classes = styles();

  const { brand } = useContext(BrandContext);
  const { hasSubscriptions } = brand || {};

  const [isEditorOpened, setIsEditorOpened] = useState(false);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formikProps;

  const hasErrors = useMemo(() => Object.entries(errors).length !== 0, [errors]);
  const toggleContentEditor = useCallback(
    () => setIsEditorOpened(!isEditorOpened),
    [isEditorOpened],
  );

  const handleSubmit = useCallback(() => {
    if (!hasErrors) {
      toggleContentEditor();
      onSubmit(values);
    }
  }, [errors, values, toggleContentEditor]);

  const handleAddressChange = useCallback((data) => {
    if (data) {
      setFieldValue('address', data.value);
    }
  }, []);

  useEffect(() => {
    if (!withSubmitButton && !hasErrors) {
      onSubmit(values);
    }
  }, [values, withSubmitButton]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
      >
        <Box className={classes.container}>
          <Box className={classes.innerContainer}>
            <Typography>
              Name
            </Typography>

            <Tooltip
              title="Edit content"
              isShown
            >
              <IconButton onClick={toggleContentEditor}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Collapse
          in={isEditorOpened}
          mountOnEnter
          unmountOnExit
        >
          <form onSubmit={handleSubmit}>
            <Box mt={2}>
              <Grid
                item
                xs={12}
              >
                <InfoSection infoMessage={infoJson.product.title}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="productName"
                    label={PRODUCT_CONFIGURATION_ITEM_NAME_LABEL}
                    placeholder={PRODUCT_CONFIGURATION_ITEM_NAME_PLACEHOLDER}
                    name="productName"
                    value={values.productName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.productName && touched.productName}
                  />
                </InfoSection>
              </Grid>

              {withLocationSetup && (
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <Box mt={2}>
                      <InfoSection
                        infoMessage={infoJson.product.location}
                      >
                        <GoogleAutocomplete
                          variant="outlined"
                          fullWidth
                          id="address"
                          label="Address"
                          placeholder="Address"
                          name="address"
                          value={values.address}
                          onChange={handleAddressChange}
                          onBlurEvent={handleAddressChange}
                        />
                      </InfoSection>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Box mt={2}>
                      <InfoSection
                        infoMessage={infoJson.product.radius}
                      >
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="pushMessagesRadius"
                          label="Push notifications radius (km)"
                          placeholder="5"
                          name="pushMessagesRadius"
                          value={values.pushMessagesRadius}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.pushMessagesRadius && touched.pushMessagesRadius}
                        />
                      </InfoSection>
                    </Box>
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
              >
                <Box mt={2}>
                  <SectionTitle title="Search tags" />

                  <InfoSection
                    infoMessage={hasSubscriptions ? infoJson.product.productTags : infoJson.product.addSubscriptionToAddSearchTags}
                  >
                    <TagsInput
                      tags={values.tags}
                      onUpdate={(data) => setFieldValue('tags', data)}
                      disabled={!hasSubscriptions}
                    />
                  </InfoSection>
                </Box>
              </Grid>

              {withSubmitButton && (
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    mt={2}
                    textAlign="right"
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={!isSubmitAvailable}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              )}
            </Box>
          </form>
        </Collapse>
      </Box>
    </>
  );
});

ProductBaseInfoEditor.propTypes = {
  initialValues: PropTypes.shape({
    productName: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
    address: PropTypes.string,
    pushMessagesRadius: PropTypes.string,
  }),
  isSubmitAvailable: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  withSubmitButton: PropTypes.bool,
};

ProductBaseInfoEditor.defaultProps = {
  initialValues: {},
  isSubmitAvailable: true,
  withSubmitButton: true,
};


const ComponentWithScriptLoader = withLocationSetup ? withScriptLoader(ProductBaseInfoEditor, { scriptUrl: config.google.apiUrl }) : ProductBaseInfoEditor;


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      productName = '',
      address = '',
      pushMessagesRadius = '',
      tags = [],
    } = initialValues || {};

    const values = {
      productName,
      address,
      pushMessagesRadius,
      tags,
    };

    return values;
  },
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
  validationSchema: formValidationSchema,
})(ComponentWithScriptLoader);
