import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import update from 'immutability-helper';

import {
  Box,
  Button,
  Collapse,
} from '@material-ui/core';

import MultipleImagesUploader from '@pro/web-common/components/multiple-images-uploader';


const ImagePageEditor = React.memo(({ isOpened, initialValues: { files: defaultFiles = [] }, onSubmit, isSubmitDisabled }) => {
  const [images, setImages] = useState(defaultFiles);

  const onImageAdd = useCallback(
    ({ file, filePath }) => setImages(images.concat({ file, filePath })),
    [images],
  );

  const onImageDelete = useCallback(
    (index) => {
      const newImageFiles = update(images, { $splice: [[index, 1]] });

      setImages(newImageFiles);
    }, [images],
  );

  const onSave = useCallback(
    () => onSubmit({ files: images }),
    [onSubmit, images],
  );

  useEffect(() => {
    if (isOpened) {
      setImages(defaultFiles);
    }
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >
      <Box
        pt={1}
        pb={1}
      >
        <MultipleImagesUploader
          images={images}
          onImageDelete={onImageDelete}
          onImageAdd={onImageAdd}
          recommendedSize={{
            height: 2208,
            width: 2208,
          }}
          withFit
        />
      </Box>

      <Box
        mt={1}
        textAlign="right"
      >
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={onSave}
          disabled={isSubmitDisabled}
        >
          Save
        </Button>
      </Box>
    </Collapse>
  );
});

ImagePageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.shape({
      filePath: PropTypes.string,
    })),
  }),
  onSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
};

ImagePageEditor.defaultProps = {
  initialValues: {},
};


export default ImagePageEditor;
