import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Hidden,
} from '@material-ui/core';

import InfoTooltip from '@pro/web-common/components/info-tooltip';


const InfoSection = React.memo(({ children, infoMessage, containerProps, ...rest }) => (
  <Box
    position="relative"
    {...containerProps}
  >
    {children}

    <Hidden xsDown>
      <Box
        position="absolute"
        top={0}
        right={-40}
        {...rest}
      >
        <InfoTooltip
          text={infoMessage}
        />
      </Box>
    </Hidden>
  </Box>
));

InfoSection.propTypes = {
  infoMessage: PropTypes.string.isRequired,
  children: PropTypes.node,
  containerProps: PropTypes.shape({}),
};

InfoSection.defaultProps = {
  containerProps: {},
  children: null,
};


export default InfoSection;
