import * as Yup from 'yup';

import { REGEXP } from '@pro/web-common/utils/validators';
import { isTestVersion } from 'constants/product-config';


export const formValidationSchema = () => Yup.object().shape({
  firstName: Yup.string()
    .required(),
  lastName: Yup.string()
    .required(),
  organisationName: Yup.string()
    .required(),
  email: isTestVersion ? (
    Yup.string()
      .email()
      .required()
  ) : (
    Yup.string()
      .email()
      .emailHasDomain(Yup.ref('website'))
      .required()
  ),
  website: Yup.string()
    .matches(REGEXP.URL)
    .required(),
  password: Yup.string()
    .min(6)
    .required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required(),
});
