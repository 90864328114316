import userSagas from '@pro/web-common/core/user/sagas';
import brandSagas from '@pro/web-common/core/brand/sagas';
import productSagas from '@pro/web-common/core/product/sagas';
import managersSagas from '@pro/web-common/core/managers/sagas';
import messagesSagas from '@pro/web-common/core/messages/sagas';
import subscriptionsSagas from '@pro/web-common/core/subscriptions/sagas';
import supportSagas from '@pro/web-common/core/support/sagas';
import adminSagas from '@pro/web-common/core/admin/sagas';
import statisticsSagas from '@pro/web-common/core/statistics/sagas';
import facebookSagas from '@pro/web-common/core/facebook/sagas';
import paymentSagas from '@pro/web-common/core/payment/sagas';
import profileSagas from '@pro/web-common/core/profile/sagas';


const sagas = [
  ...userSagas,
  ...brandSagas,
  ...productSagas,
  ...managersSagas,
  ...messagesSagas,
  ...subscriptionsSagas,
  ...supportSagas,
  ...adminSagas,
  ...statisticsSagas,
  ...facebookSagas,
  ...paymentSagas,
  ...profileSagas,
];


export default sagas;
