import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Container, Grid, Typography, Box, Button } from '@material-ui/core';

import PageTitle from '@pro/web-common/components/page-title';

import userActions from '@pro/web-common/core/user/actions';

import { styles } from './styles';


const DemoIntro = React.memo(({ signOut }) => {
  const classes = styles();

  const onSignUp = useCallback((event) => {
    event.preventDefault();
    signOut({ withRedirectToSignUp: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      <PageTitle
        title="Try our B&amp;B guest app builder!"
        subtitle="Check out how easy it is ..."
        large
      />

      <Grid
        container
        justify="space-between"
        spacing={6}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="A quick walkthrough"
            subtitle="Set up your look and feel, and add some pages"
          />

          <Box className={classes.container}>
            <iframe
              title="youtube video"
              src="https://www.youtube.com/embed/BdQ5jbe9vYc"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>

          <Typography>
            You&apos;ll need a few things when you are ready to sign up or trial PocketConcierge FREE for 30 days. Your logo as a .png with transparency, an image for your B&amp;B, and the links you want to include - like your website, booking page etc. Plus any guest information or menu&apos;s you want in the app.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <PageTitle
            title="Use our support pages and customer chat!"
            subtitle="There's a load of useful content and a helping hand when you need it."
          />

          <Typography>
            At the bottom of every page there&apos;s a &apos;Support&apos; link which gives you access to loads helpful content, including videos and walkthrus. You may also see our Chat bot, we&apos;re always here ready to help when you need us!
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
              ml={2}
              mr={2}
            >
              <Button
                component={NavLink}
                color="inherit"
                variant="outlined"
                to="/configure-brand"
              >
                Demo Admin
              </Button>
            </Box>

            <Box
              display={{
                xs: 'none',
                sm: 'block',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onSignUp}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
});

DemoIntro.propTypes = {
  signOut: PropTypes.func.isRequired,
};

DemoIntro.defaultProps = {};

const mapDispatchToProps = {
  signOut: userActions.signOut,
};


export default connect(null, mapDispatchToProps)(DemoIntro);
