import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonGroup,
  Button,
} from '@material-ui/core';

import { CURRENCY_LIST } from 'constants/currency';


const CurrencySelector = React.memo(({ currencyId, onChange }) => (
  <ButtonGroup
    color="primary"
    aria-label="choose payment currency"
  >
    {Object.entries(CURRENCY_LIST).map(([id, { sign }]) => (
      <Button
        disableRipple
        disableFocusRipple
        key={id}
        variant={id === currencyId ? 'contained' : 'outlined'}
        onClick={() => onChange(id)}
      >
        {sign}
      </Button>
    ))}
  </ButtonGroup>
));


CurrencySelector.propTypes = {
  currencyId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CurrencySelector.defaultProps = {};


export default CurrencySelector;
