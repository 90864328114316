import { put, call, takeLatest, select } from 'redux-saga/effects';

import { resolvedAction, rejectedAction } from '@pro/web-common/core/actions';

import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';

import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { getBrandId, getRole } from '@pro/web-common/core/user/selectors';
import ProductService from '@pro/web-common/core/product/service';
import { getIsDemoUser } from '@pro/web-common/core/user/utils';

import { MODAL_MESSAGE } from 'content/modals';

import ManagerService from './service';
import { constants } from './actions';


/*
 * Sagas
 */

function* getManagers () {
  try {
    const brandId = yield select(getBrandId);
    const requestParams = ['brandId', '==', brandId];
    const data = yield call(ManagerService.getManagers, requestParams);

    yield put(resolvedAction(constants.GET_MANAGERS, { data }));
  } catch ({ message }) {
    yield put(rejectedAction(constants.GET_MANAGERS, { error: message }));
  }
}

function* createManager ({ payload: { data: { firstName, lastName, email, addToProducts } } }) {
  try {
    const role = yield select(getRole);
    const isDemoUser = getIsDemoUser(role);

    if (isDemoUser) {
      throw new Error(MODAL_MESSAGE.DEMO_USER_CANNOT_ADD_MANAGERS);
    }

    const brandId = yield select(getBrandId);

    const { data } = yield call(ManagerService.createManager, {
      brandId,
      firstName,
      lastName,
      email,
      products: addToProducts,
    });

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.MANAGER_CREATE_SUCCESS },
    }));

    yield put(resolvedAction(constants.CREATE_MANAGER, { data, addToProducts }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.CREATE_MANAGER, { error: message }));
  }
}

function* updateManager ({ payload: { id, data: { firstName, lastName, addToProducts, deleteFromProducts } } }) {
  try {
    yield call(ManagerService.updateManager, id, { firstName, lastName });
    yield call(ProductService.updateProductUsers, id, addToProducts, deleteFromProducts);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.MANAGER_UPDATE_SUCCESS },
    }));

    yield put(resolvedAction(constants.UPDATE_MANAGER, { data: { id, firstName, lastName } }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.UPDATE_MANAGER, { error: message, id }));
  }
}

function* deleteManager ({ payload: { id } }) {
  try {
    yield call(ManagerService.deleteManager, id);

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.MANAGER_DELETE_SUCCESS },
    }));

    yield put(resolvedAction(constants.DELETE_MANAGER, { id }));
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.DELETE_MANAGER, { error: message, id }));
  }
}

/*
 * Watchers
 */

function* getManagersWatcher () {
  yield takeLatest(constants.GET_MANAGERS, getManagers);
}

function* createManagerWatcher () {
  yield takeLatest(constants.CREATE_MANAGER, createManager);
}

function* updateManagerWatcher () {
  yield takeLatest(constants.UPDATE_MANAGER, updateManager);
}

function* deleteManagerWatcher () {
  yield takeLatest(constants.DELETE_MANAGER, deleteManager);
}


export default [
  getManagersWatcher,
  createManagerWatcher,
  updateManagerWatcher,
  deleteManagerWatcher,
];
