import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingTop: theme.spacing(22),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
}));
