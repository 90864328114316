import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
} from '@material-ui/core';

import {
  Help as HelpIcon,
  Add as AddIcon,
} from '@material-ui/icons';


import { styles } from './styles';


const ProductAddPage = React.memo(({ onClick }) => {
  const classes = styles();

  return (
    <Box
      className={classes.container}
      onClick={onClick}
    >
      <Box className={classes.innerContainer}>
        <Box
          display="flex"
          flexDirection="row"
        >
          <Box mr={2}>
            <HelpIcon />
          </Box>

          <Typography>
            Add new page
          </Typography>
        </Box>

        <Box
          p={1}
          lineHeight={1}
        >
          <AddIcon />
        </Box>
      </Box>
    </Box>
  );
});

ProductAddPage.propTypes = {
  onClick: PropTypes.func.isRequired,
};

ProductAddPage.defaultProps = {};


export default ProductAddPage;
