import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved, rejected } from '@pro/web-common/core/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { constants as productConstants } from '@pro/web-common/core/product/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';

import { constants } from './actions';


const defaultState = {
  data: null,
  fetching: false,
  updatingState: {
    fetching: false,
    error: null,
  },
};

export default handleActions({
  [combineActions(
    resolved(constants.GET_BRAND),
    resolved(constants.WATCH_BRAND)
  )]: (state, { payload: { data } }) => (
    update(state, {
      data: { $set: data },
    })
  ),

  [combineActions(
    constants.UPDATE_BRAND,
    constants.UPDATE_ENTERPRISE_HOME_DATA,
  )]: (state) => (
    update(state, {
      updatingState: {
        fetching: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [combineActions(
    resolved(constants.UPDATE_BRAND),
    resolved(constants.UPDATE_ENTERPRISE_HOME_DATA),
  )]: (state, { payload: { data } }) => (
    update(state, {
      updatingState: {
        fetching: { $set: false },
      },
      data: { $merge: data },
    })
  ),
  [combineActions(
    rejected(constants.UPDATE_BRAND),
    rejected(constants.UPDATE_ENTERPRISE_HOME_DATA),
  )]: (state, { payload: { error } }) => (
    update(state, {
      updatingState: {
        fetching: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [combineActions(
    resolved(productConstants.COPY_PRODUCT),
    resolved(productConstants.CREATE_PRODUCT),
  )]: (state) => (
    update(state, {
      data: {
        $merge: {
          availableProducts: state.data.availableProducts - 1,
        },
      },
    })
  ),

  [resolved(productConstants.DELETE_PRODUCT)]: (state) => (
    update(state, {
      data: {
        $merge: {
          availableProducts: state.data.availableProducts + 1,
        },
      },
    })
  ),

  [combineActions(
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
  )]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
