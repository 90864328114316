import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  iconContainer: {
    display: 'inline-flex',
    padding: theme.spacing(1),
    margin: theme.spacing(1 / 2, 0),
    border: '1px solid #eee',
    cursor: 'pointer',
  },
  iconContainerSelected: {
    borderColor: theme.palette.primary.main,
  },
}));
