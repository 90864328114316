import React, { useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil } from 'ramda';
import ScriptLoader from 'react-script-loader-hoc';

import { Container, Box, Typography } from '@material-ui/core';

import { actions as productActions } from '@pro/web-common/core/product/actions';
import { getProduct as getProductData, getProductUpdatingState } from '@pro/web-common/core/product/selectors';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { actions as facebookActions } from '@pro/web-common/core/facebook/actions';

import { BrandContext, ProductIdContext } from '@pro/web-common/containers/providers';
import { ERROR_ALERT } from '@pro/web-common/containers/modal-conductor/constants';

import DataWithLoader from '@pro/web-common/components/data-with-loader';
import ProductConfigurator from '@pro/web-common/components/product-configurator';
import PageTitle from '@pro/web-common/components/page-title';
import LimitedAccessBadge from '@pro/web-common/components/limited-access-badge';

import { FB_SDK_URL } from '@pro/web-common/services/facebook/constants';

import {
  EDIT_PRODUCT_SUBTITLE,
  EDIT_PRODUCT_LIMITED_ACCESS_NOTE,
} from 'content/texts';


const EditProduct = React.memo(({ match: { params: { id } }, product, updatingState: { fetching }, updateProduct, openModal, scriptsLoadedSuccessfully, initFacebook }) => {
  const showErrorModal = useCallback(({ errors, errorsTitle }) => {
    openModal({
      modal: ERROR_ALERT,
      params: { errors, errorsTitle },
    });
  }, [openModal]);

  useEffect(() => {
    if (scriptsLoadedSuccessfully) {
      initFacebook();
    }
  }, [scriptsLoadedSuccessfully]); // eslint-disable-line react-hooks/exhaustive-deps

  const { productName, isLimited } = product || {};
  const { brand } = useContext(BrandContext);

  return (
    <ProductIdContext.Provider value={id}>
      <Container maxWidth="md">
        <DataWithLoader isLoaded={!isNil(product)}>
          {isNil(product) ? (
            <Typography>Product not found</Typography>
          ) : (
            <Box p={2}>
              <PageTitle
                title={productName}
                titlePostfix={isLimited ? <LimitedAccessBadge /> : null}
                subtitle={EDIT_PRODUCT_SUBTITLE}
              >
                {isLimited && (
                  <Typography color="error">
                    {EDIT_PRODUCT_LIMITED_ACCESS_NOTE}
                  </Typography>
                )}
              </PageTitle>

              <ProductConfigurator
                brandConfig={brand}
                initialValues={product}
                fetching={fetching}
                isSubmitAvailable={!isLimited}
                isLimited={isLimited}
                onSubmit={(data) => updateProduct({ id, ...data })}
                submitLabel="Save"
                onError={showErrorModal}
              />
            </Box>
          )}
        </DataWithLoader>
      </Container>
    </ProductIdContext.Provider>
  );
});

EditProduct.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  product: PropTypes.shape({
    productName: PropTypes.string,
    isLimited: PropTypes.bool,
  }),
  updatingState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  updateProduct: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  scriptsLoadedSuccessfully: PropTypes.bool.isRequired,
  initFacebook: PropTypes.func.isRequired,
};

EditProduct.defaultProps = {
  product: null,
};


const mapStateToProps = (state, { match: { params: { id } } }) => ({
  product: getProductData(id)(state),
  updatingState: getProductUpdatingState(state),
});

const mapDispatchToProps = {
  updateProduct: productActions.updateProduct,
  openModal: modalConductorActions.openModal,
  initFacebook: facebookActions.init,
};


const ComponentWithScriptLoader = ScriptLoader(FB_SDK_URL)(EditProduct);

export default connect(mapStateToProps, mapDispatchToProps)(ComponentWithScriptLoader);
