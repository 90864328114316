import React from 'react';
import PropTypes from 'prop-types';

import { SortableElement } from 'react-sortable-hoc';

import { Box } from '@material-ui/core';

import SortableListItemHandle from '@pro/web-common/components/sortable-list-item-handle';

import { styles } from './styles';


const SortableListItem = React.memo(({ children, isInModal }) => {
  const classes = styles({ isInModal });

  return (
    <Box className={classes.container}>
      <Box className={classes.handleContainer}>
        <SortableListItemHandle />
      </Box>
      {children}
    </Box>
  );
});

SortableListItem.propTypes = {
  children: PropTypes.node.isRequired,
  isInModal: PropTypes.bool,
};

SortableListItem.defaultProps = {
  isInModal: false,
};


export default SortableElement(SortableListItem);
