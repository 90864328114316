import { createSelector } from 'reselect';


const getLocalState = (state) => state.subscriptions;

export const getList = createSelector(
  getLocalState,
  ({ list }) => list,
);

export const getFetching = createSelector(
  getLocalState,
  ({ fetching }) => fetching,
);

export const getCreatingState = createSelector(
  getLocalState,
  ({ creatingState: { fetching, error } }) => ({ fetching, error }),
);

export const getBankTransferState = createSelector(
  getLocalState,
  ({ bankTransferState: { fetching, error } }) => ({ fetching, error }),
);

export const getProductsToBlockList = createSelector(
  getList,
  (list) => {
    const productsList = list.reduce((accumulator, { productsToBlock = [] }) => accumulator.concat(productsToBlock), []);

    return productsList;
  }
);

export default {
  getFetching,
};
