export const UPGRADED_PLAN_FEATURES = [
  {
    id: '1',
    label: 'Unlimited Users',
    description: 'You will be able to add unlimited Users to your Admin - access by BnB or access all',
  },
  {
    id: '2',
    label: 'Multi Language Guest Information',
    description: 'Automatically translate your Guest Information into multiple langauges',
  },
  {
    id: '3',
    label: 'Enhanced Messaging With Image and Push Notifications',
    description: 'Use advanced features to send messages with images, video and PDFs',
  },
  {
    id: '4',
    label: 'Search Terms/Tags',
    description: 'List search terms Guests can use to see your BnB app',
  },
];

export const PLAN_TYPE_FREE_ID = 'free';
export const PLAN_TYPE_SINGLE_ID = 'single';
export const PLAN_TYPE_BANK_TRANSFER = 'bankTransfer';

export const ACTIVE_SUBSCRIPTION_LIMIT = 1;

export const PLAN_TYPE_PRICES = {
  [PLAN_TYPE_SINGLE_ID]: {
    EUR: {
      price: '5.50',
    },
    GBP: {
      price: '4.99',
    },
    USD: {
      price: '6.20',
    },
  },
};

export const PLAN_TYPES = [
  {
    id: PLAN_TYPE_FREE_ID,
    title: 'FREE Trial',
    canAdd: false,
    features: [
      {
        id: 'products_count',
        label: '1 x BnB',
      },
      {
        id: 'users_count',
        label: '1 x User',
      },
      {
        id: 'versions_count',
        label: '1 x Language',
      },
      {
        id: 'messaging',
        label: 'Messaging',
      },
    ],
  },
  {
    id: PLAN_TYPE_SINGLE_ID,
    title: '1 BnB',
    availableProducts: 1,
    canAdd: true,
    getAddButtonLabel: ({ currencySign, price }) => `Add new BnB @${currencySign}${price} per month`,
    features: [
      {
        id: 'products_count',
        label: '1 x BnB',
      },
      {
        id: 'users_count',
        label: 'Unlimited Users',
      },
      {
        id: 'versions_count',
        label: 'Multi Language Guest Information',
      },
      {
        id: 'messaging',
        label: 'Enhanced Messaging',
      },
      {
        id: 'search',
        label: 'Search Terms/Tags',
      },
    ],
  },
  {
    id: PLAN_TYPE_BANK_TRANSFER,
    title: 'Bank Transfer',
    canAdd: false,
  },
];

export const UPGRADE_PLAN_FEATURES = [
  {
    id: 'users',
    label: 'Unlimited Users',
    description: 'You will be able to add unlimited Users to your admin - access by BnB or access all',
  },
  {
    id: 'versions',
    label: 'Multi Language Guestbooks',
    description: 'Automatically translate your Guestbook into multiple langauges',
  },
  {
    id: 'messaging',
    label: 'Enhanced Messaging With Image and Push Notifications',
    description: 'Use advanced features to send messages with images, video and PDFs',
  },
  {
    id: 'search_tags',
    label: 'Search Terms/Tags',
    description: 'List search terms Guests can use to see your BnB app',
  },
];
