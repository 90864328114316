export const CLOUD_FUNCTIONS = {
  ADD_OWNER: 'ownerCreate',
  SEND_RESET_PASSWORD_LINK: 'userSendResetPasswordLink',
  GET_IS_EXIST_BY_EMAIL: 'userGetIsExistByEmail',
  UPDATE_EMAIL: 'userUpdateEmail',
};

export const ROLES = {
  OWNER: 'owner',
  MANAGER: 'manager',
  SUPER_ADMIN: 'superAdmin',
  DEMO: 'demo',
  PROFILE: 'profile',
};

export const OWNERS_NODE = 'owners';
export const MANAGERS_NODE = 'managers';

export const DEMO_USER_CREDENTIALS = {
  email: 'demo-kappow@gmail.com',
  password: 'ZR3ywtc@@QgZz',
};
