import FirebaseDatabaseService from '@pro/web-common/services/database';
import FirebaseFunctionsService from '@pro/web-common/services/functions';

import { PRODUCTS_NODE, PRODUCTS_ORDER_NODE, CLOUD_FUNCTIONS, PRODUCT_DEFAULT_FIELDS } from './constants';


const ProductService = {
  watchProducts: (callback, requestParams) => FirebaseDatabaseService.watchData({
    refPath: PRODUCTS_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    callback,
    whereParams: [requestParams],
  }),
  watchProductsOrder: (callback, id) => FirebaseDatabaseService.watchData({
    refPath: `${PRODUCTS_ORDER_NODE}/${id}`,
    refType: FirebaseDatabaseService.REF_TYPES.DOC,
    callback,
  }),
  createProduct: (id, data) => FirebaseDatabaseService.addData(`${PRODUCTS_NODE}/${id}`, FirebaseDatabaseService.REF_TYPES.DOC, false, { ...data, ...PRODUCT_DEFAULT_FIELDS }),
  deleteProduct: (id) => FirebaseDatabaseService.deleteDoc(`${PRODUCTS_NODE}/${id}`),
  updateProduct: (id, data) => FirebaseDatabaseService.updateDoc(`${PRODUCTS_NODE}/${id}`, data),
  unlockProduct: (id) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.PRODUCT_UNLOCK, {
    productId: id,
  }),
  copyProduct: (id) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.PRODUCT_COPY, {
    productId: id,
  }),

  translateManual: (sections, targetLanguage) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.TRANSLATE_MANUAL, {
    sections,
    targetLanguage,
  }),

  updateProductUsers: (managerId, addToProducts, deleteFromProducts) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.PRODUCT_UPDATE_USERS, {
    managerId,
    addToProducts,
    deleteFromProducts,
  }),

  saveProductsOrder: (id, data) => FirebaseDatabaseService.addData(`${PRODUCTS_ORDER_NODE}/${id}`, FirebaseDatabaseService.REF_TYPES.DOC, false, data),
};


export default ProductService;
