import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { colorPropsSchema } from '@pro/web-common/utils/style';

import { styles } from './styles';


const MenuSectionPreview = React.memo(({ title, image, html, stylesConfig, children }) => {
  const {
    typeface,
    backgroundColor,
    fontColor,
    menuTitleBackgroundColor,
    menuTitleTextColor,
  } = stylesConfig;

  const classes = styles({
    typeface,
    backgroundColor,
    fontColor,
    menuTitleBackgroundColor,
    menuTitleTextColor,
  });

  return (
    <ExpansionPanel
      classes={{
        root: classes.expansionPanelRoot,
        expanded: classes.expansionPanelExpanded,
      }}
      square
    >
      <ExpansionPanelSummary
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent,
          expandIcon: classes.expansionPanelSummaryExpandIcon,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
      >
        {title && (
          <Box
            fontFamily={typeface}
            className={classes.titleText}
          >
            {title}
          </Box>
        )}
      </ExpansionPanelSummary>

      <ExpansionPanelDetails
        classes={{ root: classes.expansionPanelDetailsRoot }}
      >
        {image && (
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src={image.filePath}
              alt="section-hero"
            />
          </div>
        )}

        <Box className={classes.html}>
          {html && (
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
            />
          )}
        </Box>

        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});

MenuSectionPreview.propTypes = {
  image: PropTypes.shape({
    filePath: PropTypes.string,
  }),
  html: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  stylesConfig: PropTypes.shape({
    typeface: PropTypes.string.isRequired,
    backgroundColor: colorPropsSchema.isRequired,
    fontColor: colorPropsSchema.isRequired,
    menuTitleBackgroundColor: colorPropsSchema.isRequired,
    menuTitleTextColor: colorPropsSchema.isRequired,
  }).isRequired,
};

MenuSectionPreview.defaultProps = {
  image: null,
  title: null,
  html: null,
  children: null,
};


export default MenuSectionPreview;
