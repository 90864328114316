import { makeStyles } from '@material-ui/core/styles';
import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  text: ({ typeface, fontColor }) => ({
    fontFamily: typeface,
    color: parseRgbaColor(fontColor),
    textAlign: 'center',
  }),
}));
