import React from 'react';
import PropTypes from 'prop-types';

import {
  OutlinedInput,
  InputAdornment,
  Box,
} from '@material-ui/core';

import {
  Search as SearchIcon,
} from '@material-ui/icons';

import ManualSectionPreview from '@pro/web-common/components/manual-section-preview';
import LanguageFlagIcon from '@pro/web-common/components/add-translation/svg-icons/united-states-of-america.svg';

import { styles } from './styles';


const ManualPageContentPreview = React.memo(({ sections }) => {
  const classes = styles();

  return (
    <Box
      pl={1}
      pr={1}
    >
      <Box className={classes.searchBarContainer}>
        <OutlinedInput
          classes={{ input: classes.input }}
          fullWidth
          disabled
          endAdornment={(
            <InputAdornment position="end">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          )}
        />

        <Box className={classes.languageContainer}>
          <img
            src={LanguageFlagIcon}
            alt="flag"
          />
        </Box>
      </Box>

      {sections.map(({ id, subsections, ...rest }) => (
        <ManualSectionPreview
          key={id}
          {...rest}
        >
          {subsections.map(({ id: subsectionId, ...restSubsectionProps }) => (
            <ManualSectionPreview
              key={subsectionId}
              isSubsection
              {...restSubsectionProps}
            />
          ))}
        </ManualSectionPreview>
      ))}
    </Box>
  );
});


ManualPageContentPreview.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.shape({
      filePath: PropTypes.string,
    }),
    html: PropTypes.string,
    title: PropTypes.string,
    subsections: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
};

ManualPageContentPreview.defaultProps = {};


export default ManualPageContentPreview;
