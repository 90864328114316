import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';

import { resolved } from '@pro/web-common/core/actions';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';

import { findIndexByProp } from '@pro/web-common/utils';

import { constants } from './actions';


const defaultState = {
  list: [],
  limits: [],
  brandsList: [],
};

export default handleActions({
  [resolved(constants.WATCH_PROFILES)]: (state, { payload: { data } }) => (
    update(state, {
      list: { $set: data },
    })
  ),

  [resolved(constants.WATCH_PROFILES_MESSAGES_LIMITS)]: (state, { payload: { data } }) => (
    update(state, {
      limits: { $set: data },
    })
  ),
  [resolved(constants.CREATE_PROFILE)]: (state, { payload }) => (
    update(state, {
      ...(payload && payload.profile ? {
        list: { $push: [payload.profile] },
        limits: { $push: [payload.profileLimits] },
      } : {}),
    })
  ),
  [resolved(constants.UPDATE_PROFILE)]: (state, { payload }) => {
    if (!payload || !payload.profile) {
      return state;
    }

    const profileId = payload ? payload.profile.id : null;
    const profileIndex = profileId ? findIndexByProp(state.list, 'id', profileId) : -1;

    const limitId = payload ? payload.profileLimits.id : null;
    const limitIndex = limitId ? findIndexByProp(state.limits, 'id', limitId) : -1;

    return (
      update(state, {
        ...(profileIndex > -1 ? {
          list: { $set: [payload.profile] },
        } : {}),
        ...(limitIndex > -1 ? {
          list: { $set: [payload.profileLimits] },
        } : {}),
      })
    );
  },

  [combineActions(
    resolved(adminConstants.SIGN_OUT_FROM_USER),
    resolved(userConstants.SIGN_OUT),
  )]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
