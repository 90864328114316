import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { pick } from 'ramda';

import { Box, Button, Typography } from '@material-ui/core';

import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';

import { styles, stripeStyles } from './styles';


const PaymentMethodForm = React.memo(({ onSubmit, onClose, stripe, elements, paymentIntentKey, isPaymentIntentsMode, billingDetails, createPaymentIntent }) => {
  const classes = styles();
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    if (!isPaymentIntentsMode) {
      const stripeResponse = await stripe.createSource({
        type: 'card',
      });

      if (stripeResponse.error) {
        setError(stripeResponse.error.message);
        setIsSubmitting(false);
        return;
      }

      const { source: { id, card } } = stripeResponse;

      onSubmit({
        id,
        card,
      });
    } else {
      createPaymentIntent();
    }
  };

  useEffect(() => {
    if (!paymentIntentKey) {
      return;
    }

    const makePayment = async () => {
      const { paymentIntent, error: stripeError } = await stripe.confirmCardPayment(paymentIntentKey, {
        payment_method: {
          card: elements.getElement('card'),
          billing_details: billingDetails ? pick(['name', 'email'], billingDetails) : null,
        },
      });

      if (stripeError) {
        setError(stripeError.message);
        setIsSubmitting(false);
        return;
      }

      onSubmit(paymentIntent);
    };

    makePayment();
  }, [paymentIntentKey]);

  return (
    <ComponentWithSpinner isSubmitting={isSubmitting}>
      <form onSubmit={handleSubmit}>
        <Box
          className={classes.container}
          border="1px solid #000"
        >
          <CardElement
            style={stripeStyles}
          />
        </Box>

        {error && (
          <Box textAlign="right">
            <Typography
              variant="caption"
              color="error"
            >
              {error}
            </Typography>
          </Box>
        )}

        <Box
          mt={2}
          display="flex"
          justifyContent="flex-end"
        >
          <Box mr={1}>
            <Button
              color="primary"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Box>
      </form>
    </ComponentWithSpinner>
  );
});


PaymentMethodForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  stripe: PropTypes.shape({
    createSource: PropTypes.func.isRequired,
    confirmCardPayment: PropTypes.func.isRequired,
  }).isRequired,
  elements: PropTypes.shape({
    getElement: PropTypes.func.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  isPaymentIntentsMode: PropTypes.bool,
  paymentIntentKey: PropTypes.string,
  billingDetails: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  createPaymentIntent: PropTypes.func,
};

PaymentMethodForm.defaultProps = {
  isPaymentIntentsMode: false,
  paymentIntentKey: null,
  billingDetails: null,
  createPaymentIntent: null,
};


export default injectStripe(PaymentMethodForm);
