import memoize from 'lodash.memoize';

import { findElementByProp } from '@pro/web-common/utils';


export const currencyValues = [
  {
    value: 'GBP',
    symbol: '£',
    label: 'British Pound Sterling',
  },
  {
    value: 'USD',
    symbol: '$',
    label: 'US Dollar',
  }, {
    value: 'EUR',
    symbol: '€',
    label: 'Euro',
  },
  {
    value: 'CAD',
    symbol: '$',
    label: 'Canadian Dollar',
  }, {
    value: 'AED',
    symbol: 'د.إ.‏',
    label: 'United Arab Emirates Dirham',
  }, {
    value: 'AFN',
    symbol: '؋',
    label: 'Afghan Afghani',
  }, {
    value: 'ALL',
    symbol: 'Lek',
    label: 'Albanian Lek',
  }, {
    value: 'AMD',
    symbol: 'դր.',
    label: 'Armenian Dram',
  }, {
    value: 'ARS',
    symbol: '$',
    label: 'Argentine Peso',
  }, {
    value: 'AUD',
    symbol: '$',
    label: 'Australian Dollar',
  }, {
    value: 'AZN',
    symbol: 'man.',
    label: 'Azerbaijani Manat',
  }, {
    value: 'BAM',
    symbol: 'KM',
    label: 'Bosnia-Herzegovina Convertible Mark',
  }, {
    value: 'BDT',
    symbol: '৳',
    label: 'Bangladeshi Taka',
  }, {
    value: 'BGN',
    symbol: 'lv.',
    label: 'Bulgarian Lev',
  }, {
    value: 'BHD',
    symbol: 'د.ب.‏',
    label: 'Bahraini Dinar',
  }, {
    value: 'BIF',
    symbol: 'FBu',
    label: 'Burundian Franc',
  }, {
    value: 'BND',
    symbol: '$',
    label: 'Brunei Dollar',
  }, {
    value: 'BOB',
    symbol: 'Bs',
    label: 'Bolivian Boliviano',
  }, {
    value: 'BRL',
    symbol: 'R$',
    label: 'Brazilian Real',
  }, {
    value: 'BWP',
    symbol: 'P',
    label: 'Botswanan Pula',
  }, {
    value: 'BYN',
    symbol: 'rub.',
    label: 'Belarusian Ruble',
  }, {
    value: 'BZD',
    symbol: '$',
    label: 'Belize Dollar',
  }, {
    value: 'CDF',
    symbol: 'FrCD',
    label: 'Congolese Franc',
  }, {
    value: 'CHF',
    symbol: 'CHF',
    label: 'Swiss Franc',
  }, {
    value: 'CLP',
    symbol: '$',
    label: 'Chilean Peso',
  }, {
    value: 'CNY',
    symbol: 'CN¥',
    label: 'Chinese Yuan',
  }, {
    value: 'COP',
    symbol: '$',
    label: 'Colombian Peso',
  }, {
    value: 'CRC',
    symbol: '₡',
    label: 'Costa Rican Colón',
  }, {
    value: 'CVE',
    symbol: 'CV$',
    label: 'Cape Verdean Escudo',
  }, {
    value: 'CZK',
    symbol: 'Kč',
    label: 'Czech Republic Koruna',
  }, {
    value: 'DJF',
    symbol: 'Fdj',
    label: 'Djiboutian Franc',
  }, {
    value: 'DKK',
    symbol: 'kr',
    label: 'Danish Krone',
  }, {
    value: 'DOP',
    symbol: 'RD$',
    label: 'Dominican Peso',
  }, {
    value: 'DZD',
    symbol: 'د.ج.‏',
    label: 'Algerian Dinar',
  }, {
    value: 'EEK',
    symbol: 'kr',
    label: 'Estonian Kroon',
  }, {
    value: 'EGP',
    symbol: 'ج.م.‏',
    label: 'Egyptian Pound',
  }, {
    value: 'ERN',
    symbol: 'Nfk',
    label: 'Eritrean Nakfa',
  }, {
    value: 'ETB',
    symbol: 'Br',
    label: 'Ethiopian Birr',
  }, {
    value: 'GEL',
    symbol: 'GEL',
    label: 'Georgian Lari',
  }, {
    value: 'GHS',
    symbol: 'GH₵',
    label: 'Ghanaian Cedi',
  }, {
    value: 'GNF',
    symbol: 'FG',
    label: 'Guinean Franc',
  }, {
    value: 'GTQ',
    symbol: 'Q',
    label: 'Guatemalan Quetzal',
  }, {
    value: 'HKD',
    symbol: '$',
    label: 'Hong Kong Dollar',
  }, {
    value: 'HNL',
    symbol: 'L',
    label: 'Honduran Lempira',
  }, {
    value: 'HRK',
    symbol: 'kn',
    label: 'Croatian Kuna',
  }, {
    value: 'HUF',
    symbol: 'Ft',
    label: 'Hungarian Forint',
  }, {
    value: 'IDR',
    symbol: 'Rp',
    label: 'Indonesian Rupiah',
  }, {
    value: 'ILS',
    symbol: '₪',
    label: 'Israeli New Sheqel',
  }, {
    value: 'INR',
    symbol: 'টকা',
    label: 'Indian Rupee',
  }, {
    value: 'IQD',
    symbol: 'د.ع.‏',
    label: 'Iraqi Dinar',
  }, {
    value: 'IRR',
    symbol: '﷼',
    label: 'Iranian Rial',
  }, {
    value: 'ISK',
    symbol: 'kr',
    label: 'Icelandic Króna',
  }, {
    value: 'JMD',
    symbol: '$',
    label: 'Jamaican Dollar',
  }, {
    value: 'JOD',
    symbol: 'د.أ.‏',
    label: 'Jordanian Dinar',
  }, {
    value: 'JPY',
    symbol: '￥',
    label: 'Japanese Yen',
  }, {
    value: 'KES',
    symbol: 'Ksh',
    label: 'Kenyan Shilling',
  }, {
    value: 'KHR',
    symbol: '៛',
    label: 'Cambodian Riel',
  }, {
    value: 'KMF',
    symbol: 'FC',
    label: 'Comorian Franc',
  }, {
    value: 'KRW',
    symbol: '₩',
    label: 'South Korean Won',
  }, {
    value: 'KWD',
    symbol: 'د.ك.‏',
    label: 'Kuwaiti Dinar',
  }, {
    value: 'KZT',
    symbol: 'тңг.',
    label: 'Kazakhstani Tenge',
  }, {
    value: 'LBP',
    symbol: 'ل.ل.‏',
    label: 'Lebanese Pound',
  }, {
    value: 'LKR',
    symbol: 'SL Re',
    label: 'Sri Lankan Rupee',
  }, {
    value: 'LTL',
    symbol: 'Lt',
    label: 'Lithuanian Litas',
  }, {
    value: 'LVL',
    symbol: 'Ls',
    label: 'Latvian Lats',
  }, {
    value: 'LYD',
    symbol: 'د.ل.‏',
    label: 'Libyan Dinar',
  }, {
    value: 'MAD',
    symbol: 'د.م.‏',
    label: 'Moroccan Dirham',
  }, {
    value: 'MDL',
    symbol: 'MDL',
    label: 'Moldovan Leu',
  }, {
    value: 'MGA',
    symbol: 'MGA',
    label: 'Malagasy Ariary',
  }, {
    value: 'MKD',
    symbol: 'MKD',
    label: 'Macedonian Denar',
  }, {
    value: 'MMK',
    symbol: 'K',
    label: 'Myanma Kyat',
  }, {
    value: 'MOP',
    symbol: 'MOP$',
    label: 'Macanese Pataca',
  }, {
    value: 'MUR',
    symbol: 'MURs',
    label: 'Mauritian Rupee',
  }, {
    value: 'MXN',
    symbol: '$',
    label: 'Mexican Peso',
  }, {
    value: 'MYR',
    symbol: 'RM',
    label: 'Malaysian Ringgit',
  }, {
    value: 'MZN',
    symbol: 'MTn',
    label: 'Mozambican Metical',
  }, {
    value: 'NAD',
    symbol: 'N$',
    label: 'Namibian Dollar',
  }, {
    value: 'NGN',
    symbol: '₦',
    label: 'Nigerian Naira',
  }, {
    value: 'NIO',
    symbol: 'C$',
    label: 'Nicaraguan Córdoba',
  }, {
    value: 'NOK',
    symbol: 'kr',
    label: 'Norwegian Krone',
  }, {
    value: 'NPR',
    symbol: 'नेरू',
    label: 'Nepalese Rupee',
  }, {
    value: 'NZD',
    symbol: '$',
    label: 'New Zealand Dollar',
  }, {
    value: 'OMR',
    symbol: 'ر.ع.‏',
    label: 'Omani Rial',
  }, {
    value: 'PAB',
    symbol: 'B/.',
    label: 'Panamanian Balboa',
  }, {
    value: 'PEN',
    symbol: 'S/.',
    label: 'Peruvian Nuevo Sol',
  }, {
    value: 'PHP',
    symbol: '₱',
    label: 'Philippine Peso',
  }, {
    value: 'PKR',
    symbol: '₨',
    label: 'Pakistani Rupee',
  }, {
    value: 'PLN',
    symbol: 'zł',
    label: 'Polish Zloty',
  }, {
    value: 'PYG',
    symbol: '₲',
    label: 'Paraguayan Guarani',
  }, {
    value: 'QAR',
    symbol: 'ر.ق.‏',
    label: 'Qatari Rial',
  }, {
    value: 'RON',
    symbol: 'RON',
    label: 'Romanian Leu',
  }, {
    value: 'RSD',
    symbol: 'дин.',
    label: 'Serbian Dinar',
  }, {
    value: 'RUB',
    symbol: '₽.',
    label: 'Russian Ruble',
  }, {
    value: 'RWF',
    symbol: 'FR',
    label: 'Rwandan Franc',
  }, {
    value: 'SAR',
    symbol: 'ر.س.‏',
    label: 'Saudi Riyal',
  }, {
    value: 'SDG',
    symbol: 'SDG',
    label: 'Sudanese Pound',
  }, {
    value: 'SEK',
    symbol: 'kr',
    label: 'Swedish Krona',
  }, {
    value: 'SGD',
    symbol: '$',
    label: 'Singapore Dollar',
  }, {
    value: 'SOS',
    symbol: 'Ssh',
    label: 'Somali Shilling',
  }, {
    value: 'SYP',
    symbol: 'ل.س.‏',
    label: 'Syrian Pound',
  }, {
    value: 'THB',
    symbol: '฿',
    label: 'Thai Baht',
  }, {
    value: 'TND',
    symbol: 'د.ت.‏',
    label: 'Tunisian Dinar',
  }, {
    value: 'TOP',
    symbol: 'T$',
    label: 'Tongan Paʻanga',
  }, {
    value: 'TRY',
    symbol: 'TL',
    label: 'Turkish Lira',
  }, {
    value: 'TTD',
    symbol: '$',
    label: 'Trinidad and Tobago Dollar',
  }, {
    value: 'TWD',
    symbol: 'NT$',
    label: 'New Taiwan Dollar',
  }, {
    value: 'TZS',
    symbol: 'TSh',
    label: 'Tanzanian Shilling',
  }, {
    value: 'UAH',
    symbol: '₴',
    label: 'Ukrainian Hryvnia',
  }, {
    value: 'UGX',
    symbol: 'USh',
    label: 'Ugandan Shilling',
  }, {
    value: 'UYU',
    symbol: '$',
    label: 'Uruguayan Peso',
  }, {
    value: 'UZS',
    symbol: 'UZS',
    label: 'Uzbekistan Som',
  }, {
    value: 'VEF',
    symbol: 'Bs.F.',
    label: 'Venezuelan Bolívar',
  }, {
    value: 'VND',
    symbol: '₫',
    label: 'Vietnamese Dong',
  }, {
    value: 'XAF',
    symbol: 'FCFA',
    label: 'CFA Franc BEAC',
  }, {
    value: 'XOF',
    symbol: 'CFA',
    label: 'CFA Franc BCEAO',
  }, {
    value: 'YER',
    symbol: 'ر.ي.‏',
    label: 'Yemeni Rial',
  }, {
    value: 'ZAR',
    symbol: 'R',
    label: 'South African Rand',
  }, {
    value: 'ZMK',
    symbol: 'ZK',
    label: 'Zambian Kwacha',
  }, {
    value: 'ZWL',
    symbol: 'ZWL$',
    label: 'Zimbabwean Dollar',
  }];

export const getCurrencySymbol = memoize((currencyId) => ((findElementByProp(currencyValues, 'value', currencyId) || {}).symbol));
