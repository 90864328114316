import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  dialogActions: {
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3),
  },
  addSectionButtonContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
}));
