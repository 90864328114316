import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


import {
  Box,
  Button,
  TextField,
  Collapse,
} from '@material-ui/core';

import { withFormik } from 'formik';


import { formValidationSchema } from './config';


const PhoneNumberPageEditor = React.memo(({ isOpened, isSubmitDisabled, ...formikProps }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formikProps;

  useEffect(() => {
    if (isOpened) {
      resetForm();
    }
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >
      <form onSubmit={handleSubmit}>
        <Box
          pt={2}
          pb={1}
        >
          <TextField
            variant="outlined"
            fullWidth
            id="phoneNumber"
            label="Phone number"
            placeholder="Enter phone number"
            name="phoneNumber"
            autoComplete="tel"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phoneNumber && touched.phoneNumber}
          />
        </Box>

        <Box
          mt={1}
          textAlign="right"
        >
          <Button
            type="submit"
            size="small"
            color="primary"
            variant="contained"
            disabled={isSubmitDisabled}
          >
            Save
          </Button>
        </Box>
      </form>
    </Collapse>
  );
});

PhoneNumberPageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
};

PhoneNumberPageEditor.defaultProps = {};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      phoneNumber = '',
    } = initialValues || {};

    const values = {
      phoneNumber,
    };

    return values;
  },
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values),
  validationSchema: formValidationSchema,
  enableReinitialize: true,
})(PhoneNumberPageEditor);
