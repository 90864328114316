import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles(() => ({
  container: ({ backgroundColor }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '60px',
    backgroundColor: parseRgbaColor(backgroundColor),
  }),
  moreMenuContainer: ({ backgroundColor }) => ({
    position: 'absolute',
    bottom: '60px',
    left: 0,
    right: 0,
    backgroundColor: parseRgbaColor(backgroundColor),
  }),
  backdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.23)',
  },
}));
