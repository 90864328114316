import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  imageHtmlContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  htmlEditorContainer: {
    flex: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
  dialogActions: {
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3),
  },
  formControl: {
    width: '100%',
  },
}));
