import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Link } from '@material-ui/core';

import { styles } from './styles';


const CardListingItem = React.memo(({ last4, onRemove }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <Typography>
        <Typography
          component="span"
          className={classes.text}
        >
          **** **** ****
        </Typography>
        {' '}
        {last4}
      </Typography>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        component="button"
        variant="body2"
        onClick={onRemove}
      >
        Remove
      </Link>
    </Box>
  );
});

CardListingItem.propTypes = {
  last4: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

CardListingItem.defaultProps = {};


export default CardListingItem;
