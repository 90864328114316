export const typefaceValues = [
  {
    value: 'default',
    label: 'Standard system',
  },
  {
    value: 'Alegreya',
    label: 'Alegreya',
  },
  {
    value: 'Archivo Narrow',
    label: 'Archivo Narrow',
  },
  {
    value: 'Arvo',
    label: 'Arvo',
  },
  {
    value: 'Cabin',
    label: 'Cabin',
  },
  {
    value: 'Cormorant',
    label: 'Cormorant',
  },
  {
    value: 'Cormorant Garamond',
    label: 'Cormorant Garamond',
  },
  {
    value: 'Crimson Text',
    label: 'Crimson Text',
  },
  {
    value: 'EB Garamond',
    label: 'EB Garamond',
  },
  {
    value: 'Fira Sans',
    label: 'Fira Sans',
  },
  {
    value: 'Gelasio',
    label: 'Gelasio',
  },
  {
    value: 'IBM Plex Mono',
    label: 'IBM Plex Mono',
  },
  {
    value: 'IBM Plex Serif',
    label: 'IBM Plex Serif',
  },
  {
    value: 'Ibarra Real Nova',
    label: 'Ibarra Real Nova',
  },
  {
    value: 'Inria Serif',
    label: 'Inria Serif',
  },
  {
    value: 'Kulim Park',
    label: 'Kulim Park',
  },
  {
    value: 'Lato',
    label: 'Lato',
  },
  {
    value: 'Libre Franklin',
    label: 'Libre Franklin',
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
  },
  {
    value: 'Muli',
    label: 'Muli',
  },
  {
    value: 'Nunito',
    label: 'Nunito',
  },
  {
    value: 'Nunito Sans',
    label: 'Nunito Sans',
  },
  {
    value: 'Open Sans',
    label: 'Open Sans',
  },
  {
    value: 'Poppins',
    label: 'Poppins',
  },
  {
    value: 'Prompt',
    label: 'Prompt',
  },
  {
    value: 'Public Sans',
    label: 'Public Sans',
  },
  {
    value: 'Raleway',
    label: 'Raleway',
  },
  {
    value: 'Roboto',
    label: 'Roboto',
  },
  {
    value: 'Roboto Mono',
    label: 'Roboto Mono',
  },
  {
    value: 'Roboto Slab',
    label: 'Roboto Slab',
  },
  {
    value: 'Rubik',
    label: 'Rubik',
  },
  {
    value: 'Source Sans Pro',
    label: 'Source Sans Pro',
  },
  {
    value: 'Spectral',
    label: 'Spectral',
  },
  {
    value: 'Taviraj',
    label: 'Taviraj',
  },
  {
    value: 'Titillium Web',
    label: 'Titillium Web',
  },
  {
    value: 'Ubuntu',
    label: 'Ubuntu',
  },
  {
    value: 'Zilla Slab',
    label: 'Zilla Slab',
  },
  {
    value: 'Trirong',
    label: 'Trirong',
  },
  {
    value: 'Averia Serif Libre',
    label: 'Averia Serif Libre',
  },
  {
    value: 'Cormorant Infant',
    label: 'Cormorant Infant',
  },
];

// theme defaults
export const DEFAULT_TOP_BAR_COLOR = {
  r: 84,
  g: 84,
  b: 84,
  a: 1,
};
export const DEFAULT_LOGO_BAR_COLOR = {
  r: 129,
  g: 129,
  b: 129,
  a: 1,
};
export const DEFAULT_BACKGROUND_COLOR = {
  r: 255,
  g: 255,
  b: 255,
  a: 1,
};
export const DEFAULT_BOTTOM_BAR_COLOR = {
  r: 84,
  g: 84,
  b: 84,
  a: 1,
};
export const DEFAULT_ICON_COLOR = {
  r: 255,
  g: 255,
  b: 255,
  a: 0.7,
};
export const DEFAULT_ICON_ACTIVE_COLOR = {
  r: 255,
  g: 255,
  b: 255,
  a: 1,
};
export const DEFAULT_FONT_COLOR = {
  r: 0,
  g: 0,
  b: 0,
  a: 1,
};
export const DEFAULT_SECTION_HEADER_COLOR = {
  r: 84,
  g: 84,
  b: 84,
  a: 1,
};
export const DEFAULT_SECTION_HEADER_TEXT_COLOR = {
  r: 255,
  g: 255,
  b: 255,
  a: 1,
};
export const DEFAULT_SUB_SECTION_HEADER_COLOR = {
  r: 84,
  g: 84,
  b: 84,
  a: 1,
};
export const DEFAULT_SUB_SECTION_HEADER_TEXT_COLOR = {
  r: 255,
  g: 255,
  b: 255,
  a: 1,
};
