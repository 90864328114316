import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  userContainer: ({ fetching }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    ...(!fetching ? { cursor: 'pointer' } : {}),

    '&:hover': {
      ...(!fetching ? { color: theme.palette.primary.main } : {}),
    },
  }),
}));
