import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Card, Box } from '@material-ui/core';

import { actions as userActions } from '@pro/web-common/core/user/actions';
import { getSignUpState } from '@pro/web-common/core/user/selectors';

import PageTitle from '@pro/web-common/components/page-title';
import UserRegistrationForm from '@pro/web-common/components/user-registration-form';

import meta from 'content/meta';


const SignUp = React.memo(({ signUpState: { error, fetching }, signUp }) => (
  <>
    {meta.signUp}

    <Container maxWidth="md">
      <Card>
        <Box p={2}>
          <PageTitle
            title="Sign Up / User Profile"
            subtitle="Set up your User details here. No payment details are required until you upgrade."
          />

          <UserRegistrationForm
            fetching={fetching}
            isError={!!error}
            onSubmit={signUp}
          />
        </Box>
      </Card>
    </Container>
  </>
));

SignUp.propTypes = {
  signUpState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  signUp: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  signUpState: getSignUpState(state),
});

const mapDispatchToProps = {
  signUp: userActions.signUp,
};


export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
