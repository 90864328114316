import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Grid,
  Box,
} from '@material-ui/core';

import { withFormik } from 'formik';

import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';

import { formValidationSchema } from './config';

import { styles } from './styles';


const SendResetLinkForm = React.memo(({ fetching, isError, onSubmit, ...formikProps }) => {
  const classes = styles();

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    resetForm,
  } = formikProps;

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ComponentWithSpinner
      isSubmitting={isSubmitting}
      progressSize={30}
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              placeholder="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              textAlign="center"
              mt={2}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Send reset link
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </ComponentWithSpinner>
  );
});

SendResetLinkForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

SendResetLinkForm.defaultProps = {
  isError: false,
};


export default withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  handleSubmit: (data, { props: { onSubmit } }) => onSubmit(data),
  validationSchema: () => formValidationSchema(),
})(SendResetLinkForm);
