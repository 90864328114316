import React from 'react';

import {
  Container,
  Grid,
  Box,
  Hidden,
} from '@material-ui/core';

import HomeScreen from 'assets/images/app-screenshots/home.png';
import ClientHome from 'assets/images/app-screenshots/client_home.png';
import Messages from 'assets/images/app-screenshots/messages.png';
import GuestInfo from 'assets/images/app-screenshots/guest_info.png';


const ScreenshotsSection = React.memo(() => (
  <Box
    bgcolor="primary.additional"
    color="white"
    pt={8}
    pb={8}
  >
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={8}
      >
        <Grid
          item
          xs={12}
          sm={3}
        >
          <img
            src={HomeScreen}
            alt="Concierge Pro Home Screen QR Code Search"
          />
        </Grid>

        <Hidden only="xs">
          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={ClientHome}
              alt="No code apps for Hotels Resorts Attractions Tours"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={Messages}
              alt="Guest messaging Push Notifications"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
          >
            <img
              src={GuestInfo}
              alt="Guest Information App Increasesd Satisfaction"
            />
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  </Box>
));


ScreenshotsSection.propTypes = {};

ScreenshotsSection.defaultProps = {};


export default ScreenshotsSection;
