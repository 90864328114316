import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@material-ui/core';

import IconRenderer from '@pro/web-common/components/icon-renderer';

import { styles } from './styles';


const AppIcon = React.memo(({ iconId, icon, label, color, typeface, onClick }) => {
  const classes = styles({ color, isClickable: !!onClick, typeface });

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
    >
      <IconRenderer
        className={classes.icon}
        iconId={iconId}
        icon={icon}
      />

      <Box
        color={color}
        lineHeight={1}
      >
        <Typography
          variant="caption"
          className={classes.labelText}
          noWrap
          component="div"
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
});


AppIcon.propTypes = {
  iconId: PropTypes.string,
  icon: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  typeface: PropTypes.string.isRequired,
};

AppIcon.defaultProps = {
  iconId: null,
  icon: null,
  onClick: null,
};


export default AppIcon;
