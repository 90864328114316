import af from './svg-icons/south-africa.svg';
import sq from './svg-icons/albania.svg';
import am from './svg-icons/ethiopia.svg';
import ar from './svg-icons/united-arab-emirates.svg';
import hy from './svg-icons/armenia.svg';
import az from './svg-icons/azerbaijan.svg';
import eu from './svg-icons/basque-country.svg';
import be from './svg-icons/belarus.svg';
import bn from './svg-icons/bangladesh.svg';
import bs from './svg-icons/bosnia-and-herzegovina.svg';
import bg from './svg-icons/bulgaria.svg';
import ca from './svg-icons/spain.svg';
import ceb from './svg-icons/philippines.svg';
import ny from './svg-icons/zambia.svg';
import zh from './svg-icons/china.svg';
import co from './svg-icons/corsica.svg';
import hr from './svg-icons/croatia.svg';
import cs from './svg-icons/czech-republic.svg';
import da from './svg-icons/denmark.svg';
import nl from './svg-icons/netherlands.svg';
import en from './svg-icons/united-kingdom.svg';
import eo from './svg-icons/republic-of-poland.svg';
import et from './svg-icons/estonia.svg';
import fu from './svg-icons/finland.svg';
import fr from './svg-icons/france.svg';
import fy from './svg-icons/germany.svg';
import ka from './svg-icons/georgia.svg';
import el from './svg-icons/greece.svg';
import gu from './svg-icons/india.svg';
import ht from './svg-icons/haiti.svg';
import ha from './svg-icons/sudan.svg';
import haw from './svg-icons/hawaii.svg';
import iw from './svg-icons/israel.svg';
import hmn from './svg-icons/thailand.svg';
import hu from './svg-icons/hungary.svg';
import ig from './svg-icons/democratic-republic-of-congo.svg';
import is from './svg-icons/iceland.svg';
import id from './svg-icons/indonesia.svg';
import ga from './svg-icons/ireland.svg';
import it from './svg-icons/italy.svg';
import ja from './svg-icons/japan.svg';
import kn from './svg-icons/canada.svg';
import kk from './svg-icons/kazakhstan.svg';
import ko from './svg-icons/north-korea.svg';
import km from './svg-icons/cambodia.svg';
import ku from './svg-icons/iraq.svg';
import ky from './svg-icons/kyrgyzstan.svg';
import lo from './svg-icons/laos.svg';
import lv from './svg-icons/latvia.svg';
import lt from './svg-icons/lithuania.svg';
import lb from './svg-icons/luxembourg.svg';
import mk from './svg-icons/republic-of-macedonia.svg';
import mn from './svg-icons/mongolia.svg';
import ne from './svg-icons/nepal.svg';
import no from './svg-icons/norway.svg';
import pt from './svg-icons/portugal.svg';
import ro from './svg-icons/romania.svg';
import ru from './svg-icons/russia.svg';
import sr from './svg-icons/serbia.svg';
import sk from './svg-icons/slovakia.svg';
import sl from './svg-icons/slovenia.svg';
import so from './svg-icons/somalia.svg';
import sv from './svg-icons/sweden.svg';
import tg from './svg-icons/tajikistan.svg';
import tr from './svg-icons/turkey.svg';
import uk from './svg-icons/ukraine.svg';
import uz from './svg-icons/uzbekistn.svg';
import vi from './svg-icons/vietnam.svg';
import mg from './svg-icons/madagascar.svg';
import ms from './svg-icons/malaysia.svg';
import mt from './svg-icons/malta.svg';
import mi from './svg-icons/new-zealand.svg';
import mr from './svg-icons/mauritius.svg';
import my from './svg-icons/myanmar.svg';
import ps from './svg-icons/afghanistan.svg';
import fa from './svg-icons/iran.svg';
import pa from './svg-icons/pakistan.svg';
import sm from './svg-icons/samoa.svg';
import gd from './svg-icons/scotland.svg';
import st from './svg-icons/zimbabwe.svg';
import si from './svg-icons/sri-lanka.svg';
import sw from './svg-icons/kenya.svg';
import yo from './svg-icons/nigeria.svg';


export const languageOptions = [{
  value: 'af',
  label: 'Afrikaans',
  icon: af,
}, {
  value: 'sq',
  label: 'Albanian',
  icon: sq,
}, {
  value: 'am',
  label: 'Amharic',
  icon: am,
}, {
  value: 'ar',
  label: 'Arabic',
  icon: ar,
}, {
  value: 'hy',
  label: 'Armenian',
  icon: hy,
}, {
  value: 'az',
  label: 'Azerbaijani',
  icon: az,
}, {
  value: 'eu',
  label: 'Basque',
  icon: eu,
}, {
  value: 'be',
  label: 'Belarusian',
  icon: be,
}, {
  value: 'bn',
  label: 'Bengali',
  icon: bn,
}, {
  value: 'bs',
  label: 'Bosnian',
  icon: bs,
}, {
  value: 'bg',
  label: 'Bulgarian',
  icon: bg,
}, {
  value: 'ca',
  label: 'Catalan',
  icon: ca,
}, {
  value: 'ceb',
  label: 'Cebuano',
  icon: ceb,
}, {
  value: 'ny',
  label: 'Chichewa',
  icon: ny,
}, {
  value: 'zh',
  label: 'Chinese (Simplified)',
  icon: zh,
}, {
  value: 'zh-TW',
  label: 'Chinese (Traditional)',
  icon: zh,
}, {
  value: 'co',
  label: 'Corsican',
  icon: co,
}, {
  value: 'hr',
  label: 'Croatian',
  icon: hr,
}, {
  value: 'cs',
  label: 'Czech',
  icon: cs,
}, {
  value: 'da',
  label: 'Danish',
  icon: da,
}, {
  value: 'nl',
  label: 'Dutch',
  icon: nl,
}, {
  value: 'en',
  label: 'English',
  icon: en,
}, {
  value: 'eo',
  label: 'Esperanto',
  icon: eo,
}, {
  value: 'et',
  label: 'Estonian',
  icon: et,
}, {
  value: 'tl',
  label: 'Filipino',
  icon: ceb,
}, {
  value: 'fi',
  label: 'Finnish',
  icon: fu,
}, {
  value: 'fr',
  label: 'French',
  icon: fr,
}, {
  value: 'fy',
  label: 'Frisian',
  icon: fy,
}, {
  value: 'gl',
  label: 'Galician',
  icon: ca,
}, {
  value: 'ka',
  label: 'Georgian',
  icon: ka,
}, {
  value: 'de',
  label: 'German',
  icon: fy,
}, {
  value: 'el',
  label: 'Greek',
  icon: el,
}, {
  value: 'gu',
  label: 'Gujarati',
  icon: gu,
}, {
  value: 'ht',
  label: 'Haitian Creole',
  icon: ht,
}, {
  value: 'ha',
  label: 'Hausa',
  icon: ha,
}, {
  value: 'haw',
  label: 'Hawaiian',
  icon: haw,
}, {
  value: 'iw',
  label: 'Hebrew',
  icon: iw,
}, {
  value: 'hi',
  label: 'Hindi',
  icon: gu,
}, {
  value: 'hmn',
  label: 'Hmong',
  icon: hmn,
}, {
  value: 'hu',
  label: 'Hungarian',
  icon: hu,
}, {
  value: 'is',
  label: 'Icelandic',
  icon: is,
}, {
  value: 'ig',
  label: 'Igbo',
  icon: ig,
}, {
  value: 'id',
  label: 'Indonesian',
  icon: id,
}, {
  value: 'ga',
  label: 'Irish',
  icon: ga,
}, {
  value: 'it',
  label: 'Italian',
  icon: it,
}, {
  value: 'ja',
  label: 'Japanese',
  icon: ja,
}, {
  value: 'jw',
  label: 'Javanese',
  icon: id,
}, {
  value: 'kn',
  label: 'Kannada',
  icon: kn,
}, {
  value: 'kk',
  label: 'Kazakh',
  icon: kk,
}, {
  value: 'km',
  label: 'Khmer',
  icon: km,
}, {
  value: 'ko',
  label: 'Korean',
  icon: ko,
}, {
  value: 'ku',
  label: 'Kurdish (Kurmanji)',
  icon: ku,
}, {
  value: 'ky',
  label: 'Kyrgyz',
  icon: ky,
}, {
  value: 'lo',
  label: 'Lao',
  icon: lo,
}, {
  value: 'la',
  label: 'Latin',
  icon: it,
}, {
  value: 'lv',
  label: 'Latvian',
  icon: lv,
}, {
  value: 'lt',
  label: 'Lithuanian',
  icon: lt,
}, {
  value: 'lb',
  label: 'Luxembourgish',
  icon: lb,
}, {
  value: 'mk',
  label: 'Macedonian',
  icon: mk,
}, {
  value: 'mg',
  label: 'Malagasy',
  icon: mg,
}, {
  value: 'ms',
  label: 'Malay',
  icon: ms,
}, {
  value: 'ml',
  label: 'Malayalam',
  icon: gu,
}, {
  value: 'mt',
  label: 'Maltese',
  icon: mt,
}, {
  value: 'mi',
  label: 'Maori',
  icon: mi,
}, {
  value: 'mr',
  label: 'Marathi',
  icon: mr,
}, {
  value: 'mn',
  label: 'Mongolian',
  icon: mn,
}, {
  value: 'my',
  label: 'Myanmar (Burmese)',
  icon: my,
}, {
  value: 'ne',
  label: 'Nepali',
  icon: ne,
}, {
  value: 'no',
  label: 'Norwegian',
  icon: no,
}, {
  value: 'ps',
  label: 'Pashto',
  icon: ps,
}, {
  value: 'fa',
  label: 'Persian',
  icon: fa,
}, {
  value: 'pl',
  label: 'Polish',
  icon: eo,
}, {
  value: 'pt',
  label: 'Portuguese',
  icon: pt,
}, {
  value: 'pa',
  label: 'Punjabi',
  icon: pa,
}, {
  value: 'ro',
  label: 'Romanian',
  icon: ro,
}, {
  value: 'ru',
  label: 'Russian',
  icon: ru,
}, {
  value: 'sm',
  label: 'Samoan',
  icon: sm,
}, {
  value: 'gd',
  label: 'Scots Gaelic',
  icon: gd,
}, {
  value: 'sr',
  label: 'Serbian',
  icon: sr,
}, {
  value: 'st',
  label: 'Sesotho',
  icon: st,
}, {
  value: 'sn',
  label: 'Shona',
  icon: st,
}, {
  value: 'sd',
  label: 'Sindhi',
  icon: pa,
}, {
  value: 'si',
  label: 'Sinhala',
  icon: si,
}, {
  value: 'sk',
  label: 'Slovak',
  icon: sk,
}, {
  value: 'sl',
  label: 'Slovenian',
  icon: sl,
}, {
  value: 'so',
  label: 'Somali',
  icon: so,
}, {
  value: 'es',
  label: 'Spanish',
  icon: ca,
}, {
  value: 'su',
  label: 'Sundanese',
  icon: ha,
}, {
  value: 'sw',
  label: 'Swahili',
  icon: sw,
}, {
  value: 'sv',
  label: 'Swedish',
  icon: sv,
}, {
  value: 'tg',
  label: 'Tajik',
  icon: tg,
}, {
  value: 'ta',
  label: 'Tamil',
  icon: si,
}, {
  value: 'te',
  label: 'Telugu',
  icon: gu,
}, {
  value: 'th',
  label: 'Thai',
  icon: hmn,
}, {
  value: 'tr',
  label: 'Turkish',
  icon: tr,
}, {
  value: 'uk',
  label: 'Ukrainian',
  icon: uk,
}, {
  value: 'ur',
  label: 'Urdu',
  icon: gu,
}, {
  value: 'uz',
  label: 'Uzbek',
  icon: uz,
}, {
  value: 'vi',
  label: 'Vietnamese',
  icon: vi,
}, {
  value: 'cy',
  label: 'Welsh',
  icon: en,
}, {
  value: 'xh',
  label: 'Xhosa',
  icon: st,
}, {
  value: 'yi',
  label: 'Yiddish',
  icon: iw,
}, {
  value: 'yo',
  label: 'Yoruba',
  icon: yo,
}, {
  value: 'zu',
  label: 'Zulu',
  icon: af,
}];
