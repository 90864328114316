import { createSelector } from 'reselect';
import { RRule } from 'rrule';


const getLocalState = (state) => state.messages;

export const getTagsList = createSelector(
  getLocalState,
  ({ tagsList }) => tagsList,
);

export const getMessagesList = createSelector(
  getLocalState,
  getTagsList,
  ({ messagesList, tagsList }) => (
    messagesList.map(({ tags = [], ...rest }) => {
      let messageSentDate;

      if (rest.rrule) {
        const today = new Date();
        const rruleObject = RRule.fromString(rest.rrule);
        const after = rruleObject.after(today, true);
        if (after) {
          messageSentDate = after;
        } else {
          messageSentDate = rruleObject.before(today);
        }
      } else {
        messageSentDate = rest.startDate;
      }

      return {
        ...rest,
        messageSentDate: new Date(messageSentDate),
        tags: tags.filter(({ id }) => tagsList.findIndex(({ id: tagId }) => tagId === id) > -1), // remove tags which are not in the original messages tags list
      };
    })
  ),
);

export const getQuickMessagesList = createSelector(
  getLocalState,
  ({ quickMessagesList }) => quickMessagesList,
);

export const getFetching = createSelector(
  getLocalState,
  ({ fetching }) => fetching,
);

export const getUpdatingState = createSelector(
  getLocalState,
  ({ updatingState: { fetching, error } }) => ({ fetching, error }),
);

export const getCreatingState = createSelector(
  getLocalState,
  ({ creatingState: { fetching, error } }) => ({ fetching, error }),
);

export const getGeneratingReportState = createSelector(
  getLocalState,
  ({ generatingReportState: { fetching, error } }) => ({ fetching, error }),
);
