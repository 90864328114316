import * as Yup from 'yup';

import { REGEXP } from '@pro/web-common/utils/validators';


export const formValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(REGEXP.PHONE_NUMBER)
    .required(),
});
