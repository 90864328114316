import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';


const LocationService = {
  getLocationLatLng: async (location) => {
    let lat = null;
    let lng = null;

    if (location && location.trim().length > 0) {
      try {
        const results = await geocodeByAddress(location);
        ({ lat, lng } = await getLatLng(results[0]));
      } catch (e) {
        console.log('error why parsing location: ', e);
      }
    }

    return { lat, lng };
  },
  getUserLocation: async () => {
    const navigatorOptions = {
      enableHighAccuracy: false,
      timeout: 3000,
      maximumAge: 3 * 24 * 60 * 60 * 1000,
    };

    if (window.navigator) {
      const results = await new Promise((resolve) => {
        window.navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude, longitude } }) => {
            resolve({
              latitude,
              longitude,
            });
          },
          () => {
            resolve(null);
          }, navigatorOptions
        );
      });

      return results;
    }

    return null;
  },
  geocodeAddress: async (latitude, longitude) => {
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(latitude, longitude);

    const result = await new Promise((resolve) => {
      geocoder.geocode({ location }, (results, status) => {
        if (status === 'OK') {
          resolve(results[0].formatted_address);
        }

        resolve(null);
      }, () => {
        resolve(null);
      });
    });

    return result;
  },
};


export default LocationService;
