import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles(() => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
  },
}));
