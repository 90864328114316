import { createSelector } from 'reselect';


const getLocalState = (state) => state.modalConductor;

export const selectCurrentModal = createSelector(
  getLocalState,
  ({ modal }) => modal,
);

export const selectParams = createSelector(
  getLocalState,
  ({ params }) => params,
);
