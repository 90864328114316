import { combineReducers } from 'redux';

import user from '@pro/web-common/core/user/reducer';
import brand from '@pro/web-common/core/brand/reducer';
import product from '@pro/web-common/core/product/reducer';
import managers from '@pro/web-common/core/managers/reducer';
import messages from '@pro/web-common/core/messages/reducer';
import subscriptions from '@pro/web-common/core/subscriptions/reducer';
import support from '@pro/web-common/core/support/reducer';
import admin from '@pro/web-common/core/admin/reducer';
import statistics from '@pro/web-common/core/statistics/reducer';
import payment from '@pro/web-common/core/payment/reducer';
import profile from '@pro/web-common/core/profile/reducer';

import modalConductor from '@pro/web-common/core/modal-conductor/reducer';
import facebook from '@pro/web-common/core/facebook/reducer';


const createReducer = (injectedReducers) => combineReducers({
  user,
  brand,
  product,
  managers,
  messages,
  subscriptions,
  support,
  modalConductor,
  admin,
  statistics,
  facebook,
  payment,
  profile,
  ...injectedReducers,
});


export default createReducer;
