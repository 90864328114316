import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { BrandContext } from '@pro/web-common/containers/providers';

import { styles } from './styles';


const ManualSectionPreview = React.memo(({ title, image, html, isSubsection, children }) => {
  const { brand: { sectionHeaderColor, sectionHeaderTextColor, subSectionHeaderColor, subSectionHeaderTextColor, fontColor } } = useContext(BrandContext);

  const classes = styles({
    isSubsection,
    sectionHeaderColor,
    sectionHeaderTextColor,
    subSectionHeaderColor,
    subSectionHeaderTextColor,
    fontColor,
  });

  return (
    <ExpansionPanel
      classes={{
        root: classes.expansionPanelRoot,
        expanded: classes.expansionPanelExpanded,
      }}
      square
    >
      <ExpansionPanelSummary
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent,
          expandIcon: classes.expansionPanelSummaryExpandIcon,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
      >
        {title && (
          <Box
            fontWeight={isSubsection ? 'fontWeightRegular' : 'fontWeightBold'}
          >
            {title}
          </Box>
        )}
      </ExpansionPanelSummary>

      <ExpansionPanelDetails
        classes={{ root: classes.expansionPanelDetailsRoot }}
      >
        {image && (
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src={image.filePath}
              alt="section-hero"
            />
          </div>
        )}

        <Box className={classes.html}>
          {html && (
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
            />
          )}
        </Box>

        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});

ManualSectionPreview.propTypes = {
  image: PropTypes.shape({
    filePath: PropTypes.string,
  }),
  html: PropTypes.string,
  title: PropTypes.string,
  isSubsection: PropTypes.bool,
  children: PropTypes.node,
};

ManualSectionPreview.defaultProps = {
  image: null,
  title: null,
  html: null,
  isSubsection: false,
  children: null,
};


export default ManualSectionPreview;
