import config from 'config';

import { DEFAULT_PERMISSIONS, FB_SDK_VERSION } from './constants';


const FacebookService = {
  getLoginStatus: () => new Promise((resolve) => {
    FB.getLoginStatus((response) => {
      resolve(response);
    });
  }),

  init: () => {
    FB.init({
      appId: config.facebookAppId,
      status: true,
      xfbml: true,
      version: FB_SDK_VERSION,
    });
  },

  login: (permissions = []) => new Promise((resolve) => {
    const permissionsString = DEFAULT_PERMISSIONS.concat(permissions).join(',');

    FB.login((response) => {
      resolve(response);
    }, { scope: permissionsString });
  }),

  logout: () => new Promise((resolve) => {
    FB.logout((response) => {
      resolve(response);
    });
  }),

  api: (...params) => new Promise((resolve) => {
    const callback = (response) => {
      resolve(response);
    };

    if (params.length > 3) {
      params = params.slice(0, 3); // eslint-disable-line no-param-reassign
    }

    params.push(callback);

    FB.api(...params);
  }),
};


export default FacebookService;
