import React, { useMemo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNil } from 'ramda';
import { NavLink } from 'react-router-dom';

import { Container, Box, Typography, Link } from '@material-ui/core';

import { actions as productActions } from '@pro/web-common/core/product/actions';
import { getProductCreatingState } from '@pro/web-common/core/product/selectors';
import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { getSubscriptionPermissions } from '@pro/web-common/core/brand/utils';

import { BrandContext, ProductIdContext } from '@pro/web-common/containers/providers';
import { ERROR_ALERT } from '@pro/web-common/containers/modal-conductor/constants';

import DataWithLoader from '@pro/web-common/components/data-with-loader';
import ProductConfigurator from '@pro/web-common/components/product-configurator';
import PageTitle from '@pro/web-common/components/page-title';
import LimitedAccessBadge from '@pro/web-common/components/limited-access-badge';

import { generateId } from '@pro/web-common/utils';

import {
  ADD_PRODUCT_TITLE,
  ADD_PRODUCT_SUBTITLE,
  ADD_PRODUCT_ADD_NEW_PRODUCTS_TEXT,
} from 'content/texts';
import { isTestVersion } from 'constants/product-config';


const AddProduct = React.memo(({ creatingState: { fetching }, createProduct, openModal, location: { state } }) => {
  const { brand } = useContext(BrandContext);

  const { canAddProduct } = useMemo(() => getSubscriptionPermissions(brand), [brand]);
  const productId = useMemo(() => generateId(), []);
  const showErrorModal = useCallback(({ errors, errorsTitle }) => {
    openModal({
      modal: ERROR_ALERT,
      params: { errors, errorsTitle },
    });
  }, [openModal]);

  const onSubmit = useCallback((data) => {
    createProduct({ ...data, productId });
  }, [createProduct, productId]);

  const { title } = state || {};

  return (
    <ProductIdContext.Provider value={productId}>
      <Container maxWidth="md">
        <DataWithLoader isLoaded={!isNil(brand)}>
          <Box p={2}>
            <PageTitle
              title={title || ADD_PRODUCT_TITLE}
              titlePostfix={!canAddProduct ? <LimitedAccessBadge /> : null}
              subtitle={ADD_PRODUCT_SUBTITLE}
            >
              {!canAddProduct && (
                <Typography color="error">
                  NOTE: You have to{' '}
                  <Link
                    component={NavLink}
                    color="inherit"
                    to="/admin-home"
                    underline="always"
                  >
                    {isTestVersion ? 'upgrade' : 'subscribe'}
                  </Link>
                  {' '}to {ADD_PRODUCT_ADD_NEW_PRODUCTS_TEXT}!
                </Typography>
              )}
            </PageTitle>

            <ProductConfigurator
              brandConfig={brand}
              fetching={fetching}
              onSubmit={onSubmit}
              isSubmitAvailable={canAddProduct}
              submitLabel="Next"
              onError={showErrorModal}
              isCreationMode
            />
          </Box>
        </DataWithLoader>
      </Container>
    </ProductIdContext.Provider>
  );
});

AddProduct.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }).isRequired,
  creatingState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  createProduct: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

AddProduct.defaultProps = {};


const mapStateToProps = (state) => ({
  creatingState: getProductCreatingState(state),
});

const mapDispatchToProps = {
  createProduct: productActions.createProduct,
  openModal: modalConductorActions.openModal,
};


export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
