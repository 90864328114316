import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import SectionItemContent from '@pro/web-common/components/section-item-content';
import SortableList from '@pro/web-common/components/sortable-list';
import SortableListItem from '@pro/web-common/components/sortable-list-item';


const SectionWithSortableSubsections = React.memo(({ sectionId, currencyId, data: { subsections, ...sectionData }, onSectionDataChange, onSubSectionDataChange, onSectionDelete, onSectionVisibilityChange, onSubSectionDelete, onSubSectionVisibilityChange, onAddSubsection, onSubsectionsSortEnd, expandedSectionId, toggleSection, sectionTitle, subsectionTitle, addSubsectionText, SectionContentEditor, SubsectionContentEditor, isSectionVisible, isSubItemsEnabled }) => {
  const renderedSubsections = useMemo(() => {
    if (!isSubItemsEnabled) {
      return null;
    }

    return (
      subsections.map(({ id: subsectionId, isVisible, ...rest }, index) => (
        <SortableListItem
          key={subsectionId}
          index={index}
          isInModal
        >
          <SectionItemContent
            titleLabel={subsectionTitle}
            data={{
              ...rest,
              currencyId,
            }}
            onDelete={() => onSubSectionDelete(subsectionId)}
            onVisibilityChange={onSubSectionVisibilityChange ? () => onSubSectionVisibilityChange(subsectionId) : null}
            isVisible={isVisible}
            onChange={(data) => onSubSectionDataChange(subsectionId, data)}
            isExpanded={subsectionId === expandedSectionId}
            toggleSection={() => toggleSection(subsectionId)}
            ContentEditor={SubsectionContentEditor}
          />
        </SortableListItem>
      ))
    );
  }, [subsections, subsectionTitle, onSubSectionDelete, onSubSectionVisibilityChange, onSubSectionDataChange, toggleSection, expandedSectionId, isSubItemsEnabled]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <SectionItemContent
        titleLabel={sectionTitle}
        data={sectionData}
        onDelete={onSectionDelete}
        onVisibilityChange={onSectionVisibilityChange}
        isVisible={isSectionVisible}
        onChange={onSectionDataChange}
        isExpanded={sectionId === expandedSectionId}
        toggleSection={() => toggleSection(sectionId)}
        ContentEditor={SectionContentEditor}
      />

      {
        isSubItemsEnabled && (
          <>
            <Box
              pl={[3, 8]}
            >
              <SortableList
                onSortEnd={onSubsectionsSortEnd}
                useDragHandle
                lockAxis="y"
              >
                {renderedSubsections}
              </SortableList>
            </Box>

            <Box
              mt={2}
              textAlign="right"
            >
              <Button
                variant="contained"
                color="default"
                startIcon={<AddIcon />}
                onClick={onAddSubsection}
              >
                {addSubsectionText}
              </Button>
            </Box>
          </>
        )
      }
    </Box>
  );
});

SectionWithSortableSubsections.propTypes = {
  sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.shape({
    subsections: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.shape({
        filePath: PropTypes.string,
      }),
      html: PropTypes.string,
    })),
  }),
  onSectionDataChange: PropTypes.func.isRequired,
  onSubSectionDataChange: PropTypes.func,
  onSectionDelete: PropTypes.func.isRequired,
  onSectionVisibilityChange: PropTypes.func,
  onSubSectionDelete: PropTypes.func,
  onSubSectionVisibilityChange: PropTypes.func,
  onAddSubsection: PropTypes.func,
  onSubsectionsSortEnd: PropTypes.func,
  expandedSectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  toggleSection: PropTypes.func.isRequired,
  sectionTitle: PropTypes.string,
  subsectionTitle: PropTypes.string,
  addSubsectionText: PropTypes.string,
  SectionContentEditor: PropTypes.shape({}),
  SubsectionContentEditor: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  isSectionVisible: PropTypes.bool,
  currencyId: PropTypes.string,
  isSubItemsEnabled: PropTypes.bool,
};

SectionWithSortableSubsections.defaultProps = {
  data: '',
  expandedSectionId: null,
  sectionTitle: 'Section Title',
  subsectionTitle: 'Sub Section Title',
  addSubsectionText: 'Add sub section',
  SectionContentEditor: null,
  SubsectionContentEditor: null,
  onSectionVisibilityChange: null,
  onSubSectionVisibilityChange: null,
  isSectionVisible: true,
  currencyId: '',
  isSubItemsEnabled: true,
  onSubsectionsSortEnd: () => null,
  onAddSubsection: () => null,
  onSubSectionDataChange: () => null,
  onSubSectionDelete: () => null,
};


export default SectionWithSortableSubsections;
