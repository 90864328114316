import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  expansionPanelExpanded: {
    margin: '0!important',
  },
  expansionPanelRoot: {
    boxShadow: 'none',
    marginBottom: theme.spacing(2),

    '&:before': {
      display: 'none',
    },
  },
  expansionPanelSummaryRoot: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(0, 2),

    '&, &.Mui-expanded': {
      minHeight: theme.spacing(3),
    },
  },
  expansionPanelSummaryContent: {
    '&, &.Mui-expanded': {
      margin: theme.spacing(1.5, 0),
    },
  },
  expansionPanelSummaryExpanded: {},
  expansionPanelSummaryExpandIcon: {
    padding: theme.spacing(0, 2),
  },
  expansionPanelDetailsRoot: {
    padding: theme.spacing(2, 0, 3),
  },
}));
