import { makeStyles } from '@material-ui/core/styles';

import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  fontPreview: ({ typeface, fontColor }) => ({
    fontFamily: typeface === 'default' ? theme.typography.fontFamily : typeface, // NOTE: that's for nice preview
    color: parseRgbaColor(fontColor),
    fontSize: '1.1rem',
  }),
}));
