import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
} from '@material-ui/core';

import {
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';

import { getUsers, getSignInAsUserState } from '@pro/web-common/core/admin/selectors';
import { actions as adminActions } from '@pro/web-common/core/admin/actions';

import PageTitle from '@pro/web-common/components/page-title';
import PaginatedList from '@pro/web-common/components/paginated-list';
import SearchTextField from '@pro/web-common/components/search-text-field';
import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';

import { styles } from './styles';


const SelectUser = React.memo(({ users, signInAsUser, signInAsUserState: { fetching }, updateAllStatistics }) => {
  const classes = styles({ fetching });

  const [searchStr, setSearchStr] = useState('');
  const filteredUsers = useMemo(() => users.filter(({ email }) => email.indexOf(searchStr) > -1), [searchStr, users]);

  const onLogin = useCallback(({ id }) => signInAsUser({ id }), [signInAsUser]);

  return (
    <Container maxWidth="md">
      <Grid
        container
        justify="space-between"
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <PageTitle
            title="Admin"
            subtitle="Select user to login."
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={9}
        >
          <Card>
            <CardContent>
              <Box mb={4}>
                <SearchTextField
                  label="Search by email"
                  placeholder="Email"
                  value={searchStr}
                  onChange={({ target: { value } }) => setSearchStr(value)}
                />
              </Box>

              <ComponentWithSpinner
                isSubmitting={fetching}
                progressSize={30}
              >
                <PaginatedList
                  data={filteredUsers}
                  renderListingItem={({ data: { id, email } }) => (
                    <Box
                      key={id}
                      className={classes.userContainer}
                      onClick={() => !fetching ? onLogin({ id }) : null}
                    >
                      <Typography noWrap>{email}</Typography>

                      <ExitToAppIcon />
                    </Box>
                  )}
                  noDataText="There are no users matching your search. Please adjust search text."
                />
              </ComponentWithSpinner>

              {/* <Button
                onClick={updateAllStatistics}
                color="primary"
              >
                Update all Statistics
              </Button> */}
            </CardContent>

          </Card>
        </Grid>
      </Grid>
    </Container>
  );
});

SelectUser.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  signInAsUser: PropTypes.func.isRequired,
  signInAsUserState: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  updateAllStatistics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  users: getUsers(state),
  signInAsUserState: getSignInAsUserState(state),
});

const mapDispatchToProps = {
  signInAsUser: adminActions.signInAsUser,
  updateAllStatistics: adminActions.updateAllStatistics,
};


export default connect(mapStateToProps, mapDispatchToProps)(SelectUser);
