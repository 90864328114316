import { createSelector } from 'reselect';


const getLocalState = (state) => state.managers;

export const getManagersList = createSelector(
  getLocalState,
  ({ list }) => list,
);

export const getFetching = createSelector(
  getLocalState,
  ({ fetching }) => fetching,
);

export const getManagerUpdatingState = createSelector(
  getLocalState,
  ({ updatingState: { fetching, error } }) => ({ fetching, error }),
);

export const getManagerCreatingState = createSelector(
  getLocalState,
  ({ creatingState: { fetching, error } }) => ({ fetching, error }),
);

export default {
  getFetching,
};
