import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import update from 'immutability-helper';

import {
  Button,
  TextField,
  Grid,
  Box,
} from '@material-ui/core';

import { withFormik } from 'formik';

import { ALL_PRODUCTS_OPTION } from '@pro/web-common/core/product/config';
import Checkbox from '@pro/web-common/components/checkbox';
import SectionTitle from '@pro/web-common/components/section-title';
import ComponentWithSpinner from '@pro/web-common/components/component-with-spinner';
import { findElementByProp } from '@pro/web-common/utils';

import { MANAGER_FORM_CHOOSE_ITEM_LABEL } from 'content/texts';

import { formValidationSchema } from './config';


const ManagerForm = React.memo(({ fetching, isError, onSubmit, onClose, isEdit, ...formikProps }) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setSubmitting,
    resetForm,
  } = formikProps;

  const setProductSelection = useCallback(({ index, checked, isAll }) => {
    let newProductsValues = [];

    if (isAll && checked) {
      newProductsValues = values.products.map((product) => ({ ...product, isChecked: true }));
    } else {
      const allIndex = values.products.findIndex(({ id }) => id === ALL_PRODUCTS_OPTION.id);

      newProductsValues = update(values.products, {
        [index]: {
          isChecked: { $set: checked },
        },
        [allIndex]: {
          isChecked: { $set: isAll ? checked : false },
        },
      });
    }

    setFieldTouched('products', true);
    setFieldValue('products', newProductsValues);
  }, [values.products]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!fetching) {
      setSubmitting(false);

      if (!isError) {
        resetForm();
      }
    }
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ComponentWithSpinner isSubmitting={isSubmitting}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              id="firstName"
              label="First Name"
              placeholder="First Name"
              name="firstName"
              InputLabelProps={{ shrink: true }}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.firstName && touched.firstName}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="lastName"
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
              InputLabelProps={{ shrink: true }}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.lastName && touched.lastName}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              placeholder="Email"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email}
              disabled={isEdit}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <SectionTitle
              title={MANAGER_FORM_CHOOSE_ITEM_LABEL}
              error={errors.products && touched.products}
            />

            <Grid
              container
              spacing={1}
            >
              {values.products.map(({ id, isChecked, productName }, index) => (
                <Grid
                  key={id}
                  item
                  xs={4}
                >
                  <Checkbox
                    id={id}
                    name={id}
                    label={productName}
                    value={isChecked}
                    onChange={({ target: { checked } }) => setProductSelection({
                      index,
                      checked,
                      isAll: id === ALL_PRODUCTS_OPTION.id,
                    })}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Box mr={1}>
                <Button
                  color="primary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isEdit ? 'Save' : 'Create'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </ComponentWithSpinner>
  );
});

ManagerForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

ManagerForm.defaultProps = {
  isError: false,
  isEdit: false,
};


export default withFormik({
  mapPropsToValues: ({ initialValues, products }) => {
    const {
      firstName = '',
      lastName = '',
      email = '',
    } = initialValues || {};

    const isAllChecked = !(products.findIndex(({ isChecked }) => !isChecked) > -1);
    const values = {
      firstName,
      lastName,
      email,
      products: products.concat([{
        id: ALL_PRODUCTS_OPTION.id,
        productName: ALL_PRODUCTS_OPTION.label,
        isChecked: isAllChecked,
      }]),
    };

    return values;
  },
  handleSubmit: (data, { props: { initialValues, products: initialProducts, onSubmit } }) => {
    const { id: managerId } = initialValues || {};
    const addToProducts = [];
    const deleteFromProducts = [];

    initialProducts.forEach(({ id, isChecked }) => {
      const modifiedProduct = findElementByProp(data.products, 'id', id);

      if (isChecked && !modifiedProduct.isChecked) {
        deleteFromProducts.push(id);
      }

      if (!isChecked && modifiedProduct.isChecked) {
        addToProducts.push(id);
      }
    });

    onSubmit(managerId, { addToProducts, deleteFromProducts, ...data });
  },
  validationSchema: () => formValidationSchema(),
  enableReinitialize: true,
})(ManagerForm);
