import { createActions } from 'redux-actions';

import { createConstants } from '@pro/web-common/utils';


export const constants = createConstants([
  'SIGN_IN',
  'SIGN_UP',
  'SIGN_OUT',
  'WATCH_USER',
  'VERIFY_EMAIL',
  'SEND_RESET_PASSWORD_LINK',
  'RESET_PASSWORD',
  'CONFIRM_RESET_PASSWORD',
  'LOGIN_DEMO',
  'UPDATE_EMAIL',
], 'USER');

export const actions = createActions({
  [constants.SIGN_IN]: ({ email, password }) => ({ email, password }),
  [constants.SIGN_UP]: ({ data }) => ({ data }),
  [constants.SIGN_OUT]: ({ withRedirectToSignUp } = {}) => ({ withRedirectToSignUp }),
  [constants.WATCH_USER]: null,
  [constants.VERIFY_EMAIL]: ({ actionCode }) => ({ actionCode }),
  [constants.SEND_RESET_PASSWORD_LINK]: ({ email, isProfile }) => ({ email, isProfile }),
  [constants.RESET_PASSWORD]: ({ actionCode }) => ({ actionCode }),
  [constants.CONFIRM_RESET_PASSWORD]: ({ newPassword }) => ({ newPassword }),
  [constants.LOGIN_DEMO]: null,
  [constants.UPDATE_EMAIL]: ({ email }) => ({ email }),
}, {
  prefix: 'USER',
});


export default actions;
