import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { constants } from './actions';


const defaultState = {
  modal: null,
  params: null,
};

export default handleActions({
  [constants.OPEN_MODAL]: (state, { payload: { modal, params } }) => (
    update(state, {
      modal: { $set: modal },
      params: { $set: params },
    })
  ),
  [constants.RESET_MODAL]: (state) => (
    update(state, { $set: defaultState })
  ),
}, defaultState);
