import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Collapse,
} from '@material-ui/core';

import AppIcon from '@pro/web-common/components/app-icon';
import MoreMenuItem from '@pro/web-common/components/more-menu-item';
import { colorPropsSchema, parseRgbaColor } from '@pro/web-common/utils/style';

import { getBottomBarIcons } from './utils';
import { styles } from './styles';


const AppPreviewNavigation = React.memo(({ backgroundColor, iconColor, iconActiveColor, pages, isInteractive, selectedPageId, onPageSelect, morePage, typeface }) => {
  const [isMoreOpened, setIsMoreOpened] = useState(false);

  const classes = styles({ backgroundColor });

  const { bottomBarIcons, moreMenuItemIcons } = useMemo(() => getBottomBarIcons(pages, morePage), [pages, morePage]);

  const handlePageSelect = useCallback((page) => {
    setIsMoreOpened(false);

    if (page.id === morePage.id) {
      setIsMoreOpened(!isMoreOpened);
    } else {
      onPageSelect(page);
    }
  }, [morePage, isMoreOpened, onPageSelect]);

  const isMoreSelected = useMemo(() => moreMenuItemIcons.findIndex(({ id }) => selectedPageId === id) > -1, [selectedPageId, moreMenuItemIcons]);

  return (
    <>
      {isMoreOpened && (
        <Box
          className={classes.backdrop}
          onClick={() => setIsMoreOpened(false)}
        />
      )}

      <Box className={classes.container}>
        {bottomBarIcons.map((page) => {
          const { id, label, ...rest } = page;

          return (
            <AppIcon
              key={id}
              label={label}
              typeface={typeface}
              color={parseRgbaColor(id === selectedPageId || (id === morePage.id && isMoreSelected) ? iconActiveColor : iconColor)}
              onClick={isInteractive ? () => handlePageSelect(page) : null}
              {...rest}
            />
          );
        })}

        {moreMenuItemIcons.length > 0 && (
          <Collapse
            className={classes.moreMenuContainer}
            in={isMoreOpened}
          >
            <Box>
              {moreMenuItemIcons.map((page) => {
                const { id, label, parentPageId, ...rest } = page;

                return !parentPageId && (
                  <MoreMenuItem
                    key={id}
                    label={label}
                    typeface={typeface}
                    color={parseRgbaColor(id === selectedPageId ? iconActiveColor : iconColor)}
                    onClick={isInteractive ? () => handlePageSelect(page) : null}
                    {...rest}
                  />
                );
              })}
            </Box>
          </Collapse>
        )}
      </Box>
    </>
  );
});


AppPreviewNavigation.propTypes = {
  backgroundColor: colorPropsSchema.isRequired,
  iconColor: colorPropsSchema.isRequired,
  iconActiveColor: colorPropsSchema.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    icon: PropTypes.shape({}),
    label: PropTypes.string,
  })).isRequired,
  selectedPageId: PropTypes.string.isRequired,
  isInteractive: PropTypes.bool,
  onPageSelect: PropTypes.func,
  morePage: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  typeface: PropTypes.string.isRequired,
};

AppPreviewNavigation.defaultProps = {
  isInteractive: false,
  onPageSelect: null,
};


export default AppPreviewNavigation;
