import memoize from 'lodash.memoize';
import { isNil, groupBy } from 'ramda';

import { dynamicLink, appStoreId, iosBundleId, apn, websiteDomain } from 'constants/app';
import { PAGE_TYPE, MIN_PAGES_PER_PRODUCT } from 'constants/product-config';
import {
  PRODUCT_CONFIGURATION_ITEM_NAME_REQUIRED_ERROR,
  PRODUCT_CONFIGURATION_PAGES_COUNT_ERROR,
} from 'content/texts';


const getAddedPageTypesCount = (pages) => {
  const addedPageTypesCount = {};

  pages.forEach(({ pageTypeId }) => {
    if (!addedPageTypesCount[pageTypeId]) {
      addedPageTypesCount[pageTypeId] = 0;
    }
    addedPageTypesCount[pageTypeId] += 1;
  });

  return addedPageTypesCount;
};

export const getPageTypeValues = ({ pages, isGetAll, hasSubscriptions }) => {
  const addedPageTypesCount = getAddedPageTypesCount(pages);
  const pageTypes = [];

  Object.values(PAGE_TYPE).forEach(({ id, label, limit, freeLimit }) => {
    if (isGetAll || !limit || !addedPageTypesCount[id] || addedPageTypesCount[id] < limit) {
      if (hasSubscriptions || isNil(freeLimit) || (!hasSubscriptions && !isNil(freeLimit) && addedPageTypesCount[id] < freeLimit)) {
        pageTypes.push({
          value: id,
          label,
        });
      }
    }
  });

  return pageTypes;
};

export const getEditorByPageType = memoize((pageTypeId) => (Object.values(PAGE_TYPE).find(({ id }) => pageTypeId === id) || {}).editor);
export const getInfoMessageByPageType = memoize((pageTypeId) => (Object.values(PAGE_TYPE).find(({ id }) => pageTypeId === id) || {}).infoKey);

const validateProductName = (name) => {
  const isValid = name.trim().length > 0;

  return ({
    isValid,
    error: PRODUCT_CONFIGURATION_ITEM_NAME_REQUIRED_ERROR,
  });
};

const validateProductPages = (pages) => {
  const isPagesCountValid = pages.length >= MIN_PAGES_PER_PRODUCT;

  return ({
    isValid: isPagesCountValid,
    error: PRODUCT_CONFIGURATION_PAGES_COUNT_ERROR,
  });
};

export const validateProductData = (productName, productPages) => {
  const { isValid: isNameValid, error: nameError } = validateProductName(productName);
  const { isValid: isPagesValid, error: pagesError } = validateProductPages(productPages);
  const errors = [];

  if (!isNameValid) {
    errors.push(nameError);
  }
  if (!isPagesValid) {
    errors.push(pagesError);
  }

  return ({
    isValid: isNameValid && isPagesValid,
    errors,
  });
};

export const groupPagesByType = groupBy(
  ({ parentPageId }) => {
    const isVersion = !isNil(parentPageId); // only version pages have parents
    return isVersion ? 'version' : 'original';
  },
);

export const getQrCodeLink = (productId) => `${dynamicLink}/?link=${websiteDomain}/product/${productId}&isi=${appStoreId}&ibi=${iosBundleId}&efr=1&apn=${apn}`;
