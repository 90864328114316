import FirebaseStorageService from '@pro/web-common/services/storage';
import { generateId } from '@pro/web-common/utils';

import { DEFAULT_METADATA } from './constants';


const FileService = {
  uploadFiles: (files = [], path) => {
    const promises = files.map((file) => {
      const id = generateId();
      return FileService.uploadFile(file, `${path}/${id}`);
    });
    return Promise.all(promises);
  },
  uploadFile: (file, path) => FirebaseStorageService.uploadFile({ file, path, metadata: DEFAULT_METADATA }),
};


export default FileService;
