import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: ({ isLast, underlineLast }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(!isLast || underlineLast ? {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
    } : {}),
    padding: theme.spacing(1, 0),
    width: '100%',
  }),
}));
