import { slice } from 'ramda';

import { BOTTOM_BAR_ICONS_LIMIT } from './config';


export const getBottomBarIcons = (pages, morePage) => {
  const navPages = pages.filter(({ parentPageId }) => !parentPageId);
  const bottomBarIcons = slice(0, BOTTOM_BAR_ICONS_LIMIT, navPages);
  const moreMenuItemIcons = slice(BOTTOM_BAR_ICONS_LIMIT, navPages.length, navPages);

  if (navPages.length > BOTTOM_BAR_ICONS_LIMIT) {
    bottomBarIcons.push(morePage);
  }

  return { bottomBarIcons, moreMenuItemIcons };
};
