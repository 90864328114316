import React from 'react';

import { Container, Grid, Box } from '@material-ui/core';

import PageTitle from '@pro/web-common/components/page-title';
import SupportSection from '@pro/web-common/components/support-section';

import { SUPPORT_ITEMS_FIRST_COLUMN, SUPPORT_ITEMS_SECOND_COLUMN } from 'content/support';
import meta from 'content/meta';


const Support = React.memo(() => (
  <>
    {meta.support}

    <Container maxWidth="md">
      <Box overflow="hidden">
        <PageTitle
          title="Support/FAQs"
          subtitle="Your questions. Answered."
          large
        />

        <Box mt={7}>
          <Grid
            container
            justify="space-between"
            spacing={10}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              {SUPPORT_ITEMS_FIRST_COLUMN.map(({ title, questions }, index) => (
                <SupportSection
                  key={index} // eslint-disable-line react/no-array-index-key
                  title={title}
                  questions={questions}
                />
              ))}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              {SUPPORT_ITEMS_SECOND_COLUMN.map(({ title, questions }, index) => (
                <SupportSection
                  key={index} // eslint-disable-line react/no-array-index-key
                  title={title}
                  questions={questions}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  </>
));

Support.propTypes = {};

Support.defaultProps = {};


export default Support;
