import { put, takeLatest, call, select, all, take, cancelled } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import { resolvedAction, rejectedAction } from '@pro/web-common/core/actions';
import history from '@pro/web-common/core/history';
import { subscribeSagaToUserSession } from '@pro/web-common/core/app/sagas';
import { constants as appConstants } from '@pro/web-common/core/app/actions';
import { getBrandId, getRole } from '@pro/web-common/core/user/selectors';
import { getBrand as getBrandData } from '@pro/web-common/core/brand/selectors';
import FileService from '@pro/web-common/core/file/service';
import { BRANDS_PATH, ENTERPRISE_DATA_PATH, DEMO_PREFIX } from '@pro/web-common/constants/storage';

import { actions as modalConductorActions } from '@pro/web-common/core/modal-conductor/actions';
import { ERROR_ALERT, SUCCESS_ALERT } from '@pro/web-common/containers/modal-conductor/constants';
import { generateId } from '@pro/web-common/utils';
import { getIsDemoUser } from '@pro/web-common/core/user/utils';

import { MODAL_MESSAGE } from 'content/modals';
import { ADD_FIRST_ITEM_TITLE } from 'content/texts';

import BrandService from './service';
import { extractDBModel, normalizeBrand } from './utils';
import { constants } from './actions';

/*
 * Sagas
 */
function* init () {
  yield all([
    call(subscribeSagaToUserSession, watchBrand),
  ]);
}

function* getWatchBrandChannel () {
  const brandId = yield select(getBrandId);

  return eventChannel((emit) => {
    const unsubscribe = BrandService.watchBrand((data) => emit({ data }), brandId);
    return unsubscribe;
  });
}

function* watchBrand () {
  const channel = yield call(getWatchBrandChannel);

  while (channel) {
    try {
      const { data } = yield take(channel);
      yield put(resolvedAction(constants.WATCH_BRAND, { data: normalizeBrand(data) }));
    } catch ({ message }) {
      yield put(rejectedAction(constants.WATCH_BRAND, { error: message }));
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* updateBrand ({ payload: { data } }) {
  try {
    const role = yield select(getRole);
    const isDemoUser = getIsDemoUser(role);
    const { isConfigured: isBrandConfigured } = yield select(getBrandData);
    const brandId = yield select(getBrandId);
    const { newLogoFile } = data;
    const isNewLogoFile = !!newLogoFile;
    const logoId = generateId();
    const logoRef = `${isDemoUser ? DEMO_PREFIX : ''}${BRANDS_PATH}/${logoId}`;
    let newLogoUrl = null;

    if (isNewLogoFile) {
      ({ url: newLogoUrl } = yield call(FileService.uploadFile, newLogoFile, logoRef));
    }

    const dbData = {
      ...extractDBModel(data),
      ...(newLogoUrl ? {
        logo: {
          ref: logoRef,
          url: newLogoUrl,
        },
      } : {}),
      isConfigured: true,
    };

    if (!isDemoUser) {
      yield call(BrandService.updateBrand, brandId, dbData);
    }

    yield put(resolvedAction(constants.UPDATE_BRAND, { data: dbData }));

    if (isBrandConfigured) {
      yield put(modalConductorActions.openModal({
        modal: SUCCESS_ALERT,
        params: { message: MODAL_MESSAGE.UPDATE_BRAND_SUCCESS },
      }));
    } else {
      history.push('/add-product', { title: ADD_FIRST_ITEM_TITLE });
    }
  } catch ({ message }) {
    yield put(modalConductorActions.openModal({
      modal: ERROR_ALERT,
      params: { message },
    }));

    yield put(rejectedAction(constants.UPDATE_BRAND, { error: message }));
  }
}

function* updateEnterpriseHomeData ({ payload: { image, text } }) {
  try {
    const brandId = yield select(getBrandId);
    const { file } = image || {};
    const isNewFile = !!file;
    const fileId = generateId();
    const fileRef = `${ENTERPRISE_DATA_PATH}/${fileId}`;
    let newFileUrl = null;

    if (isNewFile) {
      ({ url: newFileUrl } = yield call(FileService.uploadFile, image.file, fileRef));
    }

    const dbData = {
      ...(newFileUrl ? {
        enterpriseHomeImage: {
          ref: fileRef,
          url: newFileUrl,
        },
      } : {}),
      enterpriseHomeText: text,
    };

    yield call(BrandService.updateBrand, brandId, dbData);

    yield put(resolvedAction(constants.UPDATE_ENTERPRISE_HOME_DATA, { data: dbData }));

    yield put(modalConductorActions.openModal({
      modal: SUCCESS_ALERT,
      params: { message: MODAL_MESSAGE.UPDATE_ENTERPRISE_IMAGE_SUCCESS },
    }));
  } catch ({ message }) {
    yield put(rejectedAction(constants.UPDATE_ENTERPRISE_HOME_DATA, { error: message }));
  }
}

function* getBrand ({ payload: { brandId } }) {
  try {
    const data = yield call(BrandService.getBrand, brandId);

    yield put(resolvedAction(constants.GET_BRAND, { data: normalizeBrand(data) }));
  } catch (e) {
    yield put(rejectedAction(constants.GET_BRAND));
  }
}

/*
 * Watchers
 */
function* initWatcher () {
  yield take(appConstants.INIT);
  yield call(init);
}

function* updateBrandWatcher () {
  yield takeLatest(constants.UPDATE_BRAND, updateBrand);
}

function* updateEnterpriseHomeDataWatcher () {
  yield takeLatest(constants.UPDATE_ENTERPRISE_HOME_DATA, updateEnterpriseHomeData);
}

function* getBrandWatcher () {
  yield takeLatest(constants.GET_BRAND, getBrand);
}


export default [
  initWatcher,
  updateBrandWatcher,
  updateEnterpriseHomeDataWatcher,
  getBrandWatcher,
];
