import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles(() => ({
  container: ({ isClickable }) => ({
    ...(isClickable ? { cursor: 'pointer' } : {}),
  }),
  icon: ({ color }) => ({
    color,
    display: 'flex',
    alignItems: 'center',
    height: '24px',
  }),
  labelText: ({ typeface }) => ({
    maxWidth: '60px',
    fontSize: '0.65rem',
    fontFamily: typeface,
  }),
}));
