import memoize from 'lodash.memoize';

import { convertDBImageToFormImage } from '@pro/web-common/utils/image';

import { ROLES } from './constants';


export const normalizeUserData = ({ paymentMethods = null, image, ...data }) => ({
  ...data,
  paymentMethods,
  hasPaymentMethods: paymentMethods ? paymentMethods.length > 0 : false,
  ...(image ? { image: convertDBImageToFormImage(image.url) } : {}),
});

export const getIsOwner = memoize((role) => role === ROLES.OWNER || role === ROLES.DEMO || role === ROLES.SUPER_ADMIN);

export const getIsProfile = memoize((role) => role === ROLES.PROFILE);
export const getIsDemoUser = memoize((role) => role === ROLES.DEMO);
export const getIsSuperAdmin = memoize((role) => role === ROLES.SUPER_ADMIN);
