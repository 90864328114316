import { createSelector } from 'reselect';


const getLocalState = (state) => state.profile;

export const getProfilesList = createSelector(
  getLocalState,
  ({ list }) => list,
);

export const getProfilesCollection = createSelector(
  getLocalState,
  ({ list }) => list.reduce((result, profile) => {
    result[profile.id] = profile; // eslint-disable-line no-param-reassign
    return result;
  }, {}),
);

export const getProfilesLimitsList = createSelector(
  getLocalState,
  ({ limits }) => limits,
);

export const getProfilesCollectionWithLimits = createSelector(
  getProfilesList,
  getProfilesLimitsList,
  (list, limitsList) => list.reduce((result, profile) => {
    const limits = limitsList.find(({ profileId }) => profileId === profile.id) || {};
    result[profile.id] = { // eslint-disable-line no-param-reassign
      ...limits,
      ...profile,
      limitsId: limits.id,
      profileId: profile.id,
    };
    return result;
  }, {}),
);

export const getProfilesLimitsCollectionByProductId = createSelector(
  getLocalState,
  ({ limits }) => limits.reduce((result, limit) => {
    result[limit.productId] = limit; // eslint-disable-line no-param-reassign
    return result;
  }, {}),
);

export const getProfileLimitsByProfileIdAndProductId = (profileId, productId) => createSelector(
  getProfilesLimitsList,
  (limitsList) => limitsList.find((limit) => limit.profileId === profileId && limit.productId === productId),
);
