import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    width: '100%',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
}));
