import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
} from '@material-ui/core';

import { styles } from './styles';


const UrlPageContentPreview = React.memo(({ url }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <iframe
        title="URL Preview"
        width="100%"
        height="100%"
        src={url}
      />
    </Box>
  );
});

UrlPageContentPreview.propTypes = {
  url: PropTypes.string.isRequired,
};

UrlPageContentPreview.defaultProps = {};


export default UrlPageContentPreview;
