import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import Image from 'react-image';

import {
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { FREQUENCY_VALUES } from '@pro/web-common/core/messages/constants';
import { UserRoleContext } from '@pro/web-common/containers/providers';
import { formatDateAndTime } from '@pro/web-common/utils/date';

import { FREQUENCY_LABELS } from './constants';
import { styles } from './styles';


const MessageListingItem = React.memo(({ image, title, startDate, endDate, frequency, rrule, messageSentDate, onEdit, onDelete, isDeleting, profile, profileId }) => {
  const classes = styles();
  const { isProfile } = useContext(UserRoleContext);

  const startDateLabel = useMemo(() => {
    const date = new Date(startDate);
    const formattedDate = formatDateAndTime(date);

    if (rrule) {
      return `Publish start date: ${formattedDate}`;
    }

    if (new Date(startDate) > new Date()) {
      return `Will be sent at ${formattedDate}`;
    }

    return `Was sent at ${formattedDate}`;
  }, [startDate, rrule]);

  const regularMessageLabel = useMemo(() => {
    if (!rrule) {
      return null;
    }

    const formattedDate = formatDateAndTime(messageSentDate);
    if (messageSentDate > new Date()) {
      return `Next message will be sent at ${formattedDate}`;
    }

    return `Last message was sent at ${formattedDate}`;
  }, [rrule, endDate]);

  const endDateLabel = useMemo(() => {
    if (!endDate) {
      return null;
    }

    return `Publish end date: ${formatDateAndTime(new Date(endDate))}`;
  }, [endDate]);

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        {image && (
          <Box className={classes.imageContainer}>
            <Image
              src={image.url}
              alt="message-hero"
            />
          </Box>
        )}

        <Box
          className={classes.textContainer}
        >
          <Typography
            variant="body2"
            gutterBottom
          >
            {title}
          </Typography>

          {
            regularMessageLabel && (
              <Typography
                variant="caption"
                component="p"
              >
                {regularMessageLabel}
              </Typography>
            )
          }

          <Typography
            variant="caption"
            component="p"
          >
            {startDateLabel}
          </Typography>

          {
            endDateLabel && (
              <Typography
                variant="caption"
                component="p"
              >
                {endDateLabel}
              </Typography>
            )
          }

          {
            frequency && (
              <Typography
                variant="caption"
                component="p"
              >
                Frequency: {FREQUENCY_LABELS[frequency]}
              </Typography>
            )
          }

          {
            profileId && !isProfile && (
              <Typography
                variant="caption"
                component="p"
              >
              Sent by {profile ? profile.title : 'Local Profile'}
              </Typography>
            )
          }
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
      >
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>

        <IconButton
          onClick={onDelete}
          disabled={isDeleting}
        >
          <DeleteIcon color="secondary" />
        </IconButton>
      </Box>
    </Box>
  );
});

MessageListingItem.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  messageSentDate: PropTypes.instanceOf(Date).isRequired,
  frequency: PropTypes.oneOf(Object.values(FREQUENCY_VALUES)),
  rrule: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  profile: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  profileId: PropTypes.string,
};

MessageListingItem.defaultProps = {
  isDeleting: false,
  profile: null,
  profileId: null,
  endDate: null,
  rrule: null,
  frequency: null,
};


export default MessageListingItem;
