import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';

import { withFormik } from 'formik';

import { DEMO_PREFIX, PRODUCTS_PATH } from '@pro/web-common/constants/storage';
import { ProductIdContext, UserRoleContext } from '@pro/web-common/containers/providers';

import CKEditorCustomized from '@pro/web-common/components/ck-editor-customized';
import LargeDialog from '@pro/web-common/components/large-dialog';
import PageLinkCopy from '@pro/web-common/components/page-link-copy';

import { RTF_PAGE_EDITOR_TITLE } from 'content/texts';


const RtfPageEditor = React.memo(({ isOpened, onClose, onSubmit, isSubmitDisabled, pages, currentPageId, ...formikProps }) => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formikProps;

  const { isDemo } = useContext(UserRoleContext);
  const productId = useContext(ProductIdContext);

  useEffect(() => {
    if (isOpened) {
      resetForm();
    }
  }, [resetForm, isOpened]);

  return (
    <LargeDialog
      open={isOpened}
      onClose={onClose}
      disableEnforceFocus
    >
      <DialogTitle id="alert-dialog-title">
        {RTF_PAGE_EDITOR_TITLE}
      </DialogTitle>

      {
        pages.length && (
          <Box
            pl={3}
            pr={3}
          >
            <PageLinkCopy
              pages={pages}
              currentPageId={currentPageId}
            />
          </Box>
        )
      }

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box
            pt={2}
            pb={1}
          >
            <CKEditorCustomized
              data={values.html}
              onChange={(data) => setFieldValue('html', data)}
              imagesPathPrefix={`${isDemo ? DEMO_PREFIX : ''}${PRODUCTS_PATH}/${productId}`}
            />
          </Box>

          <Box
            mt={1}
            textAlign="right"
          >
            <Button
              type="submit"
              size="small"
              color="primary"
              variant="contained"
              disabled={isSubmitDisabled}
            >
              Save
            </Button>
          </Box>
        </form>
      </DialogContent>
    </LargeDialog>
  );
});

RtfPageEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape(({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }))).isRequired,
  currentPageId: PropTypes.string.isRequired,
};

RtfPageEditor.defaultProps = {};


export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    const {
      html = null,
    } = initialValues || {};

    const values = {
      html,
    };

    return values;
  },
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values),
  enableReinitialize: true,
})(RtfPageEditor);
