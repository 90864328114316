import { makeStyles } from '@material-ui/core/styles';
import { parseRgbaColor } from '@pro/web-common/utils/style';


export const styles = makeStyles((theme) => ({
  itemContainer: ({ typeface, fontColor }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    fontFamily: typeface,
    color: parseRgbaColor(fontColor),
  }),
}));
