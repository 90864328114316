import { select, take, cancel, fork, race } from 'redux-saga/effects';

import { getUserUID, getBrandId, getRole } from '@pro/web-common/core/user/selectors';
import { constants as userConstants } from '@pro/web-common/core/user/actions';
import { constants as adminConstants } from '@pro/web-common/core/admin/actions';
import { getIsDemoUser, getIsProfile } from '@pro/web-common/core/user/utils';

import { resolved } from '@pro/web-common/core/actions';


/*
 * Sagas
 */

export function* subscribeSagaToUserSession (saga, shouldWaitForBrandId = true, availableForProfile = false) {
  while (true) {
    const id = yield select(getUserUID);

    if (!id) {
      yield take(resolved(userConstants.SIGN_IN));
    }

    const brandId = yield select(getBrandId);
    const role = yield select(getRole);
    const isDemoUser = getIsDemoUser(role);
    const isProfileUser = getIsProfile(role);

    if (!brandId && shouldWaitForBrandId && !isProfileUser) {
      const [skipSaga] = yield race([take(resolved(userConstants.SIGN_OUT)), take(resolved(adminConstants.SIGN_IN_AS_USER))]);
      if (skipSaga) {
        continue;
      }
    }
    if ((!isDemoUser && !isProfileUser) || (isProfileUser && availableForProfile)) {
      const task = yield fork(saga);
      yield take([resolved(userConstants.SIGN_OUT), resolved(adminConstants.SIGN_OUT_FROM_USER)]);
      yield cancel(task);
    } else {
      yield take(resolved(userConstants.SIGN_OUT));
    }
  }
}

export function* subscribeSagaToProfileSession (saga) {
  while (true) {
    const id = yield select(getUserUID);

    if (!id) {
      yield take(resolved(userConstants.SIGN_IN));
    }

    const role = yield select(getRole);
    const isProfileUser = getIsProfile(role);
    if (!isProfileUser) {
      yield race([take(resolved(userConstants.SIGN_OUT)), take(resolved(adminConstants.SIGN_IN_AS_USER))]);
      continue;
    }

    const task = yield fork(saga);
    yield take([resolved(userConstants.SIGN_OUT), resolved(adminConstants.SIGN_OUT_FROM_USER)]);
    yield cancel(task);
  }
}

export default [];
