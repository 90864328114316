import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
} from '@material-ui/core';

import { CURRENCY_LIST } from 'constants/currency';
import { styles } from './styles';


const CurrencySelectorHome = React.memo(({ currencyId, onChange }) => {
  const classes = styles();

  return (
    <>
      {Object.entries(CURRENCY_LIST).map(([id, { sign }]) => (
        <Button
          className={`${classes.button} ${currencyId === id ? classes.buttonActive : ''}`}
          disableRipple
          disableFocusRipple
          key={id}
          color="primary"
          onClick={() => onChange(id)}
        >
          {sign}
        </Button>
      ))}
    </>
  );
});


CurrencySelectorHome.propTypes = {
  currencyId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CurrencySelectorHome.defaultProps = {};


export default CurrencySelectorHome;
