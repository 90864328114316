import { makeStyles } from '@material-ui/core/styles';


export const styles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  imageContainer: {
    width: '120px',
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },

    '& > img': {
      maxWidth: '100%',
    },
  },
  textContainer: {
    flex: '1',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));
