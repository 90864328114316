import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import {
  Box,
  Collapse,
} from '@material-ui/core';

import { ProductIdContext, UserRoleContext } from '@pro/web-common/containers/providers';
import { DEMO_PREFIX, PRODUCTS_PATH } from '@pro/web-common/constants/storage';

import CKEditorCustomized from '@pro/web-common/components/ck-editor-customized';
import SingleImageUploader from '@pro/web-common/components/single-image-uploader';

import { styles } from './styles';


const ManualSectionContentEditor = React.memo(({ isOpened, image, html, onChange }) => {
  const { isDemo } = useContext(UserRoleContext);
  const productId = useContext(ProductIdContext);
  const classes = styles();

  const [handleContentChange] = useDebouncedCallback((data) => {
    onChange({ html: data });
  }, 100);

  return (
    <Collapse
      in={isOpened}
      mountOnEnter
      unmountOnExit
    >
      <Box className={classes.container}>
        <SingleImageUploader
          image={image}
          onImageDelete={() => onChange({ image: null })}
          onImageAdd={(fileData) => onChange({ image: fileData })}
        />

        <Box className={classes.editorContainer}>
          <CKEditorCustomized
            data={html}
            onChange={handleContentChange}
            imagesPathPrefix={`${isDemo ? DEMO_PREFIX : ''}${PRODUCTS_PATH}/${productId}`}
          />
        </Box>
      </Box>
    </Collapse>
  );
});

ManualSectionContentEditor.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    filePath: PropTypes.string,
  }),
  html: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ManualSectionContentEditor.defaultProps = {
  image: null,
  html: '',
};


export default ManualSectionContentEditor;
