import { isNil, pick } from 'ramda';

import { PAGE_TYPE } from 'constants/product-config';
import { convertDBImageToFormImage } from '@pro/web-common/utils/image';


const getImagePageTypeFiles = ({ files = [] }) => files.filter(({ file }) => !isNil(file)).map(({ file }) => file);

const getFileFromFormData = ({ image }) => {
  if (image) {
    const { file } = image;
    return file ? [file] : [];
  }

  return [];
};

const getFilesFromSection = ({ subsections = [], ...rest }) => {
  let files = getFileFromFormData(rest);

  if (subsections) {
    subsections.forEach(({ image }) => {
      files = files.concat(getFileFromFormData({ image }));
    });
  }

  return files;
};

const getManualPageTypeFiles = ({ sections = [] }) => {
  let files = [];

  sections.forEach((section) => {
    const sectionFiles = getFilesFromSection(section);

    files = files.concat(sectionFiles);
  });

  return files;
};

export const getPagesFiles = (pages) => {
  let files = [];

  pages.forEach(({ pageTypeId, content }) => {
    if (pageTypeId === PAGE_TYPE.IMAGE.id) {
      files = files.concat(getImagePageTypeFiles(content));
    }

    if ((PAGE_TYPE.MANUAL && pageTypeId === PAGE_TYPE.MANUAL.id) || (PAGE_TYPE.MENU && pageTypeId === PAGE_TYPE.MENU.id) || (PAGE_TYPE.TIMETABLE && pageTypeId === PAGE_TYPE.TIMETABLE.id)) {
      files = files.concat(getManualPageTypeFiles(content));
    }
  });

  return files;
};

const transformManualPageTypeSectionImage = (section, newFirebaseFile, counter) => {
  const { image, ...rest } = section;
  let newSectionData = section;
  let newCounter = counter;

  if (image) {
    const { file, filePath } = image;
    const isNewFile = !!file;

    newSectionData = ({
      image: isNewFile ? newFirebaseFile.url : filePath,
      ...rest,
    });

    if (isNewFile) {
      newCounter += 1;
    }
  }

  return ({
    newSectionData,
    newCounter,
  });
};

const transformManualPageTypeContent = ({ sections = [], currencyId }, filesPaths) => {
  let filesCount = 0;

  return ({
    sections: (
      sections.map((section) => {
        const transformedSectionData = transformManualPageTypeSectionImage(section, filesPaths[filesCount], filesCount);
        filesCount = transformedSectionData.newCounter;
        const { newSectionData: newSection } = transformedSectionData;

        const newSubsections = section.subsections?.map((subsection) => {
          const transformedSubsectionData = transformManualPageTypeSectionImage(subsection, filesPaths[filesCount], filesCount);
          filesCount = transformedSubsectionData.newCounter;
          const { newSectionData: newSubsection } = transformedSubsectionData;

          // TODO: move this fix to the mobile app data parser
          return {
            ...newSubsection,
            ...(currencyId ? { currencyId } : {}),
          };
        }) || [];

        return ({
          ...newSection,
          subsections: newSubsections,
        });
      })
    ),
    filesCount,
  });
};

const transformImagePageTypeContent = ({ files = [] }, filesPaths) => {
  let counter = 0;

  if (filesPaths === 0) {
    return ({
      files: files.map(({ filePath }) => filePath),
    });
  }

  const newFiles = files.map(({ file, filePath }) => {
    if (isNil(file)) {
      return filePath;
    }

    counter += 1;
    return filesPaths[counter - 1].url;
  });

  return ({
    files: newFiles,
  });
};

export const normalizePagePaths = (pages, filesPaths) => Promise.all(
  pages.map(async (page) => {
    const { pageTypeId, content, ...rest } = page;

    if (pageTypeId === PAGE_TYPE.IMAGE.id) {
      const filesCount = content.files ? content.files.filter(({ file }) => !isNil(file)).length : 0;
      const pageFiles = filesPaths.splice(0, filesCount);

      return ({
        pageTypeId,
        content: transformImagePageTypeContent(content, pageFiles),
        ...rest,
      });
    }

    if ((PAGE_TYPE.MANUAL && pageTypeId === PAGE_TYPE.MANUAL.id) || (PAGE_TYPE.MENU && pageTypeId === PAGE_TYPE.MENU.id) || (PAGE_TYPE.TIMETABLE && pageTypeId === PAGE_TYPE.TIMETABLE.id)) {
      const { sections: newSections, filesCount: newFilesCount } = transformManualPageTypeContent(content, filesPaths);
      filesPaths.splice(0, newFilesCount);

      return ({
        pageTypeId,
        content: {
          ...content,
          sections: newSections,
        },
        ...rest,
      });
    }

    if (PAGE_TYPE.LOCAL_DIRECTORY && pageTypeId === PAGE_TYPE.LOCAL_DIRECTORY.id && Object.keys(page.content).length === 0) {
      return ({
        pageTypeId,
        content: {
          directories: [],
        },
        ...rest,
      });
    }

    return page;
  }),
);

const normalizeDBSubsectionData = (subsection) => {
  const { image } = subsection;

  return ({
    ...subsection,
    image: image ? convertDBImageToFormImage(image) : image,
  });
};

const normalizeDBSectionData = (section) => {
  const { image, subsections } = section;

  return ({
    ...section,
    image: image ? convertDBImageToFormImage(image) : image,
    subsections: subsections?.map(normalizeDBSubsectionData) || [],
  });
};

const normalizeDBPagesData = (pages) => {
  const normalizedPages = (
    pages.map((page) => {
      const { pageTypeId, content: { files = [], sections = [], ...restContentProps }, ...rest } = page;

      if (pageTypeId === PAGE_TYPE.IMAGE.id) {
        return ({
          ...page,
          content: ({
            files: files.map(convertDBImageToFormImage),
            ...restContentProps,
          }),
        });
      }

      if ((PAGE_TYPE.MANUAL && pageTypeId === PAGE_TYPE.MANUAL.id) || (PAGE_TYPE.MENU && pageTypeId === PAGE_TYPE.MENU.id) || (PAGE_TYPE.TIMETABLE && pageTypeId === PAGE_TYPE.TIMETABLE.id)) {
        return ({
          pageTypeId,
          content: {
            sections: sections.map(normalizeDBSectionData),
            ...restContentProps,
          },
          ...rest,
        });
      }

      return page;
    })
  );

  return normalizedPages;
};

const PRODUCT_MODEL = [
  'id',
  'isLimited',
  'isVisible',
  'pages',
  'productName',
  'address',
  'pushMessagesRadius',
  'tags',
  'users',
  'shortLink',
  'brandId',
];

export const normalizeProduct = ({ pages, ...data }) => ({
  ...pick(PRODUCT_MODEL, data),
  pages: normalizeDBPagesData(pages),
});

export const normalizeProducts = (data) => data.map(normalizeProduct);

export const getLocalDirectoryProfileIds = (pages) => {
  if (!PAGE_TYPE.LOCAL_DIRECTORY) {
    return null;
  }

  return pages.filter(({ pageTypeId }) => pageTypeId === PAGE_TYPE.LOCAL_DIRECTORY.id)
    .map(({ content: { directories = [] } = {} }) => directories.map(({ profiles }) => profiles)).flat(2);
};
