import FirebaseDatabaseService from '@pro/web-common/services/database';
import FirebaseFunctionsService from '@pro/web-common/services/functions';

import { MESSAGES_NODE, MESSAGES_TAGS_NODE, QUICK_MESSAGES_NODE, TRACK_TRACE_MESSAGES_NODE, CLOUD_FUNCTIONS } from './constants';


const MessagesService = {
  getMessages: (...params) => FirebaseDatabaseService.getData({
    refPath: MESSAGES_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    whereParams: params,
  }),
  getQuickMessages: (...params) => FirebaseDatabaseService.getData({
    refPath: QUICK_MESSAGES_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    whereParams: params,
  }),
  getTrackTraceMessages: (...params) => FirebaseDatabaseService.getData({
    refPath: TRACK_TRACE_MESSAGES_NODE,
    refType: FirebaseDatabaseService.REF_TYPES.COLLECTION,
    whereParams: params,
  }),
  createMessage: (id, data) => FirebaseDatabaseService.addData(`${MESSAGES_NODE}/${id}`, FirebaseDatabaseService.REF_TYPES.DOC, false, data),
  createQuickMessage: (id, data) => FirebaseDatabaseService.addData(`${QUICK_MESSAGES_NODE}/${id}`, FirebaseDatabaseService.REF_TYPES.DOC, false, data),
  createTrackTraceMessage: (id, data) => FirebaseDatabaseService.addData(`${TRACK_TRACE_MESSAGES_NODE}/${id}`, FirebaseDatabaseService.REF_TYPES.DOC, false, data),
  deleteMessage: (id) => FirebaseDatabaseService.deleteDoc(`${MESSAGES_NODE}/${id}`),
  updateMessage: (id, data) => FirebaseDatabaseService.updateDoc(`${MESSAGES_NODE}/${id}`, data),
  deleteQuickMessage: (id) => FirebaseDatabaseService.deleteDoc(`${QUICK_MESSAGES_NODE}/${id}`),
  deleteTrackTraceMessage: (id) => FirebaseDatabaseService.deleteDoc(`${TRACK_TRACE_MESSAGES_NODE}/${id}`),

  getTags: (id) => FirebaseDatabaseService.getData({
    refPath: `${MESSAGES_TAGS_NODE}/${id}`,
    refType: FirebaseDatabaseService.REF_TYPES.DOC,
  }),
  getTagsForBrands: (brands) => Promise.all(brands.map((id) => MessagesService.getTags(id))),
  updateTags: (id, data) => FirebaseDatabaseService.addData(`${MESSAGES_TAGS_NODE}/${id}`, FirebaseDatabaseService.REF_TYPES.DOC, false, data),

  generateUsersReport: (data) => FirebaseFunctionsService.call(CLOUD_FUNCTIONS.GENERATE_USERS_REPORT, data),
};


export default MessagesService;
