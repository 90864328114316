import LinkedInIcon from 'assets/images/social-icons/linkedIn.png';
import FacebookIcon from 'assets/images/social-icons/facebook.png';


export const SOCIAL_LINKS = [
  {
    id: 'linkedIn',
    href: 'https://www.linkedin.com/',
    icon: LinkedInIcon,
  },
  {
    id: 'facebook',
    href: 'https://www.facebook.com/PcktConcierge',
    icon: FacebookIcon,
  },
];
