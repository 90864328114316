import * as Yup from 'yup';


export const logoFormats = [
  'image/png',
  'image/x-png',
];

export const formValidationSchema = (withLogo) => Yup.object().shape({
  organisationName: Yup.string().required(),
  ...(withLogo ? {
    logoImage: Yup.string().required(),
    newLogoFile: Yup.mixed()
      .nullable()
      .test(
        'fileFormat',
        'Unsupported Format',
        (value) => value ? logoFormats.includes(value.type) : true,
      ),
  } : {}),
});

export const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

export const DEFAULT_RTF_COLOR = {
  r: 84,
  g: 84,
  b: 84,
  a: 1,
};
